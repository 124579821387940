import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClientRoleEnum } from '../type/types';

// Define a type for the slice state

interface inviteMemberType {
  contactFilter: { [key: string]: any };
}

// Define the initial state using that type
const initialState: inviteMemberType = {
  contactFilter: {
    role: ClientRoleEnum.guest,
  },
};

export const inviteMemberSlice = createSlice({
  name: 'inviteMember',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    updateInviteMemberContactFilter: (
      state,
      action: PayloadAction<{ [key: string]: any }>
    ) => {
      state.contactFilter = { ...action.payload };
    },
  },
});

export const { updateInviteMemberContactFilter } = inviteMemberSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value;

export default inviteMemberSlice.reducer;
