import React from 'react';
import { avatarColorSet } from '../component/Avatar/constant';

interface Props {
  children: React.ReactNode;
}

export const UserUtilsDetailContext = React.createContext<{
  profile_color: string;
} | null>(null);

export const UserUtilsDetailContextComponent = (props: Props) => {
  const { children } = props;

  return (
    <UserUtilsDetailContext.Provider
      value={{
        profile_color:
          avatarColorSet[Math.floor(Math.random() * avatarColorSet.length)],
      }}
    >
      {children}
    </UserUtilsDetailContext.Provider>
  );
};
