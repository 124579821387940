import { useAbility } from '@casl/react';
import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { batch } from 'react-redux';
import { updateAbility } from '../authorization/Policies/AuthorizationPolicyManager';
import { AbilityContext } from '../Context/AppAbilityContext';
import { useAppDispatch } from '../store/hooks';
import { addProjects, addWorkspaces } from '../store/socket';
import {
  setSelectedWorkspace,
  setSelectedWorkspaceRole,
  setUserAuthToken,
  setWorkspaceNotification,
} from '../store/user';
import { ClientRoleEnum, CustomRQResponseType } from '../type/types';
import { baseUrl } from '../utils/config';

export const getUserDataOnLogin = async (token: string) => {
  const { data } = await axios.get<any, CustomRQResponseType>(
    `${baseUrl}/utils/appdetail`,
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return data;
};

export const getWorkspaceDataOnLogin = async (
  token: string,
  workspace_id: string
) => {
  const { data } = await axios.get<any, CustomRQResponseType>(
    `${baseUrl}/workspace/${workspace_id}`,
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return data;
};

const getWorkspaceData = async (workspace_id: string) => {
  const { data } = await axios.get<any, CustomRQResponseType>(
    `${baseUrl}/workspace/${workspace_id}`,
    {
      headers: {
        Authorization: localStorage.getItem('p-auth') || 'TOKEN',
      },
    }
  );
  return data;
};

export const useWorkspaceUserData = <T extends any = CustomRQResponseType>(
  workspace_id: string,
  options?: UseQueryOptions<T, unknown, CustomRQResponseType>
) => {
  return useQuery<any>(
    ['workspace-data', workspace_id],
    () => getWorkspaceData(workspace_id),
    {
      retry: 1,
      ...options,
    }
  );
};

const getUserData = async () => {
  const { data } = await axios.get<any, CustomRQResponseType>(
    `${baseUrl}/utils/appdetail`,
    {
      headers: {
        Authorization: localStorage.getItem('p-auth') || 'TOKEN',
      },
    }
  );
  return data;
};

export const useUserData = <T extends any = CustomRQResponseType>(
  options?: UseQueryOptions<T, unknown, CustomRQResponseType>
) => {
  const dispatch = useAppDispatch();
  const ability = useAbility(AbilityContext);

  return useQuery<any>('userData', () => getUserData(), {
    retry: 1,
    onSuccess: (data) => {
      //Set the notification

      if (data && data?.data && data?.data?.workspace_notification) {
        const map = {} as any;
        data?.data?.workspace_notification.forEach((el: any) => {
          map[el.workspace_id] = el.total_count;
        });
        dispatch(setWorkspaceNotification(map));
      }

      const connectRooms =
        data?.data && data?.data?.connects ? data?.data?.connects : [];

      const workspace = data?.data?.workspace
        ? data?.data?.workspace.map((el: any) => el.workspace)
        : [];

      let selectedWorkspaceId = workspace[0].id;

      const userSelectedWorkspace = localStorage.getItem(
        `psw_${data?.data?.id}`
      );
      if (userSelectedWorkspace) {
        selectedWorkspaceId = userSelectedWorkspace;
      } else {
        localStorage.setItem(`psw_${data?.data?.id}`, selectedWorkspaceId);
      }

      const selectedWorkspaceUserRoleData = data?.data?.workspace
        ? data?.data?.workspace.filter(
            (el: any) => el.workspace.id === selectedWorkspaceId
          )
        : [];

      const role =
        selectedWorkspaceUserRoleData &&
        selectedWorkspaceUserRoleData.length > 0
          ? selectedWorkspaceUserRoleData[0].user_workspace_role
          : ClientRoleEnum.editor;

      batch(() => {
        if (workspace && workspace.length > 0) {
          const workspaceIds = workspace.map((el: any) => el.id);
          dispatch(addWorkspaces([...workspaceIds]));
          dispatch(setSelectedWorkspaceRole(role));
          dispatch(setSelectedWorkspace(selectedWorkspaceId));
        }
        if (connectRooms && connectRooms.length > 0) {
          dispatch(addProjects([...connectRooms]));
        }
      });
      updateAbility(ability, role, data?.data);
    },
    onError: (err) => {
      console.log(err, 'error');
      localStorage.removeItem('p-auth');
      dispatch(setUserAuthToken(undefined));
      if (window.location.pathname !== `/login`) {
        window.location.reload();
      }
      // Window is used because useNavigatin / useLocation can only be used withib the router context
    },
    ...options,
  });
};
