import { Box, Center, CircularProgress } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useState } from 'react';
import useProgressiveImg from '../../../../hooks/useProgressiveImg.hook';
import { imageCDNBaseUrl } from '../../../../utils/config';
import { getRandomInt } from '../../../../utils/utility';

interface Props {
  url: string;
  containerWidth: number;
  yPosition: number;
  enableHover?: boolean;
}

let current_progress = 0;
let step = 0.4;

const _VideoPreviewComponent = (props: Props, ref: any) => {
  const [progress, setProgress] = useState<number>(0);
  const [videoPointerPosition, updateVideoPointerPosition] =
    useState<number>(0);
  const randomInt = getRandomInt(40, 90);
  const lowSpeedInt = getRandomInt(30, 50);

  const [showRuler, updateShowRuler] = useState<boolean>(false);

  const { url, containerWidth, yPosition, enableHover = true } = props;

  const [src, blur] = useProgressiveImg({
    lowQualitySrc: `${imageCDNBaseUrl}/?url=${url}&h=176&w=176&dpr=1&l=9&output=webp`,
    highQualitySrc: url,
    enabled: true,
  });

  const progressCountInit = () => {
    let interval = setInterval(function () {
      current_progress += step;
      let progress =
        Math.round((Math.atan(current_progress) / (Math.PI / 2)) * 100 * 1000) /
        1000;

      setProgress(progress);
      if (progress >= randomInt) {
        setProgress(90);
        clearInterval(interval);
      } else if (progress >= lowSpeedInt) {
        step = 0.15;
      }
    }, 100);
  };

  useEffect(() => {
    progressCountInit();
  }, []);

  useEffect(() => {
    if (blur) {
      setProgress(100);
    }
  }, [blur]);

  return (
    <Center
      height="100%"
      width="100%"
      // borderTopRadius="6"
      backgroundImage={src as string}
      backgroundRepeat="no-repeat"
      backgroundSize={'2000%'}
      pos="relative"
      overflow={'hidden'}
      cursor="pointer"
      backgroundPosition={`${
        -parseInt(String(videoPointerPosition / 20)) * containerWidth
          ? -parseInt(String(videoPointerPosition / 20)) * containerWidth
          : 0
      }px ${yPosition}px`}
      onMouseEnter={() => {
        updateShowRuler(true);
      }}
      onMouseLeave={() => {
        updateShowRuler(false);
      }}
      onMouseMove={(e: any) => {
        if (enableHover && ref?.current) {
          updateVideoPointerPosition(
            Number(
              (e.clientX - ref.current.getBoundingClientRect().left).toFixed()
            )
          );
        }
      }}
    >
      {blur && (
        <CircularProgress
          color="primary.presentable"
          thickness={'2px'}
          pos={'absolute'}
          value={progress}
          zIndex={'13'}
        />
      )}
      {enableHover && showRuler && (
        <Box
          left={`${videoPointerPosition}px`}
          pos={'absolute'}
          h="100%"
          w="2px"
          bg="primary.presentable"
        ></Box>
      )}
    </Center>
  );
};

export const VideoPreviewComponent = React.memo(
  React.forwardRef(_VideoPreviewComponent)
);
