import Icon, { IconProps } from '@chakra-ui/icon';
import { OmitCommonProps, As } from '@chakra-ui/react';
import React from 'react';

const _WebLinkIcon = (
  props: JSX.IntrinsicAttributes &
    OmitCommonProps<React.SVGProps<SVGSVGElement>, keyof IconProps> &
    IconProps &
    OmitCommonProps<any, keyof IconProps> & { as?: As<any> | undefined }
) => (
  <Icon width={35} height={35} viewBox="0 0 35 35" focusable="false" {...props}>
    <g fill="none" fillRule="evenodd">
      <rect fill="#470137" width={35} height={35} rx={4} />
      <path fill="#FF61F6" d="m10 21 4 8H6z" />
      <circle fill="#FF61F6" cx={25} cy={10} r={4} />
      <path fill="#FF61F6" d="M6 6h8v8H6z" />
      <rect fill="#FF61F6" x={21} y={21} width={8} height={8} rx={2} />
    </g>
  </Icon>
);

export const WebLinkIcon = React.memo(_WebLinkIcon);
