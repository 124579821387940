import Icon, { IconProps } from '@chakra-ui/icon';
import { OmitCommonProps, As } from '@chakra-ui/react';
import React from 'react';

const _ProjectBrandIcon = (
  props: JSX.IntrinsicAttributes &
    OmitCommonProps<React.SVGProps<SVGSVGElement>, keyof IconProps> &
    IconProps &
    OmitCommonProps<any, keyof IconProps> & { as?: As<any> | undefined }
) => (
  <Icon width={96} height={96} viewBox="0 0 96 96" focusable="false" {...props}>
    <defs>
      <path id="aq" d="M0 0h96v96H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <use fill="#163F90" xlinkHref="#aq" />
      <path fill="#6E9EFF" d="M29 18h38v60H29z" />
      <circle fill="#00246B" cx={48} cy={48} r={17} />
    </g>
  </Icon>
);

export const ProjectBrandIcon = React.memo(_ProjectBrandIcon);
