import Icon, { IconProps } from '@chakra-ui/icon';
import { OmitCommonProps, As } from '@chakra-ui/react';
import React from 'react';

const _VisibilityChatIcon = (
  props: JSX.IntrinsicAttributes &
    OmitCommonProps<React.SVGProps<SVGSVGElement>, keyof IconProps> &
    IconProps &
    OmitCommonProps<any, keyof IconProps> & { as?: As<any> | undefined }
) => (
  <Icon height={48} width={48} viewBox="0 0 48 48" focusable="false" {...props}>
    <path
      className="p-svg"
      d="M24 31.5q3.55 0 6.025-2.475Q32.5 26.55 32.5 23q0-3.55-2.475-6.025Q27.55 14.5 24 14.5q-3.55 0-6.025 2.475Q15.5 19.45 15.5 23q0 3.55 2.475 6.025Q20.45 31.5 24 31.5Zm0-2.9q-2.35 0-3.975-1.625T18.4 23q0-2.35 1.625-3.975T24 17.4q2.35 0 3.975 1.625T29.6 23q0 2.35-1.625 3.975T24 28.6Zm0 9.4q-6.9 0-12.575-3.75-5.675-3.75-8.775-9.9-.15-.25-.225-.6-.075-.35-.075-.75t.075-.75q.075-.35.225-.6 3.1-6.15 8.775-9.9Q17.1 8 24 8q6.9 0 12.575 3.75 5.675 3.75 8.775 9.9.15.25.225.6.075.35.075.75t-.075.75q-.075.35-.225.6-3.1 6.15-8.775 9.9Q30.9 38 24 38Z"
    />

    <path
      className="p-svg600"
      display={'none'}
      d="M24 31.5q3.55 0 6.025-2.475Q32.5 26.55 32.5 23q0-3.55-2.475-6.025Q27.55 14.5 24 14.5q-3.55 0-6.025 2.475Q15.5 19.45 15.5 23q0 3.55 2.475 6.025Q20.45 31.5 24 31.5Zm0-3.35q-2.15 0-3.65-1.5T18.85 23q0-2.15 1.5-3.65t3.65-1.5q2.15 0 3.65 1.5t1.5 3.65q0 2.15-1.5 3.65T24 28.15Zm0 10.5q-7.05 0-12.85-3.875T2.1 24.65q-.15-.35-.25-.775-.1-.425-.1-.875t.1-.9q.1-.45.25-.75 3.25-6.25 9.05-10.125Q16.95 7.35 24 7.35t12.85 3.875q5.8 3.875 9.05 10.125.15.3.25.75t.1.9q0 .45-.1.875t-.25.775q-3.25 6.25-9.05 10.125Q31.05 38.65 24 38.65Z"
    />
  </Icon>
);

export const VisibilityChatIcon = React.memo(_VisibilityChatIcon);
