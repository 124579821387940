import React from 'react';
import { createContextualCan } from '@casl/react';
import {
  AppAbilityType,
  globalAbility,
} from '../authorization/Policies/AuthorizationPolicyManager';

// For ref visit here : https://codesandbox.io/s/github/stalniy/casl-examples/tree/master/packages/react-todo?file=/src/components/Can.ts

export const AbilityContext = React.createContext<AppAbilityType>(undefined!);
export const Can = createContextualCan(AbilityContext.Consumer);

interface Props {
  children: React.ReactNode;
}

export const AppAbilityContextComponent = (props: Props) => {
  const { children } = props;

  return (
    <AbilityContext.Provider value={globalAbility}>
      {children}
    </AbilityContext.Provider>
  );
};
