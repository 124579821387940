import Icon, { IconProps } from '@chakra-ui/icon';
import { OmitCommonProps, As } from '@chakra-ui/react';
import React from 'react';

const _ProjectModellingIcon = (
  props: JSX.IntrinsicAttributes &
    OmitCommonProps<React.SVGProps<SVGSVGElement>, keyof IconProps> &
    IconProps &
    OmitCommonProps<any, keyof IconProps> & { as?: As<any> | undefined }
) => (
  <Icon width={96} height={96} viewBox="0 0 96 96" focusable="false" {...props}>
    <defs>
      <path id="a1" d="M0 0h96v96H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <use fill="#185C37" xlinkHref="#a1" />
      <path fill="#BFFFDC" d="M62 47h10v25H62z" />
      <path fill="#4DEE96" d="M24 48h38v24H24z" />
      <path fill="#0BAE57" d="M24 24h48v24H24z" />
    </g>
  </Icon>
);

export const ProjectModellingIcon = React.memo(_ProjectModellingIcon);
