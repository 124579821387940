import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import { presentableTheme } from '@presentable/ui-lib';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import '@fontsource/work-sans';
import '@fontsource/work-sans/variable.css';
import { Provider } from 'react-redux';
import { store } from './store';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import './styles.css';
import 'react-datepicker/dist/react-datepicker.css';
import './datePicker.scss';
import './style/global.scss';
import { AppAbilityContextComponent } from './Context/AppAbilityContext';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus:
        process.env.NODE_ENV === 'development' ? false : true,
    },
  },
});

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <AppAbilityContextComponent>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen position="bottom-right" />
          <ChakraProvider resetCSS theme={presentableTheme}>
            <ColorModeScript />
            <App />
          </ChakraProvider>
        </QueryClientProvider>
      </Provider>
    </AppAbilityContextComponent>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
