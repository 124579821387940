import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define a type for the slice state

interface BusinessProfileType {
  isEditBusinessProfileModalVisible: boolean;
}

// Define the initial state using that type
const initialState: BusinessProfileType = {
  isEditBusinessProfileModalVisible: false,
};

export const businessProfileSlice = createSlice({
  name: 'user',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    updateIsEditBusinessProfileModalVisible: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isEditBusinessProfileModalVisible = action.payload;
    },
  },
});

export const { updateIsEditBusinessProfileModalVisible } =
  businessProfileSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value;

export default businessProfileSlice.reducer;
