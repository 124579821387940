import Icon, { IconProps } from '@chakra-ui/icon';
import { OmitCommonProps, As } from '@chakra-ui/react';
import React from 'react';

const _ProfileIcon = (
  props: JSX.IntrinsicAttributes &
    OmitCommonProps<React.SVGProps<SVGSVGElement>, keyof IconProps> &
    IconProps &
    OmitCommonProps<any, keyof IconProps> & { as?: As<any> | undefined }
) => (
  <Icon
    width={120}
    height={120}
    viewBox="0 0 120 120"
    focusable="false"
    {...props}
  >
    <g fill="#BDAB9C" fillRule="evenodd">
      <path d="M60 55c33.137 0 60 26.863 60 60l-.005.521H120v8.349H0V115c0-33.137 26.863-60 60-60Z" />
      <circle cx={59.5} cy={29.5} r={21.5} />
    </g>
  </Icon>
);

export const ProfileIcon = React.memo(_ProfileIcon);
