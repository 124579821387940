import { configureStore } from '@reduxjs/toolkit';
import projectSlice from './project';
import projectReviewSlice from './projectReview';
import userSlice from './user';
import adminConsoleSlice from './adminConsole';
import socketSlice from './socket';
import teamSlice from './team';
import feedbackSlice from './feedback';
import projectDetailSlice from './projectDetail';
import librarySlice from './library';
import editorSlice from './editor';
import itemUploadSlice from './itemUpload';
import projectDirectMessageSlice from './projectDirectMessage';
import chatSlice from './chat';
import notificationSlice from './notification';
import utilsSlice from './utils';
import projectVideoReviewSlice from './projectVideoReview';
import businessProfileSlice from './businessProfile';
import createCustomLabelSlice from './customLabel';
import contactSlice from './contact';
import inviteMemberSlice from './inviteMember';
import newConnectSlice from './newConnect';
import connectDetailSlice from './connectDetail';
import connectDetailConversationSlice from './connectDetailConversation';
import connectDetailItemsSlice from './connectDetailItems';
import connectsSlice from './connect';
import videoCallSlice from './videoCall';

export const store = configureStore({
  reducer: {
    user: userSlice,
    socket: socketSlice,
    itemUpload: itemUploadSlice,
    library: librarySlice,
    notification: notificationSlice,

    project: projectSlice,
    projectDetail: projectDetailSlice,
    projectReview: projectReviewSlice,
    adminConsole: adminConsoleSlice,
    team: teamSlice,
    feedback: feedbackSlice,
    editor: editorSlice,
    projectDirectMessage: projectDirectMessageSlice,
    projectVideoReview: projectVideoReviewSlice,
    businessProfile: businessProfileSlice,
    createCustomLabel: createCustomLabelSlice,
    contact: contactSlice,
    inviteMember: inviteMemberSlice,
    newConnect: newConnectSlice,
    connectDetail: connectDetailSlice,
    connectDetailConversation: connectDetailConversationSlice,
    connectDetailItems: connectDetailItemsSlice,
    connects: connectsSlice,
    chat: chatSlice,
    utils: utilsSlice,
    videoCall: videoCallSlice,
  },
  devTools: process.env.NODE_ENV === 'development' ? true : false,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
