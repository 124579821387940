import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { queryClient } from '..';
import { Paths } from '../MainNav';

// Define a type for the slice state

export enum ConnectsTabsTypeEnum {
  active = 'ACTIVE',
  closed = 'CLOSED',
  request = 'REQUEST',
}

interface connectsType {
  currentPath?: {
    path: string;
    params: { [key: string]: any };
  };
  selectedConnectTab: ConnectsTabsTypeEnum;
  globalFilter: { [key: string]: any };
  activeFilter: { [key: string]: any };
  closedFilter: { [key: string]: any };
  requestFilter: { [key: string]: any };
}

// Define the initial state using that type
const initialState: connectsType = {
  selectedConnectTab: ConnectsTabsTypeEnum.active,
  globalFilter: {},
  activeFilter: {},
  closedFilter: {},
  requestFilter: {},
};

export const connectsSlice = createSlice({
  name: 'connects',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    updateConnectCurrentPath: (
      state,
      action: PayloadAction<{ path: string; params: { [key: string]: any } }>
    ) => {
      state.currentPath = {
        path: action.payload.path,
        params: action.payload.params,
      };
    },
    updateConnectSelectedTab: (
      state,
      action: PayloadAction<ConnectsTabsTypeEnum>
    ) => {
      state.selectedConnectTab = action.payload;
    },
    updateGlobalConnectFilter: (
      state,
      action: PayloadAction<{ [key: string]: any }>
    ) => {
      state.globalFilter = { ...action.payload };
    },
    updateActiveConnectFilter: (
      state,
      action: PayloadAction<{ [key: string]: any }>
    ) => {
      state.activeFilter = { ...action.payload };
    },
    updateClosedConnectFilter: (
      state,
      action: PayloadAction<{ [key: string]: any }>
    ) => {
      state.closedFilter = { ...action.payload };
    },
    updateRequestConnectFilter: (
      state,
      action: PayloadAction<{ [key: string]: any }>
    ) => {
      state.requestFilter = { ...action.payload };
    },
    updateConnectFilterOnSideNavTagClick: (
      state,
      action: PayloadAction<{
        data: { [key: string]: any };
        selectedTab: ConnectsTabsTypeEnum;
      }>
    ) => {
      state.globalFilter = {
        ...action.payload.data,
        search: undefined,
        isFavourite: undefined,
        custom_tag: undefined,
        work_tag: undefined,
      };
      state.selectedConnectTab = action.payload.selectedTab;
      state.activeFilter = { tag: action.payload.data.tag };
      state.closedFilter = { tag: action.payload.data.tag };
      state.requestFilter = { tag: action.payload.data.tag };
    },

    updateConnectFilterOnSideNavCustomTagClick: (
      state,
      action: PayloadAction<{
        data: { [key: string]: any };
        selectedTab: ConnectsTabsTypeEnum;
      }>
    ) => {
      state.globalFilter = {
        ...action.payload.data,
        search: undefined,
        isFavourite: undefined,
        tag: undefined,
        work_tag: undefined,
      };
      state.selectedConnectTab = action.payload.selectedTab;
      state.activeFilter = { custom_tag: action.payload.data.custom_tag };
      state.closedFilter = { custom_tag: action.payload.data.custom_tag };
      state.requestFilter = { custom_tag: action.payload.data.custom_tag };
    },

    updateConnectFilterOnSideNavWorkTagClick: (
      state,
      action: PayloadAction<{
        data: { [key: string]: any };
        selectedTab: ConnectsTabsTypeEnum;
      }>
    ) => {
      state.globalFilter = {
        ...action.payload.data,
        search: undefined,
        isFavourite: undefined,
        tag: undefined,
        custom_tag: undefined,
      };
      state.selectedConnectTab = action.payload.selectedTab;
      state.activeFilter = { work_tag: action.payload.data.work_tag };
      state.closedFilter = { work_tag: action.payload.data.work_tag };
      state.requestFilter = { work_tag: action.payload.data.work_tag };
    },
    updateConnectFilterOnGlobalSearchEnterClick: (
      state,
      action: PayloadAction<{ [key: string]: any }>
    ) => {
      state.globalFilter = { ...action.payload, isFavourite: undefined };
      state.selectedConnectTab = ConnectsTabsTypeEnum.active;
      state.activeFilter = { search: action.payload.search };
      state.closedFilter = { search: action.payload.search };
      state.requestFilter = { search: action.payload.search };
    },
    resetConnectFilterOnGlobalSearchClearClick: (state) => {
      state.selectedConnectTab = ConnectsTabsTypeEnum.active;
      state.globalFilter = {};
      state.activeFilter = { search: undefined };
      state.closedFilter = { search: undefined };
      state.requestFilter = { search: undefined };
    },

    updateOnConnectNotificationClick: (
      state,
      action: PayloadAction<ConnectsTabsTypeEnum>
    ) => {
      state.selectedConnectTab = action.payload;
      state.globalFilter = {};
      state.activeFilter = { search: undefined };
      state.closedFilter = { search: undefined };
      state.requestFilter = { search: undefined };
    },

    refreshConnectUnseenNotificationDetail: (
      state,
      action: PayloadAction<{ workspaceId: string; connectId: string }>
    ) => {
      if (state.currentPath?.path === Paths.connects) {
        queryClient.invalidateQueries([
          'unseen-connect',
          action.payload.workspaceId,
          {},
        ]);

        if (state.selectedConnectTab === ConnectsTabsTypeEnum.active) {
          queryClient.invalidateQueries([
            'active-connects',
            action.payload.workspaceId,
            { ...state.activeFilter },
          ]);
        }

        if (state.selectedConnectTab === ConnectsTabsTypeEnum.closed) {
          queryClient.invalidateQueries([
            'closed-connects',
            action.payload.workspaceId,
            { ...state.closedFilter },
          ]);
        }

        if (state.selectedConnectTab === ConnectsTabsTypeEnum.request) {
          queryClient.invalidateQueries([
            'request-connects',
            action.payload.workspaceId,
            { ...state.requestFilter },
          ]);
        }
      }

      // Refetch the unsen data if the user is not in connect + connect detail page

      if (
        state.currentPath?.path !== Paths.connects &&
        state.currentPath?.path !== Paths.connectDetail
      ) {
        queryClient.invalidateQueries([
          'unseen-connect',
          action.payload.workspaceId,
          {},
        ]);
      }
    },

    updateConnectFilterOnFavouriteClick: (state) => {
      state.globalFilter = { isFavourite: true };
      state.selectedConnectTab = ConnectsTabsTypeEnum.active;
      state.activeFilter = { isFavourite: true };
      state.closedFilter = { isFavourite: true };
      state.requestFilter = { isFavourite: true };
    },

    updateConnectOnUnmount: (state) => {
      state.selectedConnectTab = ConnectsTabsTypeEnum.active;
      state.globalFilter = {};
      state.activeFilter = {};
      state.closedFilter = {};
      state.requestFilter = {};
    },

    updateConnectOnWorkspaceChange: (state) => {
      state.selectedConnectTab = ConnectsTabsTypeEnum.active;
      state.globalFilter = {};
      state.activeFilter = {};
      state.closedFilter = {};
      state.requestFilter = {};
    },
  },
});

export const {
  updateConnectCurrentPath,
  updateConnectSelectedTab,
  updateGlobalConnectFilter,
  updateActiveConnectFilter,
  updateClosedConnectFilter,
  updateRequestConnectFilter,
  updateConnectFilterOnSideNavTagClick,
  updateConnectFilterOnSideNavCustomTagClick,
  updateConnectFilterOnSideNavWorkTagClick,
  updateConnectFilterOnGlobalSearchEnterClick,
  resetConnectFilterOnGlobalSearchClearClick,
  refreshConnectUnseenNotificationDetail,
  updateConnectFilterOnFavouriteClick,
  updateConnectOnUnmount,
  updateOnConnectNotificationClick,
  updateConnectOnWorkspaceChange,
} = connectsSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value;

export default connectsSlice.reducer;
