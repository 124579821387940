import Icon, { IconProps } from '@chakra-ui/icon';
import { OmitCommonProps, As } from '@chakra-ui/react';
import React from 'react';

const _GoogleSlidesIcon = (
  props: JSX.IntrinsicAttributes &
    OmitCommonProps<React.SVGProps<SVGSVGElement>, keyof IconProps> &
    IconProps &
    OmitCommonProps<any, keyof IconProps> & { as?: As<any> | undefined }
) => (
  <Icon width={19} height={26} viewBox="0 0 19 26" focusable="false" {...props}>
    <defs>
      <path
        d="M11.654 0H1.748C.787 0 0 .787 0 1.748v22.143c0 .961.787 1.748 1.748 1.748h15.15c.962 0 1.748-.787 1.748-1.748V6.992L11.654 0Z"
        id="a"
      />
      <path
        d="M11.654 0H1.748C.787 0 0 .787 0 1.748v22.143c0 .961.787 1.748 1.748 1.748h15.15c.962 0 1.748-.787 1.748-1.748V6.992L11.654 0Z"
        id="c"
      />
      <path
        d="M11.654 0H1.748C.787 0 0 .787 0 1.748v22.143c0 .961.787 1.748 1.748 1.748h15.15c.962 0 1.748-.787 1.748-1.748V6.992L11.654 0Z"
        id="e"
      />
      <path
        d="M11.654 0H1.748C.787 0 0 .787 0 1.748v22.143c0 .961.787 1.748 1.748 1.748h15.15c.962 0 1.748-.787 1.748-1.748V6.992L11.654 0Z"
        id="h"
      />
      <path
        d="M11.654 0H1.748C.787 0 0 .787 0 1.748v22.143c0 .961.787 1.748 1.748 1.748h15.15c.962 0 1.748-.787 1.748-1.748V6.992L11.654 0Z"
        id="j"
      />
      <path
        d="M11.654 0H1.748C.787 0 0 .787 0 1.748v22.143c0 .961.787 1.748 1.748 1.748h15.15c.962 0 1.748-.787 1.748-1.748V6.992L11.654 0Z"
        id="l"
      />
      <path
        d="M11.654 0H1.748C.787 0 0 .787 0 1.748v22.143c0 .961.787 1.748 1.748 1.748h15.15c.962 0 1.748-.787 1.748-1.748V6.992L11.654 0Z"
        id="n"
      />
      <path
        d="M11.654 0H1.748C.787 0 0 .787 0 1.748v22.143c0 .961.787 1.748 1.748 1.748h15.15c.962 0 1.748-.787 1.748-1.748V6.992L11.654 0Z"
        id="p"
      />
      <radialGradient
        cx="3.168%"
        cy="2.717%"
        fx="3.168%"
        fy="2.717%"
        r="161.249%"
        gradientTransform="matrix(1 0 0 .73077 0 .007)"
        id="r"
      >
        <stop stopColor="#FFF" stopOpacity={0.1} offset="0%" />
        <stop stopColor="#FFF" stopOpacity={0} offset="100%" />
      </radialGradient>
      <linearGradient
        x1="50.005%"
        y1="8.586%"
        x2="50.005%"
        y2="100.014%"
        id="f"
      >
        <stop stopColor="#BF360C" stopOpacity={0.2} offset="0%" />
        <stop stopColor="#BF360C" stopOpacity={0.02} offset="100%" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <path
        d="M11.654 0H1.748C.787 0 0 .787 0 1.748v22.143c0 .961.787 1.748 1.748 1.748h15.15c.962 0 1.748-.787 1.748-1.748V6.992L14.568 4.08 11.654 0Z"
        fill="#F4B400"
        fillRule="nonzero"
        mask="url(#b)"
      />
      <mask id="d" fill="#fff">
        <use xlinkHref="#c" />
      </mask>
      <path
        d="M13.11 11.654H5.537a.877.877 0 0 0-.874.874v7.575c0 .48.393.874.874.874h7.575c.48 0 .874-.393.874-.874v-7.575a.877.877 0 0 0-.874-.874Zm-.29 6.701H5.826v-4.079h6.992v4.08Z"
        fill="#F1F1F1"
        fillRule="nonzero"
        mask="url(#d)"
      />
      <mask id="g" fill="#fff">
        <use xlinkHref="#e" />
      </mask>
      <path
        fill="url(#f)"
        fillRule="nonzero"
        mask="url(#g)"
        d="m12.165 6.481 6.481 6.48V6.992z"
      />
      <mask id="i" fill="#fff">
        <use xlinkHref="#h" />
      </mask>
      <g mask="url(#i)">
        <path
          d="M11.654 0v5.244c0 .966.782 1.748 1.748 1.748h5.244L11.654 0Z"
          fill="#FADA80"
          fillRule="nonzero"
        />
      </g>
      <mask id="k" fill="#fff">
        <use xlinkHref="#j" />
      </mask>
      <path
        fillOpacity={0.1}
        fill="#FFF"
        fillRule="nonzero"
        mask="url(#k)"
        d="M11.654 0v.146l6.847 6.846h.145z"
      />
      <mask id="m" fill="#fff">
        <use xlinkHref="#l" />
      </mask>
      <path
        d="M1.748 0C.787 0 0 .787 0 1.748v.146C0 .932.787.146 1.748.146h9.906V0H1.748Z"
        fillOpacity={0.2}
        fill="#FFF"
        fillRule="nonzero"
        mask="url(#m)"
      />
      <mask id="o" fill="#fff">
        <use xlinkHref="#n" />
      </mask>
      <path
        d="M16.898 25.493H1.748A1.753 1.753 0 0 1 0 23.745v.146c0 .961.787 1.748 1.748 1.748h15.15c.962 0 1.748-.787 1.748-1.748v-.146c0 .962-.786 1.748-1.748 1.748Z"
        fillOpacity={0.2}
        fill="#BF360C"
        fillRule="nonzero"
        mask="url(#o)"
      />
      <mask id="q" fill="#fff">
        <use xlinkHref="#p" />
      </mask>
      <path
        d="M13.402 6.992a1.748 1.748 0 0 1-1.748-1.748v.146c0 .966.782 1.748 1.748 1.748h5.244v-.146h-5.244Z"
        fillOpacity={0.1}
        fill="#BF360C"
        fillRule="nonzero"
        mask="url(#q)"
      />
      <path
        d="M11.875 0H1.781C.801 0 0 .798 0 1.773v22.454C0 25.202.802 26 1.781 26H17.22c.98 0 1.781-.798 1.781-1.773V7.091L11.875 0Z"
        fill="url(#r)"
        fillRule="nonzero"
      />
    </g>
  </Icon>
);

export const GoogleSlidesIcon = React.memo(_GoogleSlidesIcon);
