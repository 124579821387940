import { Flex, HStack, Text, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { RootState } from '../../../../store';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  updateLibraryProjectFilter,
  updateProjectViewType,
} from '../../../../store/library';
import { VisibilityChatIcon } from '../../../../svg/Chat/visibility.icon';
import { InfoIcon } from '../../../../svg/Icons/info.icon';
import { ProjectFilledKanbanViewIcon } from '../../../../svg/Library/Project/filledKanban.icon';
import { ProjectFilledListViewIcon } from '../../../../svg/Library/Project/filledList.icon';
import { ProjectKanbanViewIcon } from '../../../../svg/Library/Project/kanban.icon';
import { ProjectListViewIcon } from '../../../../svg/Library/Project/list.icon';
import {
  ClientRoleEnum,
  ProjectStateEnum,
  ProjectSubStateEnum,
} from '../../../../type/types';
import { PresentableSelect } from '../../../Select';
import { StatusFormatter } from '../../../Select/Formatter/StatusFormatter';

export const activeProjectStatusList = [
  {
    label: 'Processing',
    value: ProjectSubStateEnum.processing,
  },
  {
    label: 'Work in progress',
    value: ProjectSubStateEnum.workInProgress,
  },

  {
    label: 'In review',
    value: ProjectSubStateEnum.inReview,
  },
  {
    label: 'Approved',
    value: ProjectSubStateEnum.approved,
  },
];

export enum ProjectViewTypeEnum {
  list = 'LIST',
  kanban = 'KANBAN',
}

export const ProjectSubHeaderComponent = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const selectedTab = useAppSelector(
    (state: RootState) => state.library.selectedTab
  );

  const selectedWorkspaceRole = useAppSelector(
    (state: RootState) => state.user.selectedWorkspaceRole
  );

  const projectFilters = useAppSelector(
    (state: RootState) => state.library.projectFilters
  );

  const projectSelectedViewType = useAppSelector(
    (state: RootState) => state.library.projectSelectedViewType
  );

  const handleOnViewTypeClick = (val: ProjectViewTypeEnum) => {
    dispatch(updateProjectViewType(val));
    navigate({
      pathname: location.pathname,
      search: `tab=${selectedTab}&project-type=${val}`,
    });
  };

  return (
    <React.Fragment>
      <HStack
        mt="0 !important"
        w="100%"
        alignItems={'baseline'}
        justifyContent="space-between"
      >
        <HStack alignItems={'baseline'}>
          <Text
            fontSize={13}
            textTransform="uppercase"
            fontWeight={600}
            letterSpacing="0.54px"
          >
            Projects
          </Text>
          <Tooltip
            hasArrow
            label={`Collect items from your library and add team members to start a new project. Also, view all your projects and track their progress.`}
            placement="bottom"
            bg="secondary.black"
            shouldWrapChildren
            width="50"
            ml="0 !important"
            p="3"
            fontSize={13}
          >
            <InfoIcon
              cursor="pointer"
              _hover={{
                fill: 'primary.presentable',
              }}
              height="4"
              width="4"
            />
          </Tooltip>
          {selectedWorkspaceRole &&
            selectedWorkspaceRole === ClientRoleEnum.guest && (
              <Text lineHeight={'15px'} fontSize={12} color={'#73737d'}>
                <VisibilityChatIcon
                  height="3"
                  width="3"
                  fill="#73737d"
                  mr="1"
                />
                Clients have viewer access only.
              </Text>
            )}
        </HStack>

        <HStack
          borderRadius="6px"
          py="2"
          px="3"
          border="1px solid"
          borderColor={'#f2f2f4'}
          display={['none', 'none', 'flex', 'flex']}
        >
          <HStack
            cursor={'pointer'}
            onClick={() => {
              handleOnViewTypeClick(ProjectViewTypeEnum.kanban);
            }}
            _hover={{
              svg: {
                fill: 'primary.presentable',
              },
              p: {
                color: 'primary.presentable',
              },
            }}
          >
            {projectSelectedViewType === ProjectViewTypeEnum.kanban && (
              <ProjectFilledKanbanViewIcon
                fill={'primary.presentable'}
                height="5"
                width="5"
              />
            )}
            {projectSelectedViewType !== ProjectViewTypeEnum.kanban && (
              <ProjectKanbanViewIcon fill={'black'} height="5" width="5" />
            )}
            <Text
              fontSize={13}
              textTransform="capitalize"
              color={
                projectSelectedViewType === ProjectViewTypeEnum.kanban
                  ? 'primary.presentable'
                  : '#5d636a'
              }
              fontWeight={
                projectSelectedViewType === ProjectViewTypeEnum.kanban
                  ? 600
                  : undefined
              }
              // _hover={{
              //   color: 'primary.presentable',
              // }}
            >
              Kanban
            </Text>
          </HStack>
          <HStack
            cursor={'pointer'}
            onClick={() => {
              handleOnViewTypeClick(ProjectViewTypeEnum.list);
            }}
            _hover={{
              svg: {
                fill: 'primary.presentable',
              },
              p: {
                color: 'primary.presentable',
              },
            }}
          >
            {projectSelectedViewType === ProjectViewTypeEnum.list && (
              <ProjectFilledListViewIcon
                fill={'primary.presentable'}
                height="5"
                width="5"
              />
            )}
            {projectSelectedViewType !== ProjectViewTypeEnum.list && (
              <ProjectListViewIcon fill={'black'} height="5" width="5" />
            )}
            <Text
              fontSize={13}
              textTransform="capitalize"
              color={
                projectSelectedViewType === ProjectViewTypeEnum.list
                  ? 'primary.presentable'
                  : '#5d636a'
              }
              fontWeight={
                projectSelectedViewType === ProjectViewTypeEnum.list
                  ? 600
                  : undefined
              }
            >
              List
            </Text>
          </HStack>
        </HStack>
      </HStack>
      {projectSelectedViewType === ProjectViewTypeEnum.list && (
        <Flex
          mt="0 !important"
          w="100%"
          justifyContent={'space-between'}
          pt="3"
          flexDirection={['column', 'column', 'row', 'row']}
        >
          <HStack
            borderRadius="6px"
            py="2"
            px="3"
            border="1px solid"
            borderColor={'#f2f2f4'}
            w={['fit-content', 'fit-content']}
          >
            <Text
              fontSize={13}
              textTransform="capitalize"
              color={
                projectFilters?.type === ProjectStateEnum.active
                  ? 'primary.presentable'
                  : '#5d636a'
              }
              fontWeight={
                projectFilters.type === ProjectStateEnum.active
                  ? 600
                  : undefined
              }
              cursor={'pointer'}
              onClick={() => {
                dispatch(
                  updateLibraryProjectFilter({
                    ...projectFilters,
                    type: ProjectStateEnum.active,
                  })
                );
              }}
              _hover={{
                color: 'primary.presentable',
              }}
            >
              Active
            </Text>

            <Text
              fontSize={13}
              textTransform="capitalize"
              color={
                projectFilters?.type === ProjectStateEnum.completed
                  ? 'primary.presentable'
                  : '#5d636a'
              }
              pl="3"
              fontWeight={
                projectFilters.type === ProjectStateEnum.completed
                  ? 600
                  : undefined
              }
              cursor={'pointer'}
              onClick={() => {
                dispatch(
                  updateLibraryProjectFilter({
                    ...projectFilters,
                    type: ProjectStateEnum.completed,
                    status: undefined,
                  })
                );
              }}
              _hover={{
                color: 'primary.presentable',
              }}
            >
              Completed
            </Text>
          </HStack>
          {projectFilters && projectFilters.type === ProjectStateEnum.active && (
            <HStack
              justifyContent={[
                'flex-start',
                'flex-start',
                'flex-end',
                'flex-end',
              ]}
              pt={[3, 3, 0, 0]}
            >
              <PresentableSelect
                width={'160px'}
                placeholder="All status"
                isFilterType
                options={activeProjectStatusList}
                value={projectFilters?.status}
                onChange={(option) => {
                  dispatch(
                    updateLibraryProjectFilter({
                      ...projectFilters,
                      status: option ? option.value : undefined,
                    })
                  );
                }}
                clearIconSize="sm"
                customFormattedOptionLabel={StatusFormatter}
                customChakraStyles={{
                  menuList: (provider) => ({
                    ...provider,
                    minW: '165px',
                  }),
                }}
                mr="0 !important"
              />
            </HStack>
          )}
        </Flex>
      )}
    </React.Fragment>
  );
};
