import Icon, { IconProps } from '@chakra-ui/icon';
import { OmitCommonProps, As } from '@chakra-ui/react';
import React from 'react';

const _WordFormatIcon = (
  props: JSX.IntrinsicAttributes &
    OmitCommonProps<React.SVGProps<SVGSVGElement>, keyof IconProps> &
    IconProps &
    OmitCommonProps<any, keyof IconProps> & { as?: As<any> | undefined }
) => (
  <Icon viewBox="0 0 38 35" width={38} height={35} focusable="false" {...props}>
    <defs>
      <linearGradient
        x1="17.372%"
        y1="-6.51%"
        x2="82.628%"
        y2="106.51%"
        id="alk"
      >
        <stop stopColor="#2368C4" offset="0%" />
        <stop stopColor="#1A5DBE" offset="50%" />
        <stop stopColor="#1146AC" offset="100%" />
      </linearGradient>
    </defs>
    <g fillRule="nonzero" fill="none">
      <path
        d="M36.021 0H10.354C9.468 0 8.75.718 8.75 1.604V8.75l14.875 4.375 14-4.375V1.604C37.625.718 36.907 0 36.021 0Z"
        fill="#41A5EE"
      />
      <path fill="#2B7CD3" d="M37.625 8.75H8.75v8.75l14.875 2.625 14-2.625z" />
      <path fill="#185ABD" d="M8.75 17.5v8.75l14 1.75 14.875-1.75V17.5z" />
      <path
        d="M10.354 35H36.02c.886 0 1.604-.718 1.604-1.604V26.25H8.75v7.146c0 .886.718 1.604 1.604 1.604Z"
        fill="#103F91"
      />
      <path
        d="M19.396 7H8.75v21.875h10.646A1.61 1.61 0 0 0 21 27.271V8.604A1.61 1.61 0 0 0 19.396 7Z"
        fill="#000"
        opacity={0.1}
      />
      <path
        d="M18.521 7.875H8.75V29.75h9.771a1.61 1.61 0 0 0 1.604-1.604V9.48a1.61 1.61 0 0 0-1.604-1.604Z"
        fill="#000"
        opacity={0.2}
      />
      <path
        d="M18.521 7.875H8.75V28h9.771a1.61 1.61 0 0 0 1.604-1.604V9.48a1.61 1.61 0 0 0-1.604-1.604Z"
        fill="#000"
        opacity={0.2}
      />
      <path
        d="M17.646 7.875H8.75V28h8.896a1.61 1.61 0 0 0 1.604-1.604V9.48a1.61 1.61 0 0 0-1.604-1.604Z"
        fill="#000"
        opacity={0.2}
      />
      <path
        d="M1.604 7.875h16.042c.886 0 1.604.718 1.604 1.604V25.52c0 .886-.718 1.604-1.604 1.604H1.604A1.604 1.604 0 0 1 0 25.521V9.48c0-.886.718-1.604 1.604-1.604Z"
        fill="url(#alk)"
      />
      <path
        d="M6.582 20.176a8.9 8.9 0 0 1 .062.647h.037c.014-.175.043-.386.087-.632.044-.247.084-.456.12-.626l1.687-7.278h2.181l1.75 7.169c.102.445.175.897.218 1.352h.03c.032-.44.093-.879.181-1.312l1.396-7.213h1.985l-2.452 10.43h-2.32l-1.662-6.907a16.169 16.169 0 0 1-.276-1.478h-.03c-.018.17-.056.422-.112.756s-.1.582-.135.742l-1.563 6.886H5.407L2.943 12.287h2.02l1.52 7.293c.035.15.067.349.099.596Z"
        fill="#FFF"
      />
    </g>
  </Icon>
);

export const WordFormatIcon = React.memo(_WordFormatIcon);
