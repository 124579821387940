import React from 'react';
import { CloseButton } from '@chakra-ui/close-button';
import { RenderProps, UseToastOptions, HStack, Text } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/toast';

interface Props {
  message: string;
  toastId: string | number;
}

export const toastConfig = (message: string): UseToastOptions => {
  const toastId = Math.random();
  return {
    id: toastId,
    position: 'bottom',
    render: (props: RenderProps) => {
      return <CustomToastUI message={message} toastId={toastId} />;
    },
    duration: 2000,
  };
};

export const CustomToastUI = (props: Props) => {
  const { message, toastId } = props;
  const toast = useToast();
  return (
    <HStack
      px="6"
      py="4"
      bg="secondary.black"
      borderRadius="8"
      justifyContent="space-between"
    >
      <Text fontSize="14" color="secondary.white">
        {message}
      </Text>

      <CloseButton
        size="sm"
        color="border.alpha"
        onClick={() => toast.close(toastId)}
      />
    </HStack>
  );
};
