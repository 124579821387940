import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define a type for the slice state

interface CustomLabelType {
  isCreateCustomLabelModalVisible: boolean;
}

// Define the initial state using that type
const initialState: CustomLabelType = {
  isCreateCustomLabelModalVisible: false,
};

export const createCustomLabelSlice = createSlice({
  name: 'customLabel',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    updateIsCreateCustomLabelModalVisible: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isCreateCustomLabelModalVisible = action.payload;
    },
  },
});

export const { updateIsCreateCustomLabelModalVisible } =
  createCustomLabelSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value;

export default createCustomLabelSlice.reducer;
