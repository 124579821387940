import React from 'react';
import { CompressedFileFormatIcon } from '../svg/UploadFileFormat/compressedFileFormat.icon';
import { FontFormatIcon } from '../svg/UploadFileFormat/fontFormat.icon';
import { IllustratorFormatIcon } from '../svg/UploadFileFormat/illustratorFormat.icon';
import { ImageFormatIcon } from '../svg/UploadFileFormat/imageFormat.icon';
import { OtherFormatIcon } from '../svg/UploadFileFormat/otherFormat.icon';
import { PdfFormatIcon } from '../svg/UploadFileFormat/pdfFormat.icon';
import { PhotoshopFormatIcon } from '../svg/UploadFileFormat/photoshopFormat.icon';
import { PPTFormatIcon } from '../svg/UploadFileFormat/pptFormat.icon';
import { SketchFormatIcon } from '../svg/UploadFileFormat/sketchFormat.icon';
import { VideoFormatIcon } from '../svg/UploadFileFormat/videoFormat.icon';

export const imageFormatType = [
  'image/png',
  'image/jpeg',
  'image/tiff',
  'image/webp',
  'image/vnd.microsoft.icon',
  'image/gif',
];

export const pdfFormatType = ['application/pdf'];

export const compressedFileFormatType = [
  'application/zip',
  'application/x-tar',
  'application/vnd.rar',
];

export const photoshopFileFormat = ['image/vnd.adobe.photoshop'];

export const illustratorFileFormat = [
  'application/postscript',
  'application/illustrator',
];

export const sketchFileFormat = ['application/octet-stream'];

export const powerpointFileFormatType = [
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/mspowerpoint',
];

export const videoFileFormat = [
  'video/x-msvideo',
  'video/mp4',
  'video/mpeg',
  'video/ogg',
  'video/webm',
  'video/3gpp',
  'video/3gpp2',
  'video/avi',
  'video/quicktime',
];

export const fontFileFormat = [
  'application/vnd.ms-fontobject',
  'font/otf',
  'font/ttf',
  'font/woff',
  'font/woff2',
];

export const excelFileFormatType = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.oasis.opendocument.spreadsheet',
  'text/csv',
  'application/msexcel',
];

export const afterEffectFileFormat = [
  'application/vnd.adobe.aftereffects.project',
  'application/vnd.adobe.aftereffects.template',
];

export const indesignFileFormat = ['application/x-indesign'];

export const microsoftWordFormat = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

export const audioFormat = [
  'audio/basic',
  'audio/aac',
  'audio/midi',
  'audio/x-midi',
  'audio/mpeg',
  'audio/ogg',
  'audio/opus',
  'audio/wav',
  'audio/webm',
  'audio/3gpp',
  'audio/3gpp2',
];

export const svgFileFormat = ['image/svg+xml'];

export const getExtension = (filename: string) => {
  return filename.split('.').pop();
};

export const renderFileIcon = (
  mimetype: string,
  height: number | string = 6,
  width: number | string = 6
) => {
  if (imageFormatType.includes(mimetype)) {
    return <ImageFormatIcon height={height} width={width} />;
  } else if (pdfFormatType.includes(mimetype)) {
    return <PdfFormatIcon height={height} width={width} />;
  } else if (compressedFileFormatType.includes(mimetype)) {
    return <CompressedFileFormatIcon height={height} width={width} />;
  } else if (photoshopFileFormat.includes(mimetype)) {
    return <PhotoshopFormatIcon height={height} width={width} />;
  } else if (illustratorFileFormat.includes(mimetype)) {
    return <IllustratorFormatIcon height={height} width={width} />;
  } else if (powerpointFileFormatType.includes(mimetype)) {
    return <PPTFormatIcon height={height} width={width} />;
  } else if (videoFileFormat.includes(mimetype)) {
    return <VideoFormatIcon height={height} width={width} />;
  } else if (fontFileFormat.includes(mimetype)) {
    return <FontFormatIcon height={height} width={width} />;
  } else if (sketchFileFormat.includes(mimetype)) {
    return <SketchFormatIcon height={height} width={width} />;
  } else {
    return <OtherFormatIcon height={height} width={width} />;
  }
};

export const mimetypeToLabel = (mimetype: string) => {
  if (imageFormatType.includes(mimetype)) {
    return 'Image File';
  }
  if (pdfFormatType.includes(mimetype)) {
    return 'PDF File';
  }
  if (photoshopFileFormat.includes(mimetype)) {
    return 'Photoshop File';
  }
  if (illustratorFileFormat.includes(mimetype)) {
    return 'Illustrator File';
  }
  if (sketchFileFormat.includes(mimetype)) {
    return 'Sketch File';
  }
  if (powerpointFileFormatType.includes(mimetype)) {
    return 'Presentation File';
  }
  if (videoFileFormat.includes(mimetype)) {
    return 'Video File';
  }
  if (fontFileFormat.includes(mimetype)) {
    return 'Font File';
  }
  if (compressedFileFormatType.includes(mimetype)) {
    return 'ZIP/RAR File';
  }
  if (excelFileFormatType.includes(mimetype)) {
    return 'Excel File';
  }
  if (indesignFileFormat.includes(mimetype)) {
    return 'InDesign File';
  }
  if (microsoftWordFormat.includes(mimetype)) {
    return 'Word File';
  }
  if (afterEffectFileFormat.includes(mimetype)) {
    return 'AfterEffect File';
  }
  if (audioFormat.includes(mimetype)) {
    return 'Audio File';
  }
  if (svgFileFormat.includes(mimetype)) {
    return 'Vector File';
  } else {
    return 'Unknown file format';
  }
};
