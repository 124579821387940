import { VStack, Skeleton } from '@chakra-ui/react';

interface Props {
  ml?: string | number;
}
export const ItemCardSkeleton = (props: Props) => {
  const { ml } = props;
  return (
    <VStack
      width={'200px'}
      ml={ml}
      mb="32px !important"
      borderRadius="8"
      boxShadow="0px 0px 3px 0 rgba(0, 0, 0, 0.05)"
      cursor={'pointer'}
      className="p-recent-session-card"
      // ml="0 !important"
    >
      <Skeleton
        startColor={'#eee4dcE6'}
        endColor="#f8f1ebE6"
        w="100%"
        h="176px"
        pos="relative"
        borderTopRadius="6"
      />
      <VStack
        mt="0 !important"
        w="100%"
        px="10px"
        pt="6px"
        pb="12px"
        bg="primary.peach"
        alignItems={'flex-start'}
        borderBottomRadius="6px"
      >
        <Skeleton
          startColor={'#f8f1eb'}
          endColor="#f8f1ebCC"
          w="100%"
          h="3"
          my="4px !important"
        />
        <Skeleton
          startColor={'#f8f1eb'}
          endColor="#f8f1ebCC"
          mt="100"
          w="100%"
          h="4"
        />
      </VStack>
    </VStack>
  );
};
