import Icon, { IconProps } from '@chakra-ui/icon';
import { OmitCommonProps, As } from '@chakra-ui/react';
import React from 'react';

const _SVGFormatIcon = (
  props: JSX.IntrinsicAttributes &
    OmitCommonProps<React.SVGProps<SVGSVGElement>, keyof IconProps> &
    IconProps &
    OmitCommonProps<any, keyof IconProps> & { as?: As<any> | undefined }
) => (
  <Icon viewBox="0 0 31 35" width={31} height={35} focusable="false" {...props}>
    <g fillRule="nonzero" fill="none">
      <path
        d="M2.22 0h18.542l9.828 9.79v22.945a2.182 2.182 0 0 1-2.183 2.182H2.22a2.182 2.182 0 0 1-2.182-2.182V2.182C.037.977 1.015 0 2.22 0Z"
        fill="#E2574C"
      />
      <path
        d="M8.3 22.1c.527 0 .993-.082 1.4-.245.407-.163.723-.4.95-.71.227-.31.34-.682.34-1.115 0-.46-.142-.835-.425-1.125-.283-.29-.752-.508-1.405-.655l-1.23-.28c-.34-.08-.58-.182-.72-.305A.583.583 0 0 1 7 17.21c0-.147.042-.28.125-.4a.856.856 0 0 1 .4-.29c.183-.073.415-.11.695-.11.427 0 .802.082 1.125.245.323.163.622.418.895.765l.77-.89a3.095 3.095 0 0 0-1.16-.92c-.453-.207-.98-.31-1.58-.31-.567 0-1.052.092-1.455.275-.403.183-.712.432-.925.745a1.84 1.84 0 0 0-.32 1.06c0 .327.072.608.215.845.143.237.353.43.63.58.277.15.608.272.995.365l1.34.3c.313.067.528.162.645.285a.625.625 0 0 1 .175.445.644.644 0 0 1-.145.425.92.92 0 0 1-.43.27c-.19.063-.422.095-.695.095a2.874 2.874 0 0 1-1.69-.54 2.568 2.568 0 0 1-.57-.57l-.78.93c.2.253.45.477.75.67.3.193.642.345 1.025.455.383.11.805.165 1.265.165Zm7.3-.1 2.4-6.6h-1.42l-1.4 4.21-.37 1.29-.38-1.3-1.39-4.2h-1.46l2.41 6.6h1.61Zm6.11.1c.56 0 1.025-.118 1.395-.355.231-.148.419-.336.563-.563l.014-.024.058.842h.97v-3.48h-2.92v1.01h1.73v.15c0 .22-.04.413-.12.58a1.11 1.11 0 0 1-.33.41c-.14.107-.305.187-.495.24-.19.053-.395.08-.615.08-.407 0-.748-.085-1.025-.255-.277-.17-.485-.427-.625-.77s-.21-.765-.21-1.265c0-.487.073-.898.22-1.235a1.69 1.69 0 0 1 .63-.77c.273-.177.597-.265.97-.265s.688.085.945.255c.257.17.438.432.545.785l1.28-.55c-.26-.553-.61-.962-1.05-1.225-.44-.263-1.017-.395-1.73-.395-.653 0-1.223.137-1.71.41a2.806 2.806 0 0 0-1.13 1.175c-.267.51-.4 1.118-.4 1.825 0 .713.127 1.323.38 1.83.253.507.608.893 1.065 1.16.457.267.988.4 1.595.4Z"
        fill="#FFF"
      />
    </g>
  </Icon>
);

export const SVGFormatIcon = React.memo(_SVGFormatIcon);
