import Icon, { IconProps } from '@chakra-ui/icon';
import { OmitCommonProps, As } from '@chakra-ui/react';
import React from 'react';

const _OtherFormatIcon = (
  props: JSX.IntrinsicAttributes &
    OmitCommonProps<React.SVGProps<SVGSVGElement>, keyof IconProps> &
    IconProps &
    OmitCommonProps<any, keyof IconProps> & { as?: As<any> | undefined }
) => (
  <Icon viewBox="0 0 31 35" width={31} height={35} focusable="false" {...props}>
    <defs>
      <path
        d="M15 15a5 5 0 1 0 0 10 5 5 0 0 0 0-10Zm-.084 7.686c-.4 0-.673-.294-.673-.688 0-.401.28-.689.673-.689.409 0 .673.288.681.689 0 .394-.273.688-.681.688Zm.916-2.814c-.28.31-.394.605-.386.946v.136h-1.007l-.007-.197c-.023-.386.106-.779.446-1.188.243-.287.44-.53.44-.786 0-.265-.175-.44-.553-.454-.25 0-.553.09-.75.227l-.257-.825c.273-.159.727-.31 1.264-.31.999 0 1.452.552 1.452 1.18 0 .575-.355.953-.642 1.271Z"
        id="aasasa"
      />
    </defs>
    <g fillRule="nonzero" fill="none">
      <path
        d="M2.182 0h18.542l9.829 9.79v22.945a2.182 2.182 0 0 1-2.183 2.182H2.182A2.182 2.182 0 0 1 0 32.735V2.182C0 .977.978 0 2.182 0Z"
        fill="#FF668F"
      />
      <use fill="#FFF" xlinkHref="#aasasa" />
    </g>
  </Icon>
);

export const OtherFormatIcon = React.memo(_OtherFormatIcon);
