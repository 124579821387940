import Icon, { IconProps } from '@chakra-ui/icon';
import { OmitCommonProps, As } from '@chakra-ui/react';
import React from 'react';

const _FontFormatIcon = (
  props: JSX.IntrinsicAttributes &
    OmitCommonProps<React.SVGProps<SVGSVGElement>, keyof IconProps> &
    IconProps &
    OmitCommonProps<any, keyof IconProps> & { as?: As<any> | undefined }
) => (
  <Icon viewBox="0 0 31 35" width={31} height={35} focusable="false" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M3.232 35C1.45 35 0 33.347 0 31.315V3.688C0 1.653 1.45 0 3.232 0h16.752c.786 0 1.546.325 2.136.918l7.548 7.598c.696.7 1.094 1.707 1.094 2.764v20.035c0 2.032-1.45 3.685-3.232 3.685H3.232Z"
        fill="#FF668F"
      />
      <path
        d="m9.683 23 .605-1.771h2.783L13.676 23h1.573l-2.651-7.26h-1.815L8.132 23h1.551Zm3.047-2.893h-2.101l1.045-3.168 1.056 3.168Zm4.972 3.003c.3 0 .592-.051.874-.154.283-.103.523-.251.721-.445.04-.04.076-.08.11-.122l.049-.072.003.01c.014.044.03.086.05.125l.063.113c.117.18.279.314.484.402.205.088.436.132.693.132.286 0 .524-.048.715-.143l.088-.935c-.11.037-.227.055-.352.055-.264 0-.396-.14-.396-.418v-2.233c0-.455-.1-.832-.302-1.133a1.84 1.84 0 0 0-.842-.677c-.36-.15-.781-.225-1.265-.225-.711 0-1.278.147-1.7.44a1.986 1.986 0 0 0-.819 1.166l1.287.396c.095-.323.246-.554.451-.693.205-.14.444-.209.715-.209.293 0 .53.088.71.264.18.176.269.422.269.737v.211l-1.892.383c-.421.082-.759.224-1.013.427l-.12.107c-.264.26-.396.595-.396 1.006 0 .323.073.594.22.814.147.22.356.387.627.5.271.114.594.171.968.171Zm.385-1.067a.83.83 0 0 1-.49-.143.479.479 0 0 1-.203-.418c0-.19.064-.328.193-.412.128-.085.317-.153.566-.204l1.155-.231v.341c0 .149-.019.28-.056.396l-.043.11a.813.813 0 0 1-.275.33c-.117.08-.25.14-.396.176a1.852 1.852 0 0 1-.451.055Z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </g>
  </Icon>
);

export const FontFormatIcon = React.memo(_FontFormatIcon);
