import { Button, Center, Text, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    <Center w="100%" h="100%">
      <VStack>
        <Text fontSize={64} fontWeight="700">
          404{' '}
        </Text>
        <Text fontSize={24} fontWeight="600">
          Something's missing.
        </Text>
        <Text>
          This page is missing or you assembled the link incorrectly.{' '}
        </Text>
        <Button onClick={() => navigate(-1)} mt="40px !important">
          Go back
        </Button>
      </VStack>
    </Center>
  );
};
