import Icon, { IconProps } from '@chakra-ui/icon';
import { OmitCommonProps, As } from '@chakra-ui/react';
import React from 'react';

const _ExcelFormatIcon = (
  props: JSX.IntrinsicAttributes &
    OmitCommonProps<React.SVGProps<SVGSVGElement>, keyof IconProps> &
    IconProps &
    OmitCommonProps<any, keyof IconProps> & { as?: As<any> | undefined }
) => (
  <Icon viewBox="0 0 38 35" width={38} height={35} focusable="false" {...props}>
    <defs>
      <linearGradient
        x1="17.372%"
        y1="-6.51%"
        x2="82.628%"
        y2="106.51%"
        id="asasa"
      >
        <stop stopColor="#18884F" offset="0%" />
        <stop stopColor="#117E43" offset="50%" />
        <stop stopColor="#0B6631" offset="100%" />
      </linearGradient>
    </defs>
    <g fillRule="nonzero" fill="none">
      <path
        d="M23.625 16.625 8.75 14v19.396c0 .886.718 1.604 1.604 1.604H36.02c.886 0 1.604-.718 1.604-1.604V26.25l-14-9.625Z"
        fill="#185C37"
      />
      <path
        d="M23.625 0H10.354C9.468 0 8.75.718 8.75 1.604V8.75l14.875 8.75 7.875 2.625 6.125-2.625V8.75l-14-8.75Z"
        fill="#21A366"
      />
      <path fill="#107C41" d="M8.75 8.75h14.875v8.75H8.75z" />
      <path
        d="M19.396 7H8.75v21.875h10.646A1.61 1.61 0 0 0 21 27.271V8.604A1.61 1.61 0 0 0 19.396 7Z"
        fill="#000"
        opacity={0.1}
      />
      <path
        d="M18.521 7.875H8.75V29.75h9.771a1.61 1.61 0 0 0 1.604-1.604V9.48a1.61 1.61 0 0 0-1.604-1.604Z"
        fill="#000"
        opacity={0.2}
      />
      <path
        d="M18.521 7.875H8.75V28h9.771a1.61 1.61 0 0 0 1.604-1.604V9.48a1.61 1.61 0 0 0-1.604-1.604Z"
        fill="#000"
        opacity={0.2}
      />
      <path
        d="M17.646 7.875H8.75V28h8.896a1.61 1.61 0 0 0 1.604-1.604V9.48a1.61 1.61 0 0 0-1.604-1.604Z"
        fill="#000"
        opacity={0.2}
      />
      <path
        d="M1.604 7.875h16.042c.886 0 1.604.718 1.604 1.604V25.52c0 .886-.718 1.604-1.604 1.604H1.604A1.604 1.604 0 0 1 0 25.521V9.48c0-.886.718-1.604 1.604-1.604Z"
        fill="url(#asasa)"
      />
      <path
        d="m4.967 22.713 3.374-5.228-3.091-5.2h2.487l1.687 3.326c.155.316.262.55.32.705h.022c.11-.252.227-.497.35-.734l1.803-3.294h2.283l-3.17 5.168 3.25 5.257h-2.428l-1.949-3.65a3.061 3.061 0 0 1-.233-.487h-.029a2.308 2.308 0 0 1-.225.473L7.41 22.713H4.967Z"
        fill="#FFF"
      />
      <path
        d="M36.021 0H23.625v8.75h14V1.604C37.625.718 36.907 0 36.021 0Z"
        fill="#33C481"
      />
      <path fill="#107C41" d="M23.625 17.5h14v8.75h-14z" />
    </g>
  </Icon>
);

export const ExcelFormatIcon = React.memo(_ExcelFormatIcon);
