import Icon, { IconProps } from '@chakra-ui/icon';
import { OmitCommonProps, As } from '@chakra-ui/react';
import React from 'react';

const _VideoFormatIcon = (
  props: JSX.IntrinsicAttributes &
    OmitCommonProps<React.SVGProps<SVGSVGElement>, keyof IconProps> &
    IconProps &
    OmitCommonProps<any, keyof IconProps> & { as?: As<any> | undefined }
) => (
  <Icon viewBox="0 0 31 35" width={31} height={35} focusable="false" {...props}>
    <g fillRule="nonzero" fill="none">
      <path
        d="M2.182 0h18.542l9.829 9.79v22.945a2.182 2.182 0 0 1-2.183 2.182H2.182A2.182 2.182 0 0 1 0 32.735V2.182C0 .977.978 0 2.182 0Z"
        fill="#00C8FF"
      />
      <path
        d="M6.11 22v-3.44l-.07-1.93h.02L7.88 22h1.13l1.83-5.37h.02l-.06 1.93V22h1.24v-6.6H10.1l-1.18 3.61-.43 1.48h-.02L8.05 19l-1.19-3.6h-2V22h1.25Zm9.03 0v-2.34h1.39c.507 0 .94-.085 1.3-.255.36-.17.638-.413.835-.73.197-.317.295-.698.295-1.145 0-.447-.098-.828-.295-1.145a1.922 1.922 0 0 0-.835-.73c-.36-.17-.793-.255-1.3-.255h-2.77V22h1.38Zm1.18-3.43h-1.18v-2.08h1.18c.413 0 .723.087.93.26.207.173.31.433.31.78 0 .34-.103.598-.31.775-.207.177-.517.265-.93.265ZM24.27 22v-1.55h1.07v-1h-1.07V15.4h-1.65l-2.46 3.57c-.08.127-.152.238-.215.335a2.536 2.536 0 0 0-.145.245v.9h3.22V22h1.25Zm-1.24-2.55h-2.05a.954.954 0 0 1 .065-.095.954.954 0 0 0 .065-.095l1.38-1.98c.087-.133.182-.285.285-.455.103-.17.192-.328.265-.475h.02l-.02.47c-.007.16-.01.31-.01.45v2.18Z"
        fill="#FFF"
      />
    </g>
  </Icon>
);

export const VideoFormatIcon = React.memo(_VideoFormatIcon);
