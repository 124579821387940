import Icon, { IconProps } from '@chakra-ui/icon';
import { OmitCommonProps, As } from '@chakra-ui/react';
import React from 'react';

const _ZeplinIcon = (
  props: JSX.IntrinsicAttributes &
    OmitCommonProps<React.SVGProps<SVGSVGElement>, keyof IconProps> &
    IconProps &
    OmitCommonProps<any, keyof IconProps> & { as?: As<any> | undefined }
) => (
  <Icon width={33} height={26} viewBox="0 0 33 26" focusable="false" {...props}>
    <g fillRule="nonzero" fill="none">
      <path
        d="m5.01 17.929-1.7.62-.09 5.059 5.886-2.15c-2.02-.677-3.494-1.87-4.095-3.53"
        fill="#FDBD39"
      />
      <path
        d="M5.901 12.579 0 14.734l3.31 3.817 1.7-.621c-.599-1.655-.229-3.523.891-5.351"
        fill="#F69833"
      />
      <path
        d="M15.83 5.278C8.316 8.023 3.47 13.688 5.01 17.928L32.22 7.99c-1.54-4.24-8.877-5.455-16.39-2.71"
        fill="#FECF33"
      />
      <path
        d="M21.4 20.64c7.513-2.744 12.366-8.383 10.818-12.65L5.01 17.93c1.547 4.268 8.876 5.456 16.39 2.712"
        fill="#EE6723"
      />
      <path
        d="M32.218 7.99 5.011 17.928c.532 1.469 2.565 2.193 5.415 2.193 2.78 0 6.337-.688 10.046-2.042 7.513-2.745 12.799-7.19 11.746-10.09"
        fill="#F69833"
      />
      <path
        d="M26.804 5.797c-2.779 0-6.338.687-10.046 2.042-7.514 2.745-12.8 7.188-11.747 10.09l27.209-9.94c-.534-1.47-2.565-2.192-5.416-2.192"
        fill="#FDBD39"
      />
      <g fill="#EE6723">
        <path d="M25.3 23.877c-.8 0-1.981-.723-3.51-2.15-1.808-1.686-3.879-4.158-5.833-6.958-1.953-2.8-3.558-5.598-4.52-7.882-1.046-2.488-1.196-4.017-.444-4.546.174-.12.38-.18.592-.175.611 0 1.805.456 4.01 2.627l.05.048-.007.067c-.015.157-.029.314-.04.469l-.025.307-.216-.218c-2.377-2.395-3.423-2.653-3.763-2.653a.393.393 0 0 0-.23.058c-.326.23-.348 1.354.668 3.765.944 2.242 2.525 4.998 4.453 7.762 1.928 2.765 3.968 5.199 5.745 6.856 1.916 1.787 2.779 1.98 3.06 1.98a.4.4 0 0 0 .236-.062c.344-.242.344-1.445-.788-4.041l-.052-.12.113-.061c.114-.063.224-.123.327-.183l.137-.077.063.143c.802 1.84 1.553 4.178.569 4.87-.175.118-.383.18-.595.174Zm.044-.284v.142-.142Z" />
        <path d="m21.582 20.562-.01.14c-.191 2.35-.691 5.176-1.996 5.291-.027.003-.054.003-.08.003-.885 0-1.724-1.27-2.49-3.772-.727-2.37-1.29-5.549-1.586-8.954-.297-3.405-.293-6.634.013-9.094.332-2.68.964-4.079 1.88-4.16a.841.841 0 0 1 .082-.004c.643 0 1.611.728 2.603 4.118l-.647.125c-.331-1.132-.692-2.053-1.05-2.688-.33-.585-.653-.908-.906-.908l-.026.001c-.397.036-.975.997-1.296 3.596-.3 2.415-.304 5.597-.011 8.957.293 3.362.847 6.494 1.56 8.82.319 1.041.668 1.9 1.013 2.483.316.537.623.833.862.833l.024-.001c.38-.033 1.095-1.035 1.397-4.559l.664-.227Z" />
      </g>
    </g>
  </Icon>
);

export const ZeplinIcon = React.memo(_ZeplinIcon);
