import Icon, { IconProps } from '@chakra-ui/icon';
import { OmitCommonProps, As } from '@chakra-ui/react';
import React from 'react';

const _InvisionIcon = (
  props: JSX.IntrinsicAttributes &
    OmitCommonProps<React.SVGProps<SVGSVGElement>, keyof IconProps> &
    IconProps &
    OmitCommonProps<any, keyof IconProps> & { as?: As<any> | undefined }
) => (
  <Icon width={26} height={26} viewBox="0 0 26 26" focusable="false" {...props}>
    <g fillRule="nonzero" fill="none">
      <path
        d="M23.643 0H2.357A2.357 2.357 0 0 0 0 2.357v21.286A2.357 2.357 0 0 0 2.357 26h21.286A2.357 2.357 0 0 0 26 23.643V2.357A2.357 2.357 0 0 0 23.643 0"
        fill="#DC395F"
      />
      <path
        d="M8.69 8.18c.863 0 1.588-.677 1.588-1.565 0-.887-.725-1.564-1.589-1.564s-1.588.677-1.588 1.564c0 .888.724 1.565 1.588 1.565m-3.293 8.381c-.093.397-.14.826-.14 1.176 0 1.378.747 2.293 2.335 2.293 1.318 0 2.386-.782 3.155-2.045l-.47 1.884h2.616l1.495-5.996c.374-1.518 1.098-2.306 2.196-2.306.864 0 1.4.537 1.4 1.425 0 .257-.022.537-.116.84l-.77 2.757a4.143 4.143 0 0 0-.164 1.167c0 1.309.77 2.266 2.382 2.266 1.378 0 2.476-.887 3.083-3.013l-1.027-.397c-.514 1.424-.958 1.682-1.308 1.682-.35 0-.538-.234-.538-.7 0-.21.047-.444.117-.725l.748-2.685c.186-.63.257-1.19.257-1.703 0-2.009-1.215-3.057-2.686-3.057-1.378 0-2.78 1.243-3.48 2.55l.513-2.347H11l-.56 2.068h1.868l-1.15 4.607c-.904 2.009-2.564 2.041-2.772 1.995-.342-.078-.561-.207-.561-.652 0-.256.047-.625.163-1.069l1.752-6.95H5.303l-.561 2.069h1.845l-1.191 4.866"
        fill="#FFF"
      />
    </g>
  </Icon>
);

export const InvisionIcon = React.memo(_InvisionIcon);
