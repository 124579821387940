import Icon, { IconProps } from '@chakra-ui/icon';
import { OmitCommonProps, As } from '@chakra-ui/react';
import React from 'react';

const _GoogleDocsIcon = (
  props: JSX.IntrinsicAttributes &
    OmitCommonProps<React.SVGProps<SVGSVGElement>, keyof IconProps> &
    IconProps &
    OmitCommonProps<any, keyof IconProps> & { as?: As<any> | undefined }
) => (
  <Icon width={19} height={26} viewBox="0 0 19 26" focusable="false" {...props}>
    <defs>
      <path
        d="M11.75 0H1.762C.793 0 0 .798 0 1.773v22.454C0 25.202.793 26 1.762 26h15.276c.969 0 1.762-.798 1.762-1.773V7.091L11.75 0Z"
        id="a"
      />
      <path
        d="M11.75 0H1.762C.793 0 0 .798 0 1.773v22.454C0 25.202.793 26 1.762 26h15.276c.969 0 1.762-.798 1.762-1.773V7.091L11.75 0Z"
        id="c"
      />
      <path
        d="M11.75 0H1.762C.793 0 0 .798 0 1.773v22.454C0 25.202.793 26 1.762 26h15.276c.969 0 1.762-.798 1.762-1.773V7.091L11.75 0Z"
        id="f"
      />
      <path
        d="M11.75 0H1.762C.793 0 0 .798 0 1.773v22.454C0 25.202.793 26 1.762 26h15.276c.969 0 1.762-.798 1.762-1.773V7.091L11.75 0Z"
        id="h"
      />
      <path
        d="M11.75 0H1.762C.793 0 0 .798 0 1.773v22.454C0 25.202.793 26 1.762 26h15.276c.969 0 1.762-.798 1.762-1.773V7.091L11.75 0Z"
        id="j"
      />
      <path
        d="M11.75 0H1.762C.793 0 0 .798 0 1.773v22.454C0 25.202.793 26 1.762 26h15.276c.969 0 1.762-.798 1.762-1.773V7.091L11.75 0Z"
        id="l"
      />
      <path
        d="M11.75 0H1.762C.793 0 0 .798 0 1.773v22.454C0 25.202.793 26 1.762 26h15.276c.969 0 1.762-.798 1.762-1.773V7.091L11.75 0Z"
        id="n"
      />
      <radialGradient
        cx="3.168%"
        cy="2.717%"
        fx="3.168%"
        fy="2.717%"
        r="161.249%"
        gradientTransform="matrix(1 0 0 .72308 0 .008)"
        id="p"
      >
        <stop stopColor="#FFF" stopOpacity={0.1} offset="0%" />
        <stop stopColor="#FFF" stopOpacity={0} offset="100%" />
      </radialGradient>
      <linearGradient
        x1="50.005%"
        y1="8.586%"
        x2="50.005%"
        y2="100.014%"
        id="d"
      >
        <stop stopColor="#1A237E" stopOpacity={0.2} offset="0%" />
        <stop stopColor="#1A237E" stopOpacity={0.02} offset="100%" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <path
        d="M11.75 0H1.762C.793 0 0 .798 0 1.773v22.454C0 25.202.793 26 1.762 26h15.276c.969 0 1.762-.798 1.762-1.773V7.091l-4.113-2.955L11.75 0Z"
        fill="#4285F4"
        fillRule="nonzero"
        mask="url(#b)"
      />
      <mask id="e" fill="#fff">
        <use xlinkHref="#c" />
      </mask>
      <path
        fill="url(#d)"
        fillRule="nonzero"
        mask="url(#e)"
        d="m12.266 6.572 6.534 6.571V7.091z"
      />
      <mask id="g" fill="#fff">
        <use xlinkHref="#f" />
      </mask>
      <path
        d="M4.7 18.91h9.4v-1.183H4.7v1.182Zm0 2.363h7.05V20.09H4.7v1.182ZM4.7 13v1.182h9.4V13H4.7Zm0 3.545h9.4v-1.181H4.7v1.181Z"
        fill="#F1F1F1"
        fillRule="nonzero"
        mask="url(#g)"
      />
      <mask id="i" fill="#fff">
        <use xlinkHref="#h" />
      </mask>
      <g mask="url(#i)">
        <path
          d="M11.75 0v5.318c0 .98.789 1.773 1.762 1.773H18.8L11.75 0Z"
          fill="#A1C2FA"
          fillRule="nonzero"
        />
      </g>
      <mask id="k" fill="#fff">
        <use xlinkHref="#j" />
      </mask>
      <path
        d="M1.762 0C.793 0 0 .798 0 1.773v.147C0 .945.793.148 1.762.148h9.988V0H1.762Z"
        fillOpacity={0.2}
        fill="#FFF"
        fillRule="nonzero"
        mask="url(#k)"
      />
      <mask id="m" fill="#fff">
        <use xlinkHref="#l" />
      </mask>
      <path
        d="M17.038 25.852H1.762C.793 25.852 0 25.055 0 24.08v.147C0 25.202.793 26 1.762 26h15.276c.969 0 1.762-.798 1.762-1.773v-.147c0 .975-.793 1.772-1.762 1.772Z"
        fillOpacity={0.2}
        fill="#1A237E"
        fillRule="nonzero"
        mask="url(#m)"
      />
      <mask id="o" fill="#fff">
        <use xlinkHref="#n" />
      </mask>
      <path
        d="M13.512 7.09a1.767 1.767 0 0 1-1.762-1.772v.148c0 .98.789 1.773 1.762 1.773H18.8V7.09h-5.288Z"
        fillOpacity={0.1}
        fill="#1A237E"
        fillRule="nonzero"
        mask="url(#o)"
      />
      <path
        d="M11.75 0H1.762C.793 0 0 .798 0 1.773v22.454C0 25.202.793 26 1.762 26h15.276c.969 0 1.762-.798 1.762-1.773V7.091L11.75 0Z"
        fill="url(#p)"
        fillRule="nonzero"
      />
    </g>
  </Icon>
);

export const GoogleDocsIcon = React.memo(_GoogleDocsIcon);
