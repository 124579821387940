import Icon, { IconProps } from '@chakra-ui/icon';
import { OmitCommonProps, As } from '@chakra-ui/react';
import React from 'react';

const _GoogleSheetIcon = (
  props: JSX.IntrinsicAttributes &
    OmitCommonProps<React.SVGProps<SVGSVGElement>, keyof IconProps> &
    IconProps &
    OmitCommonProps<any, keyof IconProps> & { as?: As<any> | undefined }
) => (
  <Icon width={19} height={26} viewBox="0 0 19 26" focusable="false" {...props}>
    <defs>
      <path
        d="M11.654 0H1.748C.787 0 0 .787 0 1.748v22.143c0 .961.787 1.748 1.748 1.748h15.15c.962 0 1.748-.787 1.748-1.748V6.992L11.654 0Z"
        id="a"
      />
      <path
        d="M11.654 0H1.748C.787 0 0 .787 0 1.748v22.143c0 .961.787 1.748 1.748 1.748h15.15c.962 0 1.748-.787 1.748-1.748V6.992L11.654 0Z"
        id="c"
      />
      <path
        d="M11.654 0H1.748C.787 0 0 .787 0 1.748v22.143c0 .961.787 1.748 1.748 1.748h15.15c.962 0 1.748-.787 1.748-1.748V6.992L11.654 0Z"
        id="e"
      />
      <path
        d="M11.654 0H1.748C.787 0 0 .787 0 1.748v22.143c0 .961.787 1.748 1.748 1.748h15.15c.962 0 1.748-.787 1.748-1.748V6.992L11.654 0Z"
        id="h"
      />
      <path
        d="M11.654 0H1.748C.787 0 0 .787 0 1.748v22.143c0 .961.787 1.748 1.748 1.748h15.15c.962 0 1.748-.787 1.748-1.748V6.992L11.654 0Z"
        id="j"
      />
      <path
        d="M11.654 0H1.748C.787 0 0 .787 0 1.748v22.143c0 .961.787 1.748 1.748 1.748h15.15c.962 0 1.748-.787 1.748-1.748V6.992L11.654 0Z"
        id="l"
      />
      <path
        d="M11.654 0H1.748C.787 0 0 .787 0 1.748v22.143c0 .961.787 1.748 1.748 1.748h15.15c.962 0 1.748-.787 1.748-1.748V6.992L11.654 0Z"
        id="n"
      />
      <radialGradient
        cx="3.168%"
        cy="2.717%"
        fx="3.168%"
        fy="2.717%"
        r="161.249%"
        gradientTransform="matrix(1 0 0 .72727 0 .007)"
        id="p"
      >
        <stop stopColor="#FFF" stopOpacity={0.1} offset="0%" />
        <stop stopColor="#FFF" stopOpacity={0} offset="100%" />
      </radialGradient>
      <linearGradient
        x1="50.005%"
        y1="8.586%"
        x2="50.005%"
        y2="100.014%"
        id="f"
      >
        <stop stopColor="#263238" stopOpacity={0.2} offset="0%" />
        <stop stopColor="#263238" stopOpacity={0.02} offset="100%" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <path
          d="M11.654 0H1.748C.787 0 0 .787 0 1.748v22.143c0 .961.787 1.748 1.748 1.748h15.15c.962 0 1.748-.787 1.748-1.748V6.992L14.568 4.08 11.654 0Z"
          fill="#0F9D58"
          fillRule="nonzero"
          mask="url(#b)"
        />
      </g>
      <g>
        <mask id="d" fill="#fff">
          <use xlinkHref="#c" />
        </mask>
        <path
          d="M4.662 12.528v8.45h9.323v-8.45H4.662Zm4.079 7.284H5.827v-1.457h2.914v1.457Zm0-2.33H5.827v-1.458h2.914v1.457Zm0-2.332H5.827v-1.457h2.914v1.457Zm4.078 4.662H9.906v-1.457h2.913v1.457Zm0-2.33H9.906v-1.458h2.913v1.457Zm0-2.332H9.906v-1.457h2.913v1.457Z"
          fill="#F1F1F1"
          fillRule="nonzero"
          mask="url(#d)"
        />
      </g>
      <g>
        <mask id="g" fill="#fff">
          <use xlinkHref="#e" />
        </mask>
        <path
          fill="url(#f)"
          fillRule="nonzero"
          mask="url(#g)"
          d="m12.165 6.481 6.481 6.48V6.992z"
        />
      </g>
      <g>
        <mask id="i" fill="#fff">
          <use xlinkHref="#h" />
        </mask>
        <g mask="url(#i)">
          <path
            d="M11.654 0v5.244c0 .966.782 1.748 1.748 1.748h5.244L11.654 0Z"
            fill="#87CEAC"
            fillRule="nonzero"
          />
        </g>
      </g>
      <g>
        <mask id="k" fill="#fff">
          <use xlinkHref="#j" />
        </mask>
        <path
          d="M1.748 0C.787 0 0 .787 0 1.748v.146C0 .932.787.146 1.748.146h9.906V0H1.748Z"
          fillOpacity={0.2}
          fill="#FFF"
          fillRule="nonzero"
          mask="url(#k)"
        />
      </g>
      <g>
        <mask id="m" fill="#fff">
          <use xlinkHref="#l" />
        </mask>
        <path
          d="M16.898 25.493H1.748A1.753 1.753 0 0 1 0 23.745v.146c0 .961.787 1.748 1.748 1.748h15.15c.962 0 1.748-.787 1.748-1.748v-.146c0 .962-.786 1.748-1.748 1.748Z"
          fillOpacity={0.2}
          fill="#263238"
          fillRule="nonzero"
          mask="url(#m)"
        />
      </g>
      <g>
        <mask id="o" fill="#fff">
          <use xlinkHref="#n" />
        </mask>
        <path
          d="M13.402 6.992a1.748 1.748 0 0 1-1.748-1.748v.146c0 .966.782 1.748 1.748 1.748h5.244v-.146h-5.244Z"
          fillOpacity={0.1}
          fill="#263238"
          fillRule="nonzero"
          mask="url(#o)"
        />
      </g>
      <path
        d="M11.654 0H1.748C.787 0 0 .787 0 1.748v22.143c0 .961.787 1.748 1.748 1.748h15.15c.962 0 1.748-.787 1.748-1.748V6.992L11.654 0Z"
        fill="url(#p)"
        fillRule="nonzero"
      />
    </g>
  </Icon>
);

export const GoogleSheetIcon = React.memo(_GoogleSheetIcon);
