import { detectURLs } from '../../utils/utility';
import { MessageTypeEnum } from '../ProjectDetail/ChatFooter';
import parser from 'html-react-parser';
import { ItemsTypeEnum } from '../Library/helper';
import { Element } from 'domhandler';

export const checkIfMessageLinkType = (msg: string) => {
  const urls = detectURLs(msg);
  if (urls && urls.length === 0) {
    return {
      type: MessageTypeEnum.message,
      urls: urls,
    };
  } else if (urls && urls.length > 0) {
    return {
      type: MessageTypeEnum.messageWithItems,
      urls: urls,
    };
  }
};

export const itemsLinkType = (url: string) => {
  //check for figma
  if (url.includes('figma.com')) {
    return {
      type: ItemsTypeEnum.figmaLink,
    };
  }

  //check for zeplin
  else if (url.includes('zeplin.io')) {
    return {
      type: ItemsTypeEnum.zeplinLink,
    };
  }
  //check for miro
  else if (url.includes('miro.com')) {
    return {
      type: ItemsTypeEnum.miroLink,
    };
  }

  //check for invision
  else if (url.includes('invisionapp.com')) {
    return {
      type: ItemsTypeEnum.invisionLink,
    };
  }

  //check for sheets
  else if (url.includes('docs.google.com/spreadsheets')) {
    return {
      type: ItemsTypeEnum.googleSheetLink,
    };
  }

  //check for docs
  else if (url.includes('docs.google.com/document')) {
    return {
      type: ItemsTypeEnum.googleDocLink,
    };
  }

  //check for presentation
  else if (url.includes('docs.google.com/presentation')) {
    return {
      type: ItemsTypeEnum.googlePresentationLink,
    };
  }

  //check for forms
  else if (url.includes('docs.google.com/forms')) {
    return {
      type: ItemsTypeEnum.googleFormLink,
    };
  }

  //check for loom
  else if (url.includes('loom.com')) {
    return {
      type: ItemsTypeEnum.loomLink,
    };
  }

  //check for team
  else if (url.includes('teams.live.com/meet/')) {
    return {
      type: ItemsTypeEnum.teamMeetingLink,
    };
  }

  //check for google meet
  else if (url.includes('meet.google.com')) {
    return {
      type: ItemsTypeEnum.googleMeetingLink,
    };
  }

  //check for zoom
  else if (url.includes('zoom.us')) {
    return {
      type: ItemsTypeEnum.zoomMeetingLink,
    };
  }
  //check for general link
  else {
    return {
      type: ItemsTypeEnum.link,
    };
  }
};

export const checkMessageForMentions = (message: string): string[] => {
  const data = [] as string[];
  parser(message, {
    replace: (domNode) => {
      if (
        domNode instanceof Element &&
        domNode.attribs &&
        domNode.attribs['data-id']
      ) {
        data.push(domNode.attribs['data-id']);
      }
    },
  });
  return data;
};
