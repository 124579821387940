import Icon, { IconProps } from '@chakra-ui/icon';
import { OmitCommonProps, As } from '@chakra-ui/react';
import React from 'react';

const _ProjectContentIcon = (
  props: JSX.IntrinsicAttributes &
    OmitCommonProps<React.SVGProps<SVGSVGElement>, keyof IconProps> &
    IconProps &
    OmitCommonProps<any, keyof IconProps> & { as?: As<any> | undefined }
) => (
  <Icon width={96} height={96} viewBox="0 0 96 96" focusable="false" {...props}>
    <defs>
      <path id="ap" d="M0 0h96v96H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="bq" fill="#fff">
        <use xlinkHref="#ap" />
      </mask>
      <use fill="#B132C0" xlinkHref="#ap" />
      <path fill="#DE27F3" mask="url(#bq)" d="M24 27h48v42H24z" />
      <circle fill="#6F007C" mask="url(#bq)" cx={48} cy={48} r={17} />
      <circle fill="#6F007C" cx={48} cy={48} r={17} />
    </g>
  </Icon>
);

export const ProjectContentIcon = React.memo(_ProjectContentIcon);
