import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { queryClient } from '..';
import { CustomRQResponseType, ProjectStateEnum } from '../type/types';
import { groupChatMessage } from '../utils/chat';

// Define a type for the slice state

interface ProjectStateType {
  selectedTeamMemberDetail?: any;
  chatData?: { [key: string]: any[] };
  lastReadChat: { [key: string]: string };
  lastReadThreadChat: { [key: string]: any };
  selectedThreadNotificationMessageIds?: string[];
  chatAttachmentUploadData: { [key: string]: any }[];
  projectItemsFilter: { [key: string]: any };

  createProjectModalVisibility: boolean;
  createProjectModalMode: 'EDIT' | 'CREATE';
  createProjectModalSelectedProjectId?: string;
  selectedProjectTab: ProjectStateEnum;
  activeProjectFilter: { [key: string]: any };
  recentSessionFilter: { [key: string]: any };
}

// Define the initial state using that type
const initialState: ProjectStateType = {
  chatData: {},
  lastReadChat: {},
  lastReadThreadChat: {},
  chatAttachmentUploadData: [],
  projectItemsFilter: {},
  createProjectModalVisibility: false,
  createProjectModalMode: 'CREATE',
  selectedProjectTab: ProjectStateEnum.active,
  activeProjectFilter: {},
  recentSessionFilter: {},
};

export const projectSlice = createSlice({
  name: 'project',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    updateSelectedTeamMemberDetail: (state, action: PayloadAction<any>) => {
      state.selectedTeamMemberDetail = action.payload;
    },
    updateChatHistoryData: (
      state,
      action: PayloadAction<{
        data: any[];
        projectID: string;
      }>
    ) => {
      const initData = { ...state.chatData } as { [key: string]: any[] };
      let projectChatData = initData[action.payload.projectID];
      if (projectChatData) {
        projectChatData = action.payload.data.concat(projectChatData);
      } else {
        projectChatData = [...action.payload.data];
      }
      initData[action.payload.projectID] = projectChatData;
      state.chatData = initData;
    },
    initializeChatData: (
      state,
      action: PayloadAction<{
        data: any[];
        projectID: string;
      }>
    ) => {
      const initData = { ...state.chatData } as { [key: string]: any[] };

      // const initialChatData = initData[action.payload.projectID];

      const finatChatata = [...action.payload.data];

      const map = {} as any;

      finatChatata.forEach((el: any) => {
        if (!map.hasOwnProperty(el.id)) {
          map[el.id] = el;
        }
      });
      const groupedChat = groupChatMessage(Object.values(map));
      initData[action.payload.projectID] = groupedChat;
      // state.lastReadChat[action.payload.projectID] =
      //   groupedChat.length > 0 ? groupedChat[0].id : undefined;
      state.chatData = initData;
    },
    updateChatData: (
      state,
      action: PayloadAction<{
        data: any;
        projectID: string;
      }>
    ) => {
      const initData = { ...state.chatData } as { [key: string]: any[] };
      let projectChatData = initData[action.payload.projectID];
      if (projectChatData) {
        projectChatData = [{ ...action.payload.data }, ...projectChatData];
      } else {
        projectChatData = [{ ...action.payload.data }];
      }
      initData[action.payload.projectID] = projectChatData;
      state.chatData = initData;
    },
    resetChatDataForSingleProject: (state, action: PayloadAction<string>) => {
      const initData = { ...state.chatData } as { [key: string]: any[] };
      let projectChatData = initData[action.payload];
      if (projectChatData) {
        projectChatData = [];
        delete initData[action.payload];
      }
      state.chatData = initData;
    },

    updateThreadChatDataByMessageId: (
      state,
      action: PayloadAction<{
        data: any;
        projectID: string;
        workspaceId: string;
      }>
    ) => {
      const initData = { ...state.chatData } as { [key: string]: any[] };
      let projectChatData = initData[action.payload.projectID];
      if (projectChatData) {
        const message = projectChatData.filter(
          (el: any) => el.id === action.payload.data.message_id.id
        )[0];

        if (message) {
          const mesgThreadInitData = message.message_thread
            ? message.message_thread
            : [];

          const index = projectChatData.findIndex((object) => {
            return object.id === action.payload.data.message_id.id;
          });

          message.message_thread = [action.payload.data, ...mesgThreadInitData];

          projectChatData.splice(index, 1, message);
          state.chatData = initData;

          // update the open thread notification reply messages

          const selectedProjectThreads =
            state.selectedThreadNotificationMessageIds;

          let ids = selectedProjectThreads ? [...selectedProjectThreads] : [];

          const initialThreadNotificationMessageData:
            | CustomRQResponseType
            | undefined = queryClient.getQueryData([
            'project_thread_messages',
            action.payload.workspaceId,
            ids?.join(),
          ]);

          if (
            initialThreadNotificationMessageData &&
            initialThreadNotificationMessageData.data &&
            selectedProjectThreads &&
            Object.keys(selectedProjectThreads).length > 0
          ) {
            ids &&
              ids.length > 0 &&
              queryClient.setQueryData(
                [
                  'project_thread_messages',
                  action.payload.workspaceId,
                  ids?.join(),
                ],
                (oldData: any) => {
                  const newData = { ...oldData };
                  const { data: messageData } = newData;

                  const filteredData = messageData.filter(
                    (el: any) => el.id === message.id
                  );

                  const index = messageData.findIndex((object: any) => {
                    return object.id === message.id;
                  });

                  if (filteredData && filteredData.length > 0) {
                    const mesgThreadInitData = filteredData[0].message_thread
                      ? filteredData[0].message_thread
                      : [];

                    const updatedData = {
                      ...filteredData[0],
                      message_thread: [
                        ...mesgThreadInitData,
                        action.payload.data,
                      ],
                    };
                    messageData.splice(index, 1, updatedData);
                  }
                  return { ...newData, data: messageData };
                }
              );
          }

          // update the open reply thread message
          const initialSelectedMessageData: CustomRQResponseType | undefined =
            queryClient.getQueryData([
              'selected_message_detail',
              action.payload.workspaceId,
              action.payload.data.message_id.id,
            ]);

          if (initialSelectedMessageData && initialSelectedMessageData.data) {
            queryClient.setQueryData(
              [
                'selected_message_detail',
                action.payload.workspaceId,
                action.payload.data.message_id.id,
              ],
              (oldData: any) => {
                const newData = { ...oldData };
                const { data: messageData } = newData;

                const mesgThreadInitData = messageData.message_thread
                  ? messageData.message_thread
                  : [];
                const updatedData = {
                  ...messageData,
                  message_thread: [...mesgThreadInitData, action.payload.data],
                };

                return { ...newData, data: updatedData };
              }
            );
          }

          // Update the message activity
          const initialSelectedMessageItemData:
            | CustomRQResponseType
            | undefined = queryClient.getQueryData([
            'selected_item_activity',
            action.payload.workspaceId,
            message.message_items?.item?.id,
          ]);

          if (
            message.message_items?.item?.id &&
            initialSelectedMessageItemData &&
            initialSelectedMessageItemData.data.length > 0
          ) {
            queryClient.setQueryData(
              [
                'selected_item_activity',
                action.payload.workspaceId,
                message.message_items?.item?.id,
              ],
              (oldData: any) => {
                const newData = { ...oldData };
                const { data: itemsActivityData } = newData;

                const selectedMessage = itemsActivityData.filter(
                  (el: any) => el.message.id === message.id
                )[0];

                const index = itemsActivityData.findIndex((object: any) => {
                  return object.id === selectedMessage.id;
                });

                const mesgThreadInitData = selectedMessage.message
                  .message_thread
                  ? selectedMessage.message.message_thread
                  : [];
                const updatedMessageData = {
                  ...selectedMessage,
                  message: {
                    ...selectedMessage.message,
                    message_thread: [
                      ...mesgThreadInitData,
                      action.payload.data,
                    ],
                  },
                };

                itemsActivityData.splice(index, 1, updatedMessageData);

                return { ...newData, data: itemsActivityData };
              }
            );
          }
        }
      }
    },

    updateAddWorkflowChatDataByMessageId: (
      state,
      action: PayloadAction<{
        data: any;
        projectID: string;
        messageId: string;
      }>
    ) => {
      const initData = { ...state.chatData } as { [key: string]: any[] };
      let projectChatData = initData[action.payload.projectID];
      if (projectChatData) {
        const message = projectChatData.filter(
          (el: any) => el.id === action.payload.messageId
        )[0];

        const index = projectChatData.findIndex((object) => {
          return object.id === action.payload.messageId;
        });

        message.message_items['workflow'] = [action.payload.data];

        projectChatData.splice(index, 1, message);

        state.chatData = initData;
      }
    },

    updateWorkflowChatDataByMessageItemId: (
      state,
      action: PayloadAction<{
        projectID: string;
        messageItemData: { [key: string]: any };
        workspaceId: string;
      }>
    ) => {
      const initData = { ...state.chatData } as { [key: string]: any[] };
      let projectChatData = initData[action.payload.projectID];
      if (projectChatData && projectChatData.length > 0) {
        const message = projectChatData.filter(
          (el: any) =>
            !!(
              el &&
              el?.message_items &&
              el?.message_items?.id === action.payload.messageItemData?.id
            )
        )[0];
        const index = projectChatData.findIndex((object) => {
          return object.id === message.id;
        });
        message.message_items = action.payload.messageItemData;
        projectChatData.splice(index, 1, message);

        queryClient.invalidateQueries([
          'selected_message_detail',
          action.payload.workspaceId,
          message.id,
        ]);

        queryClient.invalidateQueries([
          'selected_item_activity',
          action.payload.workspaceId,
          message.message_items?.item?.id,
        ]);

        state.chatData = initData;
      }
    },

    updateMessageDelete: (
      state,
      action: PayloadAction<{
        projectID: string;
        messageId: string;
      }>
    ) => {
      const initData = { ...state.chatData } as { [key: string]: any[] };
      let projectChatData = initData[action.payload.projectID];
      if (projectChatData) {
        const remainingMessages = projectChatData.filter(
          (el: any) => el.id !== action.payload.messageId
        );

        state.chatData = {
          ...initData,
          [action.payload.projectID]: remainingMessages,
        };
      }
    },

    // -       - - - - - - - - - - - - - - -  - - -- - - - -  - - - -  -

    initiateChatAttachmentData: (
      state,
      action: PayloadAction<{ [key: string]: any }[]>
    ) => {
      state.chatAttachmentUploadData = action.payload;
    },
    updateChatAttachmentDataProgress: (
      state,
      action: PayloadAction<{ progress: number; ids: number[] }>
    ) => {
      let initialAttachment = [...state.chatAttachmentUploadData];
      const progress = action.payload.progress;
      const ids = action.payload.ids;

      const onProgressAttachment = initialAttachment;
      const excludedAttachments = onProgressAttachment.filter(
        (el: any) => !(el.type === 'RAW' && ids.includes(el.id))
      );

      const actualAttachments = onProgressAttachment.filter(
        (el: any) => el.type === 'RAW' && ids.includes(el.id)
      );
      const updatedOnProgressAttachment = actualAttachments.map((el: any) => {
        return { ...el, progress: progress };
      });

      initialAttachment = [
        ...excludedAttachments,
        ...updatedOnProgressAttachment,
      ];
      state.chatAttachmentUploadData = [...initialAttachment];
    },
    removeChatAtttachmentFileData: (state, action: PayloadAction<number>) => {
      let initialAttachment = [...state.chatAttachmentUploadData];
      const onProgressAttachment = initialAttachment;
      onProgressAttachment.splice(action.payload, 1);
      initialAttachment = onProgressAttachment;
      state.chatAttachmentUploadData = initialAttachment;
    },
    updateChatAtttachmentFileDataSuccess: (
      state,
      action: PayloadAction<{ data: any; ids: number[] }>
    ) => {
      const data = action.payload.data;
      const ids = action.payload.ids;
      let initialAttachment = [...state.chatAttachmentUploadData];
      const onProgressAttachment = initialAttachment;
      const excludedAttachments = onProgressAttachment.filter(
        (el: any) => !(el.type === 'RAW' && ids.includes(el.id))
      );

      initialAttachment = [...data, ...excludedAttachments];
      state.chatAttachmentUploadData = initialAttachment;
    },
    addChatAttachmentData: (state, action: PayloadAction<any>) => {
      let initialAttachment = [...state.chatAttachmentUploadData];
      const onProgressAttachment = initialAttachment;
      initialAttachment = [...onProgressAttachment, ...action.payload];
      state.chatAttachmentUploadData = initialAttachment;
    },
    resetChatAttachmentData: (state) => {
      state.chatAttachmentUploadData = [];
    },

    // -  - - - - - - - - - - - - - - -  - - -- - - - -  - - - -  -

    updateprojectItemsFilter: (state, action: PayloadAction<any>) => {
      state.projectItemsFilter = action.payload;
    },

    //New
    updateCreateProjectModalVisibility: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.createProjectModalVisibility = action.payload;
    },

    //New
    updateCreateProjectModalMode: (
      state,
      action: PayloadAction<'CREATE' | 'EDIT'>
    ) => {
      state.createProjectModalMode = action.payload;
    },

    //New
    updateCreateProjectModalSelectedProjectId: (
      state,
      action: PayloadAction<string>
    ) => {
      state.createProjectModalSelectedProjectId = action.payload;
    },

    //New
    updateSelectedProjectTab: (
      state,
      action: PayloadAction<ProjectStateEnum>
    ) => {
      state.selectedProjectTab = action.payload;
    },

    //New
    updateActiveProjectFilter: (
      state,
      action: PayloadAction<{ [key: string]: any }>
    ) => {
      state.activeProjectFilter = action.payload;
    },

    //New
    updateRecentSessionFilter: (
      state,
      action: PayloadAction<{ [key: string]: any }>
    ) => {
      state.recentSessionFilter = action.payload;
    },

    // Read Chat actions
    initializeAllReadChatOfProject: (
      state,
      action: PayloadAction<{
        projectChat: { [key: string]: string };
        projectThread: { [key: string]: string };
      }>
    ) => {
      state.lastReadChat = action.payload.projectChat;

      const threadData = action.payload.projectThread;
      threadData &&
        Object.keys(threadData).forEach((el: string) => {
          const initVal = threadData[el];
          if (initVal) {
            threadData[el] = JSON.parse(initVal);
          }
        });
      state.lastReadThreadChat = threadData ? threadData : {};
    },
    updateReadChatOfProject: (
      state,
      action: PayloadAction<{ projectId: string; messageId: string }>
    ) => {
      if (state.lastReadChat && Object.keys(state.lastReadChat).length > 0) {
        state.lastReadChat[
          action.payload.projectId
        ] = `${action.payload.messageId}::0`;
      }
    },
    updateThreadReadChatOfProject: (
      state,
      action: PayloadAction<{ projectId: string }>
    ) => {
      const initData = state.lastReadThreadChat[action.payload.projectId];

      if (initData && Object.keys(initData).length > 0) {
        [...Object.keys(initData)].forEach((el) => {
          initData[el] = 0;
        });
      }
      state.lastReadThreadChat[action.payload.projectId] = { ...initData };
    },
    resetThreadReadChatOfProject: (
      state,
      action: PayloadAction<{ projectId: string }>
    ) => {
      state.lastReadThreadChat[action.payload.projectId] = undefined;
    },
    updateReadChatDataOnNewProjectIncomingMessage: (
      state,
      action: PayloadAction<{
        projectId: string;
        messageCount: number;
        messageId: string;
      }>
    ) => {
      const initialData = state.lastReadChat[action.payload.projectId];

      if (!initialData) {
        state.lastReadChat[
          action.payload.projectId
        ] = `${action.payload.messageId}::${action.payload.messageCount}`;
      } else {
        const [lastMessageId, count] = initialData.split('::');

        if (lastMessageId !== action.payload.messageId) {
          const newValue = `${action.payload.messageId}::${
            Number(count) + action.payload.messageCount
          }`;

          state.lastReadChat[action.payload.projectId] = newValue;
        }
      }
    },
    updateSelectedThreadNotificationMessageIds: (
      state,
      action: PayloadAction<string[] | undefined>
    ) => {
      state.selectedThreadNotificationMessageIds = action.payload;
    },
    updateThreadReadChatDataOnNewProjectIncomingMessage: (
      state,
      action: PayloadAction<{
        projectId: string;
        messageId: string;
      }>
    ) => {
      const initialData = state.lastReadThreadChat[action.payload.projectId];

      if (!initialData) {
        state.lastReadThreadChat[action.payload.projectId] = {
          [action.payload.messageId]: 1,
        };
      } else {
        if (
          initialData &&
          initialData.hasOwnProperty(action.payload.messageId)
        ) {
          const intCount = initialData[action.payload.messageId];
          initialData[action.payload.messageId] = intCount + 1;
        } else {
          initialData[action.payload.messageId] = 1;
        }
        state.lastReadThreadChat[action.payload.projectId] = initialData;
      }
    },
    resetBlinkNotificationMessage: (
      state,
      action: PayloadAction<{ projectId: string; messageId: string }>
    ) => {
      const initData = { ...state.chatData } as { [key: string]: any[] };

      const projectChatData = initData[action.payload.projectId];

      if (projectChatData && projectChatData.length > 0) {
        const message = projectChatData.filter(
          (el: any) => el?.id === action.payload.messageId
        )[0];
        const index = projectChatData.findIndex((object) => {
          return object.id === message.id;
        });
        message['isNew'] = false;
        projectChatData.splice(index, 1, message);
        state.chatData = initData;
      }
    },
    resetBlinkThreadNotificationMessage: (
      state,
      action: PayloadAction<{
        workspaceId: string;
        projectId: string;
        messageId: string;
        threadMessageId: string;
      }>
    ) => {
      const initData = { ...state.chatData } as { [key: string]: any[] };

      const projectChatData = initData[action.payload.projectId];

      if (projectChatData && projectChatData.length > 0) {
        const message = projectChatData.filter(
          (el: any) => el?.id === action.payload.messageId
        )[0];
        const index = projectChatData.findIndex((object) => {
          return object.id === message.id;
        });
        const threadMessages = [...message.message_thread];
        const selectedThreadMessage = threadMessages.filter(
          (el: any) => el.id === action.payload.threadMessageId
        )[0];

        const threadIndex = threadMessages.findIndex((object: any) => {
          return object.id === action.payload.threadMessageId;
        });

        selectedThreadMessage['isNew'] = false;
        threadMessages.splice(threadIndex, 1, selectedThreadMessage);
        message.message_thread = [...threadMessages];
        projectChatData.splice(index, 1, message);
        state.chatData = initData;

        const selectedProjectThreads =
          state.selectedThreadNotificationMessageIds;

        let ids = selectedProjectThreads ? [...selectedProjectThreads] : [];

        const initialThreadNotificationMessageData:
          | CustomRQResponseType
          | undefined = queryClient.getQueryData([
          'project_thread_messages',
          action.payload.workspaceId,
          ids?.join(),
        ]);

        if (
          initialThreadNotificationMessageData &&
          initialThreadNotificationMessageData.data &&
          selectedProjectThreads &&
          Object.keys(selectedProjectThreads).length > 0
        ) {
          ids &&
            ids.length > 0 &&
            queryClient.setQueryData(
              [
                'project_thread_messages',
                action.payload.workspaceId,
                ids?.join(),
              ],
              (oldData: any) => {
                const newData = { ...oldData };
                const { data: messageData } = newData;

                const filteredData = messageData.filter(
                  (el: any) => el.id === message.id
                );

                const index = messageData.findIndex((object: any) => {
                  return object.id === message.id;
                });

                if (filteredData && filteredData.length > 0) {
                  const msgThreadInitData = filteredData[0].message_thread
                    ? [...filteredData[0].message_thread]
                    : [];

                  const selectedThreadMessage = {
                    ...msgThreadInitData.filter(
                      (el: any) => el.id === action.payload.threadMessageId
                    )[0],
                  };

                  const threadIndex = msgThreadInitData.findIndex(
                    (object: any) => {
                      return object.id === action.payload.threadMessageId;
                    }
                  );

                  selectedThreadMessage['isNew'] = false;
                  msgThreadInitData.splice(
                    threadIndex,
                    1,
                    selectedThreadMessage
                  );

                  const updatedData = {
                    ...filteredData[0],
                    message_thread: [...msgThreadInitData],
                  };
                  messageData.splice(index, 1, updatedData);
                }
                return { ...newData, data: messageData };
              }
            );
        }

        // update the open reply thread message
        const initialSelectedMessageData: CustomRQResponseType | undefined =
          queryClient.getQueryData([
            'selected_message_detail',
            action.payload.workspaceId,
            action.payload.messageId,
          ]);

        console.log(
          initialSelectedMessageData,
          selectedThreadMessage,
          'initialSelectedMessageData'
        );

        if (initialSelectedMessageData && initialSelectedMessageData.data) {
          queryClient.setQueryData(
            [
              'selected_message_detail',
              action.payload.workspaceId,
              action.payload.messageId,
            ],
            (oldData: any) => {
              const newData = { ...oldData };
              const { data: messageData } = newData;

              const msgThreadInitData = messageData.message_thread
                ? [...messageData.message_thread]
                : [];

              const selectedThreadMessage = {
                ...msgThreadInitData.filter(
                  (el: any) => el.id === action.payload.threadMessageId
                )[0],
              };

              const threadIndex = msgThreadInitData.findIndex((object: any) => {
                return object.id === action.payload.threadMessageId;
              });

              selectedThreadMessage['isNew'] = false;
              msgThreadInitData.splice(threadIndex, 1, selectedThreadMessage);

              const updatedData = {
                ...messageData,
                message_thread: [...msgThreadInitData],
              };

              return { ...newData, data: updatedData };
            }
          );
        }

        // Update the message activity
        const initialSelectedMessageItemData: CustomRQResponseType | undefined =
          queryClient.getQueryData([
            'selected_item_activity',
            action.payload.workspaceId,
            message.message_items?.item?.id,
          ]);

        if (
          message.message_items?.item?.id &&
          initialSelectedMessageItemData &&
          initialSelectedMessageItemData.data.length > 0
        ) {
          queryClient.setQueryData(
            [
              'selected_item_activity',
              action.payload.workspaceId,
              message.message_items?.item?.id,
            ],
            (oldData: any) => {
              const newData = { ...oldData };
              const { data: itemsActivityData } = newData;

              const selectedMessage = itemsActivityData.filter(
                (el: any) => el.message.id === message.id
              )[0];

              const index = itemsActivityData.findIndex((object: any) => {
                return object.id === selectedMessage.id;
              });

              const msgThreadInitData = selectedMessage.message.message_thread
                ? [...selectedMessage.message.message_thread]
                : [];

              const selectedThreadMessage = {
                ...msgThreadInitData.filter(
                  (el: any) => el.id === action.payload.threadMessageId
                )[0],
              };

              const threadIndex = msgThreadInitData.findIndex((object: any) => {
                return object.id === action.payload.threadMessageId;
              });

              selectedThreadMessage['isNew'] = false;
              msgThreadInitData.splice(threadIndex, 1, selectedThreadMessage);

              const updatedMessageData = {
                ...selectedMessage,
                message: {
                  ...selectedMessage.message,
                  message_thread: [...msgThreadInitData],
                },
              };

              itemsActivityData.splice(index, 1, updatedMessageData);

              return { ...newData, data: itemsActivityData };
            }
          );
        }
      }
    },

    addBlinkToNotificationMessage: (
      state,
      action: PayloadAction<{
        projectId: string;
        messageId: string;
        count: number;
      }>
    ) => {
      const initData = { ...state.chatData } as { [key: string]: any[] };

      const projectChatData = initData[action.payload.projectId];

      if (projectChatData && projectChatData.length > 0) {
        const message = {
          ...projectChatData.filter(
            (el: any) => el?.id === action.payload.messageId
          )[0],
        };
        const index = projectChatData.findIndex((object) => {
          return object.id === action.payload.messageId;
        });

        message['isNew'] = true;
        projectChatData.splice(index, 1, message);

        let i = index;
        while (i >= 0 && i <= index) {
          const newMessage = projectChatData[index];
          if (newMessage) {
            newMessage['isNew'] = true;
            projectChatData.splice(i, 1, message);
          }
          i--;
        }
        state.chatData = initData;
      }
    },
  },
});

export const {
  updateSelectedTeamMemberDetail,
  updateChatData,
  resetChatDataForSingleProject,
  updateChatHistoryData,
  initiateChatAttachmentData,
  updateChatAttachmentDataProgress,
  removeChatAtttachmentFileData,
  resetChatAttachmentData,
  updateChatAtttachmentFileDataSuccess,
  addChatAttachmentData,
  updateprojectItemsFilter,
  updateCreateProjectModalVisibility,
  updateSelectedProjectTab,
  updateActiveProjectFilter,
  updateCreateProjectModalMode,
  updateCreateProjectModalSelectedProjectId,
  updateRecentSessionFilter,
  updateThreadChatDataByMessageId,
  updateMessageDelete,
  initializeChatData,
  updateAddWorkflowChatDataByMessageId,
  updateWorkflowChatDataByMessageItemId,
  updateReadChatOfProject,
  resetThreadReadChatOfProject,
  updateThreadReadChatOfProject,
  updateSelectedThreadNotificationMessageIds,
  initializeAllReadChatOfProject,
  updateReadChatDataOnNewProjectIncomingMessage,
  updateThreadReadChatDataOnNewProjectIncomingMessage,
  resetBlinkNotificationMessage,
  resetBlinkThreadNotificationMessage,
  addBlinkToNotificationMessage,
} = projectSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value;

export default projectSlice.reducer;
