import Icon, { IconProps } from '@chakra-ui/icon';
import { OmitCommonProps, As } from '@chakra-ui/react';
import React from 'react';

const _CompressedFileFormatIcon = (
  props: JSX.IntrinsicAttributes &
    OmitCommonProps<React.SVGProps<SVGSVGElement>, keyof IconProps> &
    IconProps &
    OmitCommonProps<any, keyof IconProps> & { as?: As<any> | undefined }
) => (
  <Icon viewBox="0 0 31 35" width={31} height={35} focusable="false" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M2.182 0h18.542l9.829 9.79v22.945a2.182 2.182 0 0 1-2.183 2.182H2.182A2.182 2.182 0 0 1 0 32.735V2.182C0 .977.978 0 2.182 0Z"
        fill="#00C8FF"
        fillRule="nonzero"
      />
      <g fill="#FFF">
        <path d="M10.194 9c.411 0 .744.353.744.788v4.589c0 1.202-.92 2.177-2.055 2.177h-.828C6.92 16.554 6 15.579 6 14.377V9.788C6 9.353 6.333 9 6.744 9h3.45Zm-.485 1.698h-2.48c0 .68.488 1.24 1.113 1.307l.127.007c.685 0 1.24-.589 1.24-1.314ZM6.665 31.227c-.367 0-.665-.315-.665-.705v-.33c0-.39.298-.705.665-.705h1.07c.367 0 .665.316.665.705v.33c0 .39-.298.705-.665.705h-1.07ZM6 33.893v-.33c0-.39.298-.705.665-.705h1.07c.367 0 .665.315.665.704v.33l-.128.384H6.107A1.027 1.027 0 0 1 6 33.893ZM9.224 32.857c-.367 0-.665-.315-.665-.704v-.33c0-.39.298-.705.665-.705h1.07c.367 0 .665.315.665.704v.33c0 .39-.298.705-.665.705h-1.07ZM6.665 27.858c-.367 0-.665-.316-.665-.705v-.33c0-.39.298-.705.665-.705h1.07c.367 0 .665.315.665.704v.33c0 .39-.298.706-.665.706h-1.07ZM9.224 29.488c-.367 0-.665-.316-.665-.705v-.33c0-.39.298-.705.665-.705h1.07c.367 0 .665.316.665.705v.33c0 .39-.298.705-.665.705h-1.07ZM6.665 24.487c-.367 0-.665-.316-.665-.705v-.33c0-.39.298-.705.665-.705h1.07c.367 0 .665.316.665.705v.33c0 .39-.298.705-.665.705h-1.07ZM9.224 26.117c-.367 0-.665-.315-.665-.704v-.33c0-.39.298-.705.665-.705h1.07c.367 0 .665.315.665.704v.33c0 .39-.298.705-.665.705h-1.07ZM6.665 21.117c-.367 0-.665-.315-.665-.704v-.33c0-.39.298-.705.665-.705h1.07c.367 0 .665.315.665.704v.33c0 .39-.298.705-.665.705h-1.07ZM9.224 22.748c-.367 0-.665-.316-.665-.705v-.33c0-.39.298-.705.665-.705h1.07c.367 0 .665.315.665.705v.33c0 .39-.298.705-.665.705h-1.07ZM9.224 19.378c-.367 0-.665-.315-.665-.705v-.33c0-.39.298-.705.665-.705h1.07c.367 0 .665.316.665.705v.33c0 .39-.298.705-.665.705h-1.07Z" />
      </g>
    </g>
  </Icon>
);

export const CompressedFileFormatIcon = React.memo(_CompressedFileFormatIcon);
