import axios, { AxiosProgressEvent } from 'axios';
import { baseUrl } from './config';

export const uploadMultipleFile = async (
  data: {
    workspace_id: string;
    project_id?: string;
    payload: any;
    file_relation_type: string;
  },
  updateData: (a: number) => void,
  onSuccess: (data: any) => void
) => {
  const formData = new FormData();

  data.payload.forEach((el: string | Blob) => {
    formData.append('p-image', el);
  });

  const url = `${baseUrl}/utils/${data.workspace_id}/upload`;

  const { data: uploadedData } = await axios.post(url, formData, {
    headers: {
      Authorization: localStorage.getItem('p-auth') || 'TOKEN',
    },
    params: {
      file_relation_type: data.file_relation_type,
    },
    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
      let percentage: number = Math.floor(
        (progressEvent.loaded * 100) / (progressEvent.total as any)
      );
      if (percentage < 100) {
        updateData(percentage);
      }
    },
  });

  if (uploadedData.success) {
    onSuccess(uploadedData);
  } else {
    console.log('Files upload error');
  }
};
