export enum RefSourcesForProjectDetail {
  //Items
  libraryItemRecentlyAdded = 'LIBRARY-ITEM-RECENTLY-ADDED',
  libraryItemAll = 'LIBRARY-ITEM-ALL',
  libraryItemRecentlyActive = 'LIBRARY-ITEM-RECENTLY-ACTIVE',

  //Favourites
  libraryFavouriteItems = 'LIBRARY-FAVOURITE-ITEMS',
  libraryFavouriteAll = 'LIBRARY-FAVOURITE-ALL',
  libraryFavouriteFolder = 'LIBRARY-FAVOURITE-FOLDER',

  //Project
  libraryProjectKanban = 'LIBRARY-PROJECT-KANBAN',
  libraryProjectList = 'LIBRARY-PROJECT-LIST',

  //ProjectDetail
  libraryProjectDetail = 'LIBRARY-PROJECT-DETAIL',

  //Team page
  teamDetail = 'TEAM-DETAIL',
}
