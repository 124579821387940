import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClientRoleEnum } from '../type/types';

// Define a type for the slice state

interface UserType {
  userToken?: string;
  selectedWorkspaceId: string;
  selectedWorkspaceRole: ClientRoleEnum;
  workspaceNotifications: { [key: string]: any };
  workspaceLastPath: { [key: string]: any };
  workspaceSideNavClosedLabelIds: string[];
}

// Define the initial state using that type
const initialState: UserType = {
  selectedWorkspaceId: '',
  selectedWorkspaceRole: ClientRoleEnum.guest,
  workspaceNotifications: {},
  workspaceLastPath: {},
  workspaceSideNavClosedLabelIds: [],
};

export const userSlice = createSlice({
  name: 'user',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setUserAuthToken: (state, action: PayloadAction<any>) => {
      state.userToken = action.payload;
    },
    setSelectedWorkspace: (state, action: PayloadAction<any>) => {
      state.selectedWorkspaceId = action.payload;
    },
    setSelectedWorkspaceRole: (
      state,
      action: PayloadAction<ClientRoleEnum>
    ) => {
      state.selectedWorkspaceRole = action.payload;
    },

    setWorkspaceNotification: (
      state,
      action: PayloadAction<{ [key: string]: any }>
    ) => {
      state.workspaceNotifications = action.payload;
    },

    setWorkspaceLastPath: (
      state,
      action: PayloadAction<{ [key: string]: any }>
    ) => {
      state.workspaceLastPath = action.payload;
    },

    setWorkspaceSideNavClosedLabelIds: (
      state,
      action: PayloadAction<string[]>
    ) => {
      state.workspaceSideNavClosedLabelIds = action.payload;
    },
  },
});

export const {
  setUserAuthToken,
  setSelectedWorkspace,
  setSelectedWorkspaceRole,
  setWorkspaceNotification,
  setWorkspaceSideNavClosedLabelIds,
  setWorkspaceLastPath,
} = userSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value;

export default userSlice.reducer;
