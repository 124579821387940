import Icon, { IconProps } from '@chakra-ui/icon';
import { OmitCommonProps, As } from '@chakra-ui/react';
import React from 'react';

const _PhotoshopFormatIcon = (
  props: JSX.IntrinsicAttributes &
    OmitCommonProps<React.SVGProps<SVGSVGElement>, keyof IconProps> &
    IconProps &
    OmitCommonProps<any, keyof IconProps> & { as?: As<any> | undefined }
) => (
  <Icon viewBox="0 0 36 35" width={36} height={35} focusable="false" {...props}>
    <g fillRule="nonzero" fill="none">
      <path
        d="M6.357 0H29.54a6.352 6.352 0 0 1 6.356 6.357v22.286A6.352 6.352 0 0 1 29.541 35H6.357A6.352 6.352 0 0 1 0 28.643V6.357A6.352 6.352 0 0 1 6.357 0Z"
        fill="#001E36"
      />
      <path
        d="M8.077 24.545V9.154c0-.105.045-.165.15-.165.254 0 .493 0 .837-.015.36-.015.733-.015 1.137-.03s.837-.015 1.301-.03c.464-.014.913-.014 1.361-.014 1.227 0 2.244.15 3.081.463a5.341 5.341 0 0 1 2.005 1.227 4.762 4.762 0 0 1 1.092 1.705c.224.628.344 1.271.344 1.944 0 1.287-.3 2.349-.898 3.186a5.124 5.124 0 0 1-2.408 1.825c-1.017.374-2.139.509-3.365.509-.36 0-.599 0-.748-.015-.15-.015-.36-.015-.643-.015v4.8c.015.106-.06.195-.165.21H8.256c-.12 0-.18-.06-.18-.194Zm3.26-12.669v5.026c.21.015.404.03.584.03h.793c.583 0 1.166-.09 1.72-.27a2.572 2.572 0 0 0 1.226-.792c.314-.374.464-.883.464-1.54a2.462 2.462 0 0 0-.344-1.332 2.169 2.169 0 0 0-1.047-.853 4.389 4.389 0 0 0-1.765-.299c-.389 0-.733 0-1.017.015-.3-.015-.509 0-.613.015ZM28.718 15.99a5.294 5.294 0 0 0-1.436-.51 7.885 7.885 0 0 0-1.675-.194c-.3-.015-.613.03-.898.105a.768.768 0 0 0-.463.3.793.793 0 0 0-.12.403c0 .135.06.27.15.389.134.164.314.3.508.404.344.18.703.344 1.062.493.808.27 1.586.644 2.304 1.092a3.48 3.48 0 0 1 1.181 1.242c.24.478.36 1.002.344 1.54a3.48 3.48 0 0 1-.583 1.99 3.748 3.748 0 0 1-1.675 1.33c-.733.315-1.63.48-2.708.48-.688 0-1.36-.06-2.034-.195a6.033 6.033 0 0 1-1.525-.479c-.105-.06-.18-.164-.165-.284v-2.602c0-.045.015-.105.06-.135.045-.03.09-.015.134.015a6.403 6.403 0 0 0 1.855.733c.569.15 1.167.224 1.765.224.569 0 .972-.075 1.242-.21a.695.695 0 0 0 .403-.627c0-.21-.12-.404-.359-.599-.239-.194-.732-.419-1.465-.703a9.151 9.151 0 0 1-2.124-1.077 3.873 3.873 0 0 1-1.137-1.271 3.21 3.21 0 0 1-.344-1.526c0-.643.18-1.256.509-1.81a3.687 3.687 0 0 1 1.57-1.376c.703-.358 1.585-.523 2.647-.523.614 0 1.242.045 1.855.135.449.06.883.18 1.286.344.06.015.12.074.15.134.015.06.03.12.03.18v2.438c0 .06-.03.12-.075.15-.134.03-.21.03-.27 0Z"
        fill="#31A8FF"
      />
    </g>
  </Icon>
);

export const PhotoshopFormatIcon = React.memo(_PhotoshopFormatIcon);
