import { Center, CircularProgress, Img } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import useProgressiveImg from '../../../../hooks/useProgressiveImg.hook';
import { imageCDNBaseUrl } from '../../../../utils/config';
import { getRandomInt } from '../../../../utils/utility';

interface Props {
  sourceUrl: string;
  borderRadius?: number | string;
  enableBlurLoader?: boolean;
}

let current_progress = 0;
let step = 0.4;

const _ImageBlurLoader = (props: Props) => {
  const [progress, setProgress] = useState<number>(0);
  const { sourceUrl, borderRadius = '6', enableBlurLoader = true } = props;
  const randomInt = getRandomInt(40, 90);
  const lowSpeedInt = getRandomInt(30, 50);
  const [src, blur] = useProgressiveImg({
    lowQualitySrc: `${imageCDNBaseUrl}/?url=${sourceUrl}&h=176&w=176&dpr=1&l=9&output=webp`,
    highQualitySrc: `${imageCDNBaseUrl}/?url=${sourceUrl}&h=176&w=176&dpr=3&output=webp`,
    enabled: true,
  });

  const mainContainerRef = useRef<HTMLDivElement>(null);

  const progressCountInit = () => {
    let interval = setInterval(function () {
      current_progress += step;
      let progress =
        Math.round((Math.atan(current_progress) / (Math.PI / 2)) * 100 * 1000) /
        1000;

      setProgress(progress);
      if (progress >= randomInt) {
        setProgress(90);
        clearInterval(interval);
      } else if (progress >= lowSpeedInt) {
        step = 0.15;
      }
    }, 100);
  };

  useEffect(() => {
    progressCountInit();
  }, []);

  useEffect(() => {
    if (blur) {
      setProgress(100);
    }
  }, [blur]);

  return (
    <Center w={'100%'} h="100%" pos="relative" ref={mainContainerRef}>
      {enableBlurLoader && blur && (
        <Center w={'100%'} h="100%" pos={'absolute'} alignItems="center">
          <CircularProgress
            color="primary.presentable"
            thickness={'2px'}
            value={progress}
            zIndex={'13'}
            size={`${
              (mainContainerRef?.current?.clientHeight as number) * 0.4
            }px`}
          />
        </Center>
      )}
      <Img
        height="100%"
        width="100%"
        objectFit="cover"
        src={src as string}
        borderRadius={borderRadius}
        loading="lazy"
        filter={blur ? 'blur(3px)' : 'none'}
        transition={blur ? 'none' : 'filter 0.3s ease-out'}
      />
    </Center>
  );
};

export const ImageBlurLoader = React.memo(_ImageBlurLoader);
