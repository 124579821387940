import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define a type for the slice state

export enum ACTabEnums {
  members = 'MEMBERS',
  setting = 'SETTING',
  billing = 'BILLING',
}

interface ACStateType {
  selectedTab: ACTabEnums;
  isAddEditBusinessLabelModalVisible: boolean;
  addEditBusinessLabelModalMode: 'ADD' | 'EDIT';
  editBusinessLabelModalData?: { [key: string]: any };
  editBusinessSubLabelModalData?: { [key: string]: any };
  isAddEditBusinessSubLabelModalVisible: boolean;
  addEditBusinessSubLabelModalMode: 'ADD' | 'EDIT';
  editBusinessSubLabelModaParentLabelId?: string;
  isAddEditInterestTagModalVisible: boolean;
  isAddEditInterestTagModalMode: 'ADD' | 'EDIT';
  selectedAddEditInterestTagModalData?: { [key: string]: any };
  isWorkTagReordering: number;
  isWorkspaceLabelTagReordering: number;
}

// Define the initial state using that type
const initialState: ACStateType = {
  selectedTab: ACTabEnums.members,
  isAddEditBusinessLabelModalVisible: false,
  isAddEditBusinessSubLabelModalVisible: false,
  addEditBusinessLabelModalMode: 'ADD',
  addEditBusinessSubLabelModalMode: 'ADD',
  isAddEditInterestTagModalVisible: false,
  isAddEditInterestTagModalMode: 'ADD',
  isWorkTagReordering: -1,
  isWorkspaceLabelTagReordering: -1,
};

export const adminConsoleSlice = createSlice({
  name: 'adminConsole',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    updateSelectedTab: (state, action: PayloadAction<ACTabEnums>) => {
      state.selectedTab = action.payload;
    },
    updateIsAddEditBusinessLabelModalVisibility: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isAddEditBusinessLabelModalVisible = action.payload;
    },

    updateAddEditBusinessLabelModalMode: (
      state,
      action: PayloadAction<'ADD' | 'EDIT'>
    ) => {
      state.addEditBusinessLabelModalMode = action.payload;
    },

    updateAddEditBusinessSubLabelModalMode: (
      state,
      action: PayloadAction<'ADD' | 'EDIT'>
    ) => {
      state.addEditBusinessSubLabelModalMode = action.payload;
    },

    updateEditBusinessLabelModalData: (
      state,
      action: PayloadAction<{ [key: string]: any } | undefined>
    ) => {
      state.editBusinessLabelModalData = action.payload;
    },

    updateEditBusinessSubLabelModalData: (
      state,
      action: PayloadAction<{ [key: string]: any } | undefined>
    ) => {
      state.editBusinessSubLabelModalData = action.payload;
    },

    updateEditBusinessSubLabelModalParentLabelId: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.editBusinessSubLabelModaParentLabelId = action.payload;
    },

    updateIsAddEditBusinessSubLabelModalVisibility: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isAddEditBusinessSubLabelModalVisible = action.payload;
    },
    updateOnAddInterestClick: (state) => {
      state.isAddEditInterestTagModalVisible = true;
      state.isAddEditInterestTagModalMode = 'ADD';
    },
    updateOneEditInterestClick: (
      state,
      action: PayloadAction<{ [key: string]: any }>
    ) => {
      state.isAddEditInterestTagModalVisible = true;
      state.isAddEditInterestTagModalMode = 'EDIT';
      state.selectedAddEditInterestTagModalData = action.payload;
    },
    updateOneAddEditInterestClose: (state) => {
      state.isAddEditInterestTagModalVisible = false;
      state.isAddEditInterestTagModalMode = 'ADD';
      state.selectedAddEditInterestTagModalData = undefined;
    },
    updateWorkTagsReordering: (state) => {
      state.isWorkTagReordering = Math.random();
    },
    updateWorkspaceLabelTagsReordering: (state) => {
      state.isWorkspaceLabelTagReordering = Math.random();
    },
  },
});

export const {
  updateSelectedTab,
  updateIsAddEditBusinessLabelModalVisibility,
  updateIsAddEditBusinessSubLabelModalVisibility,
  updateAddEditBusinessLabelModalMode,
  updateAddEditBusinessSubLabelModalMode,
  updateEditBusinessLabelModalData,
  updateEditBusinessSubLabelModalData,
  updateEditBusinessSubLabelModalParentLabelId,
  updateOnAddInterestClick,
  updateOneEditInterestClick,
  updateOneAddEditInterestClose,
  updateWorkTagsReordering,
  updateWorkspaceLabelTagsReordering,
} = adminConsoleSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value;

export default adminConsoleSlice.reducer;
