import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define a type for the slice state

interface EditorStateType {
  isConversationEditorEnterEventFired: boolean;
  isThreadEditorEnterEventFired: boolean;
  isThreadNnotificationEditorEnterEventFired: boolean;
  editorMessageData?: string;
  threadNotificationEditorMessage?: string;
  selectedThreadNotificationMessageId?: string;
}

// Define the initial state using that type
const initialState: EditorStateType = {
  isConversationEditorEnterEventFired: false,
  isThreadEditorEnterEventFired: false,
  isThreadNnotificationEditorEnterEventFired: true,
};

export const editorSlice = createSlice({
  name: 'editor',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    updateConversationEditorOnEnter: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.editorMessageData = action.payload;
      state.isConversationEditorEnterEventFired = true;
    },
    updateThreadEditorOnEnter: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.editorMessageData = action.payload;
      state.isThreadEditorEnterEventFired = true;
    },
    updateThreadNotificationEditorOnEnter: (
      state,
      action: PayloadAction<{ message: string | undefined; id: string }>
    ) => {
      state.threadNotificationEditorMessage = action.payload.message;
      state.selectedThreadNotificationMessageId = action.payload.id;
      state.isThreadNnotificationEditorEnterEventFired = true;
    },
    resetEditor: (state) => {
      state.isConversationEditorEnterEventFired = false;
      state.isThreadEditorEnterEventFired = false;
      state.editorMessageData = undefined;
      state.threadNotificationEditorMessage = undefined;
      state.selectedThreadNotificationMessageId = undefined;
    },
  },
});

export const {
  updateConversationEditorOnEnter,
  updateThreadEditorOnEnter,
  updateThreadNotificationEditorOnEnter,
  resetEditor,
} = editorSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value;

export default editorSlice.reducer;
