import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum VideoCallStateEnum {
  prejoin = 'PREJOIN',
  joined = 'JOINED',
  left = 'LEFT',
  ended = 'ENDED',
}

// Define a type for the slice state

interface VideoCallStateType {
  isVideoEnabled: boolean;
  videoDevice?: MediaDeviceInfo;
  isAudioEnabled: boolean;
  audioDevice?: MediaDeviceInfo;
  callToken?: string;
  videoCallState: VideoCallStateEnum;
}

// Define the initial state using that type
const initialState: VideoCallStateType = {
  isVideoEnabled: true,
  isAudioEnabled: true,
  videoCallState: VideoCallStateEnum.prejoin,
};

export const videoCallSlice = createSlice({
  name: 'videoCall',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    updateLKAudioDeviceDetail: (
      state,
      action: PayloadAction<MediaDeviceInfo>
    ) => {
      state.audioDevice = action.payload;
    },
    updateLKAudioEnablePermission: (state, action: PayloadAction<boolean>) => {
      state.isAudioEnabled = action.payload;
    },
    updateLKVideoDeviceDetail: (
      state,
      action: PayloadAction<MediaDeviceInfo>
    ) => {
      state.videoDevice = action.payload;
    },
    updateLKVideoEnablePermission: (state, action: PayloadAction<boolean>) => {
      state.isVideoEnabled = action.payload;
    },
    updateLKCallToken: (state, action: PayloadAction<string>) => {
      state.callToken = action.payload;
      state.videoCallState = VideoCallStateEnum.joined;
    },
    updateLKVideoCallState: (
      state,
      action: PayloadAction<VideoCallStateEnum>
    ) => {
      state.videoCallState = action.payload;
    },
    resetLKDetails: (state) => {
      state.isVideoEnabled = true;
      state.isAudioEnabled = true;
      state.videoCallState = VideoCallStateEnum.prejoin;
      state.videoDevice = undefined;
      state.audioDevice = undefined;
      state.callToken = undefined;
    },
  },
});

export const {
  updateLKAudioDeviceDetail,
  updateLKAudioEnablePermission,
  updateLKVideoDeviceDetail,
  updateLKVideoEnablePermission,
  updateLKCallToken,
  updateLKVideoCallState,
  resetLKDetails,
} = videoCallSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value;

export default videoCallSlice.reducer;
