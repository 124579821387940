import Icon, { IconProps } from '@chakra-ui/icon';
import { OmitCommonProps, As } from '@chakra-ui/react';
import React from 'react';

const _AudioFormatIcon = (
  props: JSX.IntrinsicAttributes &
    OmitCommonProps<React.SVGProps<SVGSVGElement>, keyof IconProps> &
    IconProps &
    OmitCommonProps<any, keyof IconProps> & { as?: As<any> | undefined }
) => (
  <Icon viewBox="0 0 31 35" width={31} height={35} focusable="false" {...props}>
    <g fillRule="nonzero" fill="none">
      <path
        d="M2.182 0h18.542l9.829 9.79v22.945a2.182 2.182 0 0 1-2.183 2.182H2.182A2.182 2.182 0 0 1 0 32.735V2.182C0 .977.978 0 2.182 0Z"
        fill="#00C8FF"
      />
      <path
        d="M4.923 22.885v-3.708l-.072-2.07h.023l1.964 5.778h1.216l1.972-5.779h.02l-.073 2.07v3.709h1.338v-7.108h-2.09L7.95 19.66l-.46 1.598h-.021l-.453-1.605-1.279-3.876H3.581v7.108h1.342Zm9.727 0v-2.517h1.5c.542 0 1.007-.091 1.396-.274.39-.183.69-.445.903-.787.211-.343.317-.754.317-1.235 0-.48-.106-.891-.317-1.234a2.062 2.062 0 0 0-.903-.787c-.39-.183-.854-.274-1.395-.274h-2.989v7.108h1.488Zm1.265-3.693H14.65v-2.239h1.265c.45 0 .786.094 1.007.281.22.188.33.467.33.838 0 .368-.11.646-.33.836-.22.19-.556.284-1.007.284Zm6.485 3.8c.499 0 .943-.089 1.332-.266.39-.178.696-.429.92-.753.224-.325.335-.703.335-1.136 0-.256-.045-.478-.137-.668a1.373 1.373 0 0 0-.36-.47 1.623 1.623 0 0 0-.987-.38v-.03c.13-.018.272-.063.424-.135a1.604 1.604 0 0 0 .779-.83c.088-.207.132-.455.132-.744 0-.375-.09-.707-.273-.995a1.82 1.82 0 0 0-.806-.678c-.356-.163-.798-.245-1.326-.245-.444 0-.85.07-1.218.209a2.47 2.47 0 0 0-.922.593 2.016 2.016 0 0 0-.507.928l1.33.494c.082-.342.222-.598.42-.765.2-.167.47-.251.814-.251.325 0 .577.079.755.237.178.159.266.388.266.687 0 .354-.088.612-.264.774-.176.162-.466.243-.87.243-.067 0-.13 0-.19-.002a1.969 1.969 0 0 1-.182-.012v1.082a3.352 3.352 0 0 1 .541-.036c.25 0 .458.032.622.095a.727.727 0 0 1 .372.298c.083.136.125.31.125.522 0 .206-.046.387-.138.542a.937.937 0 0 1-.39.362c-.17.087-.375.13-.618.13-.275 0-.51-.042-.706-.125a1.063 1.063 0 0 1-.47-.382 1.705 1.705 0 0 1-.254-.643l-1.393.621c.185.557.528.984 1.026 1.282.498.298 1.104.447 1.818.447Z"
        fill="#FFF"
      />
    </g>
  </Icon>
);

export const AudioFormatIcon = React.memo(_AudioFormatIcon);
