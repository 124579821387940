import Icon, { IconProps } from '@chakra-ui/icon';
import { OmitCommonProps, As } from '@chakra-ui/react';
import React from 'react';

const _IllustratorFormatIcon = (
  props: JSX.IntrinsicAttributes &
    OmitCommonProps<React.SVGProps<SVGSVGElement>, keyof IconProps> &
    IconProps &
    OmitCommonProps<any, keyof IconProps> & { as?: As<any> | undefined }
) => (
  <Icon viewBox="0 0 36 35" width={36} height={35} focusable="false" {...props}>
    <g fillRule="nonzero" fill="none">
      <path
        d="M6.357 0H29.54a6.352 6.352 0 0 1 6.356 6.357v22.286A6.352 6.352 0 0 1 29.541 35H6.357A6.352 6.352 0 0 1 0 28.643V6.357A6.352 6.352 0 0 1 6.357 0Z"
        fill="#300"
      />
      <path
        d="M17.395 21h-5.564l-1.137 3.515a.262.262 0 0 1-.284.21H7.598c-.164 0-.21-.09-.164-.27L12.25 10.59c.045-.15.09-.314.15-.494.06-.314.09-.643.09-.972-.016-.075.044-.15.119-.165h3.874c.12 0 .18.045.194.12l5.46 15.406c.045.165 0 .24-.15.24h-3.126c-.105.014-.21-.06-.24-.165L17.396 21ZM12.7 17.964h3.799c-.09-.314-.21-.688-.344-1.077-.135-.404-.27-.838-.404-1.287-.15-.463-.284-.912-.434-1.376-.15-.463-.284-.897-.404-1.33-.12-.42-.224-.809-.329-1.168h-.03c-.134.644-.299 1.287-.508 1.93-.224.718-.449 1.466-.688 2.214-.21.762-.434 1.465-.658 2.094ZM25.397 11.517a1.851 1.851 0 0 1-1.33-.523 1.943 1.943 0 0 1-.51-1.376 1.76 1.76 0 0 1 .54-1.332 1.916 1.916 0 0 1 1.33-.523c.584 0 1.032.18 1.361.523.33.36.509.838.494 1.332a1.893 1.893 0 0 1-.523 1.376 1.759 1.759 0 0 1-1.362.523ZM23.722 24.5V12.983c0-.15.06-.21.195-.21h2.961c.135 0 .195.075.195.21V24.5c0 .165-.06.24-.195.24h-2.931c-.15 0-.225-.09-.225-.24Z"
        fill="#FF9A00"
      />
    </g>
  </Icon>
);

export const IllustratorFormatIcon = React.memo(_IllustratorFormatIcon);
