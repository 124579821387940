import { useToast } from "@chakra-ui/react";
import React, { useContext, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { queryClient } from "../..";
import { useSingleConnectData } from "../../apiHooks/Connect/read.hook";
import { useAllCustomTagData } from "../../apiHooks/CustomLabel/read.hook";
import { toastConfig } from "../../component/Alert/CustumToastComponent";
import { AppDetailContext } from "../../Context/AppDetailContext";
import { WorkspaceTagTypeEnum, CustomRQResponseType } from "../../type/types";

interface Props {
  children: React.ReactNode;
}

export const ConnectDetailDataContext = React.createContext<{
  connectData: { [key: string]: any };
  isConnectDataLoading: boolean;
  userConnectStateMap: { [key: string]: any };
  labels: { [key: string]: any }[];
  customLabel: { [key: string]: any }[];
}>({
  connectData: {},
  isConnectDataLoading: true,
  userConnectStateMap: {},
  labels: [],
  customLabel: [],
});

const _ConnectDetailDataContextProvider = ({ children }: Props) => {
  const toast = useToast();
  let navigate = useNavigate();
  const params = useParams<{
    connect_id: string;
    workspace_id: string;
  }>();

  const { selectedWorkspaceData } = useContext(AppDetailContext);

  const { data, status } = useSingleConnectData(
    params.workspace_id as string,
    params.connect_id as string,
    {
      enabled: !!(params.connect_id && params.connect_id.length > 0),
      onError: (error: any) => {
        if (error.response.status === 403) {
          toast(toastConfig("Forbidden Access"));
          navigate(`/workspace/${params.workspace_id}/connects`);
        } else {
          toast(toastConfig("Page could not be opened. Try again."));
        }
      },
    }
  );

  const userConnectStateMap = useMemo(() => {
    const map = {} as any;
    const userConnectStateData =
      data && data.data && data.data.user_to_connect_state
        ? data.data.user_to_connect_state
        : [];

    userConnectStateData.forEach((el: any) => {
      if (!map[el?.user_to_workspace?.user.id]) {
        map[el?.user_to_workspace?.user.id] = el;
      }
    });

    return map;
  }, [data]);

  const labelsData = useMemo(() => {
    const tags =
      selectedWorkspaceData && selectedWorkspaceData.workspace_tags
        ? selectedWorkspaceData.workspace_tags
        : [];

    const finalOptions = [] as any;
    const plainLabelOptions = [] as any;

    tags.forEach((el: any) => {
      let obj = {} as any;
      obj["label"] = el.name;
      obj["value"] = el.id;

      if (el.tag_type === WorkspaceTagTypeEnum.singleSelect) {
        const options = el?.workspace_sub_tags?.map((item: any) => {
          return {
            label: item.name,
            value: item.id,
          };
        });

        obj["options"] = options;
        finalOptions.push(obj);
      } else if (el.tag_type === WorkspaceTagTypeEnum.plain) {
        const subLabel = el.workspace_sub_tags[0];
        if (subLabel) {
          plainLabelOptions.push({
            label: subLabel.name,
            value: subLabel.id,
          });
        }
      }
    });

    finalOptions.push({
      label: "Plain Label",
      value: WorkspaceTagTypeEnum.plain,
      options: plainLabelOptions,
    });

    return finalOptions;
  }, [selectedWorkspaceData]);

  const initialCustomTagsData: CustomRQResponseType | undefined =
    queryClient.getQueryData(["custom-tags", params.workspace_id]);

  const { data: customTagData } = useAllCustomTagData(
    params.workspace_id as string,
    {
      initialData: initialCustomTagsData,
      enabled: !!(
        !initialCustomTagsData &&
        params.workspace_id &&
        params.workspace_id.length > 0
      ),
    }
  );

  const allCustomTagData = useMemo(() => {
    if (customTagData && customTagData.data) {
      return customTagData.data?.map((el: any) => {
        return {
          label: el.name,
          value: el.id,
          type: "CUSTOM_TAG",
        };
      });
    } else {
      return [];
    }
  }, [customTagData]);

  return (
    <ConnectDetailDataContext.Provider
      value={{
        connectData: data && data.data ? data.data : {},
        isConnectDataLoading: status !== "success",
        userConnectStateMap: userConnectStateMap,
        labels: [...labelsData],
        customLabel: [...allCustomTagData],
      }}
    >
      {children}
    </ConnectDetailDataContext.Provider>
  );
};

export const ConnectDetailDataContextProvider = React.memo(
  _ConnectDetailDataContextProvider
);
