import Icon, { IconProps } from '@chakra-ui/icon';
import { OmitCommonProps, As } from '@chakra-ui/react';
import React from 'react';

const _ProjectPackagingIcon = (
  props: JSX.IntrinsicAttributes &
    OmitCommonProps<React.SVGProps<SVGSVGElement>, keyof IconProps> &
    IconProps &
    OmitCommonProps<any, keyof IconProps> & { as?: As<any> | undefined }
) => (
  <Icon width={96} height={96} viewBox="0 0 96 96" focusable="false" {...props}>
    <defs>
      <path id="klk" d="M0 0h96v96H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <use fill="#DEAC00" xlinkHref="#klk" />
      <g transform="translate(25 20)">
        <path fill="#F4D878" d="M0 36h46v20H0zM0 0h46v20H0z" />
        <circle fill="#9A7805" cx={23} cy={28} r={15} />
      </g>
    </g>
  </Icon>
);

export const ProjectPackagingIcon = React.memo(_ProjectPackagingIcon);
