import {
  afterEffectFileFormat,
  audioFormat,
  compressedFileFormatType,
  excelFileFormatType,
  fontFileFormat,
  illustratorFileFormat,
  imageFormatType,
  indesignFileFormat,
  microsoftWordFormat,
  pdfFormatType,
  photoshopFileFormat,
  powerpointFileFormatType,
  sketchFileFormat,
  svgFileFormat,
  videoFileFormat,
} from '../../utils/upload.utils';

export enum ItemsTypeEnum {
  //Review
  review = 'REVIEW',
  reviewClose = 'REVIEW_CLOSE',

  //General Attachment
  attachment = 'ATTACHMENT',

  // Files
  imageFile = 'IMAGE_FILE',
  pdfFile = 'PDF_FILE',
  compressedFile = 'COMPRESS_FILE',
  photoshopFile = 'PHOTOSHOP_FILE',
  illustratorFile = 'ILLUSTRATOR_FILE',
  sketchFile = 'SKETCH_FILE',
  presentationFile = 'PRESENTATION_FILE',
  videoFile = 'VIDEO_FILE',
  fontFile = 'FONT_FILE',
  excelFile = 'EXCEL_FILE',
  indesignFile = 'INDESIGN_FILE',
  wordFile = 'WORD_FILE',
  afterEffectFile = 'AFTEREFFECT_FILE',
  audioFile = 'AUDIO_FILE',
  svgFile = 'SVG_FILE',

  //General Links
  link = 'LINK',

  // Meeting Links
  teamMeetingLink = 'TEAM_MEETING_LINK',
  googleMeetingLink = 'GOOGLE_MEETING_LINK',
  zoomMeetingLink = 'ZOOM_MEETING_LINK',
  presentableMeetingLink = 'PRESENTABLE_MEETING_LINK',

  // Collaboratin Links
  figmaLink = 'FIGMA_LINK',
  zeplinLink = 'ZEPLIN_LINK',
  miroLink = 'MIRO_LINK',
  invisionLink = 'INVISION_LINK',
  googleDocLink = 'GOOGLE_DOC_LINK',
  googleSheetLink = 'GOOGLE_SHEET_LINK',
  googleFormLink = 'GOOGLE_FORM_LINK',
  googlePresentationLink = 'GOOGLE_PRESENTATION_LINK',
  loomLink = 'LOOM_LINK',

  //Payment Ledger
  guestRequestConnect = 'GUEST_REQUEST_CONNECT',
  joinedPaidConnect = 'JOINED_PAID_CONNECT',
}

export const GetFileItemTypeFromMimetype = (mimetype: string) => {
  if (imageFormatType.includes(mimetype)) {
    return { type: ItemsTypeEnum.imageFile };
  }
  if (pdfFormatType.includes(mimetype)) {
    return { type: ItemsTypeEnum.pdfFile };
  }
  if (photoshopFileFormat.includes(mimetype)) {
    return { type: ItemsTypeEnum.photoshopFile };
  }
  if (illustratorFileFormat.includes(mimetype)) {
    return { type: ItemsTypeEnum.illustratorFile };
  }
  if (sketchFileFormat.includes(mimetype)) {
    return { type: ItemsTypeEnum.sketchFile };
  }
  if (powerpointFileFormatType.includes(mimetype)) {
    return { type: ItemsTypeEnum.presentationFile };
  }
  if (videoFileFormat.includes(mimetype)) {
    return { type: ItemsTypeEnum.videoFile };
  }
  if (fontFileFormat.includes(mimetype)) {
    return { type: ItemsTypeEnum.fontFile };
  }
  if (compressedFileFormatType.includes(mimetype)) {
    return { type: ItemsTypeEnum.compressedFile };
  }
  if (excelFileFormatType.includes(mimetype)) {
    return { type: ItemsTypeEnum.excelFile };
  }
  if (indesignFileFormat.includes(mimetype)) {
    return { type: ItemsTypeEnum.indesignFile };
  }
  if (microsoftWordFormat.includes(mimetype)) {
    return { type: ItemsTypeEnum.wordFile };
  }
  if (afterEffectFileFormat.includes(mimetype)) {
    return { type: ItemsTypeEnum.afterEffectFile };
  }
  if (audioFormat.includes(mimetype)) {
    return { type: ItemsTypeEnum.audioFile };
  }
  if (svgFileFormat.includes(mimetype)) {
    return { type: ItemsTypeEnum.svgFile };
  } else {
    return { type: ItemsTypeEnum.attachment };
  }
};

export const GetLinkItemsTypeFromUrl = (url: string) => {
  //check for figma
  if (url.includes('figma.com')) {
    return {
      type: ItemsTypeEnum.figmaLink,
    };
  }

  //check for zeplin
  else if (url.includes('zeplin.io')) {
    return {
      type: ItemsTypeEnum.zeplinLink,
    };
  }

  //check for miro
  else if (url.includes('miro.com')) {
    return {
      type: ItemsTypeEnum.miroLink,
    };
  }

  //check for invision
  else if (url.includes('invisionapp.com')) {
    return {
      type: ItemsTypeEnum.invisionLink,
    };
  }

  //check for sheets
  else if (url.includes('docs.google.com/spreadsheets')) {
    return {
      type: ItemsTypeEnum.googleSheetLink,
    };
  }

  //check for docs
  else if (url.includes('docs.google.com/document')) {
    return {
      type: ItemsTypeEnum.googleDocLink,
    };
  }

  //check for presentation
  else if (url.includes('docs.google.com/presentation')) {
    return {
      type: ItemsTypeEnum.googlePresentationLink,
    };
  }

  //check for forms
  else if (url.includes('docs.google.com/forms')) {
    return {
      type: ItemsTypeEnum.googleFormLink,
    };
  }

  //check for loom
  else if (url.includes('loom.com')) {
    return {
      type: ItemsTypeEnum.loomLink,
    };
  }

  //check for team
  else if (url.includes('teams.live.com/meet/')) {
    return {
      type: ItemsTypeEnum.teamMeetingLink,
    };
  }

  //check for google meet
  else if (url.includes('meet.google.com')) {
    return {
      type: ItemsTypeEnum.googleMeetingLink,
    };
  }

  //check for zoom
  else if (url.includes('zoom.us')) {
    return {
      type: ItemsTypeEnum.zoomMeetingLink,
    };
  }
  //check for general link
  else {
    return {
      type: ItemsTypeEnum.link,
    };
  }
};

export enum ItemsTypeFilterEnum {
  meetings = 'MEETINGS',
  collaboration = 'COLLABORATION',
  files = 'FILES',
  links = 'LINKS',
}

export const itemsTypeList = [
  {
    label: 'Meetings',
    value: 'MEETINGS',
  },
  {
    label: 'Collaboration',
    value: 'COLLABORATION',
  },
  {
    label: 'Files',
    value: 'FILES',
  },
  {
    label: 'Links',
    value: 'LINKS',
  },
];
