import Icon, { IconProps } from '@chakra-ui/icon';
import { OmitCommonProps, As } from '@chakra-ui/react';
import React from 'react';

const _ZoomIcon = (
  props: JSX.IntrinsicAttributes &
    OmitCommonProps<React.SVGProps<SVGSVGElement>, keyof IconProps> &
    IconProps &
    OmitCommonProps<any, keyof IconProps> & { as?: As<any> | undefined }
) => (
  <Icon width={26} height={26} viewBox="0 0 26 26" focusable="false" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M13 26c7.18 0 13-5.82 13-13S20.18 0 13 0 0 5.82 0 13s5.82 13 13 13Z"
        fill="#2196F3"
        fillRule="nonzero"
      />
      <path
        d="M7.629 16.715h8.597v-6.304c0-.95-.77-1.72-1.72-1.72H5.91v6.305c0 .95.77 1.72 1.72 1.72Zm9.743-2.292 3.44 2.292V8.691l-3.44 2.293v3.44Z"
        fill="#FFF"
      />
    </g>
  </Icon>
);

export const ZoomIcon = React.memo(_ZoomIcon);
