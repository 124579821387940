import dayjs from 'dayjs';

export const groupChatMessage = (data: { [key: string]: any }[]) => {
  const dataMap = {} as any;
  const initData = [...data];
  initData.forEach((el: any) => {
    const startOfDayEpoch = dayjs(el.created_at).startOf('day').valueOf();
    if (!dataMap.hasOwnProperty(startOfDayEpoch)) {
      dataMap[startOfDayEpoch] = undefined;
    }
  });
  return initData;
};

export const groupChatCounts = (
  data: { [key: string]: any }[]
): { [key: number]: string } => {
  const dataMap = {} as any;
  const initData = [...data];
  initData.forEach((el: any) => {
    const startOfDayEpoch = dayjs(el.created_at).startOf('day').valueOf();
    if (!dataMap.hasOwnProperty(startOfDayEpoch)) {
      dataMap[startOfDayEpoch] = 1;
    } else {
      dataMap[startOfDayEpoch] = dataMap[startOfDayEpoch] + 1;
    }
  });

  return dataMap;
};
