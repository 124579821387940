import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { LibraryHeaderTabsEnum } from '../component/Library/LeftSideComponent/HeaderComponent';
import { ProjectViewTypeEnum } from '../component/Library/LeftSideComponent/ProjectComponent/ProjectSubHeaderComponent';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

// Define a type for the slice state

export enum ItemsDetailHeaderTabsEnum {
  overview = 'OVERVIEW',
  activity = 'ACTIVITY',
}

interface LibraryStateType {
  selectedTab: LibraryHeaderTabsEnum;
  itemDetailselectedTab: ItemsDetailHeaderTabsEnum;
  isRightComponentVisible: boolean;
  libraryRightComponentMode: 'ASSET' | 'PROJECT' | 'PROFILE';
  selectedItemId?: string;
  selectedItemToFocusId?: string;
  itemFilters: { [key: string]: any };
  projectFilters: { [key: string]: any };
  favouriteFilters: { [key: string]: any };
  favouriteFolderFilters: { [key: string]: any };
  isAddItemModalVisible: boolean;
  isEditItemModalVisible: boolean;
  editItemModalSelectedField: 'TAG' | 'DESCRIPTION' | 'EXPIRY' | 'NAME';
  selectedEditItemData: { [key: string]: any };
  isAddItemToProjectModalVisible: boolean;
  selectedAddItemToProjectID?: { [key: string]: any };

  isAddEditFavouriteFolderModalVisible: boolean;
  addEditFavouriteFolderModalMode: 'CREATE' | 'EDIT';
  selectedaddEditFavouriteFolderModalData: { [key: string]: any };
  selectedFavouriteFolderId?: string;
  isDeleteFavouriteItemsOrFolderModalVisible: boolean;
  deleteFavouriteItemsOrFolderModalMode: 'FOLDER' | 'ITEM';
  selectedDeleteFavouriteId?: string;
  selectedDeleteFavouriteData: { [key: string]: any };

  isItemDeleteModalVisible: boolean;
  selectedItemDeleteId?: string;
  selectedTaskDate: number;
  isTaskDetailDrawerVisible: boolean;
  taskDetailDrawerData?: { [key: string]: any };

  projectSelectedViewType: ProjectViewTypeEnum;

  //Folder add item
  isAddFavouriteItemToFolderModalVisible: boolean;
  addFavouriteItemToFolderModalSelectedItemId?: string;
  addFavouriteItemToFolderModalSelectedFavouriteId?: string;
}

// Define the initial state using that type
const initialState: LibraryStateType = {
  selectedTab: LibraryHeaderTabsEnum.items,
  isRightComponentVisible: false,
  libraryRightComponentMode: 'ASSET',
  itemDetailselectedTab: ItemsDetailHeaderTabsEnum.overview,
  itemFilters: { type: 'RECENTLY_ADDED' },
  projectFilters: { type: 'ACTIVE' },
  favouriteFilters: { type: 'ALL' },
  favouriteFolderFilters: {},
  isAddItemModalVisible: false,
  isEditItemModalVisible: false,
  editItemModalSelectedField: 'NAME',
  selectedEditItemData: {},
  isAddItemToProjectModalVisible: false,
  isAddEditFavouriteFolderModalVisible: false,
  addEditFavouriteFolderModalMode: 'CREATE',
  selectedaddEditFavouriteFolderModalData: {},
  isDeleteFavouriteItemsOrFolderModalVisible: false,
  deleteFavouriteItemsOrFolderModalMode: 'ITEM',
  selectedDeleteFavouriteData: {},
  isItemDeleteModalVisible: false,
  selectedTaskDate: dayjs().valueOf(),
  isTaskDetailDrawerVisible: false,
  projectSelectedViewType: ProjectViewTypeEnum.kanban,
  isAddFavouriteItemToFolderModalVisible: false,
};

export const librarySlice = createSlice({
  name: 'library',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    resetLibraryOnNavChange: (state) => {
      state.selectedTab = LibraryHeaderTabsEnum.items;
      state.isRightComponentVisible = false;
      state.libraryRightComponentMode = 'ASSET';
      state.selectedItemId = undefined;
      state.itemFilters = { type: 'RECENTLY_ADDED' };
      state.projectFilters = { type: 'ACTIVE' };
      state.favouriteFilters = { type: 'ALL' };
    },
    updateLibrarySelectedTab: (
      state,
      action: PayloadAction<LibraryHeaderTabsEnum>
    ) => {
      state.selectedTab = action.payload;
    },
    updateLibraryRightComponentVisibility: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isRightComponentVisible = action.payload;
    },

    updateLibraryRightComponentMode: (
      state,
      action: PayloadAction<'ASSET' | 'PROJECT' | 'PROFILE'>
    ) => {
      state.libraryRightComponentMode = action.payload;
    },
    updateItemCardClick: (state, action: PayloadAction<string>) => {
      state.isRightComponentVisible = true;
      state.itemDetailselectedTab = ItemsDetailHeaderTabsEnum.overview;
      state.selectedItemId = action.payload;
    },
    updateItemCardonClose: (state) => {
      state.isRightComponentVisible = false;
      state.libraryRightComponentMode = 'ASSET';
      state.itemDetailselectedTab = ItemsDetailHeaderTabsEnum.overview;
      state.selectedItemId = undefined;
      state.selectedItemToFocusId = undefined;
    },
    updateSelectedItemToFocusId: (state, action: PayloadAction<string>) => {
      state.selectedItemToFocusId = action.payload;
    },
    updateItemDetailSelectedTab: (
      state,
      action: PayloadAction<ItemsDetailHeaderTabsEnum>
    ) => {
      state.itemDetailselectedTab = action.payload;
    },
    updateLibraryItemFilter: (
      state,
      action: PayloadAction<{ [key: string]: any }>
    ) => {
      state.itemFilters = action.payload;
    },
    updateLibraryFavouriteFilter: (
      state,
      action: PayloadAction<{ [key: string]: any }>
    ) => {
      state.favouriteFilters = action.payload;
    },
    updateLibraryFavouriteFolderFilter: (
      state,
      action: PayloadAction<{ [key: string]: any }>
    ) => {
      state.favouriteFolderFilters = action.payload;
    },
    updateLibraryProjectFilter: (
      state,
      action: PayloadAction<{ [key: string]: any }>
    ) => {
      state.projectFilters = action.payload;
    },
    updateAddItemModalVisibility: (state, action: PayloadAction<boolean>) => {
      state.isAddItemModalVisible = action.payload;
    },
    updateEditItemCardClick: (
      state,
      action: PayloadAction<{
        data: { [key: string]: any };
        field: 'TAG' | 'DESCRIPTION' | 'EXPIRY' | 'NAME';
      }>
    ) => {
      state.isEditItemModalVisible = true;
      state.selectedEditItemData = action.payload.data;
      state.editItemModalSelectedField = action.payload.field;
    },
    updateEditItemCardClose: (state) => {
      state.isEditItemModalVisible = false;
      state.selectedEditItemData = {};
      state.editItemModalSelectedField = 'NAME';
    },

    updateAddItemToProjectClick: (
      state,
      action: PayloadAction<{ [key: string]: any }>
    ) => {
      state.isAddItemToProjectModalVisible = true;
      state.selectedAddItemToProjectID = action.payload;
    },
    updateAddItemToProjectClose: (state) => {
      state.isAddItemToProjectModalVisible = false;
      state.selectedAddItemToProjectID = undefined;
    },

    updateAddFavouriteFolderClick: (state) => {
      state.isAddEditFavouriteFolderModalVisible = true;
      state.addEditFavouriteFolderModalMode = 'CREATE';
    },
    updateEditFavouriteFolderClick: (
      state,
      action: PayloadAction<{ [key: string]: any }>
    ) => {
      state.isAddEditFavouriteFolderModalVisible = true;
      state.addEditFavouriteFolderModalMode = 'EDIT';
      state.selectedaddEditFavouriteFolderModalData = action.payload;
    },
    updateAddEditFavouriteFolderClose: (state) => {
      state.isAddEditFavouriteFolderModalVisible = false;
      state.addEditFavouriteFolderModalMode = 'CREATE';
      state.selectedaddEditFavouriteFolderModalData = {};
    },
    updateFavouriteFolderCardClick: (state, action: PayloadAction<string>) => {
      state.selectedTab = LibraryHeaderTabsEnum.favouriteFolder;
      state.selectedFavouriteFolderId = action.payload;
    },
    updateFavouriteFolderCardClose: (state) => {
      state.selectedTab = LibraryHeaderTabsEnum.favourite;
      state.selectedFavouriteFolderId = undefined;
    },

    updateOnDeleteFavouriteModalClick: (
      state,
      action: PayloadAction<{
        id: string;
        mode: 'FOLDER' | 'ITEM';
        data: { [key: string]: any };
      }>
    ) => {
      state.isDeleteFavouriteItemsOrFolderModalVisible = true;
      state.selectedDeleteFavouriteId = action.payload.id;
      state.deleteFavouriteItemsOrFolderModalMode = action.payload.mode;
      state.selectedDeleteFavouriteData = action.payload.data;
    },

    updateOnDeleteFavouriteModalClose: (state) => {
      state.isDeleteFavouriteItemsOrFolderModalVisible = false;
      state.selectedDeleteFavouriteId = undefined;
      state.deleteFavouriteItemsOrFolderModalMode = 'ITEM';
      state.selectedDeleteFavouriteData = {};
    },

    updateDeleteItemModalClick: (state, action: PayloadAction<string>) => {
      state.isItemDeleteModalVisible = true;
      state.selectedItemDeleteId = action.payload;
    },
    updateDeleteItemModalClose: (state) => {
      state.isItemDeleteModalVisible = false;
      state.selectedItemDeleteId = undefined;
    },
    updateSelectedTaskDate: (state, action: PayloadAction<number>) => {
      state.selectedTaskDate = action.payload;
    },
    updateTaskDetailDrawerOnClick: (
      state,
      action: PayloadAction<{ [key: string]: any }>
    ) => {
      state.taskDetailDrawerData = action.payload;
      state.isTaskDetailDrawerVisible = true;
    },
    updateTaskDetailDrawerOnClose: (state) => {
      state.taskDetailDrawerData = undefined;
      state.isTaskDetailDrawerVisible = false;
    },
    updateProjectViewType: (
      state,
      action: PayloadAction<ProjectViewTypeEnum>
    ) => {
      state.projectSelectedViewType = action.payload;
    },

    // Add folder

    updateIsAddFavouriteItemToFolderModalVisible: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isAddFavouriteItemToFolderModalVisible = action.payload;
    },

    updateAddFavouriteItemToFolderModalSelectedItemId: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.addFavouriteItemToFolderModalSelectedItemId = action.payload;
    },
    updateAddFavouriteItemToFolderModalSelectedFavouriteId: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.addFavouriteItemToFolderModalSelectedFavouriteId = action.payload;
    },
  },
});

export const {
  resetLibraryOnNavChange,
  updateLibrarySelectedTab,
  updateLibraryRightComponentVisibility,
  updateLibraryRightComponentMode,
  updateItemCardClick,
  updateSelectedItemToFocusId,
  updateItemCardonClose,
  updateItemDetailSelectedTab,
  updateLibraryItemFilter,
  updateLibraryFavouriteFilter,
  updateLibraryFavouriteFolderFilter,
  updateLibraryProjectFilter,
  updateAddItemModalVisibility,
  updateEditItemCardClick,
  updateEditItemCardClose,
  updateAddItemToProjectClick,
  updateAddItemToProjectClose,
  updateAddFavouriteFolderClick,
  updateEditFavouriteFolderClick,
  updateAddEditFavouriteFolderClose,
  updateFavouriteFolderCardClick,
  updateFavouriteFolderCardClose,
  updateOnDeleteFavouriteModalClick,
  updateOnDeleteFavouriteModalClose,
  updateDeleteItemModalClick,
  updateDeleteItemModalClose,
  updateSelectedTaskDate,
  updateTaskDetailDrawerOnClick,
  updateTaskDetailDrawerOnClose,
  updateProjectViewType,
  updateIsAddFavouriteItemToFolderModalVisible,
  updateAddFavouriteItemToFolderModalSelectedItemId,
  updateAddFavouriteItemToFolderModalSelectedFavouriteId,
} = librarySlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value;

export default librarySlice.reducer;
