import { Center, Box } from '@chakra-ui/react';
import LogoIcon from '../theme/icons/LogoIcon';

interface Props {
  background?: string;
}

export const SuspenseLoader = (props: Props) => {
  const { background = 'white' } = props;
  return (
    <Center h="100%" w="100%" bg={background}>
      <Box w="40">
        <LogoIcon height="100%" width="100%" />
      </Box>
    </Center>
  );
};
