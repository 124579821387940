import Icon, { IconProps } from '@chakra-ui/icon';
import { OmitCommonProps, As } from '@chakra-ui/react';
import React from 'react';

const _LoomIcon = (
  props: JSX.IntrinsicAttributes &
    OmitCommonProps<React.SVGProps<SVGSVGElement>, keyof IconProps> &
    IconProps &
    OmitCommonProps<any, keyof IconProps> & { as?: As<any> | undefined }
) => (
  <Icon width={26} height={26} viewBox="0 0 26 26" focusable="false" {...props}>
    <path
      d="M25.89 11.505h-7.57l6.555-3.785-1.44-2.495-6.556 3.786 3.785-6.556-2.495-1.44-3.784 6.555V0h-2.88v7.57L7.719 1.015l-2.494 1.44L9.01 9.01 2.454 5.225 1.014 7.72l6.556 3.785H0v2.88h7.57L1.014 18.17l1.44 2.494L9.01 16.88l-3.786 6.556 2.495 1.44 3.785-6.557v7.57h2.88v-7.57l3.784 6.556 2.495-1.44-3.785-6.556 6.556 3.785 1.44-2.494-6.556-3.785h7.57v-2.88h.002Zm-12.945 5.357a3.931 3.931 0 1 1 0-7.863 3.931 3.931 0 0 1 0 7.863Z"
      fill="#625DF5"
      fillRule="nonzero"
    />
  </Icon>
);

export const LoomIcon = React.memo(_LoomIcon);
