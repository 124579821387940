import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

// Define a type for the slice state

export enum ProjectReviewDetailTabEnums {
  detail = 'PROJECT_DETAIL',
  notes = 'NOTES',
}

interface ProjectStateType {
  isDetailSectionVisible: boolean;
  zoomValue: number;
  zoomFactor: number;
  pinnedPoints: { [key: string]: any };
  selectedAttachment: string;
  isDrawingEnabled: boolean;
  drawingToolSelectedColor: string;
  isCommentsVisible: boolean;
  notificationDataToShowOnFeedbackPage?: { [key: string]: any };
}

// Define the initial state using that type
const initialState: ProjectStateType = {
  isDetailSectionVisible: true,
  zoomValue: 1,
  zoomFactor: 1,
  pinnedPoints: {},
  selectedAttachment: '',
  isDrawingEnabled: false,
  drawingToolSelectedColor: 'black',
  isCommentsVisible: false,
};

export const projectReviewSlice = createSlice({
  name: 'projectReview',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    toggleSidenavBar: (state) => {
      const value = !state.isDetailSectionVisible;
      state.isDetailSectionVisible = value;
      if (value) {
        state.isDrawingEnabled = false;
      }
    },
    enablePointerSelection: (state) => {
      state.isDetailSectionVisible = true;
      state.isDrawingEnabled = false;
    },
    toggleDrawingEnabled: (state) => {
      const value = !state.isDrawingEnabled;
      state.isDrawingEnabled = value;
      if (value) {
        state.isDetailSectionVisible = false;
      }
    },
    updateDrawingToolSelectedColor: (state, action: PayloadAction<string>) => {
      state.drawingToolSelectedColor = action.payload;
    },
    updateZoomValue: (state, action: PayloadAction<number>) => {
      state.zoomValue = action.payload;
    },
    updateZoomFactor: (state, action: PayloadAction<number>) => {
      state.zoomFactor = action.payload;
    },
    resetPinnedPoints: (state) => {
      state.pinnedPoints = {};
    },

    initializePinnedPoints: (
      state,
      action: PayloadAction<{
        messageItemId: string;
        value: { [key: string]: any }[];
      }>
    ) => {
      const initialData = { ...state.pinnedPoints };
      initialData[action.payload.messageItemId] = action.payload.value;
      state.pinnedPoints = initialData;
    },
    addPinnedPoints: (
      state,
      action: PayloadAction<{
        messageItemId: string;
        value: { [key: string]: any };
      }>
    ) => {
      // if existing attachment
      let initialData = { ...state.pinnedPoints };

      const selectedMessageItemPointesData =
        initialData[action.payload.messageItemId];

      if (
        selectedMessageItemPointesData &&
        selectedMessageItemPointesData.length > 0
      ) {
        selectedMessageItemPointesData.push(action.payload.value);
        const reorderedData = [...selectedMessageItemPointesData].sort(
          (a: any, b: any) =>
            dayjs(a.created_at).valueOf() - dayjs(b.created_at).valueOf()
        );
        initialData[action.payload.messageItemId] = reorderedData;
      } else {
        initialData[action.payload.messageItemId] = [action.payload.value];
      }
      state.pinnedPoints = initialData;
    },
    updatePinnedPointData: (
      state,
      action: PayloadAction<{
        messageItemId: string;
        value: { [key: string]: any };
      }>
    ) => {
      const initialData = { ...state.pinnedPoints };
      const selectedMessageItemPointesData =
        initialData[action.payload.messageItemId];

      const filterPoints = selectedMessageItemPointesData.filter(
        (el: any) =>
          !(el.x === action.payload.value.x && el.y === action.payload.value.y)
      );

      const reorderedData = [...filterPoints, action.payload.value].sort(
        (a: any, b: any) =>
          dayjs(a.created_at).valueOf() - dayjs(b.created_at).valueOf()
      );

      initialData[action.payload.messageItemId] = reorderedData;

      state.pinnedPoints = initialData;
    },
    updateSelectedAttachment: (state, action: PayloadAction<string>) => {
      state.selectedAttachment = action.payload;
    },
    updateSinglePinnedPointData: (
      state,
      action: PayloadAction<{
        messageItemId: string;
        value: {
          [key: string]: any;
        };
      }>
    ) => {
      const initialData = { ...state.pinnedPoints };
      const selectedAttachmentPinnedPoints =
        initialData[action.payload.messageItemId];
      const pointerId = action.payload.value.pointer_id;

      let index = -1;

      selectedAttachmentPinnedPoints.forEach((el: any, key: number) => {
        if (el.pointer_id === pointerId) {
          index = key;
        }
      });
      if (index > -1) {
        selectedAttachmentPinnedPoints.splice(index, 1, {
          ...action.payload.value,
        });
        initialData[action.payload.messageItemId] =
          selectedAttachmentPinnedPoints;
        state.pinnedPoints = initialData;
      }
    },

    updateSinglePinnedPointDataOnDeleteAction: (
      state,
      action: PayloadAction<{
        messageItemId: string;
        value: {
          [key: string]: any;
        };
      }>
    ) => {
      const initialData = { ...state.pinnedPoints };
      const selectedAttachmentPinnedPoints =
        initialData[action.payload.messageItemId];
      const pointerId = action.payload.value.pointer_id;

      let index = -1;

      selectedAttachmentPinnedPoints.forEach((el: any, key: number) => {
        if (el.pointer_id === pointerId) {
          index = key;
        }
      });
      if (index > -1) {
        selectedAttachmentPinnedPoints.splice(index, 1);
        initialData[action.payload.messageItemId] =
          selectedAttachmentPinnedPoints;
        state.pinnedPoints = initialData;
      }
    },

    updateAddedReviewMsgOnPinnedPointData: (
      state,
      action: PayloadAction<{
        messageItemId: string;
        value: {
          [key: string]: any;
        };
      }>
    ) => {
      const initialData = { ...state.pinnedPoints };
      const selectedAttachmentPinnedPoints =
        initialData[action.payload.messageItemId];
      const pointerId = action.payload.value.reviewPointer;

      let index = -1;

      selectedAttachmentPinnedPoints.forEach((el: any, key: number) => {
        if (el.pointer_id === pointerId) {
          index = key;
        }
      });

      if (index > -1) {
        const selectedPointerData = selectedAttachmentPinnedPoints[index];

        selectedPointerData.review_messages.push(action.payload.value);

        selectedAttachmentPinnedPoints.splice(index, 1, {
          ...selectedPointerData,
        });

        const reorderedData = [...selectedAttachmentPinnedPoints].sort(
          (a: any, b: any) =>
            dayjs(a.created_at).valueOf() - dayjs(b.created_at).valueOf()
        );

        initialData[action.payload.messageItemId] = reorderedData;
        state.pinnedPoints = initialData;
      }
    },

    updateReviewMsgOnPinnedPointData: (
      state,
      action: PayloadAction<{
        messageItemId: string;
        value: {
          [key: string]: any;
        };
      }>
    ) => {
      const initialData = { ...state.pinnedPoints };
      const selectedAttachmentPinnedPoints =
        initialData[action.payload.messageItemId];
      const pointerId = action.payload.value.pointer_id;

      let index = -1;

      selectedAttachmentPinnedPoints.forEach((el: any, key: number) => {
        if (el.pointer_id === pointerId) {
          index = key;
        }
      });

      if (index > -1) {
        const selectedPointerData = selectedAttachmentPinnedPoints[index];

        const reviewMessages = selectedPointerData.review_messages;

        reviewMessages.forEach((el: any) => {
          if (el.id === action.payload.value.review_message_id) {
            el.message = action.payload.value.message;
          }
        });

        selectedPointerData.review_messages = reviewMessages;

        selectedAttachmentPinnedPoints.splice(index, 1, {
          ...selectedPointerData,
        });

        const reorderedData = [...selectedAttachmentPinnedPoints].sort(
          (a: any, b: any) =>
            dayjs(a.created_at).valueOf() - dayjs(b.created_at).valueOf()
        );

        initialData[action.payload.messageItemId] = reorderedData;
        state.pinnedPoints = initialData;
      }
    },

    deleteReviewMsgOnPinnedPointData: (
      state,
      action: PayloadAction<{
        messageItemId: string;
        value: {
          [key: string]: any;
        };
      }>
    ) => {
      const initialData = { ...state.pinnedPoints };
      const selectedAttachmentPinnedPoints =
        initialData[action.payload.messageItemId];
      const pointerId = action.payload.value.pointer_id;

      let index = -1;

      selectedAttachmentPinnedPoints.forEach((el: any, key: number) => {
        if (el.pointer_id === pointerId) {
          index = key;
        }
      });

      if (index > -1) {
        const selectedPointerData = selectedAttachmentPinnedPoints[index];

        if (
          action.payload.value.main_comment &&
          action.payload.value.main_comment === 'true'
        ) {
          selectedAttachmentPinnedPoints.splice(index, 1);
        } else {
          const reviewMessages = selectedPointerData.review_messages;

          const filteredMsg = reviewMessages.filter((el: any) => {
            return el.id !== action.payload.value.review_message_id;
          });

          selectedPointerData.review_messages = filteredMsg;

          selectedAttachmentPinnedPoints.splice(index, 1, {
            ...selectedPointerData,
          });
        }

        const reorderedData = [...selectedAttachmentPinnedPoints].sort(
          (a: any, b: any) =>
            dayjs(a.created_at).valueOf() - dayjs(b.created_at).valueOf()
        );

        initialData[action.payload.messageItemId] = reorderedData;
        state.pinnedPoints = initialData;
      }
    },

    updateIsCommentSectionVisible: (state, action: PayloadAction<boolean>) => {
      state.isCommentsVisible = action.payload;
    },

    addNotificationDataToShowOnFeedbackPage: (
      state,
      action: PayloadAction<{
        [key: string]: any;
      }>
    ) => {
      state.notificationDataToShowOnFeedbackPage = action.payload;
    },

    resetNotificationDataToShowOnFeedbackPage: (state) => {
      state.notificationDataToShowOnFeedbackPage = undefined;
    },
  },
});

export const {
  toggleSidenavBar,
  enablePointerSelection,
  toggleDrawingEnabled,
  updateDrawingToolSelectedColor,
  updateZoomValue,
  updateZoomFactor,
  addPinnedPoints,
  resetPinnedPoints,
  updatePinnedPointData,
  updateSelectedAttachment,
  initializePinnedPoints,
  updateSinglePinnedPointData,
  updateSinglePinnedPointDataOnDeleteAction,
  updateAddedReviewMsgOnPinnedPointData,
  updateReviewMsgOnPinnedPointData,
  deleteReviewMsgOnPinnedPointData,
  updateIsCommentSectionVisible,
  addNotificationDataToShowOnFeedbackPage,
  resetNotificationDataToShowOnFeedbackPage,
} = projectReviewSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value;

export default projectReviewSlice.reducer;
