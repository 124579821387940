import dayjs from 'dayjs';
import { ItemsTypeEnum } from '../component/Library/helper';
import { GoogleDocsIcon } from '../svg/Chat/link/googleDocs.icon';
import { GoogleFormsIcon } from '../svg/Chat/link/googleForm.icon';
import { GoogleMeetIcon } from '../svg/Chat/link/googleMeet.icon';
import { GoogleSheetIcon } from '../svg/Chat/link/googleSheet.icon';
import { GoogleSlidesIcon } from '../svg/Chat/link/googleSlides.icon';
import { InvisionIcon } from '../svg/Chat/link/invision.icon';
import { LoomIcon } from '../svg/Chat/link/loom.icon';
import { MiroIcon } from '../svg/Chat/link/miro.icon';
import { TeamsMeetingIcon } from '../svg/Chat/link/teamsMeeting.icon';
import { WebLinkIcon } from '../svg/Chat/link/webLink.icon';
import { ZeplinIcon } from '../svg/Chat/link/zeplin.icon';
import { ZoomIcon } from '../svg/Chat/link/zoom.icon';
import { RupeeIcon } from '../svg/Icons/rupee.icons';
import { FigmaIcon } from '../svg/Project/figma.icon';
import { AfterEffectFormatIcon } from '../svg/UploadFileFormat/afterEffectFormat.icon';
import { AudioFormatIcon } from '../svg/UploadFileFormat/audioFormat.icon';
import { CompressedFileFormatIcon } from '../svg/UploadFileFormat/compressedFileFormat.icon';
import { ExcelFormatIcon } from '../svg/UploadFileFormat/excelFormat.icon';
import { FontFormatIcon } from '../svg/UploadFileFormat/fontFormat.icon';
import { IllustratorFormatIcon } from '../svg/UploadFileFormat/illustratorFormat.icon';
import { ImageFormatIcon } from '../svg/UploadFileFormat/imageFormat.icon';
import { IndesignFormatIcon } from '../svg/UploadFileFormat/indesignFormat.icon';
import { OtherFormatIcon } from '../svg/UploadFileFormat/otherFormat.icon';
import { PdfFormatIcon } from '../svg/UploadFileFormat/pdfFormat.icon';
import { PhotoshopFormatIcon } from '../svg/UploadFileFormat/photoshopFormat.icon';
import { PPTFormatIcon } from '../svg/UploadFileFormat/pptFormat.icon';
import { SketchFormatIcon } from '../svg/UploadFileFormat/sketchFormat.icon';
import { SVGFormatIcon } from '../svg/UploadFileFormat/svgFormat.icon';
import { VideoFormatIcon } from '../svg/UploadFileFormat/videoFormat.icon';
import { WordFormatIcon } from '../svg/UploadFileFormat/wordFormat.icon';

export const getItemsTypeOptionsData = (
  type: ItemsTypeEnum,
  iconHeight?: string,
  iconWidth?: string
) => {
  switch (type) {
    case ItemsTypeEnum.teamMeetingLink: {
      return {
        icon: (
          <TeamsMeetingIcon
            height={iconHeight ? iconHeight : undefined}
            width={iconWidth ? iconWidth : undefined}
          />
        ),
        bg: '#d1d3ff',
        label: 'Teams meeting',
      };
    }
    case ItemsTypeEnum.googleMeetingLink: {
      return {
        icon: (
          <GoogleMeetIcon
            height={iconHeight ? iconHeight : undefined}
            width={iconWidth ? iconWidth : undefined}
          />
        ),
        bg: '#caf4d5',
        label: 'Google meeting',
      };
    }
    case ItemsTypeEnum.zoomMeetingLink: {
      return {
        icon: (
          <ZoomIcon
            height={iconHeight ? iconHeight : undefined}
            width={iconWidth ? iconWidth : undefined}
          />
        ),
        bg: '#d9eeff',
        label: 'Zoom meeting',
      };
    }
    case ItemsTypeEnum.presentableMeetingLink: {
      return {
        icon: (
          <ZoomIcon
            height={iconHeight ? iconHeight : undefined}
            width={iconWidth ? iconWidth : undefined}
          />
        ),
        bg: '#d9eeff',
        label: 'Live Connect',
      };
    }
    case ItemsTypeEnum.figmaLink: {
      return {
        icon: (
          <FigmaIcon
            height={iconHeight ? iconHeight : undefined}
            width={iconWidth ? iconWidth : undefined}
          />
        ),
        bg: '#ebdcff',
        label: 'Figma link',
      };
    }
    case ItemsTypeEnum.zeplinLink: {
      return {
        icon: (
          <ZeplinIcon
            height={iconHeight ? iconHeight : undefined}
            width={iconWidth ? iconWidth : undefined}
          />
        ),
        bg: '#ffd5c8',
        label: 'Zeplin link',
      };
    }
    case ItemsTypeEnum.miroLink: {
      return {
        icon: (
          <MiroIcon
            height={iconHeight ? iconHeight : undefined}
            width={iconWidth ? iconWidth : undefined}
          />
        ),
        bg: '#ffecb7',
        label: 'Miro link',
      };
    }
    case ItemsTypeEnum.invisionLink: {
      return {
        icon: (
          <InvisionIcon
            height={iconHeight ? iconHeight : undefined}
            width={iconWidth ? iconWidth : undefined}
          />
        ),
        bg: '#ffb7c8',
        label: 'Invision link',
      };
    }
    case ItemsTypeEnum.googleDocLink: {
      return {
        icon: (
          <GoogleDocsIcon
            height={iconHeight ? iconHeight : undefined}
            width={iconWidth ? iconWidth : undefined}
          />
        ),
        bg: '#bee4ff',
        label: 'Google docs link',
      };
    }
    case ItemsTypeEnum.googleSheetLink: {
      return {
        icon: (
          <GoogleSheetIcon
            height={iconHeight ? iconHeight : undefined}
            width={iconWidth ? iconWidth : undefined}
          />
        ),
        bg: '#a2efcc',
        label: 'Google sheet link',
      };
    }
    case ItemsTypeEnum.googleFormLink: {
      return {
        icon: (
          <GoogleFormsIcon
            height={iconHeight ? iconHeight : undefined}
            width={iconWidth ? iconWidth : undefined}
          />
        ),
        bg: '#ebdcff',
        label: 'Google form link',
      };
    }
    case ItemsTypeEnum.googlePresentationLink: {
      return {
        icon: (
          <GoogleSlidesIcon
            height={iconHeight ? iconHeight : undefined}
            width={iconWidth ? iconWidth : undefined}
          />
        ),
        bg: '#ffecb7',
        label: 'Google slides link',
      };
    }
    case ItemsTypeEnum.loomLink: {
      return {
        icon: (
          <LoomIcon
            height={iconHeight ? iconHeight : undefined}
            width={iconWidth ? iconWidth : undefined}
          />
        ),
        bg: '#dbdaff',
        label: 'Loom link',
      };
    }
    case ItemsTypeEnum.link: {
      return {
        icon: (
          <WebLinkIcon
            height={iconHeight ? iconHeight : undefined}
            width={iconWidth ? iconWidth : undefined}
          />
        ),
        bg: '#fbc8f8',
        label: 'Web link',
      };
    }

    // FFile Formats

    case ItemsTypeEnum.imageFile: {
      return {
        icon: (
          <ImageFormatIcon
            height={iconHeight ? iconHeight : undefined}
            width={iconWidth ? iconWidth : undefined}
          />
        ),
        bg: '#cacaff',
        label: 'Image file',
      };
    }

    case ItemsTypeEnum.pdfFile: {
      return {
        icon: (
          <PdfFormatIcon
            height={iconHeight ? iconHeight : undefined}
            width={iconWidth ? iconWidth : undefined}
          />
        ),
        bg: '#ffcac6',
        label: 'PDF file',
      };
    }

    case ItemsTypeEnum.compressedFile: {
      return {
        icon: (
          <CompressedFileFormatIcon
            height={iconHeight ? iconHeight : undefined}
            width={iconWidth ? iconWidth : undefined}
          />
        ),
        bg: '#dbf7ff',
        label: 'Compressed file',
      };
    }
    case ItemsTypeEnum.photoshopFile: {
      return {
        icon: (
          <PhotoshopFormatIcon
            height={iconHeight ? iconHeight : undefined}
            width={iconWidth ? iconWidth : undefined}
          />
        ),
        bg: '#b2dfff',
        label: 'Photoshop file',
      };
    }
    case ItemsTypeEnum.illustratorFile: {
      return {
        icon: (
          <IllustratorFormatIcon
            height={iconHeight ? iconHeight : undefined}
            width={iconWidth ? iconWidth : undefined}
          />
        ),
        bg: '#ffdca7',
        label: 'Illustrator file',
      };
    }
    case ItemsTypeEnum.sketchFile: {
      return {
        icon: (
          <SketchFormatIcon
            height={iconHeight ? iconHeight : undefined}
            width={iconWidth ? iconWidth : undefined}
          />
        ),
        bg: '#ffdca7',
        label: 'Sketch file',
      };
    }
    case ItemsTypeEnum.presentationFile: {
      return {
        icon: (
          <PPTFormatIcon
            height={iconHeight ? iconHeight : undefined}
            width={iconWidth ? iconWidth : undefined}
          />
        ),
        bg: '#ffd5c8',
        label: 'Presentation file',
      };
    }
    case ItemsTypeEnum.videoFile: {
      return {
        icon: (
          <VideoFormatIcon
            height={iconHeight ? iconHeight : undefined}
            width={iconWidth ? iconWidth : undefined}
          />
        ),
        bg: '#dbf7ff',
        label: 'Video file',
      };
    }
    case ItemsTypeEnum.fontFile: {
      return {
        icon: (
          <FontFormatIcon
            height={iconHeight ? iconHeight : undefined}
            width={iconWidth ? iconWidth : undefined}
          />
        ),
        bg: '#ffccda',
        label: 'Font file',
      };
    }
    case ItemsTypeEnum.excelFile: {
      return {
        icon: (
          <ExcelFormatIcon
            height={iconHeight ? iconHeight : undefined}
            width={iconWidth ? iconWidth : undefined}
          />
        ),
        bg: '#a2efcc',
        label: 'Excel file',
      };
    }
    case ItemsTypeEnum.indesignFile: {
      return {
        icon: (
          <IndesignFormatIcon
            height={iconHeight ? iconHeight : undefined}
            width={iconWidth ? iconWidth : undefined}
          />
        ),
        bg: '#ffb8ca',
        label: 'Indesign file',
      };
    }
    case ItemsTypeEnum.wordFile: {
      return {
        icon: (
          <WordFormatIcon
            height={iconHeight ? iconHeight : undefined}
            width={iconWidth ? iconWidth : undefined}
          />
        ),
        bg: '#bee4ff',
        label: 'Word file',
      };
    }
    case ItemsTypeEnum.afterEffectFile: {
      return {
        icon: (
          <AfterEffectFormatIcon
            height={iconHeight ? iconHeight : undefined}
            width={iconWidth ? iconWidth : undefined}
          />
        ),
        bg: '#bbbbff',
        label: 'After Effects file',
      };
    }
    case ItemsTypeEnum.audioFile: {
      return {
        icon: (
          <AudioFormatIcon
            height={iconHeight ? iconHeight : undefined}
            width={iconWidth ? iconWidth : undefined}
          />
        ),
        bg: '#dbf7ff',
        label: 'Audio file',
      };
    }
    case ItemsTypeEnum.svgFile: {
      return {
        icon: (
          <SVGFormatIcon
            height={iconHeight ? iconHeight : undefined}
            width={iconWidth ? iconWidth : undefined}
          />
        ),
        bg: '#ffcac6',
        label: 'Vector file',
      };
    }

    case ItemsTypeEnum.attachment: {
      return {
        icon: (
          <OtherFormatIcon
            height={iconHeight ? iconHeight : undefined}
            width={iconWidth ? iconWidth : undefined}
          />
        ),
        bg: '#ffccda',
        label: 'Unknown file format',
      };
    }

    case ItemsTypeEnum.guestRequestConnect: {
      return {
        icon: (
          <RupeeIcon
            height={iconHeight ? iconHeight : undefined}
            width={iconWidth ? iconWidth : undefined}
            fill="white"
          />
        ),
        bg: '#0d5231',
        label: 'Transaction',
      };
    }

    case ItemsTypeEnum.joinedPaidConnect: {
      return {
        icon: (
          <RupeeIcon
            height={iconHeight ? iconHeight : undefined}
            width={iconWidth ? iconWidth : undefined}
            fill="white"
          />
        ),
        bg: '#0d5231',
        label: 'Transaction',
      };
    }
  }
};

export const GetItemName = (data: { [key: string]: any }) => {
  if (data?.name) {
    return data.name;
  } else if (data?.type === 'FILE') {
    return data.attachment.originalname;
  } else if (data?.type === 'LINK') {
    const hasProtocol = data?.content?.includes('http');
    const { hostname } = new URL(
      hasProtocol ? data?.content : `https://${data?.content}`
    );
    return hostname;
  } else {
    return 'Item';
  }
};

const groupByItemOfDifferentDayAndUpdateProjectChangeCount = (
  array: { [key: string]: any }[]
) => {
  const groupedItemIdProjectId = [] as any[];

  const groupedItemIdProjectIdMap = {} as any;
  array.forEach((el: any) => {
    if (el.project && el.item && !el.dm_shared_to_user) {
      if (
        !groupedItemIdProjectIdMap.hasOwnProperty(el.item.id + el.project.id)
      ) {
        groupedItemIdProjectIdMap[el.item.id + el.project.id] = null;
        groupedItemIdProjectId.push(el);
      }
    } else {
      groupedItemIdProjectId.push(el);
    }
  });

  const groupByItemIdProjectChangeCountMap = {} as any;

  groupedItemIdProjectId.forEach((el: any) => {
    if (!groupByItemIdProjectChangeCountMap.hasOwnProperty(el.item.id)) {
      groupByItemIdProjectChangeCountMap[el.item.id] = {
        ...el,
      };
    } else if (groupByItemIdProjectChangeCountMap.hasOwnProperty(el.item.id)) {
      const initVal = groupByItemIdProjectChangeCountMap[el.item.id];
      groupByItemIdProjectChangeCountMap[el.item.id] = {
        ...initVal,
        project_count: initVal.project_count + el.project_count,
      };
    }
  });

  return Object.values(groupByItemIdProjectChangeCountMap);
};

const groupByItemAndAddProjectChangeCount = (
  array: { [key: string]: any }[]
) => {
  const groupedItemIdProjectId = [] as any[];

  const groupedItemIdProjectIdMap = {} as any;
  array.forEach((el: any) => {
    if (el.project && el.item && !el.dm_shared_to_user) {
      if (
        !groupedItemIdProjectIdMap.hasOwnProperty(el.item.id + el.project.id)
      ) {
        groupedItemIdProjectIdMap[el.item.id + el.project.id] = null;
        groupedItemIdProjectId.push(el);
      }
    } else {
      groupedItemIdProjectId.push(el);
    }
  });

  const groupByItemIdProjectChangeCountMap = {} as any;

  groupedItemIdProjectId.forEach((el: any) => {
    if (!groupByItemIdProjectChangeCountMap.hasOwnProperty(el.item.id)) {
      if (el.item && el.project && !el.dm_shared_to_user) {
        groupByItemIdProjectChangeCountMap[el.item.id] = {
          ...el,
          project_count: 1,
        };
      } else {
        groupByItemIdProjectChangeCountMap[el.item.id] = {
          ...el,
          project_count: 0,
        };
      }
    } else if (groupByItemIdProjectChangeCountMap.hasOwnProperty(el.item.id)) {
      if (el.item && el.project && !el.dm_shared_to_user) {
        const initVal = groupByItemIdProjectChangeCountMap[el.item.id];
        groupByItemIdProjectChangeCountMap[el.item.id] = {
          ...initVal,
          project_count: initVal.project_count + 1,
        };
      }
    }
  });

  return Object.values(groupByItemIdProjectChangeCountMap);
};

export const getGroupedAddedItemResuts = (
  data: { [key: string]: any }[],
  containerData: {
    count: number;
    margin: number;
  }
) => {
  const map = {} as any;

  data.forEach((el) => {
    const uixTimestamp = dayjs(el.created_at)
      .startOf('day')
      .valueOf();
    if (map[uixTimestamp]) {
      const intData = map[uixTimestamp];
      intData.push(el);
      map[uixTimestamp] = intData;
    } else {
      map[uixTimestamp] = [el];
    }
  });

  Object.keys(map).forEach((el: any) => {
    const initValue = map[el];
    const formattedValue = groupByItemAndAddProjectChangeCount([...initValue]);
    map[el] = [...formattedValue];
  });

  // make grouping

  const finalObject = {} as any;
  const slot = [2, 6, 14, 29, 44];
  const maxLength = Object.keys(map).length > 0 ? Object.keys(map).length : 0;
  let pointer: number = 0;

  while (maxLength > 0 && pointer < maxLength) {
    const dateKeys: number[] = Object.keys(map)
      .map((el) => Number(el))
      .sort((a: number, b: number) => Number(b) - Number(a));

    // check the length of the current pointer
    const pointerItemslength = map[dateKeys[pointer]].length;

    // debugger;
    if (pointerItemslength >= containerData.count) {
      finalObject[`${dayjs(dateKeys[pointer]).format('DD MMM, YYYY')}`] = [
        ...map[dateKeys[pointer]],
      ];
      // Increasre the pointer
      pointer++;
    } else if (
      pointerItemslength < containerData.count &&
      pointer === maxLength - 1
    ) {
      finalObject[`${dayjs(dateKeys[pointer]).format('DD MMM, YYYY')}`] = [
        ...map[dateKeys[pointer]],
      ];
      // Increasre the pointer
      pointer++;
    } else {
      // Go to the slot loop and check the length
      let i = 0;
      const slotLength = slot.length;

      const intialItemsMapIds = map[dateKeys[pointer]].map(
        (el: any) => el.item.id
      );

      for (i = 0; i < slotLength; i++) {
        let totalFilteredItemsIds = [...intialItemsMapIds];
        const initDate = dateKeys[pointer];
        const finalDate = dayjs(initDate)
          .subtract(slot[i], 'day')
          .valueOf();

        // debugger;
        const fileredMapItems: number[] = dateKeys.filter((el: any) => {
          if (el < initDate && finalDate - el <= 0) {
            return true;
          } else {
            return false;
          }
        });

        let filterMapLength = 0;
        fileredMapItems.forEach((el, k) => {
          filterMapLength += [...map[el]].filter(
            (item: any) => !totalFilteredItemsIds.includes(item.item.id)
          ).length;
          const filterdItemsId = map[el].map((item: any) => item.item.id);
          const unique = totalFilteredItemsIds.concat(filterdItemsId);
          const uniqueIds = unique.filter(
            (item, index) => unique.indexOf(item) === index
          );
          totalFilteredItemsIds = [...uniqueIds];
        });

        if (filterMapLength > containerData.count) {
          let finalArrayTest = [...map[dateKeys[pointer]]];
          fileredMapItems.forEach((el: number) => {
            finalArrayTest = finalArrayTest.concat(map[el]);
          });

          const formattedValue = groupByItemOfDifferentDayAndUpdateProjectChangeCount(
            [...finalArrayTest]
          );

          finalObject[
            `${dayjs(dateKeys[pointer]).format('DD MMM')} - ${dayjs(
              finalDate
            ).format('DD MMM, YYYY')}`
          ] = [...formattedValue];
          pointer += fileredMapItems.length + 1;
          break;
        } else if (i === slotLength - 1) {
          let finalArray = [...map[dateKeys[pointer]]];
          fileredMapItems.forEach((el: number) => {
            finalArray = finalArray.concat(map[el]);
          });

          const formattedValue = groupByItemOfDifferentDayAndUpdateProjectChangeCount(
            [...finalArray]
          );

          finalObject[
            `${dayjs(dateKeys[pointer]).format('DD MMM')} - ${dayjs(
              finalDate
            ).format('DD MMM, YYYY')}`
          ] = [...formattedValue];
          pointer += fileredMapItems.length + 1;
          break;
        }
      }
    }
  }

  return finalObject;
};

const groupByItemAndAddProjectActivityCount = (
  array: { [key: string]: any }[]
) => {
  const groupByItemIdProjectActivityChangeCountMap = {} as {
    [key: string]: any;
  };

  array.forEach((el: any) => {
    if (
      !groupByItemIdProjectActivityChangeCountMap.hasOwnProperty(el.item.id)
    ) {
      groupByItemIdProjectActivityChangeCountMap[el.item.id] = {
        ...el,
        activity_count: 1,
      };
    } else {
      const initVal = groupByItemIdProjectActivityChangeCountMap[el.item.id];
      groupByItemIdProjectActivityChangeCountMap[el.item.id] = {
        ...initVal,
        activity_count: initVal.activity_count + 1,
      };
    }
  });

  return Object.values(groupByItemIdProjectActivityChangeCountMap);
};

const groupByItemAndProjectActivityCountForCluster = (
  array: { [key: string]: any }[]
) => {
  const groupByItemIdProjectActivityChangeCountMap = {} as {
    [key: string]: any;
  };

  array.forEach((el: any) => {
    if (
      !groupByItemIdProjectActivityChangeCountMap.hasOwnProperty(el.item.id)
    ) {
      groupByItemIdProjectActivityChangeCountMap[el.item.id] = {
        ...el,
      };
    } else {
      const initVal = groupByItemIdProjectActivityChangeCountMap[el.item.id];
      groupByItemIdProjectActivityChangeCountMap[el.item.id] = {
        ...initVal,
        activity_count: initVal.activity_count + el.item.activity_count,
      };
    }
  });

  return Object.values(groupByItemIdProjectActivityChangeCountMap);
};

export const getGroupedRecentActivityItemResuts = (
  data: { [key: string]: any }[],
  containerData: {
    count: number;
    margin: number;
  }
) => {
  const map = {} as any;

  data.forEach((el) => {
    const uixTimestamp = dayjs(el.created_at)
      .startOf('day')
      .valueOf();
    if (map[uixTimestamp]) {
      const intData = map[uixTimestamp];
      intData.push(el);
      map[uixTimestamp] = intData;
    } else {
      map[uixTimestamp] = [el];
    }
  });

  Object.keys(map).forEach((el: any) => {
    const initValue = map[el];
    const formattedValue = groupByItemAndAddProjectActivityCount([
      ...initValue,
    ]);
    map[el] = [...formattedValue];
  });

  // make grouping

  const finalObject = {} as any;
  const slot = [2, 6, 14, 29, 44];
  const maxLength = Object.keys(map).length > 0 ? Object.keys(map).length : 0;
  let pointer: number = 0;

  while (maxLength > 0 && pointer < maxLength) {
    const dateKeys: number[] = Object.keys(map)
      .map((el) => Number(el))
      .sort((a: number, b: number) => Number(b) - Number(a));

    // check the length of the current pointer
    const pointerItemslength = [...map[dateKeys[pointer]]].length;

    // debugger;
    if (pointerItemslength >= containerData.count) {
      finalObject[`${dayjs(dateKeys[pointer]).format('DD MMM, YYYY')}`] = [
        ...map[dateKeys[pointer]],
      ];
      // Increasre the pointer
      pointer++;
    } else if (
      pointerItemslength < containerData.count &&
      pointer === maxLength - 1
    ) {
      finalObject[`${dayjs(dateKeys[pointer]).format('DD MMM, YYYY')}`] = [
        ...map[dateKeys[pointer]],
      ];
      // Increasre the pointer
      pointer++;
    } else {
      // Go to the slot loop and check the length
      let i = 0;
      const slotLength = slot.length;

      const intialItemsMapIds = [
        ...map[dateKeys[pointer]].map((el: any) => el.item.id),
      ];

      for (i = 0; i < slotLength; i++) {
        let totalFilteredItemsIds = [...intialItemsMapIds];
        const initDate = dateKeys[pointer];
        const finalDate = dayjs(initDate)
          .subtract(slot[i], 'day')
          .valueOf();

        // debugger;
        const fileredMapItems: number[] = dateKeys.filter((el: any) => {
          if (el < initDate && finalDate - el <= 0) {
            return true;
          } else {
            return false;
          }
        });

        let filterMapLength = 0;
        fileredMapItems.forEach((el, k) => {
          filterMapLength += [...map[el]].filter(
            (item: any) => !totalFilteredItemsIds.includes(item.item.id)
          ).length;
          const filterdItemsId = map[el].map((item: any) => item.item.id);
          const unique = totalFilteredItemsIds.concat(filterdItemsId);
          const uniqueIds = unique.filter(
            (item, index) => unique.indexOf(item) === index
          );
          totalFilteredItemsIds = [...uniqueIds];
        });

        if (filterMapLength > containerData.count) {
          let finalArrayTest = [...map[dateKeys[pointer]]];
          fileredMapItems.forEach((el: number) => {
            finalArrayTest = finalArrayTest.concat(map[el]);
          });

          const formattedValue = groupByItemAndProjectActivityCountForCluster([
            ...finalArrayTest,
          ]);

          finalObject[
            `${dayjs(dateKeys[pointer]).format('DD MMM')} - ${dayjs(
              finalDate
            ).format('DD MMM, YYYY')}`
          ] = [...formattedValue];

          pointer += fileredMapItems.length + 1;
          break;
        } else if (i === slotLength - 1) {
          let finalArray = [...map[dateKeys[pointer]]];
          fileredMapItems.forEach((el: number) => {
            finalArray = finalArray.concat(map[el]);
          });

          const formattedValue = groupByItemAndProjectActivityCountForCluster([
            ...finalArray,
          ]);

          finalObject[
            `${dayjs(dateKeys[pointer]).format('DD MMM')} - ${dayjs(
              finalDate
            ).format('DD MMM, YYYY')}`
          ] = [...formattedValue];
          pointer += fileredMapItems.length + 1;
          break;
        }
      }
    }
  }

  return finalObject;
};

export const getGroupedAllItemResuts = (
  data: { [key: string]: any }[],
  containerData: {
    count: number;
    margin: number;
  }
) => {
  const map = {} as any;

  data.forEach((el) => {
    const uixTimestamp = dayjs(el.created_at)
      .startOf('day')
      .valueOf();
    if (map[uixTimestamp]) {
      const intData = map[uixTimestamp];
      intData.push(el);
      map[uixTimestamp] = intData;
    } else {
      map[uixTimestamp] = [el];
    }
  });

  // make grouping

  const finalObject = {} as any;
  const slot = [2, 6, 14, 29, 44];
  const maxLength = Object.keys(map).length > 0 ? Object.keys(map).length : 0;
  let pointer: number = 0;

  while (maxLength > 0 && pointer < maxLength) {
    const dateKeys: number[] = Object.keys(map)
      .map((el) => Number(el))
      .sort((a: number, b: number) => Number(b) - Number(a));

    // check the length of the current pointer
    const pointerItemslength = map[dateKeys[pointer]].length;

    // debugger;
    if (pointerItemslength >= containerData.count) {
      finalObject[`${dayjs(dateKeys[pointer]).format('DD MMM, YYYY')}`] = [
        ...map[dateKeys[pointer]],
      ];
      // Increasre the pointer
      pointer++;
    } else if (
      pointerItemslength < containerData.count &&
      pointer === maxLength - 1
    ) {
      finalObject[`${dayjs(dateKeys[pointer]).format('DD MMM, YYYY')}`] = [
        ...map[dateKeys[pointer]],
      ];
      // Increasre the pointer
      pointer++;
    } else {
      // Go to the slot loop and check the length
      let i = 0;
      const slotLength = slot.length;

      for (i = 0; i < slotLength; i++) {
        const initDate = dateKeys[pointer];
        const finalDate = dayjs(initDate)
          .subtract(slot[i], 'day')
          .valueOf();

        // debugger;
        const fileredMapItems: number[] = dateKeys.filter((el: any) => {
          if (el < initDate && finalDate - el <= 0) {
            return true;
          } else {
            return false;
          }
        });

        let filterMapLength = 0;
        fileredMapItems.forEach((el, k) => {
          filterMapLength += [...map[el]].length;
        });

        if (filterMapLength > containerData.count) {
          let finalArray = [...map[dateKeys[pointer]]];
          fileredMapItems.forEach((el: number) => {
            finalArray = finalArray.concat(map[el]);
          });

          finalObject[
            `${dayjs(dateKeys[pointer]).format('DD MMM')} - ${dayjs(
              finalDate
            ).format('DD MMM')}`
          ] = [...finalArray];

          pointer += fileredMapItems.length + 1;
          break;
        } else if (i === slotLength - 1) {
          let finalArray = [...map[dateKeys[pointer]]];
          fileredMapItems.forEach((el: number) => {
            finalArray = finalArray.concat(map[el]);
          });

          finalObject[
            `${dayjs(dateKeys[pointer]).format('DD MMM')} - ${dayjs(
              finalDate
            ).format('DD MMM, YYYY')}`
          ] = [...finalArray];
          pointer += fileredMapItems.length + 1;
          break;
        }
      }
    }
  }

  return finalObject;
};
