import Icon, { IconProps } from '@chakra-ui/icon';
import { OmitCommonProps, As } from '@chakra-ui/react';
import React from 'react';

const _ProjectMarketingIcon = (
  props: JSX.IntrinsicAttributes &
    OmitCommonProps<React.SVGProps<SVGSVGElement>, keyof IconProps> &
    IconProps &
    OmitCommonProps<any, keyof IconProps> & { as?: As<any> | undefined }
) => (
  <Icon width={96} height={96} viewBox="0 0 96 96" focusable="false" {...props}>
    <defs>
      <path id="poi" d="M0 0h96v96H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <use fill="#6E9EFF" xlinkHref="#poi" />
      <g transform="translate(23 23)">
        <circle fill="#C3F3F6" cx={24.667} cy={24.667} r={21.667} />
        <path fill="#1753CA" d="m25 0 25 50H0z" />
      </g>
    </g>
  </Icon>
);

export const ProjectMarketingIcon = React.memo(_ProjectMarketingIcon);
