import { Grid } from '@chakra-ui/react';
import * as React from 'react';
import { lazy } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { AppDetailContextComponent } from './Context/AppDetailContext';
import { AppSocketContextComponent } from './Context/AppSocketContext';
import { UserUtilsDetailContextComponent } from './Context/UserUtilsContext';
import { ErrorPage } from './Routes/Errorpage';
import { SuspenseLoader } from './Routes/SuspenseLoader';
import { ProtectedRoute } from './utils/protectedRoute';

const LoginPage = lazy(() => import('./pages/Login'));
const ProfilePage = lazy(() => import('./pages/Profile'));
const DiscoverPage = lazy(() => import('./pages/Discover'));

const MainLayout = lazy(() => import('./layout/MainLayout'));

const WorkspaceConsole = lazy(() => import('./pages/WorkspaceConsole'));

const ConnectsPage = lazy(() => import('./pages/Connects'));
const NewConnectPage = lazy(() => import('./pages/NewConnect'));
const NewGroupConnectPage = lazy(() => import('./pages/NewGroupConnect'));
const BusinessProfilePage = lazy(() => import('./pages/BusinessProfile'));
const InviteMemberConnectPage = lazy(() =>
  import('./pages/InviteMemberConnect')
);

const ConnectDetailPage = lazy(() => import('./pages/ConnectDetail'));
const ConnectDetailItemPreviewPage = lazy(() =>
  import('./pages/ConnectItemPreview')
);
const VideoCallPage = lazy(() => import('./pages/VideoCall'));
const InitVideoCall = lazy(() => import('./pages/InitVideoCall'));
const ConnectOrderPage = lazy(() => import('./pages/ConnectOrder'));

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <React.Suspense
        fallback={<SuspenseLoader background="primary.soft_peach" />}
      >
        <LoginPage />
      </React.Suspense>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/login',
    element: (
      <React.Suspense
        fallback={<SuspenseLoader background="primary.soft_peach" />}
      >
        <LoginPage />
      </React.Suspense>
    ),
    errorElement: <ErrorPage />,
  },
  {
    element: (
      <React.Suspense
        fallback={<SuspenseLoader background="primary.soft_peach" />}
      >
        <ProtectedRoute path={'discover'} subject="Discover">
          <DiscoverPage />
        </ProtectedRoute>
      </React.Suspense>
    ),
    path: 'discover',
    errorElement: <ErrorPage />,
  },

  {
    element: (
      <React.Suspense
        fallback={<SuspenseLoader background="primary.soft_peach" />}
      >
        <ProtectedRoute
          path={
            '/workspace/:workspace_id/connect/:connect_id/message-item/:message_item_id'
          }
          subject="ConnectDetailItemPreview"
        >
          <ConnectDetailItemPreviewPage />
        </ProtectedRoute>
      </React.Suspense>
    ),
    path:
      '/workspace/:workspace_id/connect/:connect_id/message-item/:message_item_id',
  },

  {
    element: (
      <React.Suspense
        fallback={<SuspenseLoader background="primary.soft_peach" />}
      >
        <ProtectedRoute
          path={'/workspace/:workspace_id/connect/:connect_id/call/:room_id'}
          subject="VideoCall"
        >
          <VideoCallPage />
        </ProtectedRoute>
      </React.Suspense>
    ),
    path: '/workspace/:workspace_id/connect/:connect_id/call/:room_id',
  },

  {
    element: (
      <React.Suspense
        fallback={<SuspenseLoader background="primary.soft_peach" />}
      >
        <ProtectedRoute
          path={'/workspace/:workspace_id/connect/:connect_id/init-call'}
          subject="InitVideoCall"
        >
          <InitVideoCall />
        </ProtectedRoute>
      </React.Suspense>
    ),
    path: '/workspace/:workspace_id/connect/:connect_id/init-call',
  },
  {
    path: '/workspace/:workspace_id',
    element: (
      <React.Suspense
        fallback={<SuspenseLoader background="primary.soft_peach" />}
      >
        <MainLayout />
      </React.Suspense>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        element: (
          <React.Suspense fallback={<SuspenseLoader />}>
            <ProtectedRoute
              path={
                '/workspace/:workspace_id/connect/:connect_id/order/:order_id'
              }
              subject="ConnectOrder"
            >
              <ConnectOrderPage />
            </ProtectedRoute>
          </React.Suspense>
        ),
        path: 'connect/:connect_id/order/:order_id',
      },
      {
        element: (
          <React.Suspense fallback={<SuspenseLoader />}>
            <ProtectedRoute
              path={'/workspace/:workspace_id/profile'}
              subject="Profile"
            >
              <ProfilePage />
            </ProtectedRoute>
          </React.Suspense>
        ),
        path: 'profile',
        errorElement: <ErrorPage />,
      },
      {
        element: (
          <React.Suspense fallback={<SuspenseLoader />}>
            <ProtectedRoute
              path={'/workspace/:workspace_id/business-profile'}
              subject="BusinessProfile"
            >
              <BusinessProfilePage />
            </ProtectedRoute>
          </React.Suspense>
        ),
        path: 'business-profile',
      },
      {
        element: (
          <React.Suspense fallback={<SuspenseLoader />}>
            <ProtectedRoute
              path={'/workspace/:workspace_id/console'}
              subject="WorkspaceConsole"
            >
              <WorkspaceConsole />
            </ProtectedRoute>
          </React.Suspense>
        ),
        path: 'console',
      },
      {
        element: (
          <React.Suspense fallback={<SuspenseLoader />}>
            <ProtectedRoute
              path={'/workspace/:workspace_id/new-connect'}
              subject="NewConnects"
            >
              <NewConnectPage />
            </ProtectedRoute>
          </React.Suspense>
        ),
        path: 'new-connect',
      },
      {
        element: (
          <React.Suspense fallback={<SuspenseLoader />}>
            <ProtectedRoute
              path={'/workspace/:workspace_id/new-group-connect'}
              subject="NewGroupConnects"
            >
              <NewGroupConnectPage />
            </ProtectedRoute>
          </React.Suspense>
        ),
        path: 'new-group-connect',
      },
      {
        element: (
          <React.Suspense fallback={<SuspenseLoader />}>
            <ProtectedRoute
              path={'/workspace/:workspace_id/invite-member-connect'}
              subject="InviteMemberConnect"
            >
              <InviteMemberConnectPage />
            </ProtectedRoute>
          </React.Suspense>
        ),
        path: 'invite-member-connect',
      },
      {
        element: (
          <React.Suspense fallback={<SuspenseLoader />}>
            <ProtectedRoute
              path={'/workspace/:workspace_id/connects'}
              subject="Connects"
            >
              <ConnectsPage />
            </ProtectedRoute>
          </React.Suspense>
        ),
        path: 'connects',
      },
      {
        element: (
          <React.Suspense fallback={<SuspenseLoader />}>
            <ProtectedRoute
              path={'/workspace/:workspace_id/connect/:connect_id'}
              subject="ConnectDetail"
            >
              <ConnectDetailPage />
            </ProtectedRoute>
          </React.Suspense>
        ),
        path: 'connect/:connect_id',
      },
    ],
  },
]);

export const App = () => (
  <Grid minH="100vh" p={0} bg="primary.soft_peach">
    <AppDetailContextComponent>
      <AppSocketContextComponent>
        <UserUtilsDetailContextComponent>
          <RouterProvider router={router} />
        </UserUtilsDetailContextComponent>
      </AppSocketContextComponent>
    </AppDetailContextComponent>
  </Grid>
);
