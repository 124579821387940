export const avatarColorSet = [
  '#C139AF',
  '#F9A416',
  '#5D9AFE',
  '#AF4BFF',
  '#00AD4E',
  '#C139AF',
  '#02B8F1',
  '#0071E3',
  '#E36942',
  '#00b4d8',
  '#073b4c',
  '#e56b6f',
  '#43aa8b',
  '#7b2cbf',
  '#db00b6',
];

export const getAvatarInitials = (first: any, last: any) => {
  let final = [];
  if (last && last.length > 0) final.push(last[0]);
  if (first && first.length > 0 && final.length > 0) {
    final.push(first[0]);
  } else if (first && first.length > 0 && final.length === 0) {
    return first.slice(0, 2);
  }
  if (final.length) return final.reverse().join('');

  return 'AO';
};
