import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define a type for the slice state

interface ChatType {
  chatScrollToBottom: boolean;
  scrollToChatMessageId?: string;
}

// Define the initial state using that type
const initialState: ChatType = {
  chatScrollToBottom: false,
};

export const chatSlice = createSlice({
  name: 'chat',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    resetChatScrollToBottom: (state) => {
      state.chatScrollToBottom = false;
    },
    updateChatScrollToBottom: (state, action: PayloadAction<boolean>) => {
      state.chatScrollToBottom = action.payload;
    },
    updateScrollToChatMessageId: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.scrollToChatMessageId = action.payload;
    },
  },
});

export const {
  resetChatScrollToBottom,
  updateChatScrollToBottom,
  updateScrollToChatMessageId,
} = chatSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value;

export default chatSlice.reducer;
