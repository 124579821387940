import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { queryClient } from '..';
import { Paths } from '../MainNav';

// Define a type for the slice state

export enum ConnectDetailTabEnum {
  conversation = 'CONVERSATION',
  items = 'ITEMS',
}

export enum ConnectDetailTagModalModeEnum {
  add = 'ADD',
  modify = 'MODIFY',
}

export enum ConnectDetailRightComponentModeTabEnum {
  profile = 'PROFILE',
  connectDetail = 'CONNECT_DETAIL',
  connectReplyThread = 'CONNECT_REPLY_THREAD',
}

export enum ConnectDetailUIModeEnum {
  connectDetail = 'CONNECT_DETAIL',
  addContact = 'ADD_CONTACT',
}

interface connectDetailType {
  currentPath?: {
    path: string;
    params: { [key: string]: any };
  };
  selectedConnectDetailTab: ConnectDetailTabEnum;
  isConnectDetailRightComponentVisible: boolean;
  rightComponentMode: ConnectDetailRightComponentModeTabEnum;
  rightComponentSelectedId?: string;
  rightComponentThreadSelectedMessageIds?: string[];
  addTagModalVisibility: boolean;
  addTagModelMode: ConnectDetailTagModalModeEnum;
  addTagModalSelectedMessageData?: { [key: string]: any };
  isConnectAddEditModalVisible: boolean;
  isAddEditTagModalVisible: boolean;
  connectUIMode: ConnectDetailUIModeEnum;
  checkoutData?: { [key: string]: any };
  isDeclineConnectModalVisible: boolean;
  unseenConnectThreadNotification: { [key: string]: any[] };
  isAddReactionVisible: boolean;
  addReactionMessageData?: { [key: string]: any[] };
  isThreadAddReactionVisible: boolean;
  addThreadReactionMessageData?: { [key: string]: any[] };
}

// Define the initial state using that type
const initialState: connectDetailType = {
  selectedConnectDetailTab: ConnectDetailTabEnum.conversation,
  isConnectDetailRightComponentVisible: false,
  rightComponentMode: ConnectDetailRightComponentModeTabEnum.connectDetail,
  addTagModalVisibility: false,
  addTagModelMode: ConnectDetailTagModalModeEnum.add,
  isConnectAddEditModalVisible: false,
  isAddEditTagModalVisible: false,
  connectUIMode: ConnectDetailUIModeEnum.connectDetail,
  isDeclineConnectModalVisible: false,
  unseenConnectThreadNotification: {},
  isAddReactionVisible: false,
  isThreadAddReactionVisible: false,
};

export const connectDetailSlice = createSlice({
  name: 'connectDetail',
  initialState,
  reducers: {
    updateConnectDetailCurrentPath: (
      state,
      action: PayloadAction<{ path: string; params: { [key: string]: any } }>
    ) => {
      state.currentPath = {
        path: action.payload.path,
        params: action.payload.params,
      };
    },
    updateConnectDetailSelectedTab: (
      state,
      action: PayloadAction<ConnectDetailTabEnum>
    ) => {
      state.selectedConnectDetailTab = action.payload;
    },
    updateIsConnectDetailRightComponentVisible: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isConnectDetailRightComponentVisible = action.payload;
    },
    updateOnViewConnectDetailClick: (state) => {
      state.rightComponentMode =
        ConnectDetailRightComponentModeTabEnum.connectDetail;
      state.isConnectDetailRightComponentVisible = true;
    },
    updateOnConnectDetailRightComponentClose: (state) => {
      state.isConnectDetailRightComponentVisible = false;
      state.rightComponentMode =
        ConnectDetailRightComponentModeTabEnum.connectDetail;
    },
    updateOnViewConnectParticipantDetailClick: (
      state,
      action: PayloadAction<string>
    ) => {
      state.rightComponentSelectedId = action.payload;
      state.rightComponentMode = ConnectDetailRightComponentModeTabEnum.profile;
      state.isConnectDetailRightComponentVisible = true;
    },

    updateOnViewConnectMessageThreadReplyDetailClick: (
      state,
      action: PayloadAction<string>
    ) => {
      state.rightComponentThreadSelectedMessageIds = [action.payload];
      state.selectedConnectDetailTab = ConnectDetailTabEnum.conversation;
      state.rightComponentMode =
        ConnectDetailRightComponentModeTabEnum.connectReplyThread;
      state.isConnectDetailRightComponentVisible = true;
    },

    updateOnViewConnectMessageThreadNotificationViewClick: (
      state,
      action: PayloadAction<{ connectId: string; messageIds: string[] }>
    ) => {
      state.rightComponentThreadSelectedMessageIds = action.payload.messageIds;
      state.unseenConnectThreadNotification[action.payload.connectId] = [];
      state.selectedConnectDetailTab = ConnectDetailTabEnum.conversation;
      state.rightComponentMode =
        ConnectDetailRightComponentModeTabEnum.connectReplyThread;
      state.isConnectDetailRightComponentVisible = true;

      //
    },
    updateOnAddTagClick: (
      state,
      action: PayloadAction<{ [key: string]: any }>
    ) => {
      state.addTagModalVisibility = true;
      state.addTagModelMode = ConnectDetailTagModalModeEnum.add;
      state.addTagModalSelectedMessageData = action.payload;
    },
    updateOnModifyTagClick: (
      state,
      action: PayloadAction<{ [key: string]: any }>
    ) => {
      state.addTagModalVisibility = true;
      state.addTagModelMode = ConnectDetailTagModalModeEnum.modify;
      state.addTagModalSelectedMessageData = action.payload;
    },
    updateOnTagModalClose: (state) => {
      state.addTagModalVisibility = false;
      state.addTagModelMode = ConnectDetailTagModalModeEnum.add;
      state.addTagModalSelectedMessageData = undefined;
    },
    updateOnTagEditModalClick: (state, action: PayloadAction<boolean>) => {
      state.isAddEditTagModalVisible = action.payload;
    },
    updateOnAddContactToConnectModalClick: (state) => {
      state.connectUIMode = ConnectDetailUIModeEnum.addContact;
    },
    updateOnAddContactToConnectModalClose: (state) => {
      state.connectUIMode = ConnectDetailUIModeEnum.connectDetail;
    },
    updateOnConnectCheckoutPaymentRedirect: (state) => {
      state.connectUIMode = ConnectDetailUIModeEnum.connectDetail;
      state.selectedConnectDetailTab = ConnectDetailTabEnum.conversation;
    },
    updateIsDeclineConnectModalVisible: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isDeclineConnectModalVisible = action.payload;
    },

    updateConnectUnseenThreadNotification: (
      state,
      action: PayloadAction<{ connectId: string; data: { [key: string]: any } }>
    ) => {
      if (
        state.currentPath?.path === Paths.connectDetail &&
        state.currentPath.params.connect_id === action.payload.connectId &&
        !state.isConnectDetailRightComponentVisible
      ) {
        state.unseenConnectThreadNotification[
          action.payload.connectId
        ] = Object.keys(action.payload.data);
      }
    },
    updateConnectDetailOnUnmount: (state) => {
      state.selectedConnectDetailTab = ConnectDetailTabEnum.conversation;
      state.isConnectDetailRightComponentVisible = false;
      state.rightComponentMode =
        ConnectDetailRightComponentModeTabEnum.connectDetail;
      state.addTagModalVisibility = false;
      state.addTagModelMode = ConnectDetailTagModalModeEnum.add;
      state.isConnectAddEditModalVisible = false;
      state.isAddEditTagModalVisible = false;
      state.connectUIMode = ConnectDetailUIModeEnum.connectDetail;
      state.isDeclineConnectModalVisible = false;
      state.unseenConnectThreadNotification = {};
    },

    updateOnIgnoreThreadNotification: (
      state,
      action: PayloadAction<{ connectId: string }>
    ) => {
      state.unseenConnectThreadNotification[action.payload.connectId] = [];
    },

    updateConnectThreadOnSocketNewMsgReceive: (
      state,
      action: PayloadAction<{
        data: any;
        connectId: string;
        workspaceId: string;
      }>
    ) => {
      // state.unseenConnectThreadNotification[action.payload.connectId] = [];
      if (
        state.currentPath?.path === Paths.connectDetail &&
        state.currentPath.params.connect_id === action.payload.connectId &&
        state.isConnectDetailRightComponentVisible &&
        state.selectedConnectDetailTab === ConnectDetailTabEnum.conversation &&
        state.rightComponentMode ===
          ConnectDetailRightComponentModeTabEnum.connectReplyThread &&
        state.rightComponentThreadSelectedMessageIds
      ) {
        const allThredMessageIds = state.rightComponentThreadSelectedMessageIds;

        if (
          action.payload.data.message_id.id &&
          allThredMessageIds.includes(action.payload.data.message_id.id)
        ) {
          queryClient.invalidateQueries([
            'connect_thread_messages',
            action.payload.workspaceId,
            allThredMessageIds?.join(),
          ]);
        }
      }

      // if the right thread component is not visible then update the unread thead message count

      if (
        state.currentPath?.path === Paths.connectDetail &&
        state.currentPath.params.connect_id === action.payload.connectId &&
        (!state.isConnectDetailRightComponentVisible ||
          (state.isConnectDetailRightComponentVisible &&
            state.rightComponentMode !==
              ConnectDetailRightComponentModeTabEnum.connectReplyThread))
      ) {
        if (state.unseenConnectThreadNotification[action.payload.connectId]) {
          const allMessageIds =
            state.unseenConnectThreadNotification[action.payload.connectId];
          //check if the message id exist
          if (!allMessageIds.includes(action.payload.data.message_id.id)) {
            state.unseenConnectThreadNotification[action.payload.connectId] = [
              ...allMessageIds,
              action.payload.data.message_id.id,
            ];
          }
        } else {
          state.unseenConnectThreadNotification[action.payload.connectId] = [
            action.payload.data.message_id.id,
          ];
        }
      }
    },
    updateConnectThreadOnWorkflowAddOrModify: (
      state,
      action: PayloadAction<{
        connectId: string;
        messageItemData: { [key: string]: any };
        workspaceId: string;
      }>
    ) => {
      if (
        state.currentPath?.path === Paths.connectDetail &&
        state.currentPath.params.connect_id === action.payload.connectId &&
        state.isConnectDetailRightComponentVisible &&
        state.selectedConnectDetailTab === ConnectDetailTabEnum.conversation &&
        state.rightComponentMode ===
          ConnectDetailRightComponentModeTabEnum.connectReplyThread &&
        state.rightComponentThreadSelectedMessageIds
      ) {
        const allThredMessageIds = state.rightComponentThreadSelectedMessageIds;

        if (
          action.payload.messageItemData.message.id &&
          allThredMessageIds.includes(action.payload.messageItemData.message.id)
        ) {
          queryClient.invalidateQueries([
            'connect_thread_messages',
            action.payload.workspaceId,
            allThredMessageIds?.join(),
          ]);
        }
      }
    },
    updateOnAddReactionClick: (
      state,
      action: PayloadAction<{ [key: string]: any }>
    ) => {
      state.isAddReactionVisible = true;
      state.addReactionMessageData = action.payload;
    },
    updateOnAddReactionClose: (state) => {
      state.isAddReactionVisible = false;
      state.addReactionMessageData = undefined;
    },
    updateConnectThreadOnReactionAddOrModify: (
      state,
      action: PayloadAction<{
        workspaceId: any;
        connectId: string;
        messageId: string;
      }>
    ) => {
      if (
        state.currentPath?.path === Paths.connectDetail &&
        state.currentPath.params.connect_id === action.payload.connectId &&
        state.isConnectDetailRightComponentVisible &&
        state.selectedConnectDetailTab === ConnectDetailTabEnum.conversation &&
        state.rightComponentMode ===
          ConnectDetailRightComponentModeTabEnum.connectReplyThread &&
        state.rightComponentThreadSelectedMessageIds
      ) {
        const allThredMessageIds = state.rightComponentThreadSelectedMessageIds;

        if (
          action.payload.messageId &&
          allThredMessageIds.includes(action.payload.messageId)
        ) {
          queryClient.invalidateQueries([
            'connect_thread_messages',
            action.payload.workspaceId,
            allThredMessageIds?.join(),
          ]);
        }
      }
    },
    updateOnThreadAddReactionClick: (
      state,
      action: PayloadAction<{ [key: string]: any }>
    ) => {
      state.isThreadAddReactionVisible = true;
      state.addThreadReactionMessageData = action.payload;
    },
    updateOnThreadAddReactionClose: (state) => {
      state.isThreadAddReactionVisible = false;
      state.addThreadReactionMessageData = undefined;
    },
  },
});

export const {
  updateConnectDetailCurrentPath,
  updateConnectDetailSelectedTab,
  updateIsConnectDetailRightComponentVisible,
  updateOnViewConnectDetailClick,
  updateOnConnectDetailRightComponentClose,
  updateOnViewConnectParticipantDetailClick,
  updateOnViewConnectMessageThreadReplyDetailClick,
  updateOnViewConnectMessageThreadNotificationViewClick,
  updateOnAddTagClick,
  updateOnModifyTagClick,
  updateOnTagModalClose,
  updateOnTagEditModalClick,
  updateOnAddContactToConnectModalClick,
  updateOnAddContactToConnectModalClose,
  updateIsDeclineConnectModalVisible,
  updateConnectUnseenThreadNotification,
  updateConnectDetailOnUnmount,
  updateOnIgnoreThreadNotification,
  updateConnectThreadOnSocketNewMsgReceive,
  updateConnectThreadOnWorkflowAddOrModify,
  updateOnAddReactionClick,
  updateOnAddReactionClose,
  updateConnectThreadOnReactionAddOrModify,
  updateOnThreadAddReactionClick,
  updateOnThreadAddReactionClose,
  updateOnConnectCheckoutPaymentRedirect,
} = connectDetailSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value;

export default connectDetailSlice.reducer;
