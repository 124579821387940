import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RightComponentModeEnum } from '../component/NewProject/RightSideComponents/RightMainComponent';
import { SessionUploadEnum } from '../component/ProjectDetail/Conversation/UploadMenu/UploadMenu';

// Define a type for the slice state

export enum ProjectDetailTabEnums {
  items = 'PROJECT_ITEMS',
  conversations = 'CONVERSATIONS',
  dm = 'DIRECT_MESSAGE',
}

interface ProjectDetailType {
  createWorkflowModalVisibility: boolean;
  addWorkflowModalVisibility: boolean;
  addWorkflowSelectedProject: { [key: string]: any };
  addWorkflowSelectedMessageItemId: string;
  addWorkflowSelectedMessageId?: string;
  addWorkflowMode: 'ADD' | 'MODIFY';
  isSessionUploadModalVisible: boolean;
  sessionUploadType: SessionUploadEnum;
  sessionAttachmentUploadData: { [key: string]: any }[];
  isMemberOfThisConversation: boolean;

  isRightComponentVisible: boolean;
  rightComponentMode: RightComponentModeEnum;
  rightComponentTriggeredFrom: 'LIBRARY' | 'PROJECT_DETAIL';
  selectedRightComponentId?: string;
  selectedThreadMessageId?: string;
  isCreateProjectModalVisibility: boolean;
  createProjectModalMode: 'EDIT' | 'CREATE';
  createProjectModalSelectedProjectId?: string;
  projectDetailsItemsFilter: { [key: string]: any };
  isAddMemberToProjectModalVisible: boolean;
  selectedProjectDetailTab: ProjectDetailTabEnums;

  //Tasks
  isCreateTaskModalVisibility: boolean;
  createTaskModalMode: 'EDIT' | 'CREATE';
  createTaskModalSelectedItemData?: { [key: string]: any };
  editTaskModalData?: { [key: string]: any };
  isTaskDetailDrawerVisible: boolean;
  createTaskItemAdditionalDetail?: {
    selected_project_id?: string;
    selected_workflow_id?: string;
  };
}

// Define the initial state using that type
const initialState: ProjectDetailType = {
  createWorkflowModalVisibility: false,
  addWorkflowModalVisibility: false,
  addWorkflowSelectedProject: {},
  addWorkflowSelectedMessageItemId: '',
  addWorkflowMode: 'ADD',
  isSessionUploadModalVisible: false,
  sessionUploadType: SessionUploadEnum.review,
  sessionAttachmentUploadData: [],
  isMemberOfThisConversation: false,

  isRightComponentVisible: false,
  rightComponentMode: RightComponentModeEnum.projectDetail,
  rightComponentTriggeredFrom: 'PROJECT_DETAIL',
  isCreateProjectModalVisibility: false,
  createProjectModalMode: 'CREATE',
  projectDetailsItemsFilter: {
    type: 'ALL',
  },
  isAddMemberToProjectModalVisible: false,
  selectedProjectDetailTab: ProjectDetailTabEnums.conversations,

  isCreateTaskModalVisibility: false,
  createTaskModalMode: 'CREATE',
  isTaskDetailDrawerVisible: false,
};

export const projectDetailSlice = createSlice({
  name: 'projectDetail',
  initialState,
  reducers: {
    updateCreateWorkflowModalVisibility: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.createWorkflowModalVisibility = action.payload;
    },
    updateAddWorkflowModalVisibility: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.addWorkflowModalVisibility = action.payload;
    },
    updateAddWorkflowSelectedProject: (
      state,
      action: PayloadAction<{ [key: string]: any }>
    ) => {
      state.addWorkflowSelectedProject = action.payload;
    },
    updateAddWorkflowMessageItemId: (state, action: PayloadAction<string>) => {
      state.addWorkflowSelectedMessageItemId = action.payload;
    },
    updateAddWorkflowMessageId: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.addWorkflowSelectedMessageId = action.payload;
    },

    updateAddWorkflowMode: (state, action: PayloadAction<'ADD' | 'MODIFY'>) => {
      state.addWorkflowMode = action.payload;
    },
    updateSessionUploadModalVisibility: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isSessionUploadModalVisible = action.payload;
    },
    updateSessionUploadType: (
      state,
      action: PayloadAction<SessionUploadEnum>
    ) => {
      state.sessionUploadType = action.payload;
    },

    // -       - - - - - - - - - - - - - - -  - - -- - - - -  - - - -  -

    initiateSessionAttachmentData: (
      state,
      action: PayloadAction<{ [key: string]: any }[]>
    ) => {
      state.sessionAttachmentUploadData = action.payload;
    },
    updateSessionAttachmentDataProgress: (
      state,
      action: PayloadAction<{ progress: number; ids: number[] }>
    ) => {
      let initialAttachment = [...state.sessionAttachmentUploadData];
      const progress = action.payload.progress;
      const ids = action.payload.ids;

      const onProgressAttachment = initialAttachment;
      const excludedAttachments = onProgressAttachment.filter(
        (el: any) => !(el.type === 'RAW' && ids.includes(el.id))
      );

      const actualAttachments = onProgressAttachment.filter(
        (el: any) => el.type === 'RAW' && ids.includes(el.id)
      );
      const updatedOnProgressAttachment = actualAttachments.map((el: any) => {
        return { ...el, progress: progress };
      });

      initialAttachment = [
        ...excludedAttachments,
        ...updatedOnProgressAttachment,
      ];
      state.sessionAttachmentUploadData = [...initialAttachment];
    },
    removeSessionAttachmentFileData: (state, action: PayloadAction<number>) => {
      let initialAttachment = [...state.sessionAttachmentUploadData];
      const onProgressAttachment = initialAttachment;
      onProgressAttachment.splice(action.payload, 1);
      initialAttachment = onProgressAttachment;
      state.sessionAttachmentUploadData = initialAttachment;
    },
    updateSessionAttachmentFileDataSuccess: (
      state,
      action: PayloadAction<{ data: any; ids: number[] }>
    ) => {
      const data = action.payload.data;
      const ids = action.payload.ids;
      let initialAttachment = [...state.sessionAttachmentUploadData];
      const onProgressAttachment = initialAttachment;
      const excludedAttachments = onProgressAttachment.filter(
        (el: any) => !(el.type === 'RAW' && ids.includes(el.id))
      );

      initialAttachment = [...data, ...excludedAttachments];
      state.sessionAttachmentUploadData = initialAttachment;
    },
    addSessionAttachmentData: (state, action: PayloadAction<any>) => {
      let initialAttachment = [...state.sessionAttachmentUploadData];
      const onProgressAttachment = initialAttachment;
      initialAttachment = [...onProgressAttachment, ...action.payload];
      state.sessionAttachmentUploadData = initialAttachment;
    },
    resetSessionAttachmentData: (state) => {
      state.sessionAttachmentUploadData = [];
    },

    // -  - - - - - - - - - - - - - - -  - - -- - - - -  - - - -  -

    updateIsMemberOfThisConversation: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isMemberOfThisConversation = action.payload;
    },

    updateRightComponentVisibility: (state, action: PayloadAction<boolean>) => {
      state.isRightComponentVisible = action.payload;
    },

    updateRightComponentMode: (
      state,
      action: PayloadAction<RightComponentModeEnum>
    ) => {
      state.rightComponentMode = action.payload;
    },
    updateRightComponentTriggeredFrom: (
      state,
      action: PayloadAction<'LIBRARY' | 'PROJECT_DETAIL'>
    ) => {
      state.rightComponentTriggeredFrom = action.payload;
    },

    updateSelectedRightComponentId: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.selectedRightComponentId = action.payload;
    },

    resetProjectDetailRightComponent: (state) => {
      state.isRightComponentVisible = false;
      state.rightComponentMode = RightComponentModeEnum.projectDetail;
      state.rightComponentTriggeredFrom = 'PROJECT_DETAIL';
      state.selectedRightComponentId = undefined;
    },

    updateIsCreateProjectModalVisibility: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isCreateProjectModalVisibility = action.payload;
    },
    updateCreateProjectModalMode: (
      state,
      action: PayloadAction<'CREATE' | 'EDIT'>
    ) => {
      state.createProjectModalMode = action.payload;
    },
    updateCreateProjectModalClose: (state) => {
      state.createProjectModalMode = 'CREATE';
      state.isCreateProjectModalVisibility = false;
      state.createProjectModalSelectedProjectId = undefined;
    },

    updateCreateProjectModaProjectId: (
      state,
      action: PayloadAction<string>
    ) => {
      state.createProjectModalSelectedProjectId = action.payload;
    },

    updateProjectDetailsItemsFilter: (
      state,
      action: PayloadAction<{ [key: string]: any }>
    ) => {
      state.projectDetailsItemsFilter = action.payload;
    },

    updateAddMemberToProjectVisibility: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isAddMemberToProjectModalVisible = action.payload;
    },

    updateSelectedThreadMessageId: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.selectedThreadMessageId = action.payload;
    },

    resetSelectedProjectDetailTab: (state) => {
      state.selectedProjectDetailTab = ProjectDetailTabEnums.conversations;
      state.projectDetailsItemsFilter = {
        type: 'ALL',
      };
    },
    updateSelectedProjectDetailTab: (state, action: PayloadAction<any>) => {
      state.selectedProjectDetailTab = action.payload;
    },

    updateIsCreateTaskModalVisibility: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isCreateTaskModalVisibility = action.payload;
    },
    updateCreateTaskModalItemData: (
      state,
      action: PayloadAction<{ [key: string]: any } | undefined>
    ) => {
      state.createTaskModalSelectedItemData = action.payload;
    },
    updateEditTaskModalData: (
      state,
      action: PayloadAction<{ [key: string]: any } | undefined>
    ) => {
      state.editTaskModalData = action.payload;
    },
    updateCreateTaskModalMode: (
      state,
      action: PayloadAction<'CREATE' | 'EDIT'>
    ) => {
      state.createTaskModalMode = action.payload;
    },
    updateCreateTaskModalItemAdditionalData: (
      state,
      action: PayloadAction<{
        selected_project_id?: string;
        selected_workflow_id?: string;
      }>
    ) => {
      state.createTaskItemAdditionalDetail = action.payload;
    },
    updateCreateTaskModalClose: (state) => {
      state.createTaskModalMode = 'CREATE';
      state.isCreateTaskModalVisibility = false;
      state.createTaskModalSelectedItemData = undefined;
      state.editTaskModalData = undefined;
      state.createTaskItemAdditionalDetail = undefined;
    },

    updateIsTaskDetailDrawerVisible: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isTaskDetailDrawerVisible = action.payload;
    },
  },
});

export const {
  updateCreateWorkflowModalVisibility,
  updateAddWorkflowModalVisibility,
  updateAddWorkflowSelectedProject,
  updateAddWorkflowMessageItemId,
  updateAddWorkflowMode,
  updateSessionUploadModalVisibility,
  updateSessionUploadType,
  initiateSessionAttachmentData,
  updateSessionAttachmentDataProgress,
  removeSessionAttachmentFileData,
  updateSessionAttachmentFileDataSuccess,
  addSessionAttachmentData,
  resetSessionAttachmentData,
  updateIsMemberOfThisConversation,

  resetProjectDetailRightComponent,
  updateRightComponentVisibility,
  updateRightComponentMode,
  updateRightComponentTriggeredFrom,
  updateSelectedRightComponentId,
  updateIsCreateProjectModalVisibility,
  updateCreateProjectModalMode,
  updateCreateProjectModalClose,
  updateCreateProjectModaProjectId,
  updateProjectDetailsItemsFilter,
  updateAddMemberToProjectVisibility,
  updateSelectedThreadMessageId,
  updateAddWorkflowMessageId,
  updateSelectedProjectDetailTab,
  resetSelectedProjectDetailTab,

  //Task
  updateIsCreateTaskModalVisibility,
  updateCreateTaskModalItemAdditionalData,
  updateCreateTaskModalMode,
  updateCreateTaskModalClose,
  updateCreateTaskModalItemData,
  updateEditTaskModalData,
} = projectDetailSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value;

export default projectDetailSlice.reducer;
