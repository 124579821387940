import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define a type for the slice state

interface ItemUploadStateType {
  fileProgressPercentage: number;
}

// Define the initial state using that type
const initialState: ItemUploadStateType = {
  fileProgressPercentage: 0,
};

export const itemUploadSlice = createSlice({
  name: 'itemUpload',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    resetFileProgressPercentage: (state) => {
      state.fileProgressPercentage = 0;
    },
    updateFileProgressPercentage: (state, action: PayloadAction<number>) => {
      state.fileProgressPercentage = action.payload;
    },
  },
});

export const { resetFileProgressPercentage, updateFileProgressPercentage } =
  itemUploadSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value;

export default itemUploadSlice.reducer;
