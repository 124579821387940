import Icon, { IconProps } from '@chakra-ui/icon';
import { OmitCommonProps, As } from '@chakra-ui/react';
import React from 'react';

const _ArrowDownIcon = (
  props: JSX.IntrinsicAttributes &
    OmitCommonProps<React.SVGProps<SVGSVGElement>, keyof IconProps> &
    IconProps &
    OmitCommonProps<any, keyof IconProps> & { as?: As<any> | undefined }
) => (
  <Icon height={25} width={25} focusable="false" {...props}>
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="m7 10 5 5 5-5z" />
  </Icon>
);

export const ArrowDownIcon = React.memo(_ArrowDownIcon);
