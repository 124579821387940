import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define a type for the slice state

interface ContactType {
  isCreateContactModalVisible: boolean;
}

// Define the initial state using that type
const initialState: ContactType = {
  isCreateContactModalVisible: false,
};

export const contactSlice = createSlice({
  name: 'contact',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    updateIsCreateContactModalVisible: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isCreateContactModalVisible = action.payload;
    },
  },
});

export const { updateIsCreateContactModalVisible } = contactSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value;

export default contactSlice.reducer;
