import { AxiosResponse } from 'axios';
import { ItemsTypeEnum } from '../component/Library/helper';
import { MessageTypeEnum } from '../component/ProjectDetail/ChatFooter';

export type CustomResponseType = AxiosResponse<{
  data?: any;
  message?: any;
  success: boolean;
}>;

export type CustomRQResponseType = {
  data?: any;
  message?: any;
  success: boolean;
};

export enum ClientRoleEnum {
  admin = 'CLT_ADMIN',
  editor = 'CLT_EDITOR',
  guest = 'CLT_GUEST',
}

export const clientRoleLabel = [
  {
    label: 'Admin',
    value: ClientRoleEnum.admin,
  },
  {
    label: 'Team',
    value: ClientRoleEnum.editor,
  },
  {
    label: 'Client',
    value: ClientRoleEnum.guest,
  },
];

export enum UserState {
  invited = 'INVITED', // when new user is created
  activated = 'ACTIVATED', // when user accept and verify the invite
  deleted = 'DELETED', // when deleted from the org
  onhold = 'ONHOLD', // on hold request from admin
  revoked = 'REVOKED',
}

export enum ProjectStateEnum {
  active = 'ACTIVE',
  completed = 'COMPLETED',
}

export enum UploadFileRelationEnum {
  other = 'OTHERS',
  asset = 'ASSET',
  project = 'PROJECT',
  profile = 'PROFILE',
  chat = 'CHAT',
  items = 'ITEMS',
  connect = 'CONNECT',
}

export enum ProjectSubStateEnum {
  processing = 'PROCESSING',
  approved = 'APPROVED',
  inReview = 'IN_REVIEW',
  workInProgress = 'WORK_IN_PROGRESS',
  completed = 'COMPLETED',
  onHold = 'ON_HOLD',
}

export interface QuerykeyType {
  pageParam?: number;
  queryKey: any;
}

export enum InternalNotesTypeEnum {
  projectNote = 'PROJECT_NOTE',
  accountNote = 'ACCOUNT_NOTE',
}

export const projectState = [
  {
    label: 'Processing',
    value: ProjectSubStateEnum.processing,
  },
  {
    label: 'Work In Progress',
    value: ProjectSubStateEnum.workInProgress,
  },
  {
    label: 'In Review',
    value: ProjectSubStateEnum.inReview,
  },
  {
    label: 'Approved',
    value: ProjectSubStateEnum.approved,
  },
  {
    label: 'Completed',
    value: ProjectSubStateEnum.completed,
  },
  {
    label: 'On Hold',
    value: ProjectSubStateEnum.onHold,
  },
];

export enum PresentableRoleEnum {
  clientAdmin = 'CLT_ADMIN',
  clientEditor = 'CLT_EDITOR',
}

export interface SaveMessageDataType {
  workspace_id: string;
  sender_id: string;
  type: MessageTypeEnum;
  message: string;
  conversation_id: string;
  connect_id: string;
  created_at: string;
  message_items?: {
    sender_id: string;
    conversation_id: string;
    connect_id: string;
    message_type: MessageTypeEnum;
    type: string;
    sub_type: ItemsTypeEnum;
    attachment?: { [key: string]: any };
    content?: string;
  };
  message_participant_mention?: string[];
  is_closed?: boolean;
}

export enum UserToConnectStateTypeEnum {
  creator = 'CREATOR',
  inviteList = 'INVITE_LIST',
  invited = 'INVITED',
  ignored = 'IGNORED',
  declined = 'DECLINED',
  leave = 'LEAVE',
  joined = 'JOINED',
}
export enum ConnectStateTypeEnum {
  active = 'ACTIVE',
  closed = 'CLOSED',
  draft = 'DRAFT',
}

export enum ConnectTypeEnum {
  request = 'REQUEST',
  newConnect = 'NEW_CONNECT',
  inviteMember = 'INVITE_MEMBER',
}

export enum ConnectMessageTypeEnum {
  message = 'MESSAGE',
  messageWithItems = 'MESSAGE_WITH_ITEMS',
  messageWithItemsTransaction = 'MESSAGE_WITH_ITEMS_TRANSACTION',
  messageWithVideoCallItem = 'MESSAGE_WITH_VIDEO_CALL_ITEMS',
  messageAddMemberNotification = 'MESSAGE_ADD_MEMBER_NOTIFICATION',
  messageJoinedConnectNotification = 'MESSAGE_JOINED_CONNECT_NOTIFICATION',
  messageLeftConnectNotification = 'MESSAGE_LEFT_CONNECT_NOTIFICATION',
  messageEndedConnectNotification = 'MESSAGE_ENDED_CONNECT_NOTIFICATION',
  messageIgnoredConnectNotification = 'MESSAGE_IGNORED_CONNECT_NOTIFICATION',
  messageDeclinedConnectNotification = 'MESSAGE_DECLINED_CONNECT_NOTIFICATION',
  messageLabelUpdateNotification = 'MESSAGE_LABEL_UPDATE_NOTIFICATION',
  messageConnectDetailUpdateNotification = 'MESSAGE_CONNECT_DETAIL_UPDATE_NOTIFICATION',
}

export enum WorkspaceTagTypeEnum {
  plain = 'PLAIN',
  singleSelect = 'SINGLE_SELECT',
}
