import Icon, { IconProps } from '@chakra-ui/icon';
import { OmitCommonProps, As } from '@chakra-ui/react';
import React from 'react';

const _ProjectAnimationIcon = (
  props: JSX.IntrinsicAttributes &
    OmitCommonProps<React.SVGProps<SVGSVGElement>, keyof IconProps> &
    IconProps &
    OmitCommonProps<any, keyof IconProps> & { as?: As<any> | undefined }
) => (
  <Icon width={96} height={96} viewBox="0 0 96 96" focusable="false" {...props}>
    <defs>
      <path id="aass1" d="M0 0h96v96H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <use fill="#6E9EFF" xlinkHref="#aass1" />
      <path fill="#FFF" d="M23 23h50v50H23z" />
      <path fill="#00246B" d="m48 27 21 42H27z" />
    </g>
  </Icon>
);

export const ProjectAnimationIcon = React.memo(_ProjectAnimationIcon);
