import { useAbility } from '@casl/react';
import {
  HStack,
  VStack,
  Text,
  Button,
  Box,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  chakra,
  useToast,
  Flex,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { batch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { queryClient } from '../../..';
import { AbilityContext } from '../../../Context/AppAbilityContext';
import { useURLQuery } from '../../../hooks/useURLQuery';
import {
  useAddItem,
  useItemUploadFile,
} from '../../../pages/Library/items.hooks';
import { RootState } from '../../../store';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { updateFileProgressPercentage } from '../../../store/itemUpload';
import {
  updateAddItemModalVisibility,
  updateItemCardonClose,
  updateLibraryFavouriteFilter,
  updateLibraryItemFilter,
  updateLibrarySelectedTab,
  updateProjectViewType,
} from '../../../store/library';
import {
  updateCreateProjectModalMode,
  updateCreateTaskModalMode,
  updateIsCreateProjectModalVisibility,
  updateIsCreateTaskModalVisibility,
} from '../../../store/projectDetail';
import { AddFilePayloadType } from '../../AddItemModal';
import { toastConfig } from '../../Alert/CustumToastComponent';
import { fileUploadToastConfig } from '../../Alert/FileUploadCustumToastComponent';
import { GetFileItemTypeFromMimetype } from '../helper';
import { FavouriteFolderSubHeaderComponent } from './FavouriteComponent/FavouriteFolderSubHeaderComponent';
import { FavouriteSubHeaderComponent } from './FavouriteComponent/FavouriteSubHeaderComponent';
import { ItemsSubHeaderComponent } from './ItemsComponent/ItemsSubHeaderComponent';
import {
  ProjectSubHeaderComponent,
  ProjectViewTypeEnum,
} from './ProjectComponent/ProjectSubHeaderComponent';
import { TasksSubHeaderComponent } from './TaskComponent/TaskSubHeaderComponent';

export enum LibraryHeaderTabsEnum {
  tasks = 'TASKS',
  items = 'ITEMS',
  favourite = 'FAVOURITE',
  favouriteFolder = 'FAVOURITE_FOLDER',
  project = 'PROJECT',
}

interface Props {
  favouriteItemData: any;
}

const _LibraryHeaderComponent = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = useURLQuery();
  const ability = useAbility(AbilityContext);

  // const [progress, updateProgress] = useState<number>(0);
  const dispatch = useAppDispatch();
  const toast = useToast();
  const { favouriteItemData } = props;

  const selectedTab = useAppSelector(
    (state: RootState) => state.library.selectedTab
  );

  const itemFilters = useAppSelector(
    (state: RootState) => state.library.itemFilters
  );

  const favouriteFilters = useAppSelector(
    (state: RootState) => state.library.favouriteFilters
  );

  const favouriteFolderFilters = useAppSelector(
    (state: RootState) => state.library.favouriteFolderFilters
  );

  const selectedWorkspaceId = useAppSelector(
    (state: RootState) => state.user.selectedWorkspaceId
  );

  const selectedFavouriteFolderId = useAppSelector(
    (state: RootState) => state.library.selectedFavouriteFolderId
  );

  const updateTab = (val: LibraryHeaderTabsEnum) => {
    query.set('tab', val);
    navigate({
      pathname: location.pathname,
      search: query.toString(),
    });
    batch(() => {
      dispatch(updateLibrarySelectedTab(val));
      dispatch(updateItemCardonClose());
    });
  };

  const updateProgress = (per: number) => {
    dispatch(updateFileProgressPercentage(per));
  };

  const { mutate } = useItemUploadFile(updateProgress);

  const { mutate: mutateAddItem } = useAddItem(selectedWorkspaceId);

  useEffect(() => {
    const tabValue = query.get('tab');
    const tabs = Object.values(LibraryHeaderTabsEnum);

    if (tabValue && tabs.includes(tabValue as LibraryHeaderTabsEnum)) {
      updateTab(tabValue as LibraryHeaderTabsEnum);
      switch (tabValue) {
        case LibraryHeaderTabsEnum.items: {
          const tabFilterValue = query.get('item-type');
          if (
            tabFilterValue &&
            ['ALL', 'RECENTLY_ADDED', 'RECENT_ACTIVITY'].includes(
              tabFilterValue
            )
          ) {
            dispatch(
              updateLibraryItemFilter({
                ...itemFilters,
                type: tabFilterValue,
              })
            );
          }
          break;
        }
        case LibraryHeaderTabsEnum.favourite: {
          const tabFilterValue = query.get('favourite-type');
          if (
            tabFilterValue &&
            ['ITEMS', 'FOLDER', 'ALL'].includes(tabFilterValue)
          ) {
            dispatch(
              updateLibraryFavouriteFilter({
                ...favouriteFilters,
                type: tabFilterValue,
                item_type: undefined,
                access_type: undefined,
              })
            );
          }
          break;
        }
        case LibraryHeaderTabsEnum.project: {
          const tabFilterValue = query.get('project-type');
          if (
            tabFilterValue &&
            Object.values(ProjectViewTypeEnum).includes(
              tabFilterValue as ProjectViewTypeEnum
            )
          ) {
            dispatch(
              updateProjectViewType(tabFilterValue as ProjectViewTypeEnum)
            );
          }

          break;
        }
      }
    } else {
      updateTab(LibraryHeaderTabsEnum.items);
      navigate({
        pathname: location.pathname,
        search: `tab=${LibraryHeaderTabsEnum.items}&item_type=RECENTLY_ADDED`,
      });
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    multiple: true,
    onDrop: (files) => {
      toast(fileUploadToastConfig(files.length));
      mutate(files, {
        onSuccess: (data) => {
          if (data.success) {
            const payload = [] as AddFilePayloadType[];

            data.data.forEach((file: any) => {
              let sub_type = GetFileItemTypeFromMimetype(
                file.mimetype as string
              ).type;

              const obj = {} as AddFilePayloadType;
              obj['attachment'] = file;
              obj['sub_type'] = sub_type;
              obj['type'] = 'FILE';
              payload.push(obj);
            });

            mutateAddItem(
              {
                items: payload,
                page_type: selectedTab,
                selected_folder_id: selectedFavouriteFolderId,
              },
              {
                onSuccess: (data) => {
                  if (
                    selectedTab === LibraryHeaderTabsEnum.items &&
                    itemFilters.type === 'ALL'
                  ) {
                    queryClient.invalidateQueries([
                      'all_items',
                      selectedWorkspaceId,
                      itemFilters,
                    ]);
                  } else if (
                    selectedTab === LibraryHeaderTabsEnum.items &&
                    itemFilters.type === 'RECENTLY_ADDED'
                  ) {
                    queryClient.invalidateQueries([
                      'recently_added_items',
                      selectedWorkspaceId,
                      itemFilters,
                    ]);
                  } else if (selectedTab === LibraryHeaderTabsEnum.favourite) {
                    queryClient.invalidateQueries([
                      'favourite_items',
                      selectedWorkspaceId,
                      favouriteFilters,
                    ]);
                  } else if (
                    selectedTab === LibraryHeaderTabsEnum.favouriteFolder
                  ) {
                    queryClient.invalidateQueries([
                      'selected_favourite_item',
                      selectedWorkspaceId,
                      selectedFavouriteFolderId as string,
                      favouriteFolderFilters,
                    ]);
                  }
                  toast(toastConfig('Items Added'));
                },
                onError: () => {
                  toast(toastConfig('Something went wrong. Try again.'));
                },
              }
            );
          }
        },
        onError: () => {
          toast(toastConfig('File upload failed'));
        },
      });
    },
  });

  return (
    <VStack w="100%" py="9" pb="0" px={10} justify="space-between">
      <Flex
        mt="0 !important"
        pb={[2, 2, 9, 9]}
        justify="space-between"
        w="100%"
        pos="relative"
        flexDirection={['column', 'column', 'row', 'row']}
      >
        <HStack justifyContent={'space-between'}>
          <Text fontSize="24" fontWeight="600">
            Library
          </Text>
          <HStack display={['inherit', 'inherit', 'none', 'none']}>
            {selectedTab !== LibraryHeaderTabsEnum.project &&
              selectedTab !== LibraryHeaderTabsEnum.tasks && (
                <Menu size={'sm'}>
                  <MenuButton as={Button} size={'md'}>
                    + Add Item
                  </MenuButton>
                  <MenuList>
                    <MenuItem>
                      <Box w="100%" {...getRootProps()}>
                        <chakra.input {...getInputProps()} w="100%" />
                        Upload file
                      </Box>
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        dispatch(updateAddItemModalVisibility(true))
                      }
                    >
                      Add link
                    </MenuItem>
                  </MenuList>
                </Menu>
              )}
            {ability &&
              ability.can('manage', 'CreateProject') &&
              selectedTab === LibraryHeaderTabsEnum.project && (
                <Button
                  size={'md'}
                  onClick={() => {
                    batch(() => {
                      dispatch(updateCreateProjectModalMode('CREATE'));
                      dispatch(updateIsCreateProjectModalVisibility(true));
                    });
                  }}
                >
                  + Create Project
                </Button>
              )}
            {selectedTab === LibraryHeaderTabsEnum.tasks && (
              <Button
                size={'md'}
                onClick={() => {
                  batch(() => {
                    dispatch(updateCreateTaskModalMode('CREATE'));
                    dispatch(updateIsCreateTaskModalVisibility(true));
                  });
                }}
              >
                + Create a task
              </Button>
            )}
          </HStack>
        </HStack>

        <HStack
          pos={['relative', 'relative', 'absolute', 'absolute']}
          justifyContent={['flex-start', 'flex-start', 'center', 'center']}
          w="100%"
          py={[4, 4, 0, 0]}
        >
          <HStack
            boxShadow="2px 2px 6px 0 rgba(0, 0, 0, 0.1)"
            p="1"
            borderRadius="4"
            ml={[0, 0, '-7%', '-7%']}
          >
            <Button
              size="sm"
              py="0"
              px="5"
              variant={
                selectedTab === LibraryHeaderTabsEnum.tasks
                  ? 'p_tab_btn_selected'
                  : 'p_tab_btn'
              }
              onClick={() => updateTab(LibraryHeaderTabsEnum.tasks)}
              fontWeight={
                selectedTab === LibraryHeaderTabsEnum.tasks ? '600' : '500'
              }
            >
              Tasks
            </Button>
            <Button
              size="sm"
              py="0"
              px="5"
              variant={
                selectedTab === LibraryHeaderTabsEnum.items
                  ? 'p_tab_btn_selected'
                  : 'p_tab_btn'
              }
              onClick={() => updateTab(LibraryHeaderTabsEnum.items)}
              fontWeight={
                selectedTab === LibraryHeaderTabsEnum.items ? '600' : '500'
              }
            >
              Items
            </Button>
            <Button
              size="sm"
              py="0"
              px="5"
              variant={
                selectedTab === LibraryHeaderTabsEnum.favourite ||
                selectedTab === LibraryHeaderTabsEnum.favouriteFolder
                  ? 'p_tab_btn_selected'
                  : 'p_tab_btn'
              }
              onClick={() => updateTab(LibraryHeaderTabsEnum.favourite)}
              fontWeight={
                selectedTab === LibraryHeaderTabsEnum.favourite ||
                selectedTab === LibraryHeaderTabsEnum.favouriteFolder
                  ? '600'
                  : '500'
              }
            >
              Favourites
            </Button>
            <Button
              size="sm"
              py="0"
              px="5"
              variant={
                selectedTab === LibraryHeaderTabsEnum.project
                  ? 'p_tab_btn_selected'
                  : 'p_tab_btn'
              }
              onClick={() => updateTab(LibraryHeaderTabsEnum.project)}
              fontWeight={
                selectedTab === LibraryHeaderTabsEnum.project ? '600' : '500'
              }
            >
              Projects
            </Button>
          </HStack>
        </HStack>

        <HStack display={['none', 'none', 'inherit', 'inherit']}>
          {selectedTab !== LibraryHeaderTabsEnum.project &&
            selectedTab !== LibraryHeaderTabsEnum.tasks && (
              // <Button
              //   size={'md'}
              //   onClick={() => dispatch(updateAddItemModalVisibility(true))}
              // >
              //   + Add Item
              // </Button>

              <Menu size={'sm'}>
                <MenuButton as={Button} size={'md'}>
                  + Add Item
                </MenuButton>
                <MenuList>
                  <MenuItem>
                    <Box w="100%" {...getRootProps()}>
                      <chakra.input {...getInputProps()} w="100%" />
                      Upload file
                    </Box>
                  </MenuItem>
                  <MenuItem
                    onClick={() => dispatch(updateAddItemModalVisibility(true))}
                  >
                    Add link
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
          {ability &&
            ability.can('manage', 'CreateProject') &&
            selectedTab === LibraryHeaderTabsEnum.project && (
              <Button
                size={'md'}
                onClick={() => {
                  batch(() => {
                    dispatch(updateCreateProjectModalMode('CREATE'));
                    dispatch(updateIsCreateProjectModalVisibility(true));
                  });
                }}
              >
                + Create Project
              </Button>
            )}
          {selectedTab === LibraryHeaderTabsEnum.tasks && (
            <Button
              size={'md'}
              onClick={() => {
                batch(() => {
                  dispatch(updateCreateTaskModalMode('CREATE'));
                  dispatch(updateIsCreateTaskModalVisibility(true));
                });
              }}
            >
              + Create a task
            </Button>
          )}
        </HStack>
      </Flex>
      {selectedTab === LibraryHeaderTabsEnum.tasks && (
        <TasksSubHeaderComponent />
      )}
      {selectedTab === LibraryHeaderTabsEnum.items && (
        <ItemsSubHeaderComponent />
      )}
      {selectedTab === LibraryHeaderTabsEnum.favourite && (
        <FavouriteSubHeaderComponent />
      )}
      {selectedTab === LibraryHeaderTabsEnum.favouriteFolder && (
        <FavouriteFolderSubHeaderComponent
          favouriteItemData={favouriteItemData}
        />
      )}
      {selectedTab === LibraryHeaderTabsEnum.project && (
        <ProjectSubHeaderComponent />
      )}
      <Box
        mt="0 !important"
        pt="3"
        w="100%"
        borderBottom={'1px solid'}
        borderColor="#f2f2f4"
      />
    </VStack>
  );
};

export const LibraryHeaderComponent = React.memo(_LibraryHeaderComponent);
