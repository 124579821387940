import { VStack } from '@chakra-ui/react';
import React from 'react';
import { useSingleItemData } from '../../../pages/Library/items.hooks';
import { RootState } from '../../../store';
import { useAppSelector } from '../../../store/hooks';
import { LibraryRightMainContainer } from '../../Library/RightSideComponent/RightMainComponent';
import { DMRightComponentThreadNotificationComponent } from './DMThreadNotificationComponent/DMThreadNotificationComponent';
import { RightComponentProfileDetailComponent } from './ProfileDetailComponent/ProfileDetailComponent';
import { RightComponentProjectDetailComponent } from './ProjectDetailComponent/ProjectDetailComponent';
import { ProjectThreadDetailComponent } from './ProjectThreadDetailComponent/ProjectThreadDetailComponent';
import { RightComponentThreadNotificationComponent } from './ThreadNotificationComponent/ThreadNotificationComponent';

export enum RightComponentModeEnum {
  asset = 'ASSET',
  reply = 'REPLY',
  projectDetail = 'PROJECT_DETAIL',
  profile = 'PROFILE',
  threadNotification = 'THREAD_NOTIFICATION',
  dmThreadNotification = 'DM_THREAD_NOTIFICATION',
}

const _ProjectRightMainContainer = () => {
  const isProjectDetailRightComponentVisible = useAppSelector(
    (state: RootState) => state.projectDetail.isRightComponentVisible
  );

  const isLibraryRightComponentVisible = useAppSelector(
    (state: RootState) => state.library.isRightComponentVisible
  );

  const rightComponentMode = useAppSelector(
    (state: RootState) => state.projectDetail.rightComponentMode
  );

  const selectedWorkspaceId = useAppSelector(
    (state: RootState) => state.user.selectedWorkspaceId
  );

  const selectedItemId = useAppSelector(
    (state: RootState) => state.library.selectedItemId
  );

  const { data, isLoading } = useSingleItemData(
    selectedWorkspaceId,
    selectedItemId as string
  );

  return (
    <React.Fragment>
      {rightComponentMode !== RightComponentModeEnum.asset &&
        rightComponentMode !== RightComponentModeEnum.profile &&
        rightComponentMode !== RightComponentModeEnum.threadNotification &&
        rightComponentMode !== RightComponentModeEnum.dmThreadNotification && (
          <VStack
            h="100%"
            // w="calc(38% - 16px)"
            w="424px"
            display={[
              'none',
              'none',
              'none',
              isLibraryRightComponentVisible ||
              isProjectDetailRightComponentVisible
                ? 'flex'
                : 'none',
            ]}
            bg="white"
            borderRadius={'16'}
          >
            {rightComponentMode === RightComponentModeEnum.projectDetail && (
              <RightComponentProjectDetailComponent />
            )}

            {rightComponentMode === RightComponentModeEnum.reply && (
              <ProjectThreadDetailComponent />
            )}
          </VStack>
        )}

      {rightComponentMode === RightComponentModeEnum.profile && (
        <VStack
          h="100%"
          w="424px"
          display={[
            'none',
            'none',
            'none',
            isLibraryRightComponentVisible ||
            isProjectDetailRightComponentVisible
              ? 'flex'
              : 'none',
          ]}
          bg="white"
          borderRadius={'16'}
        >
          <RightComponentProfileDetailComponent />
        </VStack>
      )}

      {rightComponentMode === RightComponentModeEnum.asset && (
        <LibraryRightMainContainer />
      )}

      {rightComponentMode === RightComponentModeEnum.threadNotification && (
        <VStack
          h="100%"
          // w="calc(38% - 16px)"
          w="424px"
          display={[
            'none',
            'none',
            'none',
            isProjectDetailRightComponentVisible ? 'flex' : 'none',
          ]}
          bg="white"
          borderRadius={'16'}
        >
          <RightComponentThreadNotificationComponent />
        </VStack>
      )}

      {rightComponentMode === RightComponentModeEnum.dmThreadNotification && (
        <VStack
          h="100%"
          w="424px"
          display={[
            'none',
            'none',
            'none',
            isProjectDetailRightComponentVisible ? 'flex' : 'none',
          ]}
          bg="white"
          borderRadius={'16'}
        >
          <DMRightComponentThreadNotificationComponent />
        </VStack>
      )}
    </React.Fragment>
  );
};

export const ProjectRightMainContainer = React.memo(_ProjectRightMainContainer);
