import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define a type for the slice state

interface SocketStateType {
  workspaces: string[];
  projects: string[];
  workspaceOnlineUserIds: string[];
}

// Define the initial state using that type
const initialState: SocketStateType = {
  workspaces: [],
  projects: [],
  workspaceOnlineUserIds: [],
};

export const socketSlice = createSlice({
  name: 'socket',
  initialState,
  reducers: {
    addWorkspaces: (state, action: PayloadAction<string[]>) => {
      let finalWorkspaces = state.workspaces.concat(action.payload);
      finalWorkspaces = finalWorkspaces.filter((item, index) => {
        return finalWorkspaces.indexOf(item) === index;
      });
      state.workspaces = [...finalWorkspaces];
    },
    addProjects: (state, action: PayloadAction<string[]>) => {
      let finalProjects = state.workspaces.concat(action.payload);
      finalProjects = finalProjects.filter((item, index) => {
        return finalProjects.indexOf(item) === index;
      });
      state.projects = [...finalProjects];
    },
    addSingleProjects: (state, action: PayloadAction<string>) => {
      let initialList = [...state.projects];
      state.projects = [...initialList, action.payload];
    },
    updateProjectOnlineUserData: (
      state,
      action: PayloadAction<{ users: string[] }>
    ) => {
      const { users } = action.payload;
      state.workspaceOnlineUserIds = [...users];
    },
  },
});

export const {
  addWorkspaces,
  addProjects,
  updateProjectOnlineUserData,
  addSingleProjects,
} = socketSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value;

export default socketSlice.reducer;
