import Icon, { IconProps } from '@chakra-ui/icon';
import { OmitCommonProps, As } from '@chakra-ui/react';
import React from 'react';

const _SendChatIcon = (
  props: JSX.IntrinsicAttributes &
    OmitCommonProps<React.SVGProps<SVGSVGElement>, keyof IconProps> &
    IconProps &
    OmitCommonProps<any, keyof IconProps> & { as?: As<any> | undefined }
) => (
  <Icon height={5} width={5} focusable="false" {...props}>
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M2.01 21 23 12 2.01 3 2 10l15 2-15 2z" />
  </Icon>
);

export const SendChatIcon = React.memo(_SendChatIcon);
