import React, { useContext, useState } from 'react';
import {
  Box,
  MenuItem,
  MenuList,
  MenuButton,
  Menu,
  Button,
  useToast,
  chakra,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import { queryClient } from '../../../..';
import { AppDetailContext } from '../../../../Context/AppDetailContext';
import { AppSocketContext } from '../../../../Context/AppSocketContext';
import { useItemUploadFile } from '../../../../pages/Library/items.hooks';
import { useAddItemAndSaveMessage } from '../../../../pages/ProjectDetail/conversation.hooks';
import { RootState } from '../../../../store';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { updateFileProgressPercentage } from '../../../../store/itemUpload';
import { updateAddItemModalVisibility } from '../../../../store/library';
import {
  ProjectDetailTabEnums,
  resetSessionAttachmentData,
  updateSessionUploadModalVisibility,
} from '../../../../store/projectDetail';
import { AddFilePayloadType } from '../../../AddItemModal';
import { AddItemsToFavouriteFolderModal } from '../../../AddItemsToFavouriteFolderModal';
import { toastConfig } from '../../../Alert/CustumToastComponent';
import { fileUploadToastConfig } from '../../../Alert/FileUploadCustumToastComponent';
import { GetFileItemTypeFromMimetype } from '../../../Library/helper';
import { MessageTypeEnum } from '../../ChatFooter';
import { ProjectDetailContext } from '../../../../pages/ProjectDetail/projectChatData.context';

export enum SessionUploadEnum {
  review = 'REVIEW',
  link = 'LINK',
  device = 'DEVICE',
}

interface Props {}

const _UploadMenu = (props: Props) => {
  const [isAddItemModalVisible, updateIsAddItemModalVisible] =
    useState<boolean>(false);

  const { socket } = useContext(AppSocketContext);
  const { appDetail } = useContext(AppDetailContext);
  const {
    conversationDetailData: { id: conversationId },
  } = useContext(ProjectDetailContext);

  const toast = useToast();
  const dispatch = useAppDispatch();
  const params = useParams<{
    workspace_id: string;
    project_id: string;
  }>();

  const isMemberOfThisConversation = useAppSelector(
    (state: RootState) => state.projectDetail.isMemberOfThisConversation
  );

  const selectedTab = useAppSelector(
    (state: RootState) => state.projectDetail.selectedProjectDetailTab
  );

  const selectedReceiverId = useAppSelector(
    (state: RootState) => state.projectDirectMessage.selectedReceiverId
  );

  const projectItemsFilter = useAppSelector(
    (state: RootState) => state.project.projectItemsFilter
  );

  const { mutate: mutateAddItemAndSaveMessage } = useAddItemAndSaveMessage(
    params.workspace_id as string
  );

  const handleModalClose = () => {
    updateIsAddItemModalVisible(false);
    dispatch(updateSessionUploadModalVisibility(false));
  };

  const handleSendClick = (itemData: { [key: string]: any }[]) => {
    if (isMemberOfThisConversation && socket) {
      const messagePayload = itemData.map((el: any) => {
        const messageItemObj = {
          sender_id: appDetail.id,
          conversation_id: conversationId,
          project_id: params.project_id,
          message_type: MessageTypeEnum.messageWithItems,
          type: el.sub_type,
          item: el,
        };

        const msgObj = {
          workspace_id: params.workspace_id,
          sender_id: appDetail.id,
          type: MessageTypeEnum.messageWithItems,
          message: '',
          conversation_id: conversationId,
          project_id: params.project_id,
          created_at: dayjs().format(),
          message_items: messageItemObj,
          item_source_type: 'EXISTING',
        };
        return msgObj;
      });

      socket.emit('project:message', {
        source: 'ADD_FROM_EXISTING_LIBRARY',
        data: messagePayload,
      });

      // refetch the items data if items tab  is selected

      if (selectedTab === ProjectDetailTabEnums.items) {
        queryClient.invalidateQueries([
          'conversation_items',
          conversationId,
          projectItemsFilter,
        ]);
      }

      toast(toastConfig('Item has been added to your project. '));

      handleModalClose();
    } else {
      toast(toastConfig('Items could not be uploaded. Try again.'));
    }
    dispatch(resetSessionAttachmentData());
  };

  const handleDMSendClick = (itemData: { [key: string]: any }[]) => {
    if (isMemberOfThisConversation && socket) {
      const messagePayload = itemData.map((el: any) => {
        const messageItemObj = {
          sender_id: appDetail.id,
          receiver_id: selectedReceiverId,
          conversation_id: conversationId,
          project_id: params.project_id,
          message_type: MessageTypeEnum.messageWithItems,
          type: el.sub_type,
          item: el,
        };

        const msgObj = {
          workspace_id: params.workspace_id,
          sender_id: appDetail.id,
          receiver_id: selectedReceiverId,
          type: MessageTypeEnum.messageWithItems,
          message: '',
          conversation_id: conversationId,
          project_id: params.project_id,
          created_at: dayjs().format(),
          message_items: messageItemObj,
        };
        return msgObj;
      });

      socket.emit('project:direct-message', {
        source: 'ADD_FROM_EXISTING_LIBRARY',
        data: messagePayload,
      });

      // refetch the items data if items tab  is selected

      if (selectedTab === ProjectDetailTabEnums.items) {
        queryClient.invalidateQueries([
          'conversation_items',
          conversationId,
          projectItemsFilter,
        ]);
      }

      toast(toastConfig('Item added. '));

      handleModalClose();
    } else {
      toast(toastConfig('Items could not be uploaded. Try again.'));
    }
    dispatch(resetSessionAttachmentData());
  };

  const updateProgress = (per: number) => {
    dispatch(updateFileProgressPercentage(per));
  };

  const { mutate } = useItemUploadFile(updateProgress);

  const { getRootProps, getInputProps } = useDropzone({
    multiple: true,
    disabled: !isAddItemModalVisible,
    onDrop: (files) => {
      toast(fileUploadToastConfig(files.length));
      mutate(files, {
        onSuccess: (data) => {
          if (data.success) {
            const payload = [] as AddFilePayloadType[];
            data.data.forEach((file: any) => {
              let sub_type = GetFileItemTypeFromMimetype(
                file.mimetype as string
              ).type;
              const obj = {} as AddFilePayloadType;
              obj['attachment'] = file;
              obj['sub_type'] = sub_type;
              obj['type'] = 'FILE';
              payload.push(obj);
            });

            mutateAddItemAndSaveMessage(
              {
                items: payload,
                project_id: params.project_id as string,
                conversation_type: selectedTab,
                receiver_id: selectedReceiverId,
              },
              {
                onSuccess: (data) => {
                  toast(toastConfig('Item has been added to your project. '));
                },
                onError: () => {
                  toast(toastConfig('Something went wrong. Try again.'));
                },
              }
            );
          }
        },

        onError: () => {
          toast(toastConfig('File upload failed'));
        },
      });
    },
  });

  return (
    <Box>
      <Menu
        boundary="scrollParent"
        placement="bottom-start"
        computePositionOnMount
        direction="ltr"
        preventOverflow
        strategy="absolute"
        size="sm"
        gutter={4}
      >
        <MenuButton as={Button} py="2" px="4" size="md">
          + Add Item
        </MenuButton>
        <MenuList position="relative">
          <MenuItem
            fontSize="14"
            onClick={() => updateIsAddItemModalVisible(true)}
          >
            Share from Library
          </MenuItem>
          <MenuItem>
            <Box w="100%" {...getRootProps()}>
              <chakra.input {...getInputProps()} w="100%" />
              Upload file
            </Box>
          </MenuItem>
          <MenuItem
            fontSize="14"
            onClick={() => dispatch(updateAddItemModalVisibility(true))}
          >
            Add Link
          </MenuItem>
        </MenuList>
      </Menu>
      <AddItemsToFavouriteFolderModal
        title="Add items to your project"
        subTitle="Click to select items you want to add to your project."
        secondaryFooterText="Cancel"
        primaryFooterText="Add Items"
        isVisible={isAddItemModalVisible}
        handleOnClose={() => updateIsAddItemModalVisible(false)}
        handleAddFolder={(
          selectedItems: string[],
          selectedItemData: { [key: string]: any }[]
        ) => {
          if (selectedTab === ProjectDetailTabEnums.dm) {
            handleDMSendClick(selectedItemData);
          } else {
            handleSendClick(selectedItemData);
          }
        }}
        isLoading={false}
      />
    </Box>
  );
};

export const UploadMenu = React.memo(_UploadMenu);
