import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

// Define a type for the slice state

interface ProjectVideoReviewStateType {
  pinnedPoints: { [key: string]: any };
  selectedPointerData?: { [key: string]: any };
  isReplyCommentInFocus: boolean;
  notificationDataToShowOnVideoFeedbackPage?: { [key: string]: any };
}

// Define the initial state using that type
const initialState: ProjectVideoReviewStateType = {
  pinnedPoints: {},
  isReplyCommentInFocus: false,
};

export const projectVideoReviewSlice = createSlice({
  name: 'projectVideoReview',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    resetVideoReviewData: (state) => {
      state = { ...initialState };
    },
    initializeVideoReviewPinnedPoints: (
      state,
      action: PayloadAction<{
        messageItemId: string;
        value: { [key: string]: any }[];
      }>
    ) => {
      const initialData = { ...state.pinnedPoints };
      initialData[action.payload.messageItemId] = action.payload.value;
      state.pinnedPoints = initialData;
    },
    addNewPinnedPoints: (
      state,
      action: PayloadAction<{
        messageItemId: string;
        value: { [key: string]: any };
      }>
    ) => {
      const initialData = { ...state.pinnedPoints };

      const selectedMessagePinnedPoints =
        initialData[action.payload.messageItemId];
      if (selectedMessagePinnedPoints) {
        const allData = [...selectedMessagePinnedPoints, action.payload.value];
        const reorderedData = [...allData].sort(
          (a: any, b: any) =>
            dayjs(a.created_at).valueOf() - dayjs(b.created_at).valueOf()
        );
        initialData[action.payload.messageItemId] = [...reorderedData];
      } else {
        initialData[action.payload.messageItemId] = [action.payload.value];
      }

      state.pinnedPoints = initialData;
    },

    updateVideoReviewPinnedPointData: (
      state,
      action: PayloadAction<{
        messageItemId: string;
        value: { [key: string]: any };
      }>
    ) => {
      const initialData = { ...state.pinnedPoints };
      const selectedMessageItemPointesData =
        initialData[action.payload.messageItemId];

      const filterPoints = selectedMessageItemPointesData.filter(
        (el: any) => !(el.timestamp === action.payload.value.timestamp)
      );

      const reorderedData = [...filterPoints, action.payload.value].sort(
        (a: any, b: any) =>
          dayjs(a.created_at).valueOf() - dayjs(b.created_at).valueOf()
      );

      initialData[action.payload.messageItemId] = reorderedData;

      state.pinnedPoints = initialData;
    },

    updateVideoReviewSinglePinnedPointDataOnDeleteAction: (
      state,
      action: PayloadAction<{
        messageItemId: string;
        value: {
          [key: string]: any;
        };
      }>
    ) => {
      const initialData = { ...state.pinnedPoints };
      const selectedAttachmentPinnedPoints =
        initialData[action.payload.messageItemId];
      const pointerId = action.payload.value.pointer_id;

      let index = -1;

      selectedAttachmentPinnedPoints.forEach((el: any, key: number) => {
        if (el.pointer_id === pointerId) {
          index = key;
        }
      });
      if (index > -1) {
        selectedAttachmentPinnedPoints.splice(index, 1);
        initialData[action.payload.messageItemId] =
          selectedAttachmentPinnedPoints;
        state.pinnedPoints = initialData;
      }
    },

    updateVideoReviewSinglePinnedPointData: (
      state,
      action: PayloadAction<{
        messageItemId: string;
        value: {
          [key: string]: any;
        };
      }>
    ) => {
      const initialData = { ...state.pinnedPoints };
      const selectedAttachmentPinnedPoints =
        initialData[action.payload.messageItemId];
      const pointerId = action.payload.value.pointer_id;

      let index = -1;

      selectedAttachmentPinnedPoints.forEach((el: any, key: number) => {
        if (el.pointer_id === pointerId) {
          index = key;
        }
      });
      if (index > -1) {
        selectedAttachmentPinnedPoints.splice(index, 1, {
          ...action.payload.value,
        });
        initialData[action.payload.messageItemId] =
          selectedAttachmentPinnedPoints;
        state.pinnedPoints = initialData;
      }
    },

    updateProjectVideoReviewSelectedPointer: (
      state,
      action: PayloadAction<{ [key: string]: any } | undefined>
    ) => {
      state.selectedPointerData = action.payload;
    },

    updateVideoReviewReplyCommentInFocus: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isReplyCommentInFocus = action.payload;
    },

    updateAddedVideoReviewMsgOnPinnedPointData: (
      state,
      action: PayloadAction<{
        messageItemId: string;
        value: {
          [key: string]: any;
        };
      }>
    ) => {
      const initialData = { ...state.pinnedPoints };
      const selectedAttachmentPinnedPoints =
        initialData[action.payload.messageItemId];
      const pointerId = action.payload.value.videoReviewPointer;

      let index = -1;

      selectedAttachmentPinnedPoints.forEach((el: any, key: number) => {
        if (el.pointer_id === pointerId) {
          index = key;
        }
      });

      if (index > -1) {
        const selectedPointerData = selectedAttachmentPinnedPoints[index];

        selectedPointerData.review_messages.push(action.payload.value);

        selectedAttachmentPinnedPoints.splice(index, 1, {
          ...selectedPointerData,
        });

        const reorderedData = [...selectedAttachmentPinnedPoints].sort(
          (a: any, b: any) =>
            dayjs(a.created_at).valueOf() - dayjs(b.created_at).valueOf()
        );

        initialData[action.payload.messageItemId] = reorderedData;
        state.pinnedPoints = initialData;
      }
    },

    updateVideoReviewMsgOnPinnedPointData: (
      state,
      action: PayloadAction<{
        messageItemId: string;
        value: {
          [key: string]: any;
        };
      }>
    ) => {
      const initialData = { ...state.pinnedPoints };
      const selectedAttachmentPinnedPoints =
        initialData[action.payload.messageItemId];
      const pointerId = action.payload.value.pointer_id;

      let index = -1;

      selectedAttachmentPinnedPoints.forEach((el: any, key: number) => {
        if (el.pointer_id === pointerId) {
          index = key;
        }
      });

      if (index > -1) {
        const selectedPointerData = selectedAttachmentPinnedPoints[index];

        const reviewMessages = selectedPointerData.review_messages;

        reviewMessages.forEach((el: any) => {
          if (el.id === action.payload.value.review_message_id) {
            el.message = action.payload.value.message;
          }
        });

        selectedPointerData.review_messages = reviewMessages;

        selectedAttachmentPinnedPoints.splice(index, 1, {
          ...selectedPointerData,
        });

        const reorderedData = [...selectedAttachmentPinnedPoints].sort(
          (a: any, b: any) =>
            dayjs(a.created_at).valueOf() - dayjs(b.created_at).valueOf()
        );

        initialData[action.payload.messageItemId] = reorderedData;
        state.pinnedPoints = initialData;
      }
    },

    deleteVideoReviewMsgOnPinnedPointData: (
      state,
      action: PayloadAction<{
        messageItemId: string;
        value: {
          [key: string]: any;
        };
      }>
    ) => {
      const initialData = { ...state.pinnedPoints };
      const selectedAttachmentPinnedPoints =
        initialData[action.payload.messageItemId];
      const pointerId = action.payload.value.pointer_id;

      let index = -1;

      selectedAttachmentPinnedPoints.forEach((el: any, key: number) => {
        if (el.pointer_id === pointerId) {
          index = key;
        }
      });

      if (index > -1) {
        const selectedPointerData = selectedAttachmentPinnedPoints[index];

        if (
          action.payload.value.main_comment &&
          action.payload.value.main_comment === 'true'
        ) {
          selectedAttachmentPinnedPoints.splice(index, 1);
        } else {
          const reviewMessages = selectedPointerData.review_messages;

          const filteredMsg = reviewMessages.filter((el: any) => {
            return el.id !== action.payload.value.review_message_id;
          });

          selectedPointerData.review_messages = filteredMsg;

          selectedAttachmentPinnedPoints.splice(index, 1, {
            ...selectedPointerData,
          });
        }

        const reorderedData = [...selectedAttachmentPinnedPoints].sort(
          (a: any, b: any) =>
            dayjs(a.created_at).valueOf() - dayjs(b.created_at).valueOf()
        );

        initialData[action.payload.messageItemId] = reorderedData;
        state.pinnedPoints = initialData;
      }
    },

    addNotificationDataToShowOnVideoFeedbackPage: (
      state,
      action: PayloadAction<{
        [key: string]: any;
      }>
    ) => {
      state.notificationDataToShowOnVideoFeedbackPage = action.payload;
    },

    resetNotificationDataToShowOnVideoFeedbackPage: (state) => {
      state.notificationDataToShowOnVideoFeedbackPage = undefined;
    },
  },
});

export const {
  resetVideoReviewData,
  addNewPinnedPoints,
  initializeVideoReviewPinnedPoints,
  updateVideoReviewPinnedPointData,
  updateVideoReviewSinglePinnedPointDataOnDeleteAction,
  updateVideoReviewSinglePinnedPointData,
  updateProjectVideoReviewSelectedPointer,
  updateVideoReviewReplyCommentInFocus,
  updateAddedVideoReviewMsgOnPinnedPointData,
  updateVideoReviewMsgOnPinnedPointData,
  deleteVideoReviewMsgOnPinnedPointData,
  addNotificationDataToShowOnVideoFeedbackPage,
  resetNotificationDataToShowOnVideoFeedbackPage,
} = projectVideoReviewSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value;

export default projectVideoReviewSlice.reducer;
