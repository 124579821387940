import { Box, Center } from '@chakra-ui/react';
import React, { lazy, Suspense } from 'react';
import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
} from 'react-router-dom';
import LogoIcon from './theme/icons/LogoIcon';
import { ProtectedRoute } from './utils/protectedRoute';

export enum Paths {
  login = '/login',
  profile = '/workspace/:workspace_id/profile',
  console = '/workspace/:workspace_id/console',

  // 16Oct New One
  connects = '/workspace/:workspace_id/connects',
  newConnect = '/workspace/:workspace_id/new-connect',
  newGroupConnect = '/workspace/:workspace_id/new-group-connect',
  businessProfile = '/workspace/:workspace_id/business-profile',
  inviteMemberConnect = '/workspace/:workspace_id/invite-member-connect',
  connectDetail = '/workspace/:workspace_id/connect/:connect_id',
  connectDetailItemPreview = '/workspace/:workspace_id/connect/:connect_id/message-item/:message_item_id',
  videoCall = '/workspace/:workspace_id/connect/:connect_id/call/:room_id',
  initVideoCall = '/workspace/:workspace_id/connect/:connect_id/init-call',
  connectOrder = '/workspace/:workspace_id/connect/:connect_id/order/:order_id',
  discover = '/discover',
}

const LoginPage = lazy(() => import('./pages/Login'));
const WorkspaceConsole = lazy(() => import('./pages/WorkspaceConsole'));
const ProfilePage = lazy(() => import('./pages/Profile'));

// 16 OcT NEW pages
const ConnectsPage = lazy(() => import('./pages/Connects'));
const NewConnectPage = lazy(() => import('./pages/NewConnect'));
const NewGroupConnectPage = lazy(() => import('./pages/NewGroupConnect'));
const BusinessProfilePage = lazy(() => import('./pages/BusinessProfile'));
const InviteMemberConnectPage = lazy(() =>
  import('./pages/InviteMemberConnect')
);
const ConnectDetailPage = lazy(() => import('./pages/ConnectDetail'));
const ConnectDetailItemPreviewPage = lazy(() =>
  import('./pages/ConnectItemPreview')
);
const VideoCallPage = lazy(() => import('./pages/VideoCall'));

const InitVideoCall = lazy(() => import('./pages/InitVideoCall'));

const ConnectOrderPage = lazy(() => import('./pages/ConnectOrder'));
const DiscoverPage = lazy(() => import('./pages/Discover'));

export const MainNav: React.FC = () => {
  return (
    <Router>
      <Suspense
        fallback={
          <Center h="100%" w="100%" bg="primary.soft_peach">
            <Box w="40">
              <LogoIcon height="100%" width="100%" />
            </Box>
          </Center>
        }
      >
        <Routes>
          <Route
            path={Paths.console}
            element={
              <ProtectedRoute path={Paths.console} subject="WorkspaceConsole">
                <WorkspaceConsole />
              </ProtectedRoute>
            }
          />
          <Route
            path={Paths.profile}
            element={
              <ProtectedRoute path={Paths.profile} subject="Profile">
                <ProfilePage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Paths.connects}
            element={
              <ProtectedRoute path={Paths.connects} subject="Connects">
                <ConnectsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Paths.newConnect}
            element={
              <ProtectedRoute path={Paths.newConnect} subject="NewConnects">
                <NewConnectPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Paths.newGroupConnect}
            element={
              <ProtectedRoute
                path={Paths.newGroupConnect}
                subject="NewGroupConnects"
              >
                <NewGroupConnectPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Paths.businessProfile}
            element={
              <ProtectedRoute
                path={Paths.businessProfile}
                subject="BusinessProfile"
              >
                <BusinessProfilePage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Paths.inviteMemberConnect}
            element={
              <ProtectedRoute
                path={Paths.inviteMemberConnect}
                subject="InviteMemberConnect"
              >
                <InviteMemberConnectPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Paths.connectDetail}
            element={
              <ProtectedRoute
                path={Paths.connectDetail}
                subject="ConnectDetail"
              >
                <ConnectDetailPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Paths.connectDetailItemPreview}
            element={
              <ProtectedRoute
                path={Paths.connectDetailItemPreview}
                subject="ConnectDetailItemPreview"
              >
                <ConnectDetailItemPreviewPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Paths.videoCall}
            element={
              <ProtectedRoute path={Paths.videoCall} subject="VideoCall">
                <VideoCallPage />
              </ProtectedRoute>
            }
          />

          <Route
            path={Paths.initVideoCall}
            element={
              <ProtectedRoute
                path={Paths.initVideoCall}
                subject="InitVideoCall"
              >
                <InitVideoCall />
              </ProtectedRoute>
            }
          />

          <Route
            path={Paths.connectOrder}
            element={
              <ProtectedRoute path={Paths.connectOrder} subject="ConnectOrder">
                <ConnectOrderPage />
              </ProtectedRoute>
            }
          />

          <Route
            path={Paths.discover}
            element={
              <ProtectedRoute path={Paths.discover} subject="Discover">
                <DiscoverPage />
              </ProtectedRoute>
            }
          />

          <Route path={Paths.login} element={<LoginPage />} />
          <Route path="*" element={<Navigate to={Paths.login} replace />} />
        </Routes>
      </Suspense>
    </Router>
  );
};
