import Icon, { IconProps } from '@chakra-ui/icon';
import { OmitCommonProps, As } from '@chakra-ui/react';
import React from 'react';

const _ImageFormatIcon = (
  props: JSX.IntrinsicAttributes &
    OmitCommonProps<React.SVGProps<SVGSVGElement>, keyof IconProps> &
    IconProps &
    OmitCommonProps<any, keyof IconProps> & { as?: As<any> | undefined }
) => (
  <Icon viewBox="0 0 31 35" width={31} height={35} focusable="false" {...props}>
    <g fillRule="nonzero" fill="none">
      <path
        d="M2.182 0h18.542l9.829 9.79v22.945a2.182 2.182 0 0 1-2.183 2.182H2.182A2.182 2.182 0 0 1 0 32.735V2.182C0 .977.978 0 2.182 0Z"
        fill="#3635AD"
      />
      <path
        d="M11.55 22.519h6.53L16.116 19.9l-1.762 2.284-1.242-1.696-1.563 2.03Zm-.749 2.096c-.213 0-.4-.08-.56-.24a.769.769 0 0 1-.241-.56V15.8c0-.213.08-.4.24-.56.16-.16.348-.241.561-.241h8.013c.214 0 .4.08.561.24.16.16.24.348.24.561v8.013c0 .214-.08.4-.24.561a.769.769 0 0 1-.56.24H10.8Z"
        fill="#FFF"
      />
    </g>
  </Icon>
);

export const ImageFormatIcon = React.memo(_ImageFormatIcon);
