import Icon, { IconProps } from '@chakra-ui/icon';
import { OmitCommonProps, As } from '@chakra-ui/react';
import React from 'react';

const _MiroIcon = (
  props: JSX.IntrinsicAttributes &
    OmitCommonProps<React.SVGProps<SVGSVGElement>, keyof IconProps> &
    IconProps &
    OmitCommonProps<any, keyof IconProps> & { as?: As<any> | undefined }
) => (
  <Icon width={26} height={26} viewBox="0 0 26 26" focusable="false" {...props}>
    <g fillRule="nonzero" fill="none">
      <path fill="#FFD02F" d="M0 0h26v26H0z" />
      <path
        fill="#050038"
        d="M17.717 3.467H14.94l2.464 3.818-5.209-3.818H9.421l2.464 5.004-5.21-5.004H3.9l2.423 6.46L3.9 22.533h2.776l5.209-13.517L9.42 22.533h2.775l5.21-14.702-2.465 14.702h2.776l5.25-16.15z"
      />
    </g>
  </Icon>
);

export const MiroIcon = React.memo(_MiroIcon);
