import Icon, { IconProps } from '@chakra-ui/icon';
import { OmitCommonProps, As } from '@chakra-ui/react';
import React from 'react';

const _ProjectArchitectureIcon = (
  props: JSX.IntrinsicAttributes &
    OmitCommonProps<React.SVGProps<SVGSVGElement>, keyof IconProps> &
    IconProps &
    OmitCommonProps<any, keyof IconProps> & { as?: As<any> | undefined }
) => (
  <Icon width={96} height={96} viewBox="0 0 96 96" focusable="false" {...props}>
    <defs>
      <path id="sasa" d="M0 0h96v96H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <use fill="#6A10A2" xlinkHref="#sasa" />
      <path fill="#A746E3" d="M62 62h10v10H62z" />
      <path fill="#D8A6F7" d="M62 24h10v38H62zM24 62h38v10H24z" />
      <path fill="#A746E3" d="M24 24h38v38H24z" />
    </g>
  </Icon>
);

export const ProjectArchitectureIcon = React.memo(_ProjectArchitectureIcon);
