import Icon, { IconProps } from '@chakra-ui/icon';
import { OmitCommonProps, As } from '@chakra-ui/react';
import React from 'react';

const _GoogleMeetIcon = (
  props: JSX.IntrinsicAttributes &
    OmitCommonProps<React.SVGProps<SVGSVGElement>, keyof IconProps> &
    IconProps &
    OmitCommonProps<any, keyof IconProps> & { as?: As<any> | undefined }
) => (
  <Icon width={31} height={26} viewBox="0 0 31 26" focusable="false" {...props}>
    <g fillRule="nonzero" fill="none">
      <path
        fill="#188038"
        d="m17.378 12.64 2.994 3.422 4.028 2.573.703-5.973L24.4 6.82l-4.105 2.26z"
      />
      <path
        d="M0 18.08v5.09c0 1.165.943 2.107 2.107 2.107h5.09L8.25 21.43l-1.053-3.35-3.492-1.052L0 18.08Z"
        fill="#1967D2"
      />
      <path
        fill="#EA4335"
        d="M7.197 0 0 7.197 3.705 8.25l3.492-1.053 1.035-3.304z"
      />
      <path fill="#4285F4" d="M0 18.08h7.197V7.198H0z" />
      <path
        d="M28.997 3.047 24.4 6.82v11.815l4.617 3.787c.693.54 1.703.048 1.703-.83V3.863c0-.89-1.035-1.377-1.723-.815Zm-11.62 9.593v5.442H7.198v7.198h15.098a2.106 2.106 0 0 0 2.107-2.108v-4.537l-7.024-5.995Z"
        fill="#34A853"
      />
      <path
        d="M22.295 0H7.197v7.197H17.38v5.443l7.02-5.82V2.107A2.106 2.106 0 0 0 22.295 0Z"
        fill="#FBBC04"
      />
    </g>
  </Icon>
);

export const GoogleMeetIcon = React.memo(_GoogleMeetIcon);
