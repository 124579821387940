/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

interface Props {
  root?: any;
  target: React.RefObject<HTMLDivElement>;
  onIntersect: () => void;
  onNotIntersect?: () => void;
  threshold?: number;
  rootMargin?: string;
  enabled?: boolean;
}

export default function useIntersectionObserver({
  root,
  target,
  onIntersect,
  threshold = 1.0,
  rootMargin = '0px',
  enabled = true,
  onNotIntersect = () => {},
}: Props) {
  React.useEffect(() => {
    if (!enabled) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) => {
        return entries.forEach((entry) => {
          entry.isIntersecting ? onIntersect() : onNotIntersect();
        });
      },

      {
        root: root && root.current,
        rootMargin,
        threshold,
      }
    );

    // console.log(target, enabled, 'Added a note on a lead');

    const el = target && target.current;

    if (!el) {
      return;
    }

    observer.observe(el);

    return () => {
      observer.unobserve(el);
    };
  }, [target, target.current, enabled]);
}
