import axios from 'axios';
import {
  UseQueryOptions,
  useQuery,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from 'react-query';
import { CustomRQResponseType, QuerykeyType } from '../../type/types';
import { baseUrl } from '../../utils/config';

const getSingleConnectData = async (
  workspace_id: string,
  connect_id: string
) => {
  const { data } = await axios.get<any, CustomRQResponseType>(
    `${baseUrl}/workspace/${workspace_id}/connect/${connect_id}`,
    {
      headers: {
        Authorization: localStorage.getItem('p-auth') || 'TOKEN',
      },
    }
  );
  return data;
};

export const useSingleConnectData = <T extends any = CustomRQResponseType>(
  workspace_id: string,
  connect_id: any,
  options?: UseQueryOptions<T, unknown, CustomRQResponseType>
) => {
  return useQuery<any>(
    ['selected_connect', workspace_id, connect_id],
    () => getSingleConnectData(workspace_id, connect_id),
    {
      retry: 1,
      ...options,
    }
  );
};

const getAllActiveConnectsData = async (
  workspace_id: string,
  { pageParam = 1, queryKey }: QuerykeyType,
  params: any
) => {
  const updatedParams = { ...params };

  const { data } = await axios.get<any, CustomRQResponseType>(
    `${baseUrl}/workspace/${workspace_id}/connect/active?per_page=50&page=${pageParam}`,
    {
      headers: {
        Authorization: localStorage.getItem('p-auth') || 'TOKEN',
      },
      params: updatedParams,
    }
  );
  return data;
};

export const usePaginatedActiveConnectsData = <
  T extends any = CustomRQResponseType
>(
  workspace_id: string,
  params?: any,
  options?: UseInfiniteQueryOptions<T, unknown, CustomRQResponseType>
) => {
  return useInfiniteQuery<any>(
    ['active-connects', workspace_id, params],
    (query) => getAllActiveConnectsData(workspace_id, query, params),
    {
      retry: 1,
      getNextPageParam: (page) => {
        return page.data.current_page === page.data.last_page ||
          page.data.last_page === 0
          ? undefined
          : page.data.current_page + 1;
      },
      ...options,
    }
  );
};

const getAllClosedConnectsData = async (
  workspace_id: string,
  { pageParam = 1, queryKey }: QuerykeyType,
  params: any
) => {
  const updatedParams = { ...params };

  const { data } = await axios.get<any, CustomRQResponseType>(
    `${baseUrl}/workspace/${workspace_id}/connect/closed?per_page=50&page=${pageParam}`,
    {
      headers: {
        Authorization: localStorage.getItem('p-auth') || 'TOKEN',
      },
      params: updatedParams,
    }
  );
  return data;
};

export const usePaginatedClosedConnectsData = <
  T extends any = CustomRQResponseType
>(
  workspace_id: string,
  params?: any,
  options?: UseInfiniteQueryOptions<T, unknown, CustomRQResponseType>
) => {
  return useInfiniteQuery<any>(
    ['closed-connects', workspace_id, params],
    (query) => getAllClosedConnectsData(workspace_id, query, params),
    {
      retry: 1,
      getNextPageParam: (page) => {
        return page.data.current_page === page.data.last_page ||
          page.data.last_page === 0
          ? undefined
          : page.data.current_page + 1;
      },
      ...options,
    }
  );
};

const getAllRequestConnectsData = async (
  workspace_id: string,
  { pageParam = 1, queryKey }: QuerykeyType,
  params: any
) => {
  const updatedParams = { ...params };

  const { data } = await axios.get<any, CustomRQResponseType>(
    `${baseUrl}/workspace/${workspace_id}/connect/request?per_page=50&page=${pageParam}`,
    {
      headers: {
        Authorization: localStorage.getItem('p-auth') || 'TOKEN',
      },
      params: updatedParams,
    }
  );
  return data;
};

export const usePaginatedRequestConnectsData = <
  T extends any = CustomRQResponseType
>(
  workspace_id: string,
  params?: any,
  options?: UseInfiniteQueryOptions<T, unknown, CustomRQResponseType>
) => {
  return useInfiniteQuery<any>(
    ['request-connects', workspace_id, params],
    (query) => getAllRequestConnectsData(workspace_id, query, params),
    {
      retry: 1,
      getNextPageParam: (page) => {
        return page.data.current_page === page.data.last_page ||
          page.data.last_page === 0
          ? undefined
          : page.data.current_page + 1;
      },
      ...options,
    }
  );
};

const getConnectSearchData = async (workspace_id: string, search: string) => {
  const { data } = await axios.get<any, CustomRQResponseType>(
    `${baseUrl}/workspace/${workspace_id}/connect`,
    {
      headers: {
        Authorization: localStorage.getItem('p-auth') || 'TOKEN',
      },
      params: {
        search: search,
      },
    }
  );
  return data;
};

export const useConnectSearchData = <T extends any = CustomRQResponseType>(
  workspace_id: string,
  search: string,
  options?: UseQueryOptions<T, unknown, CustomRQResponseType>
) => {
  return useQuery<any>(
    ['connect-search', workspace_id, search],
    () => getConnectSearchData(workspace_id, search),
    {
      retry: 1,
      ...options,
    }
  );
};
