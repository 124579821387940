import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { CustomRQResponseType } from '../../type/types';
import { baseUrl } from '../../utils/config';

const allCustomTagData = async (workspace_id: string) => {
  const { data } = await axios.get<any, CustomRQResponseType>(
    `${baseUrl}/workspace/${workspace_id}/custom-tag`,
    {
      headers: {
        Authorization: localStorage.getItem('p-auth') || 'TOKEN',
      },
    }
  );
  return data;
};

export const useAllCustomTagData = <T extends any = CustomRQResponseType>(
  workspace_id: string,
  options?: UseQueryOptions<T, unknown, CustomRQResponseType>
) => {
  return useQuery<any>(
    ['custom-tags', workspace_id],
    () => allCustomTagData(workspace_id),
    {
      retry: 1,
      ...options,
    }
  );
};
