import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { queryClient } from '..';
import { Paths } from '../MainNav';
import path from '../sound/boop.mp3';

// Define a type for the slice state

interface NotificationType {
  currentPath?: {
    path: string;
    params: { [key: string]: any };
  };
  memberAddedToProjectCount: string[];
  unseenConnectsData: { [key: string]: any };
  unseenActiveConnects: { [key: string]: any };
  unseenClosedConnects: { [key: string]: any };
  unseenRequestConnects: { [key: string]: any };
}

// Define the initial state using that type
const initialState: NotificationType = {
  memberAddedToProjectCount: [],
  unseenConnectsData: {},
  unseenActiveConnects: {},
  unseenClosedConnects: {},
  unseenRequestConnects: {},
};

export const notificationSlice = createSlice({
  name: 'notification',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    updateNavigationCurrentPath: (
      state,
      action: PayloadAction<{ path: string; params: { [key: string]: any } }>
    ) => {
      state.currentPath = {
        path: action.payload.path,
        params: action.payload.params,
      };
    },
    updateMemberAddedToProject: (
      state,
      action: PayloadAction<{ projectId: string; workspaceId: string }>
    ) => {
      const initialData = state.memberAddedToProjectCount;
      state.memberAddedToProjectCount = [
        action.payload.projectId,
        ...initialData,
      ];
      queryClient.invalidateQueries([
        'projects',
        action.payload.workspaceId,
        {},
      ]);
    },
    resetMemberAddedToProject: (state) => {
      state.memberAddedToProjectCount = [];
    },

    refreshConnectDetail: (
      state,
      action: PayloadAction<{ connectId: string; workspaceId: string }>
    ) => {
      queryClient.invalidateQueries([
        'selected_connect',
        action.payload.workspaceId,
        action.payload.connectId,
      ]);
    },

    resetUnseenCountOnConnectDetailMount: (
      state,
      action: PayloadAction<{ connectId: string; workspaceId: string }>
    ) => {
      if (
        state.unseenConnectsData &&
        state.unseenConnectsData.hasOwnProperty(action.payload.workspaceId)
      ) {
        const alldata = state.unseenConnectsData[action.payload.workspaceId];
        const filteredData = [...alldata].filter(
          (el: any) => el.connect_id !== action.payload.connectId
        );
        state.unseenConnectsData[action.payload.workspaceId] = filteredData;
      }

      if (
        state.unseenActiveConnects &&
        state.unseenActiveConnects.hasOwnProperty(action.payload.workspaceId)
      ) {
        const alldata = state.unseenActiveConnects[action.payload.workspaceId];
        const filteredData = [...alldata].filter(
          (el: any) => el.connect_id !== action.payload.connectId
        );
        state.unseenActiveConnects[action.payload.workspaceId] = filteredData;
      }

      if (
        state.unseenClosedConnects &&
        state.unseenClosedConnects.hasOwnProperty(action.payload.workspaceId)
      ) {
        const alldata = state.unseenClosedConnects[action.payload.workspaceId];
        const filteredData = [...alldata].filter(
          (el: any) => el.connect_id !== action.payload.connectId
        );
        state.unseenClosedConnects[action.payload.workspaceId] = filteredData;
      }

      if (
        state.unseenRequestConnects &&
        state.unseenRequestConnects.hasOwnProperty(action.payload.workspaceId)
      ) {
        const alldata = state.unseenRequestConnects[action.payload.workspaceId];
        const filteredData = [...alldata].filter(
          (el: any) => el.connect_id !== action.payload.connectId
        );
        state.unseenRequestConnects[action.payload.workspaceId] = filteredData;
      }
    },

    refreshConnectUnseenDetail: (
      state,
      action: PayloadAction<{ workspaceId: string; connectId: string }>
    ) => {
      // If the path is the connect detail and the notification is regarding same connect id then remove the unseen count and emit a socket to detele the notification detail
      if (
        state.currentPath?.path === Paths.connectDetail &&
        state.currentPath?.params?.connect_id === action.payload.connectId
      ) {
        if (
          state.unseenConnectsData &&
          state.unseenConnectsData.hasOwnProperty(action.payload.workspaceId)
        ) {
          const alldata = state.unseenConnectsData[action.payload.workspaceId];
          const filteredData = [...alldata].filter(
            (el: any) => el.connect_id !== action.payload.connectId
          );
          state.unseenConnectsData[action.payload.workspaceId] = filteredData;
        }
      }

      // If the path is the connect detail and the notification is NOT regarding same connect id then refetch

      if (
        state.currentPath?.path === Paths.connectDetail &&
        state.currentPath?.params?.connect_id !== action.payload.connectId
      ) {
        if (
          state.unseenConnectsData &&
          state.unseenConnectsData.hasOwnProperty(action.payload.workspaceId)
        ) {
          const alldata = state.unseenConnectsData[action.payload.workspaceId];
          const index = [...alldata].findIndex(
            (el: any) => el.connect_id === action.payload.connectId
          );

          if (index === -1) {
            queryClient.invalidateQueries([
              'unseen-connect',
              action.payload.workspaceId,
              {},
            ]);
          }
        } else {
          state.unseenConnectsData[action.payload.workspaceId] = [
            {
              connect_id: action.payload.connectId,
              workspace_sub_tags: [],
              user_custom_tags: [],
            },
          ];
        }
      }
    },

    initializeUnseenConnectsData: (
      state,
      action: PayloadAction<{
        data: { [key: string]: any };
        dataByConnectState: { [key: string]: any };
      }>
    ) => {
      let initialSumVal = 0;
      let finalSumVal = 0;
      if (
        state.unseenConnectsData &&
        Object.keys(state.unseenConnectsData).length > 0
      ) {
        Object.keys(state.unseenConnectsData).forEach((el: string) => {
          initialSumVal =
            initialSumVal + state.unseenConnectsData[el] &&
            state.unseenConnectsData[el].length > 0
              ? state.unseenConnectsData[el].length
              : 0;
        });
      }
      if (action.payload.data && Object.keys(action.payload.data).length > 0) {
        Object.keys(action.payload.data).forEach((el: string) => {
          finalSumVal =
            finalSumVal + action.payload.data[el] &&
            action.payload.data[el].length > 0
              ? action.payload.data[el].length
              : 0;
        });
      }

      state.unseenConnectsData = action.payload.data;
      state.unseenActiveConnects = action.payload.dataByConnectState?.active;
      state.unseenClosedConnects = action.payload.dataByConnectState?.closed;
      state.unseenRequestConnects = action.payload.dataByConnectState?.request;

      // if (initialSumVal < finalSumVal) {
      //   const audio = new Audio(path);
      //   audio.play();
      // }
    },
  },
});

export const {
  updateNavigationCurrentPath,
  updateMemberAddedToProject,
  resetMemberAddedToProject,
  refreshConnectDetail,
  refreshConnectUnseenDetail,
  initializeUnseenConnectsData,
  resetUnseenCountOnConnectDetailMount,
} = notificationSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value;

export default notificationSlice.reducer;
