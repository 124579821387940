import axios from 'axios';
import { useMutation } from 'react-query';
import { CustomResponseType } from '../../type/types';
import { baseUrl } from '../../utils/config';

// create connect purchase order✅
const createConnectPurchaseOrder = async (
  workspace_id: string,
  connect_id: string,
  message_item: string
) => {
  const { data } = await axios.post<any, CustomResponseType>(
    `${baseUrl}/workspace/${workspace_id}/connect/${connect_id}/order`,
    { message_item: message_item },
    {
      headers: {
        Authorization: localStorage.getItem('p-auth') || 'TOKEN',
      },
    }
  );
  return data;
};

export const useCreateConnectPurchaseOrder = (
  workspace_id: string,
  connect_id: string
) => {
  return useMutation((message_item: string) =>
    createConnectPurchaseOrder(workspace_id, connect_id, message_item)
  );
};

// ✅
const initiateRazorpay = async (
  workspace_id: string,
  connect_id: string,
  order_id: string
) => {
  const { data } = await axios.post<any, CustomResponseType>(
    `${baseUrl}/workspace/${workspace_id}/connect/${connect_id}/order/${order_id}/init-payment`,
    {},
    {
      headers: {
        Authorization: localStorage.getItem('p-auth') || 'TOKEN',
      },
    }
  );
  return data;
};

export const useInitPayment = (workspace_id: string, connect_id: string) => {
  return useMutation((order_id: string) =>
    initiateRazorpay(workspace_id, connect_id, order_id)
  );
};

// create connect purchase order✅
const createGuestConnectRequestPurchaseOrder = async (
  workspace_id: string,
  work_tag_id: string
) => {
  const { data } = await axios.post<any, CustomResponseType>(
    `${baseUrl}/workspace/${workspace_id}/order/guest-connect-request`,
    { work_tag_id: work_tag_id },
    {
      headers: {
        Authorization: localStorage.getItem('p-auth') || 'TOKEN',
      },
    }
  );
  return data;
};

export const useCreateGuestConnectPurchaseOrder = (workspace_id: string) => {
  return useMutation((work_tag_id: string) =>
    createGuestConnectRequestPurchaseOrder(workspace_id, work_tag_id)
  );
};

const createPaidConnectPurchaseOrder = async (
  workspace_id: string,
  connect_id: string
) => {
  const { data } = await axios.post<any, CustomResponseType>(
    `${baseUrl}/workspace/${workspace_id}/connect/${connect_id}/order/paid-connect`,
    {},
    {
      headers: {
        Authorization: localStorage.getItem('p-auth') || 'TOKEN',
      },
    }
  );
  return data;
};

export const useCreatePaidConnectPurchaseOrder = (workspace_id: string) => {
  return useMutation((connect_id: string) =>
    createPaidConnectPurchaseOrder(workspace_id, connect_id)
  );
};
