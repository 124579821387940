import {
  Center,
  VStack,
  Text,
  FormControl,
  FormLabel,
  HStack,
  Tag,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import React, { useRef, useState } from 'react';
import { useContext, useMemo } from 'react';
import { AppDetailContext } from '../../../Context/AppDetailContext';
import { RootState } from '../../../store';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  updateAddItemToProjectClick,
  updateEditItemCardClick,
} from '../../../store/library';
import { InfoIcon } from '../../../svg/Icons/info.icon';
import { ClientRoleEnum } from '../../../type/types';
import { getItemsTypeOptionsData } from '../../../utils/items';
import { RefSourcesForProjectDetail } from '../../../utils/router';
import { ProjectListCard } from '../../Team/TeamMemberProjectList';
import { ItemsTypeEnum } from '../helper';
import { Document, Page, pdfjs } from 'react-pdf';
import { ImageBlurLoader } from '../LeftSideComponent/ItemCard/ImageBlurLoader';
import { VideoPreviewComponent } from '../LeftSideComponent/ItemCard/VideoPreviewComponent';
import { ItemDetailOverviewSkeleton } from '../../Skeleton/Library/ItemDetailOverview';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface Props {
  data: { [key: string]: any };
  searchUrlParam: RefSourcesForProjectDetail | undefined;
  isLoading: boolean;
}

const _ItemOverviewTab = (props: Props) => {
  const { data, searchUrlParam, isLoading } = props;
  const dispatch = useAppDispatch();
  const [isPDFLoaded, updateIsPDFLoaded] = useState<boolean>(false);
  const itemRef = useRef<HTMLDivElement>(null);

  const { appDetail } = useContext(AppDetailContext);

  const selectedWorkspaceRole = useAppSelector(
    (state: RootState) => state.user.selectedWorkspaceRole
  );

  const iconData = useMemo(() => {
    return getItemsTypeOptionsData(data?.sub_type, '50px', '50px');
  }, [data]);

  const handleAddToProject = () => {
    dispatch(updateAddItemToProjectClick(data));
  };

  const itemName = useMemo(() => {
    if (data.name) {
      return data.name;
    } else if (data.type === 'FILE') {
      return data.attachment.originalname;
    } else if (data.type === 'LINK') {
      const hasProtocol = data?.content.includes('http');
      const { hostname } = new URL(
        hasProtocol ? data?.content : `https://${data?.content}`
      );
      return hostname;
    } else {
      return 'Item';
    }
  }, [data]);

  const showImage = useMemo(() => {
    if (
      data &&
      data.type === 'FILE' &&
      data?.sub_type === ItemsTypeEnum.imageFile
    ) {
      return true;
    } else if (
      data &&
      data.type === 'LINK' &&
      data?.meta_image &&
      data?.meta_image.length > 0
    ) {
      return true;
    }

    return false;
  }, [data]);

  const isSharingEnabled = useMemo(() => {
    if (appDetail?.id === data.created_by?.id) {
      return true;
    } else if (
      appDetail?.id !== data.created_by?.id &&
      data &&
      data.access &&
      data.access === 'EVERYONE'
    ) {
      return true;
    } else {
      return false;
    }
  }, [appDetail?.id, data]);

  const projectsIamPartOf = useMemo(() => {
    const finalProjects = [] as any;

    data &&
      data.project &&
      appDetail?.id &&
      data.project.forEach((el: any) => {
        if (
          selectedWorkspaceRole !== ClientRoleEnum.admin &&
          el.team.map((member: any) => member.id).includes(appDetail.id)
        ) {
          finalProjects.push(el);
        } else if (selectedWorkspaceRole === ClientRoleEnum.admin) {
          finalProjects.push(el);
        }
      });

    return finalProjects.sort(
      (a: any, b: any) =>
        dayjs(b.created_at).valueOf() - dayjs(a.created_at).valueOf()
    );
  }, [appDetail.id, data, selectedWorkspaceRole]);

  return (
    <React.Fragment>
      {!isLoading && (
        <VStack w="100%">
          <Center
            h="256px"
            w="256px"
            bg={iconData?.bg}
            borderRadius={12}
            overflow="hidden"
            boxShadow="0px 0px 3px 0 rgba(0, 0, 0, 0.05)"
            ref={itemRef}
            cursor="pointer"
          >
            {Object.keys(data) &&
              Object.keys(data).length > 0 &&
              data?.sub_type === ItemsTypeEnum.videoFile &&
              data.videoItemMetadata &&
              data.videoItemMetadata?.sprite && (
                <VideoPreviewComponent
                  url={data.videoItemMetadata?.sprite}
                  containerWidth={256}
                  yPosition={0}
                  ref={itemRef}
                />
              )}
            {data?.sub_type !== ItemsTypeEnum.videoFile && showImage && (
              <ImageBlurLoader
                sourceUrl={
                  data && data.type === 'LINK'
                    ? data.meta_image
                    : data.attachment.location
                }
              />
            )}
            {data?.sub_type === ItemsTypeEnum.pdfFile && (
              <Document
                file={data.attachment.location}
                onLoadSuccess={(val: any) => {
                  updateIsPDFLoaded(true);
                }}
                loading=""
              >
                <Page height={200} width={200} pageNumber={1} />
              </Document>
            )}
            {!showImage &&
              data?.sub_type !== ItemsTypeEnum.videoFile &&
              (data?.sub_type !== ItemsTypeEnum.pdfFile || !isPDFLoaded) && (
                <Center w="100%" height={'100%'} borderTopRadius={8}>
                  {iconData?.icon}
                </Center>
              )}
            {!showImage &&
              data?.sub_type === ItemsTypeEnum.videoFile &&
              (!data.videoItemMetadata ||
                (data.videoItemMetadata &&
                  !data.videoItemMetadata?.sprite)) && (
                <VStack>
                  <Center
                    w="100%"
                    height={'100%'}
                    alignItems={'center'}
                    flexDir="column"
                    justifyContent={'center'}
                    borderTopRadius={8}
                    pt="6"
                  >
                    {iconData?.icon}
                  </Center>
                  <Text mt="2" fontSize={'12'}>
                    Generating preview ...
                  </Text>
                </VStack>
              )}
          </Center>
          <Text
            fontSize="18"
            fontWeight="600"
            lineHeight={'24px'}
            pt="5"
            whiteSpace="nowrap"
            overflow={'hidden'}
            textOverflow="ellipsis"
            w="60%"
            textAlign={'center'}
            _hover={{
              textDecoration: 'underline',
            }}
            cursor="pointer"
            onClick={(e) => {
              e.stopPropagation();
              if (data.type === 'FILE') {
                window.open(data.attachment.location, '_blank');
              } else if (data.type === 'LINK') {
                window.open(data.content, '_blank');
              }
            }}
          >
            {itemName}
          </Text>
          <Text
            fontSize="15"
            lineHeight={'22px'}
            textTransform="capitalize"
            mt="0 !important"
            textAlign={'center'}
          >
            {iconData?.label}
          </Text>
          <VStack w="100%" px="6">
            <FormControl mt="24px !important">
              <FormLabel
                fontSize="13"
                color="secondary.stone"
                mb="1"
                textTransform="capitalize"
              >
                Added by
              </FormLabel>
              <Text fontSize="15" lineHeight={'22px'}>
                {data?.created_by?.first_name
                  ? `${data?.created_by?.first_name}  ${
                      data?.created_by?.last_name
                        ? data?.created_by?.last_name
                        : ''
                    }`
                  : 'Member'}
              </Text>
            </FormControl>
            <FormControl mt="24px !important">
              <FormLabel
                fontSize="13"
                color="secondary.stone"
                mb="1"
                textTransform="capitalize"
              >
                Added on
              </FormLabel>
              <Text fontSize="15" lineHeight={'22px'}>
                {dayjs(data?.created_at).format('DD MMMM YYYY, hh:mm a')}
              </Text>
            </FormControl>
            <FormControl mt="24px !important">
              <FormLabel
                fontSize="13"
                color="secondary.stone"
                mb="1"
                textTransform="capitalize"
              >
                Item Tags
              </FormLabel>
              {data &&
                data.tags &&
                data.tags.length > 0 &&
                data.tags.map((el: any, key: string) => {
                  return (
                    <Tag
                      bg={'primary.soft_peach'}
                      color={'black'}
                      key={key}
                      m="0px !important"
                      mt="8px !important"
                      mb="8px !important"
                      mr="8px !important"
                      px="3"
                      py="2"
                      fontSize={13}
                      fontWeight="600"
                    >
                      {el.label}
                    </Tag>
                  );
                })}
              {data && data.tags && data.tags.length === 0 && (
                <Text
                  fontSize="15"
                  color={isSharingEnabled ? 'primary.presentable' : '#73737d'}
                  lineHeight={'22px'}
                  cursor={isSharingEnabled ? 'pointer' : 'no-drop'}
                  onClick={(e) => {
                    e.stopPropagation();
                    isSharingEnabled &&
                      dispatch(
                        updateEditItemCardClick({
                          data: data,
                          field: 'TAG',
                        })
                      );
                  }}
                >
                  Add tags
                </Text>
              )}
            </FormControl>

            <FormControl mt="24px !important">
              <FormLabel
                fontSize="13"
                color="secondary.stone"
                mb="1"
                textTransform="capitalize"
              >
                Enter a description
              </FormLabel>
              {data && data.description && data.description.length > 0 && (
                <Text fontSize="15" lineHeight={'22px'}>
                  {data.description}
                </Text>
              )}
              {data &&
                (!data.description ||
                  (data.description && data.description.length === 0)) && (
                  <Text
                    fontSize="15"
                    color={isSharingEnabled ? 'primary.presentable' : '#73737d'}
                    lineHeight={'22px'}
                    cursor={isSharingEnabled ? 'pointer' : 'no-drop'}
                    onClick={(e) => {
                      e.stopPropagation();
                      isSharingEnabled &&
                        dispatch(
                          updateEditItemCardClick({
                            data: data,
                            field: 'DESCRIPTION',
                          })
                        );
                    }}
                  >
                    Add description
                  </Text>
                )}
            </FormControl>
            <FormControl mt="24px !important">
              <FormLabel
                fontSize="13"
                color="secondary.stone"
                mb="1"
                textTransform="capitalize"
              >
                Projects
              </FormLabel>
              {projectsIamPartOf?.map((items: any, key: number) => {
                return (
                  <ProjectListCard
                    data={items}
                    urlSearchParams={
                      searchUrlParam ? `ref=${searchUrlParam}` : ''
                    }
                  />
                );
              })}

              {projectsIamPartOf && projectsIamPartOf.length === 0 && (
                <Text
                  color={isSharingEnabled ? 'primary.presentable' : '#73737d'}
                  fontSize="15"
                  lineHeight={'22px'}
                  cursor={isSharingEnabled ? 'pointer' : 'no-drop'}
                  onClick={handleAddToProject}
                >
                  No projects
                </Text>
              )}
            </FormControl>
            <FormControl mt="24px !important">
              <FormLabel
                fontSize="13"
                color="secondary.stone"
                mb="1"
                textTransform="capitalize"
              >
                Sharing permissions
              </FormLabel>
              <Text fontSize="15" lineHeight={'22px'}>
                {data.access &&
                  data.access === 'EVERYONE' &&
                  'Everyone in associated projects and direct messages can edit & share.'}
                {data.access &&
                  data.access === 'OWN' &&
                  'Only owner can edit & share.'}
              </Text>
            </FormControl>
            <FormControl mt="24px !important">
              <FormLabel
                fontSize="13"
                color="secondary.stone"
                mb="1"
                textTransform="capitalize"
              >
                Item Expiration
              </FormLabel>
              <HStack alignItems={'flex-start'} pb="2">
                <InfoIcon height="15px" width="15px" fill="#73737d" />
                <Text fontSize={12} lineHeight="15px" color="#73737d">
                  Item expiration automates the decluttering process by offering
                  to delete redundant items after they’re utilised.
                </Text>
              </HStack>
              {data && data.expire_on && (
                <Text fontSize="15" lineHeight={'22px'}>
                  {dayjs(data.expire_on).format('DD MMMM YYYY, hh:mm a')}
                </Text>
              )}
              {data && !data.expire_on && (
                <Text
                  fontSize="15"
                  lineHeight={'22px'}
                  cursor={isSharingEnabled ? 'pointer' : 'no-drop'}
                  onClick={(e) => {
                    e.stopPropagation();
                    isSharingEnabled &&
                      dispatch(
                        updateEditItemCardClick({ data: data, field: 'EXPIRY' })
                      );
                  }}
                  color={isSharingEnabled ? 'primary.presentable' : '#73737d'}
                >
                  Set an expiration date for this item
                </Text>
              )}
            </FormControl>
          </VStack>
        </VStack>
      )}
      {isLoading && <ItemDetailOverviewSkeleton />}
    </React.Fragment>
  );
};

export const ItemOverviewTab = React.memo(_ItemOverviewTab);
