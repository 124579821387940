import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define a type for the slice state

interface newConnectType {
  newConnectContactFilter: { [key: string]: any };
  selectedNewGroupConnectContact: string[];
  isCreateNewConnectModalVisible: boolean;
  isSelectRequestInterestModalVisible: boolean;
}

// Define the initial state using that type
const initialState: newConnectType = {
  newConnectContactFilter: {},
  selectedNewGroupConnectContact: [],
  isCreateNewConnectModalVisible: false,
  isSelectRequestInterestModalVisible: false,
};

export const newConnectSlice = createSlice({
  name: 'inviteMember',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    resetNewConnectContactFilter: (state) => {
      state.newConnectContactFilter = {};
    },
    updateNewConnectContactFilter: (
      state,
      action: PayloadAction<{ [key: string]: any }>
    ) => {
      state.newConnectContactFilter = { ...action.payload };
    },
    updateSelectedNewGroupConnectContact: (
      state,
      action: PayloadAction<string[]>
    ) => {
      state.selectedNewGroupConnectContact = [...action.payload];
    },

    updateIsCreateNewConnectModalVisible: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isCreateNewConnectModalVisible = action.payload;
    },
    updateOnEditConnectDetailClick: (state) => {
      state.isCreateNewConnectModalVisible = true;
    },
    updateSelectRequestInterestModalVisible: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isSelectRequestInterestModalVisible = action.payload;
    },
  },
});

export const {
  resetNewConnectContactFilter,
  updateNewConnectContactFilter,
  updateSelectedNewGroupConnectContact,
  updateIsCreateNewConnectModalVisible,
  updateOnEditConnectDetailClick,
  updateSelectRequestInterestModalVisible,
} = newConnectSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value;

export default newConnectSlice.reducer;
