import mixpanel, { Dict, Query } from 'mixpanel-browser';
import { mixpanelBaseUrl } from '../config';

mixpanel.init(process.env.REACT_APP_PUBLIC_MIXPANEL_ID || '', {
  api_host: mixpanelBaseUrl,
  debug: process.env.NODE_ENV === 'development' ? true : false,
  ignore_dnt: true,
});

export const Mixpanel = {
  identify: (id: string) => {
    mixpanel.identify(id);
  },
  alias: (id: string) => {
    mixpanel.alias(id);
  },
  track: (name: string, props?: Dict) => {
    mixpanel.track(name, props);
  },
  track_links: (query: Query, name: string) => {
    mixpanel.track_links(query, name, {
      referrer: document.referrer,
    });
  },
  people: {
    set: (props: Dict) => {
      mixpanel.people.set(props);
    },
  },
};
