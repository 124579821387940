import {
  Box,
  Center,
  HStack,
  IconButton,
  Img,
  Spinner,
  useOutsideClick,
  VStack,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { removeChatAtttachmentFileData } from "../../store/project";
import { EmojiChatIcon } from "../../svg/Chat/emoji.icon";
import { SendChatIcon } from "../../svg/Chat/send.icon";
import { ChatAddAttachmentButton } from "./Conversation/ChatAddAttachment";
import { RootState } from "../../store";
import { CircularCloseIcon } from "../../svg/Icons/circularClose.icon";
import { imageFormatType, renderFileIcon } from "../../utils/upload.utils";
import { useChatStore } from "../../pages/ProjectDetail/chat.context";
import { PresentableRichTextEditor } from "../TextEditor";
import { useMemo } from "react";
import {
  resetEditor,
  updateConversationEditorOnEnter,
} from "../../store/editor";
import { ProjectDetailTabEnums } from "../../store/projectDetail";
import { ProjectDetailContext } from "../../pages/ProjectDetail/projectChatData.context";

export enum MessageTypeEnum {
  message = "MESSAGE",
  messageNotification = "MESSAGE_NOTIFICATION",
  messageWithItems = "MESSAGE_WITH_ITEMS",
  messageGeneralUpdateNotification = "MESSAGE_GENERAL_UPDATE_NOTIFICATION",
  messageNameUpdateNotification = "MESSAGE_NAME_UPDATE_NOTIFICATION",
  messageStatusUpdateNotification = "MESSAGE_STATUS_UPDATE_NOTIFICATION",
  messageMemberAddedUpdateNotification = "MESSAGE_MEMBER_ADDED_UPDATE_NOTIFICATION",
  messageMemberRemovedUpdateNotification = "MESSAGE_MEMBER_REMOVED_UPDATE_NOTIFICATION",
}

interface Props {
  placeholder: string;
}

const _ChatFooter = (props: Props) => {
  const { placeholder } = props;
  const emojiRef = useRef<HTMLDivElement>(null);
  const { conversationDetailData } = useContext(ProjectDetailContext);

  const [isTextAreaVisible, setIsTextAreaVisible] = useState<boolean>(true);
  const [isFocussed, setIsFocussed] = useState<boolean>(false);
  const [emojiListVisibility, updateEmojiListVisibility] =
    useState<boolean>(false);

  const dispatch = useAppDispatch();

  const [chatContextStore, updateChatContextStore] = useChatStore();

  const chatAttachmentUploadData = useAppSelector(
    (state: RootState) => state.project.chatAttachmentUploadData
  );

  const isConversationEditorEnterEventFired = useAppSelector(
    (state: RootState) => state.editor.isConversationEditorEnterEventFired
  );

  const editorMessageData = useAppSelector(
    (state: RootState) => state.editor.editorMessageData
  );

  const selectedReceiverId = useAppSelector(
    (state: RootState) => state.projectDirectMessage.selectedReceiverId
  );

  const selectedTab = useAppSelector(
    (state: RootState) => state.projectDetail.selectedProjectDetailTab
  );

  useOutsideClick({
    ref: emojiRef,
    handler: () => {
      updateEmojiListVisibility(false);
    },
  });

  // const sendDirectNewMessage = useCallback(
  //   (message: string, receiver_id: string) => {
  //     const mentionsData = checkMessageForMentions(message);

  //     // If attachment is uploading
  //     if (
  //       chatAttachmentUploadData &&
  //       chatAttachmentUploadData.length > 0 &&
  //       chatAttachmentUploadData.filter((el: any) => !el.location).length > 0
  //     ) {
  //       toast(toastConfig('File upload in progress.'));
  //       return;
  //     }

  //     // If user is not the member of this conversation
  //     // if (!isMemberOfThisConversation) {
  //     //   toast(toastConfig('You are not a member of this conversation.'));
  //     // }
  //     // Normal chat message without attachment but can have sessions
  //     else if (
  //       socket &&
  //       message &&
  //       message.length &&
  //       message !== '<p><br></p>' &&
  //       chatAttachmentUploadData &&
  //       chatAttachmentUploadData.length === 0
  //     ) {
  //       const messagePayload = [] as SaveMessageDataType[];
  //       const urls = detectURLs(message);

  //       // Add text message entry to the array
  //       const msgObj = {
  //         workspace_id: params.workspace_id as string,
  //         sender_id: appDetail?.id,
  //         receiver_id: receiver_id,
  //         type: MessageTypeEnum.message,
  //         message: message,
  //         conversation_id: conversationDetailData?.id,
  //         project_id: params.project_id as string,
  //         created_at: dayjs().format(),
  //       };

  //       messagePayload.push(msgObj);

  //       // Check for url and then Add message items entry to the array
  //       if (urls && urls.length > 0) {
  //         urls.forEach((el: string) => {
  //           const messageItemSubType = itemsLinkType(el);
  //           const messageItemObj = {
  //             sender_id: appDetail?.id,
  //             conversation_id: conversationDetailData?.id,
  //             project_id: params.project_id as string,
  //             message_type: MessageTypeEnum.messageWithItems,
  //             type: messageItemSubType.type,
  //             item: {
  //               name: undefined,
  //               content: el,
  //               description: '',
  //               // attachment : {},
  //               type: 'LINK',
  //               sub_type: messageItemSubType.type,
  //               project_id: params.project_id as string,
  //               workspace_id: params.workspace_id as string,
  //             },
  //           };
  //           const msgObj = {
  //             workspace_id: params.workspace_id as string,
  //             sender_id: appDetail?.id,
  //             receiver_id: receiver_id,
  //             type: MessageTypeEnum.messageWithItems,
  //             message: '',
  //             conversation_id: conversationDetailData?.id,
  //             project_id: params.project_id as string,
  //             created_at: dayjs().format(),
  //             message_items: messageItemObj,
  //           };
  //           messagePayload.push(msgObj);
  //         });
  //       }

  //       socket.emit('project:direct-message', {
  //         source: 'NEW_ADD',
  //         data: messagePayload,
  //       });

  //       // bottomRef?.current?.scrollIntoView();
  //     }

  //     // Normal chat message with attachment but can have sessions
  //     else if (
  //       socket &&
  //       chatAttachmentUploadData &&
  //       chatAttachmentUploadData.length > 0
  //     ) {
  //       const messagePayload = [] as SaveMessageDataType[];
  //       const uploadedAttachments = chatAttachmentUploadData;
  //       const urls = detectURLs(message);

  //       // Add text message if present

  //       if (message && message.length > 0 && message !== '<p><br></p>') {
  //         const msgObj = {
  //           workspace_id: params.workspace_id as string,
  //           sender_id: appDetail?.id,
  //           receiver_id: receiver_id,
  //           type: MessageTypeEnum.message,
  //           message: message,
  //           conversation_id: conversationDetailData?.id,
  //           project_id: params.project_id as string,
  //           created_at: dayjs().format(),
  //         };

  //         messagePayload.push(msgObj);
  //       }

  //       // Add urls if present any

  //       if (urls && urls.length > 0) {
  //         urls.forEach((el: string) => {
  //           const messageItemSubType = itemsLinkType(el);
  //           const messageItemObj = {
  //             sender_id: appDetail?.id,
  //             conversation_id: conversationDetailData?.id,
  //             project_id: params.project_id as string,
  //             message_type: MessageTypeEnum.messageWithItems,
  //             type: messageItemSubType.type,
  //             item: {
  //               name: undefined,
  //               content: el,
  //               description: undefined,
  //               attachment: undefined,
  //               type: 'LINK',
  //               sub_type: messageItemSubType.type,
  //               project_id: params.project_id as string,
  //               workspace_id: params.workspace_id as string,
  //             },
  //           };

  //           const msgObj = {
  //             workspace_id: params.workspace_id as string,
  //             sender_id: appDetail?.id,
  //             receiver_id: receiver_id,
  //             type: MessageTypeEnum.messageWithItems,
  //             message: '',
  //             conversation_id: conversationDetailData?.id,
  //             project_id: params.project_id as string,
  //             created_at: dayjs().format(),
  //             message_items: messageItemObj,
  //           };

  //           messagePayload.push(msgObj);
  //         });
  //       }

  //       // Add attachment file

  //       if (uploadedAttachments && uploadedAttachments.length > 0) {
  //         uploadedAttachments.map((el: any) => {
  //           const { type } = GetFileItemTypeFromMimetype(el.mimetype);
  //           const messageAttachentItem = {
  //             sender_id: appDetail?.id,
  //             conversation_id: conversationDetailData?.id,
  //             project_id: params.project_id as string,
  //             message_type: MessageTypeEnum.messageWithItems,
  //             type: type,
  //             item: {
  //               name: undefined,
  //               content: undefined,
  //               description: undefined,
  //               attachment: el,
  //               type: 'FILE',
  //               sub_type: type,
  //               project_id: params.project_id as string,
  //               workspace_id: params.workspace_id as string,
  //             },
  //           };

  //           const msgObj = {
  //             workspace_id: params.workspace_id as string,
  //             sender_id: appDetail?.id,
  //             receiver_id: receiver_id,
  //             type: MessageTypeEnum.messageWithItems,
  //             message: '',
  //             conversation_id: conversationDetailData?.id,
  //             project_id: params.project_id as string,
  //             created_at: dayjs().format(),
  //             message_items: messageAttachentItem,
  //           };

  //           messagePayload.push(msgObj);
  //         });
  //       }

  //       // ------------------------------------------

  //       socket.emit('project:direct-message', {
  //         source: 'NEW_ADD',
  //         data: messagePayload,
  //       });

  //       batch(() => {
  //         dispatch(resetChatAttachmentData());
  //       });

  //       // bottomRef?.current?.scrollIntoView();
  //     }
  //     // If empty message then throw toast
  //     else if (socket && message && message === '<p><br></p>') {
  //       toast(toastConfig('Enter message'));
  //     } else {
  //       alert('No socket context. Re-connect again');
  //     }
  //   },
  //   [
  //     chatAttachmentUploadData,
  //     socket,
  //     toast,
  //     params.workspace_id,
  //     params.project_id,
  //     appDetail?.id,
  //     conversationDetailData?.id,
  //     dispatch,
  //   ]
  // );

  // const sendNewMessage = useCallback(
  //   (message: string) => {
  //     const mentionsData = checkMessageForMentions(message);

  //     // If attachment is uploading
  //     if (
  //       chatAttachmentUploadData &&
  //       chatAttachmentUploadData.length > 0 &&
  //       chatAttachmentUploadData.filter((el: any) => !el.location).length > 0
  //     ) {
  //       toast(toastConfig('File upload in progress.'));
  //       return;
  //     }

  //     // If user is not the member of this conversation
  //     // if (!isMemberOfThisConversation) {
  //     //   toast(toastConfig('You are not a member of this conversation.'));
  //     // }
  //     // Normal chat message without attachment but can have sessions
  //     else if (
  //       socket &&
  //       message &&
  //       message.length &&
  //       message !== '<p><br></p>' &&
  //       chatAttachmentUploadData &&
  //       chatAttachmentUploadData.length === 0
  //     ) {
  //       const messagePayload = [] as SaveMessageDataType[];
  //       const urls = detectURLs(message);

  //       // Add text message entry to the array
  //       const msgObj = {
  //         workspace_id: params.workspace_id as string,
  //         sender_id: appDetail?.id,
  //         type: MessageTypeEnum.message,
  //         message: message,
  //         conversation_id: conversationDetailData?.id,
  //         project_id: params.project_id as string,
  //         created_at: dayjs().format(),
  //         message_participant_mention:
  //           mentionsData && mentionsData.length > 0 ? mentionsData : undefined,
  //       };

  //       messagePayload.push(msgObj);

  //       // Check for url and then Add message items entry to the array
  //       if (urls && urls.length > 0) {
  //         urls.forEach((el: string) => {
  //           const messageItemSubType = itemsLinkType(el);
  //           const messageItemObj = {
  //             sender_id: appDetail?.id,
  //             conversation_id: conversationDetailData?.id,
  //             project_id: params.project_id as string,
  //             message_type: MessageTypeEnum.messageWithItems,
  //             type: messageItemSubType.type,
  //             item: {
  //               name: undefined,
  //               content: el,
  //               description: '',
  //               // attachment : {},
  //               type: 'LINK',
  //               sub_type: messageItemSubType.type,
  //               project_id: params.project_id as string,
  //               workspace_id: params.workspace_id as string,
  //             },
  //           };
  //           const msgObj = {
  //             workspace_id: params.workspace_id as string,
  //             sender_id: appDetail?.id,
  //             type: MessageTypeEnum.messageWithItems,
  //             message: '',
  //             conversation_id: conversationDetailData?.id,
  //             project_id: params.project_id as string,
  //             created_at: dayjs().format(),
  //             message_items: messageItemObj,
  //           };
  //           messagePayload.push(msgObj);
  //         });
  //       }

  //       socket.emit('project:message', {
  //         source: 'NEW_ADD',
  //         data: messagePayload,
  //       });

  //       // bottomRef?.current?.scrollIntoView();
  //     }

  //     // Normal chat message with attachment but can have sessions
  //     else if (
  //       socket &&
  //       chatAttachmentUploadData &&
  //       chatAttachmentUploadData.length > 0
  //     ) {
  //       const messagePayload = [] as SaveMessageDataType[];
  //       const uploadedAttachments = chatAttachmentUploadData;
  //       const urls = detectURLs(message);

  //       // Add text message if present

  //       if (message && message.length > 0 && message !== '<p><br></p>') {
  //         const msgObj = {
  //           workspace_id: params.workspace_id as string,
  //           sender_id: appDetail?.id,
  //           type: MessageTypeEnum.message,
  //           message: message,
  //           conversation_id: conversationDetailData?.id,
  //           project_id: params.project_id as string,
  //           created_at: dayjs().format(),
  //           message_participant_mention:
  //             mentionsData && mentionsData.length > 0
  //               ? mentionsData
  //               : undefined,
  //         };

  //         messagePayload.push(msgObj);
  //       }

  //       // Add urls if present any

  //       if (urls && urls.length > 0) {
  //         urls.forEach((el: string) => {
  //           const messageItemSubType = itemsLinkType(el);
  //           const messageItemObj = {
  //             sender_id: appDetail?.id,
  //             conversation_id: conversationDetailData?.id,
  //             project_id: params.project_id as string,
  //             message_type: MessageTypeEnum.messageWithItems,
  //             type: messageItemSubType.type,
  //             item: {
  //               name: undefined,
  //               content: el,
  //               description: undefined,
  //               attachment: undefined,
  //               type: 'LINK',
  //               sub_type: messageItemSubType.type,
  //               project_id: params.project_id as string,
  //               workspace_id: params.workspace_id as string,
  //             },
  //           };

  //           const msgObj = {
  //             workspace_id: params.workspace_id as string,
  //             sender_id: appDetail?.id,
  //             type: MessageTypeEnum.messageWithItems,
  //             message: '',
  //             conversation_id: conversationDetailData?.id,
  //             project_id: params.project_id as string,
  //             created_at: dayjs().format(),
  //             message_items: messageItemObj,
  //           };

  //           messagePayload.push(msgObj);
  //         });
  //       }

  //       // Add attachment file

  //       if (uploadedAttachments && uploadedAttachments.length > 0) {
  //         uploadedAttachments.map((el: any) => {
  //           const { type } = GetFileItemTypeFromMimetype(el.mimetype);
  //           const messageAttachentItem = {
  //             sender_id: appDetail?.id,
  //             conversation_id: conversationDetailData?.id,
  //             project_id: params.project_id as string,
  //             message_type: MessageTypeEnum.messageWithItems,
  //             type: type,
  //             item: {
  //               name: undefined,
  //               content: undefined,
  //               description: undefined,
  //               attachment: el,
  //               type: 'FILE',
  //               sub_type: type,
  //               project_id: params.project_id as string,
  //               workspace_id: params.workspace_id as string,
  //             },
  //           };

  //           const msgObj = {
  //             workspace_id: params.workspace_id as string,
  //             sender_id: appDetail?.id,
  //             type: MessageTypeEnum.messageWithItems,
  //             message: '',
  //             conversation_id: conversationDetailData?.id,
  //             project_id: params.project_id as string,
  //             created_at: dayjs().format(),
  //             message_items: messageAttachentItem,
  //           };

  //           messagePayload.push(msgObj);
  //         });
  //       }

  //       // ------------------------------------------

  //       socket.emit('project:message', {
  //         source: 'NEW_ADD',
  //         data: messagePayload,
  //       });

  //       batch(() => {
  //         dispatch(resetChatAttachmentData());
  //       });

  //       // bottomRef?.current?.scrollIntoView();
  //     }
  //     // If empty message then throw toast
  //     else if (socket && message && message === '<p><br></p>') {
  //       toast(toastConfig('Enter message'));
  //     } else {
  //       alert('No socket context. Re-connect again');
  //     }
  //   },
  //   [
  //     chatAttachmentUploadData,
  //     socket,
  //     toast,
  //     appDetail?.id,
  //     conversationDetailData?.id,
  //     params.project_id,
  //     params.workspace_id,
  //     dispatch,
  //   ]
  // );

  useEffect(() => {
    if (isConversationEditorEnterEventFired) {
      if (selectedTab === ProjectDetailTabEnums.dm) {
        // sendDirectNewMessage(
        //   editorMessageData as string,
        //   selectedReceiverId as string
        // );
      } else {
        // sendNewMessage(editorMessageData as string);
      }
      dispatch(resetEditor());
    }
  }, [
    dispatch,
    isConversationEditorEnterEventFired,
    editorMessageData,
    selectedTab,
    selectedReceiverId,
  ]);

  const mentionsData = useMemo(() => {
    const participantData =
      conversationDetailData &&
      conversationDetailData &&
      conversationDetailData.participants
        ? conversationDetailData.participants
        : [];

    const mentionData = participantData.map((el: any) => {
      return {
        id: el.id,
        value:
          el.user_id?.first_name +
          " " +
          (el.user_id?.last_name ? el.user_id?.last_name : ""),
      };
    });
    return mentionData;
  }, [conversationDetailData]);

  useEffect(() => {
    if (isTextAreaVisible && !emojiListVisibility) {
      // editor.current.focus();
      setIsFocussed(true);
    }
  }, [emojiListVisibility, isTextAreaVisible]);

  useEffect(() => {
    updateChatContextStore({
      ...chatContextStore,
      isTextAreaVisible: isTextAreaVisible,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTextAreaVisible]);

  // useEffect(() => {
  //   if (selectedReceiverId && selectedTab === ProjectDetailTabEnums.dm) {
  //     setIsTextAreaVisible(false);
  //     setIsFocussed(false);
  //   }
  // }, [quillEditorState, selectedReceiverId, selectedTab]);

  return (
    <React.Fragment>
      <VStack
        w="100%"
        border="2px solid"
        borderColor={isFocussed ? "primary.presentable" : "border.gamma"}
        borderRadius={6}
        mt="0 !important"
      >
        <Box
          display={isTextAreaVisible ? "block" : "none"}
          w="100%"
          className="chat-footer-editor"
          onBlur={() => {
            setIsFocussed(false);
          }}
        >
          <PresentableRichTextEditor
            onFocus={() => {
              setIsFocussed(true);
            }}
            mentionsData={mentionsData}
            handleEnter={() => {
              dispatch(updateConversationEditorOnEnter(""));
            }}
            // placeholder={placeholder}
          />
        </Box>

        <HStack
          onClick={(e) => {
            e.preventDefault();
          }}
          w="100%"
          justifyContent={"space-between"}
          mt="0 !important"
          borderBottomRadius={6}
          bg={isFocussed ? "primary.soft_peach" : "secondary.pearl"}
          whiteSpace="nowrap"
          overflow={"hidden"}
          textOverflow="ellipsis"
        >
          <Box
            onClick={(e) => {
              e.preventDefault();
              setIsTextAreaVisible(true);
            }}
            w="calc(100% - 100px)"
            h="100%"
            borderRadius={6}
            pl="4"
            fontSize={15}
          >
            <HStack>
              {chatAttachmentUploadData &&
                chatAttachmentUploadData.length > 0 &&
                chatAttachmentUploadData.map((el: any, key: number) => {
                  const isRaw = el.type === "RAW";
                  /* Check the file format and file size :
                      Only image file of size < 5MB will be converted to data/base64.
                      For the rest, we will show the icon
                    */
                  const isReadableFile = el.location
                    ? imageFormatType.includes(el.mimetype) &&
                      el.mimetype !== "image/svg+xml"
                    : imageFormatType.includes(el.mimetype) &&
                      el.mimetype !== "image/svg+xml" &&
                      el.size < 1024 * 1024 * 5;
                  return (
                    <Center
                      pos={"relative"}
                      h="8"
                      w="8"
                      borderRadius={4}
                      mr="1"
                      cursor={"pointer"}
                      className="p-chat-image-uploader-container"
                      border="2px solid"
                      borderColor={"secondary.powder"}
                    >
                      <CircularCloseIcon
                        height="3"
                        width="3"
                        pos="absolute"
                        zIndex="2"
                        cursor="pointer"
                        _hover={{
                          fill: "primary.presentable",
                          bg: "black",
                          borderRadius: "8",
                        }}
                        onClick={() => {
                          dispatch(removeChatAtttachmentFileData(key));
                        }}
                        display="none"
                      />
                      {isReadableFile && (
                        <Img
                          borderRadius={4}
                          opacity={isRaw ? 0.6 : 1}
                          src={isRaw ? el.imgUrl : el.location}
                          h="100%"
                          w="100%"
                        />
                      )}
                      {!isReadableFile && renderFileIcon(el.mimetype)}
                      {isRaw && (
                        <Spinner
                          color="primary.presentable"
                          size="xs"
                          pos={"absolute"}
                        />
                      )}
                    </Center>
                  );
                })}
            </HStack>
            {!isTextAreaVisible && `${placeholder}`}
          </Box>
          <HStack
            py="2"
            onClick={(e: any) => {
              e.stopPropagation();
              setIsTextAreaVisible(true);
            }}
            pr="2"
          >
            <ChatAddAttachmentButton isFocussed={isFocussed} />
            <IconButton
              className="p-chat-footer-control"
              variant="p_primary"
              icon={
                <EmojiChatIcon fill="secondary.lead" height="4" width="4" />
              }
              h="1.75rem"
              size="sm"
              aria-label="Emoji"
              border={"none"}
              ml="0 !important"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setIsTextAreaVisible(true);
                updateEmojiListVisibility(!emojiListVisibility);
              }}
              bg={isFocussed ? "primary.soft_peach" : "secondary.pearl"}
            />
            {emojiListVisibility && (
              <Box
                zIndex="1"
                position="absolute"
                h="auto"
                w="auto"
                bottom="10"
                right="0"
                ref={emojiRef}
              >
                <Picker
                  autoFocus
                  showPreview={false}
                  onEmojiSelect={({ native }: any) => {}}
                  data={data}
                  theme="light"
                />
              </Box>
            )}
            <IconButton
              variant="p_secondary"
              className="p-chat-footer-control"
              icon={<SendChatIcon fill="secondary.lead" height="4" width="4" />}
              h="1.75rem"
              size="sm"
              aria-label="Send"
              border={"none"}
              onClick={() => {}}
              ml="0 !important"
              bg={isFocussed ? "primary.soft_peach" : "secondary.pearl"}
            />
          </HStack>
        </HStack>
      </VStack>
    </React.Fragment>
  );
};

export const ChatFooter = React.memo(React.forwardRef(_ChatFooter));
