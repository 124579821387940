import { ProjectModellingIcon } from '../svg/Project/ProjectType/3dModelling.icon';
import { ProjectAnimationIcon } from '../svg/Project/ProjectType/animation.icon';
import { ProjectArchitectureIcon } from '../svg/Project/ProjectType/architecture.icon';
import { ProjectBrandIcon } from '../svg/Project/ProjectType/brand.icon';
import { ProjectContentIcon } from '../svg/Project/ProjectType/content.icon';
import { ProjectCreativeIcon } from '../svg/Project/ProjectType/creative.icon';
import { ProjectIllustrationIcon } from '../svg/Project/ProjectType/illustration.icon';
import { ProjectMarketingIcon } from '../svg/Project/ProjectType/marketing.icon';
import { ProjectMotionDesignIcon } from '../svg/Project/ProjectType/motionDesign.icon';
import { ProjectOtherIcon } from '../svg/Project/ProjectType/other.icon';
import { ProjectPackagingIcon } from '../svg/Project/ProjectType/packaging.icon';
import { ProjectPrintIcon } from '../svg/Project/ProjectType/print.icon';
import { ProjectProductIcon } from '../svg/Project/ProjectType/product.icon';
import { ProjectUIUXIcon } from '../svg/Project/ProjectType/uiux.icon';

export const getProjectTypeOptionsData = (
  type: string,
  iconHeight?: string,
  iconWidth?: string
) => {
  switch (type) {
    case '0': {
      return (
        <ProjectCreativeIcon
          height={iconHeight ? iconHeight : undefined}
          width={iconWidth ? iconWidth : undefined}
        />
      );
    }
    case '1': {
      return (
        <ProjectContentIcon
          height={iconHeight ? iconHeight : undefined}
          width={iconWidth ? iconWidth : undefined}
        />
      );
    }
    case '2': {
      return (
        <ProjectProductIcon
          height={iconHeight ? iconHeight : undefined}
          width={iconWidth ? iconWidth : undefined}
        />
      );
    }
    case '3': {
      return (
        <ProjectUIUXIcon
          height={iconHeight ? iconHeight : undefined}
          width={iconWidth ? iconWidth : undefined}
        />
      );
    }
    case '4': {
      return (
        <ProjectBrandIcon
          height={iconHeight ? iconHeight : undefined}
          width={iconWidth ? iconWidth : undefined}
        />
      );
    }
    case '5': {
      return (
        <ProjectPackagingIcon
          height={iconHeight ? iconHeight : undefined}
          width={iconWidth ? iconWidth : undefined}
        />
      );
    }
    case '6': {
      return (
        <ProjectPrintIcon
          height={iconHeight ? iconHeight : undefined}
          width={iconWidth ? iconWidth : undefined}
        />
      );
    }
    case '7': {
      return (
        <ProjectIllustrationIcon
          height={iconHeight ? iconHeight : undefined}
          width={iconWidth ? iconWidth : undefined}
        />
      );
    }
    case '8': {
      return (
        <ProjectAnimationIcon
          height={iconHeight ? iconHeight : undefined}
          width={iconWidth ? iconWidth : undefined}
        />
      );
    }
    case '9': {
      return (
        <ProjectMotionDesignIcon
          height={iconHeight ? iconHeight : undefined}
          width={iconWidth ? iconWidth : undefined}
        />
      );
    }
    case '10': {
      return (
        <ProjectModellingIcon
          height={iconHeight ? iconHeight : undefined}
          width={iconWidth ? iconWidth : undefined}
        />
      );
    }
    case '11': {
      return (
        <ProjectArchitectureIcon
          height={iconHeight ? iconHeight : undefined}
          width={iconWidth ? iconWidth : undefined}
        />
      );
    }
    case '12': {
      return (
        <ProjectMarketingIcon
          height={iconHeight ? iconHeight : undefined}
          width={iconWidth ? iconWidth : undefined}
        />
      );
    }
    case '13': {
      return (
        <ProjectOtherIcon
          height={iconHeight ? iconHeight : undefined}
          width={iconWidth ? iconWidth : undefined}
        />
      );
    }
  }
};
