import Icon, { IconProps } from '@chakra-ui/icon';
import { OmitCommonProps, As } from '@chakra-ui/react';
import React from 'react';

const _IndesignFormatIcon = (
  props: JSX.IntrinsicAttributes &
    OmitCommonProps<React.SVGProps<SVGSVGElement>, keyof IconProps> &
    IconProps &
    OmitCommonProps<any, keyof IconProps> & { as?: As<any> | undefined }
) => (
  <Icon viewBox="0 0 36 35" width={36} height={35} focusable="false" {...props}>
    <g fillRule="nonzero" fill="none">
      <path
        d="M6.357 0H29.54a6.352 6.352 0 0 1 6.356 6.357v22.286A6.352 6.352 0 0 1 29.541 35H6.357A6.352 6.352 0 0 1 0 28.643V6.357A6.352 6.352 0 0 1 6.357 0Z"
        fill="#49021F"
      />
      <g fill="#F36">
        <path d="M13.043 9.154V24.56c0 .164-.075.24-.21.24H9.902c-.135 0-.195-.076-.195-.24V9.154c0-.135.075-.195.21-.195h2.916c.09-.015.18.045.195.15.015.015.015.03.015.045ZM21.688 24.979a7.436 7.436 0 0 1-3.216-.673 5.215 5.215 0 0 1-2.258-2.035c-.554-.912-.823-2.049-.823-3.41a6.143 6.143 0 0 1 .823-3.156 6.211 6.211 0 0 1 2.378-2.318c1.047-.584 2.303-.868 3.784-.868.075 0 .18 0 .314.015.135.015.284.015.464.03V7.838c0-.105.045-.165.15-.165h3.036c.075-.015.134.045.15.105v14.269c0 .27.014.568.03.897.03.315.044.614.06.868 0 .105-.046.194-.15.24-.778.328-1.6.568-2.439.717-.747.135-1.525.21-2.303.21Zm1.466-2.992v-6.581c-.135-.03-.27-.06-.404-.075-.165-.015-.33-.03-.494-.03-.583 0-1.166.12-1.69.39a3.353 3.353 0 0 0-1.271 1.106c-.33.479-.494 1.122-.494 1.9a4.168 4.168 0 0 0 .255 1.54c.15.404.373.763.673 1.062.284.27.628.479 1.017.598.404.135.822.195 1.241.195.225 0 .434-.015.628-.03.195.015.374-.015.539-.075Z" />
      </g>
    </g>
  </Icon>
);

export const IndesignFormatIcon = React.memo(_IndesignFormatIcon);
