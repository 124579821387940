import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define a type for the slice state

interface UtilsStateType {
  libraryResizeItemsCount: number;
  favouriteResizeItemsCount: number;
  projectItemsResizeItemsCount: number;
  selectItemsModalResizeCount: number;
}

// Define the initial state using that type
const initialState: UtilsStateType = {
  libraryResizeItemsCount: 5,
  favouriteResizeItemsCount: 5,
  projectItemsResizeItemsCount: 5,
  selectItemsModalResizeCount: 5,
};

export const utilsSlice = createSlice({
  name: 'utils',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    updateLibraryResizeItemsCount: (state, action: PayloadAction<number>) => {
      state.libraryResizeItemsCount = action.payload;
    },
    updateFavouriteResizeItemsCount: (state, action: PayloadAction<number>) => {
      state.favouriteResizeItemsCount = action.payload;
    },
    updateProjectItemsResizeItemsCount: (
      state,
      action: PayloadAction<number>
    ) => {
      state.projectItemsResizeItemsCount = action.payload;
    },
    updateselectItemsModalResizeCount: (
      state,
      action: PayloadAction<number>
    ) => {
      state.selectItemsModalResizeCount = action.payload;
    },
  },
});

export const {
  updateLibraryResizeItemsCount,
  updateFavouriteResizeItemsCount,
  updateProjectItemsResizeItemsCount,
  updateselectItemsModalResizeCount,
} = utilsSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value;

export default utilsSlice.reducer;
