import Icon, { IconProps } from '@chakra-ui/icon';
import { OmitCommonProps, As } from '@chakra-ui/react';
import React from 'react';

const _FigmaIcon = (
  props: JSX.IntrinsicAttributes &
    OmitCommonProps<React.SVGProps<SVGSVGElement>, keyof IconProps> &
    IconProps &
    OmitCommonProps<any, keyof IconProps> & { as?: As<any> | undefined }
) => (
  <Icon width={18} height={26} viewBox="0 0 18 26" focusable="false" {...props}>
    <g fillRule="nonzero" fill="none">
      <path
        d="M4.332 25.995a4.334 4.334 0 0 0 4.333-4.333V17.33H4.332A4.334 4.334 0 0 0 0 21.662a4.334 4.334 0 0 0 4.332 4.333Z"
        fill="#0ACF83"
      />
      <path
        d="M0 12.997a4.334 4.334 0 0 1 4.332-4.332h4.333v8.665H4.332A4.334 4.334 0 0 1 0 12.997Z"
        fill="#A259FF"
      />
      <path
        d="M0 4.332A4.334 4.334 0 0 1 4.332 0h4.333v8.665H4.332A4.334 4.334 0 0 1 0 4.332Z"
        fill="#F24E1E"
      />
      <path
        d="M8.665 0h4.332a4.334 4.334 0 0 1 4.333 4.332 4.334 4.334 0 0 1-4.333 4.333H8.665V0Z"
        fill="#FF7262"
      />
      <path
        d="M17.33 12.997a4.334 4.334 0 0 1-4.333 4.333 4.334 4.334 0 0 1-4.332-4.333 4.334 4.334 0 0 1 4.332-4.332 4.334 4.334 0 0 1 4.333 4.332Z"
        fill="#1ABCFE"
      />
    </g>
  </Icon>
);

export const FigmaIcon = React.memo(_FigmaIcon);
