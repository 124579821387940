import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define a type for the slice state

interface FeedbackStateType {
  isSharedFeedbackModalVisible: boolean;
}

// Define the initial state using that type
const initialState: FeedbackStateType = {
  isSharedFeedbackModalVisible: false,
};

export const feedbackSlice = createSlice({
  name: 'feedback',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    updateIsSharedFeedbackModalVisible: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isSharedFeedbackModalVisible = action.payload;
    },
  },
});

export const { updateIsSharedFeedbackModalVisible } = feedbackSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value;

export default feedbackSlice.reducer;
