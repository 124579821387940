import {
  chakra,
  VStack,
  Box,
  HStack,
  Text,
  Button,
  Center,
  Tooltip,
  IconButton,
} from '@chakra-ui/react';
import { useCallback, useContext, useMemo, useRef, useState } from 'react';
import parser from 'html-react-parser';

import { batch } from 'react-redux';
import { getItemsTypeOptionsData } from '../../../../utils/items';
import saveAs from 'file-saver';
import { AppDetailContext } from '../../../../Context/AppDetailContext';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  updateAddWorkflowSelectedProject,
  updateAddWorkflowMessageItemId,
  updateAddWorkflowMessageId,
  updateAddWorkflowModalVisibility,
  updateRightComponentMode,
  updateRightComponentVisibility,
  updateSelectedRightComponentId,
  resetProjectDetailRightComponent,
  updateSelectedProjectDetailTab,
  ProjectDetailTabEnums,
  updateProjectDetailsItemsFilter,
} from '../../../../store/projectDetail';
import { DownloadChatIcon } from '../../../../svg/Chat/download.icon';
import { RedirectIcon } from '../../../../svg/Chat/redirect.icon';
import { ReviewNoteChatIcon } from '../../../../svg/Chat/reviewNote.icon';
import { PresentableAvatar } from '../../../Avatar';
import { ItemsTypeEnum } from '../../helper';
import { RightComponentModeEnum } from '../../../NewProject/RightSideComponents/RightMainComponent';
import {
  updateItemCardonClose,
  updateLibraryRightComponentMode,
} from '../../../../store/library';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';
import { Document, Page, pdfjs } from 'react-pdf';
import { VideoPreviewComponent } from '../../LeftSideComponent/ItemCard/VideoPreviewComponent';
import { ImageBlurLoader } from '../../LeftSideComponent/ItemCard/ImageBlurLoader';
import { RootState } from '../../../../store';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface Props {
  data: { [key: string]: any };
  isOnline: boolean;
  participantMap: { [key: string]: any };
  projectData: { [key: string]: any };
  searchUrlParam: string;
}

export const ItemsActivityThreadComponent = (props: Props) => {
  const { isOnline, data, participantMap, projectData, searchUrlParam } = props;
  const [isPDFLoaded, updateIsPDFLoaded] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null);
  const itemRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { appDetail } = useContext(AppDetailContext);

  const projectDetailsItemsFilter = useAppSelector(
    (state: RootState) => state.projectDetail.projectDetailsItemsFilter
  );

  const selectedLibraryTab = useAppSelector(
    (state: RootState) => state.library.selectedTab
  );

  const params = useParams<{
    project_id: string;
    workspace_id: string;
  }>();

  const handleAddWorkflow = (session_id: string) => {
    batch(() => {
      dispatch(updateAddWorkflowSelectedProject(projectData));
      dispatch(updateAddWorkflowMessageItemId(session_id));
      dispatch(updateAddWorkflowMessageId(data?.id));
      dispatch(updateAddWorkflowModalVisibility(true));
    });
  };

  const senderData = useMemo(() => {
    return data && data.sender_id ? participantMap[data.sender_id] : {};
  }, [data, participantMap]);

  const isSelf = useMemo(() => {
    return data?.sender_id === appDetail?.id;
  }, [appDetail?.id, data?.sender_id]);

  const iconData = useMemo(() => {
    if (data.message_items) {
      const size =
        data.message_items?.item?.sub_type === ItemsTypeEnum.videoFile
          ? '44px'
          : '20px';

      return getItemsTypeOptionsData(
        data.message_items?.item?.sub_type,
        size,
        size
      );
    } else {
      return undefined;
    }
  }, [data.message_items]);

  const itemName = useMemo(() => {
    if (data.message_items?.item?.name) {
      return data.message_items?.item?.name;
    } else if (data.message_items?.item?.type === 'FILE') {
      return data.message_items?.item?.attachment.originalname;
    } else if (data.message_items?.item?.type === 'LINK') {
      const hasProtocol = data.message_items?.item?.content.includes('http');
      const { hostname } = new URL(
        hasProtocol
          ? data.message_items?.item?.content
          : `https://${data.message_items?.item?.content}`
      );
      return hostname;
    } else {
      return 'Item';
    }
  }, [data]);

  const handleProfileClick = () => {
    batch(() => {
      dispatch(updateRightComponentMode(RightComponentModeEnum.profile));
      dispatch(updateLibraryRightComponentMode('PROFILE'));
      dispatch(updateRightComponentVisibility(true));
      dispatch(updateSelectedRightComponentId(senderData.id));
    });
  };

  const getUniquePointerCount = useCallback(
    (reviews: any[], itemType: ItemsTypeEnum) => {
      const map = {} as any;
      reviews &&
        reviews.forEach((el: any) => {
          if (
            itemType === ItemsTypeEnum.videoFile &&
            el?.videoReviewPointer?.id &&
            !map[el.videoReviewPointer.id]
          ) {
            map[el.videoReviewPointer.id] = 1;
          }

          if (
            itemType !== ItemsTypeEnum.videoFile &&
            el?.reviewPointer?.id &&
            !map[el.reviewPointer.id]
          ) {
            map[el.reviewPointer.id] = 1;
          }
        });
      return Object.keys(map).length;
    },
    []
  );

  return (
    <Box py="4" ref={ref} display="block" w="100%">
      <HStack alignItems="flex-start" w="100%">
        <PresentableAvatar
          size="8"
          borderRadius="4"
          name={senderData?.name}
          profileImg={senderData?.profile_img}
          profileColor={senderData?.profile_color}
          isOnline={isOnline}
        />
        <VStack
          w="calc(100% - 40px)"
          alignItems={'flex-start'}
          onClick={(e: any) => {
            e.stopPropagation();
            if (
              data?.message_items?.item?.type === 'FILE' &&
              data?.message_items?.item?.sub_type !== ItemsTypeEnum.imageFile &&
              (data?.message_items?.item?.sub_type !== ItemsTypeEnum.pdfFile ||
                !isPDFLoaded) &&
              data?.message_items?.item?.sub_type !== ItemsTypeEnum.videoFile
            ) {
              window.open(
                data?.message_items?.item.attachment.location,
                '_blank'
              );
            } else if (data?.message_items?.item?.type === 'LINK') {
              window.open(data?.message_items?.item.content, '_blank');
            } else if (
              data?.message_items?.item?.type === 'FILE' &&
              (data?.message_items?.item?.sub_type ===
                ItemsTypeEnum.imageFile ||
                data?.message_items?.item?.sub_type === ItemsTypeEnum.pdfFile)
            ) {
              navigate(
                `/workspace/${params.workspace_id}/project/${projectData?.id}/messageitem/${data.message_items?.id}/review${searchUrlParam}`
              );
            } else if (
              data?.message_items?.item?.type === 'FILE' &&
              data?.message_items?.item?.sub_type === ItemsTypeEnum.videoFile
            ) {
              navigate(
                `/workspace/${params.workspace_id}/project/${projectData?.id}/messageitem/${data.message_items?.id}/videoreview${searchUrlParam}`
              );
            }
          }}
          cursor="pointer"
        >
          {data.message_items?.item?.sub_type === ItemsTypeEnum.videoFile &&
            (!data.message_items?.item.videoItemMetadata ||
              (data.message_items?.item.videoItemMetadata &&
                !data.message_items?.item.videoItemMetadata?.sprite)) && (
              <Center
                height={'200px'}
                overflow={'hidden'}
                w="100%"
                bg={iconData?.bg}
                display="flex"
                alignItems={'center'}
                flexDir="column"
                justifyContent={'center'}
                borderTopRadius={8}
              >
                {iconData?.icon}
                <Text pt="12px" fontSize={'12'}>
                  Generating preview ...
                </Text>
              </Center>
            )}
          {data.message_items?.item?.sub_type === ItemsTypeEnum.videoFile &&
            data.message_items?.item.videoItemMetadata &&
            data.message_items?.item.videoItemMetadata?.sprite && (
              <Box height={'200px'} width="100%" bg="white" ref={itemRef}>
                <VideoPreviewComponent
                  url={data.message_items?.item.videoItemMetadata?.sprite}
                  containerWidth={itemRef?.current?.clientWidth as number}
                  yPosition={-62}
                  ref={itemRef}
                />
              </Box>
            )}
          {data.message_items?.item?.sub_type === ItemsTypeEnum.pdfFile && (
            <Center overflow={'hidden'} w="100%" bg="white">
              <Document
                file={data.message_items?.item?.attachment.location}
                onLoadSuccess={(val: any) => {
                  updateIsPDFLoaded(true);
                }}
                loading=""
              >
                <Page height={200} pageNumber={1} />
              </Document>
            </Center>
          )}
          {data && data.message && data.message.length > 0 && (
            <Box
              bg={isSelf ? '#f2e1d3' : 'secondary.powder'}
              fontSize="15"
              py="3"
              px="4"
              borderRightRadius="20"
              borderBottomLeftRadius="20"
              maxW="100%"
              className="p-chat-message"
            >
              {parser(data.message)}
            </Box>
          )}
          {data &&
            data.message_items &&
            Object.keys(data.message_items).length > 0 && (
              <VStack
                borderRightRadius="20"
                borderBottomLeftRadius="20"
                justifyContent="space-between"
                border="1px solid"
                borderColor={'#e7e9ea'}
                alignItems="flex-start"
                overflow={'hidden'}
                mb={'0px !important'}
                mt="0px !important"
                bg="white"
                width={'100%'}
                cursor="pointer"
              >
                {data.message_items?.item?.sub_type ===
                  ItemsTypeEnum.imageFile && (
                  <Box
                    height={'200px'}
                    width="100%"
                    onClick={(e) => {
                      e.preventDefault();
                      if (
                        data.message_items?.item?.sub_type ===
                        ItemsTypeEnum.imageFile
                      ) {
                        navigate(
                          `/workspace/${params.workspace_id}/project/${projectData?.id}/messageitem/${data.message_items?.id}/review${searchUrlParam}`
                        );
                      }
                    }}
                  >
                    <ImageBlurLoader
                      sourceUrl={data.message_items?.item?.attachment?.location}
                    />
                  </Box>
                )}
                {data.message_items?.item?.type === 'LINK' &&
                  data.message_items?.item.meta_image && (
                    <Box
                      height={'200px'}
                      width="100%"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(data.message_items?.item.content, '_blank');
                      }}
                      bg="white"
                    >
                      <ImageBlurLoader
                        sourceUrl={data.message_items?.item?.meta_image}
                      />
                    </Box>
                  )}
                <HStack
                  p="3"
                  w="100%"
                  alignItems={'flex-start'}
                  justifyContent="space-between"
                >
                  <HStack w="calc(100% - 80px)">
                    {data.message_items?.item?.sub_type !==
                      ItemsTypeEnum.imageFile &&
                      !(
                        data.message_items?.item?.type === 'LINK' &&
                        data.message_items?.item.meta_image
                      ) &&
                      (!isPDFLoaded ||
                        data.message_items?.item?.sub_type !==
                          ItemsTypeEnum.pdfFile) &&
                      data.message_items?.item?.sub_type !==
                        ItemsTypeEnum.videoFile && (
                        <Center
                          borderRadius={6}
                          bg={iconData?.bg}
                          h="14"
                          w="14"
                        >
                          {iconData?.icon}
                        </Center>
                      )}
                    <VStack
                      w={
                        data.message_items?.item?.sub_type !==
                        ItemsTypeEnum.imageFile
                          ? 'calc(100% - 70px)'
                          : '100%'
                      }
                    >
                      <HStack w="100%">
                        <Text
                          w={'70%'}
                          fontSize="15"
                          color="secondary.black"
                          fontWeight="600"
                          textAlign="left"
                          whiteSpace="nowrap"
                          overflow="hidden"
                          textOverflow="ellipsis"
                          mt="0 !important"
                          _hover={{
                            textDecoration: 'underline',
                          }}
                        >
                          {itemName}
                        </Text>
                      </HStack>
                      <Text
                        w="100%"
                        fontSize="15"
                        color="secondary.black"
                        textAlign="left"
                        lineHeight={'22px'}
                        mt="0 !important"
                      >
                        {iconData?.label}
                      </Text>
                    </VStack>
                  </HStack>
                  <HStack>
                    {data.message_items?.item?.type === 'FILE' &&
                      data.message_items?.item?.sub_type ===
                        ItemsTypeEnum.imageFile && (
                        <Tooltip
                          hasArrow
                          label="Download File"
                          fontSize="13"
                          bg="secondary.black"
                          px="3"
                          py="2"
                        >
                          <IconButton
                            variant="p_project_action"
                            size="sm"
                            aria-label="zoom-in"
                            icon={<DownloadChatIcon height="4" width="4" />}
                            onClick={(e) => {
                              e.stopPropagation();
                              saveAs(
                                data.message_items?.item?.attachment?.location,
                                data.message_items?.item?.attachment
                                  ?.originalname
                              );
                            }}
                            bg="secondary.white"
                            className="p-header-icon"
                            _hover={{
                              bg: 'primary.soft_peach',
                              borderColor: 'primary.presentable',
                              '.p-svg600': {
                                display: 'block !important',
                              },
                              '.p-svg': {
                                display: 'none',
                              },
                            }}
                          />
                        </Tooltip>
                      )}
                    {data.message_items?.item?.type === 'FILE' &&
                      data.message_items?.item?.sub_type !==
                        ItemsTypeEnum.imageFile && (
                        <Tooltip
                          hasArrow
                          label="Download File"
                          fontSize="13"
                          bg="secondary.black"
                          px="3"
                          py="2"
                        >
                          <IconButton
                            variant="p_project_action"
                            size="sm"
                            aria-label="zoom-in"
                            icon={<DownloadChatIcon height="4" width="4" />}
                            onClick={(e) => {
                              e.preventDefault();
                              saveAs(
                                data.message_items?.item?.attachment?.location,
                                data.message_items?.item?.attachment
                                  ?.originalname
                              );
                            }}
                            bg="secondary.white"
                            className="p-header-icon"
                            _hover={{
                              bg: 'primary.soft_peach',
                              borderColor: 'primary.presentable',
                              '.p-svg600': {
                                display: 'block !important',
                              },
                              '.p-svg': {
                                display: 'none',
                              },
                            }}
                          />
                        </Tooltip>
                      )}
                    {data.message_items?.item?.type === 'LINK' && (
                      <Tooltip
                        hasArrow
                        label="View"
                        fontSize="13"
                        bg="secondary.black"
                        px="3"
                        py="2"
                      >
                        <IconButton
                          variant="p_project_action"
                          size="sm"
                          aria-label="zoom-in"
                          icon={<RedirectIcon height="4" width="4" />}
                          onClick={(e) => {
                            e.preventDefault();
                            window.open(data.message_items?.content, '_blank');
                          }}
                          bg="secondary.white"
                          className="p-header-icon"
                          _hover={{
                            bg: 'primary.soft_peach',
                            borderColor: 'primary.presentable',
                            '.p-svg600': {
                              display: 'block !important',
                            },
                            '.p-svg': {
                              display: 'none',
                            },
                          }}
                        />
                      </Tooltip>
                    )}
                  </HStack>
                </HStack>
                <HStack justifyContent={'space-between'} px="3" pb="4" w="100%">
                  {data.message_items.workflow &&
                    data.message_items.workflow.length === 0 && (
                      <Button
                        variant={'p_black'}
                        size={'sm'}
                        fontSize={13}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleAddWorkflow(data.message_items.id);
                        }}
                        _hover={{
                          boxShadow:
                            '0 0 0 4px rgb(0 0 0 / 20%), 0 0 0 2px #ffffff',
                        }}
                      >
                        + Add to a workflow
                      </Button>
                    )}
                  {data.message_items.workflow &&
                    data.message_items.workflow.length > 0 && (
                      <Text
                        fontSize={13}
                        bg="primary.presentable"
                        py="2"
                        px="3"
                        borderRadius={6}
                        fontWeight="600"
                        color="white"
                        _hover={{
                          boxShadow:
                            '0 0 0 4px rgb(0 0 0 / 20%), 0 0 0 2px #ffffff',
                        }}
                        maxW="50%"
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        onClick={(e) => {
                          e.stopPropagation();

                          batch(() => {
                            dispatch(
                              updateProjectDetailsItemsFilter({
                                ...projectDetailsItemsFilter,
                                workflow: data.message_items.workflow[0].id,
                              })
                            );
                            dispatch(updateItemCardonClose());
                            dispatch(resetProjectDetailRightComponent());
                            dispatch(
                              updateSelectedProjectDetailTab(
                                ProjectDetailTabEnums.items
                              )
                            );
                          });
                          navigate(
                            `/workspace/${params.workspace_id}/project/${projectData?.id}/detail?ref=${selectedLibraryTab}&project-tab=${ProjectDetailTabEnums.items}`
                          );
                        }}
                      >
                        {data.message_items.workflow[0].name}
                      </Text>
                    )}
                  {data.message_items?.item?.sub_type !==
                    ItemsTypeEnum.videoFile &&
                    data.message_items.review_messages &&
                    data.message_items.review_messages.length > 0 && (
                      <Text
                        fontSize={13}
                        py="2"
                        px="3"
                        borderRadius={6}
                        color="black"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(
                            `/workspace/${params.workspace_id}/project/${params.project_id}/messageitem/${data.message_items?.id}/review${searchUrlParam}`
                          );
                        }}
                        _hover={{
                          color: 'primary.presentable',
                          svg: {
                            fill: 'primary.presentable',
                          },
                        }}
                      >
                        <ReviewNoteChatIcon height="4" width="4" mr="2" />
                        {getUniquePointerCount(
                          data.message_items.review_messages,
                          data.message_items?.item?.sub_type
                        )}{' '}
                        comment
                        {getUniquePointerCount(
                          data.message_items.review_messages,
                          data.message_items?.item?.sub_type
                        ) > 1
                          ? 's'
                          : ''}
                      </Text>
                    )}

                  {data.message_items?.item?.sub_type ===
                    ItemsTypeEnum.videoFile &&
                    data.message_items.video_review_messages &&
                    data.message_items.video_review_messages.length > 0 && (
                      <Text
                        fontSize={13}
                        py="2"
                        px="3"
                        borderRadius={6}
                        color="black"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(
                            `/workspace/${params.workspace_id}/project/${params.project_id}/messageitem/${data.message_items?.id}/videoreview${searchUrlParam}`
                          );
                        }}
                        _hover={{
                          color: 'primary.presentable',
                          svg: {
                            fill: 'primary.presentable',
                          },
                        }}
                      >
                        <ReviewNoteChatIcon height="4" width="4" mr="2" />
                        {getUniquePointerCount(
                          data.message_items.video_review_messages,
                          data.message_items?.item?.sub_type
                        )}{' '}
                        comment
                        {getUniquePointerCount(
                          data.message_items.video_review_messages,
                          data.message_items?.item?.sub_type
                        ) > 1
                          ? 's'
                          : ''}
                      </Text>
                    )}
                </HStack>
              </VStack>
            )}
        </VStack>
      </HStack>
      <Text
        w="100%"
        pl="10"
        fontSize="13"
        fontWeight="500"
        pt="2"
        color="#5d636a"
      >
        <chakra.span
          _hover={{
            textDecoration: 'underline',
            color: 'primary.presentable',
          }}
          onClick={handleProfileClick}
          cursor="pointer"
          color="#5d636a"
        >
          {senderData?.first_name
            ? senderData?.first_name +
              ' ' +
              `${senderData?.last_name ? senderData?.last_name : ''}`
            : 'Unnammed user'}
        </chakra.span>
        <chakra.span fontWeight="500" color="secondary.smoke">
          ∙{dayjs(data?.created_at).format('DD MMM [at] hh:mm a')}
        </chakra.span>
      </Text>
    </Box>
  );
};
