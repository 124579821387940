import Icon, { IconProps } from '@chakra-ui/icon';
import { OmitCommonProps, As } from '@chakra-ui/react';
import React from 'react';

const _ProjectUIUXIcon = (
  props: JSX.IntrinsicAttributes &
    OmitCommonProps<React.SVGProps<SVGSVGElement>, keyof IconProps> &
    IconProps &
    OmitCommonProps<any, keyof IconProps> & { as?: As<any> | undefined }
) => (
  <Icon width={96} height={96} viewBox="0 0 96 96" focusable="false" {...props}>
    <defs>
      <path id="oifg" d="M0 0h96v96H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <use fill="#62009E" xlinkHref="#oifg" />
      <path d="M24 27h48v42H24z" fill="#D8A6F7" />
      <path fill="#A746E3" d="M32 35h32v26H32z" />
    </g>
  </Icon>
);

export const ProjectUIUXIcon = React.memo(_ProjectUIUXIcon);
