import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define a type for the slice state

interface TeamStateType {
  filters: any;
  selectedMember?: string;
  selectedContactData?: { [key: string]: any };
  teamProjectFilters: { [key: string]: any };
  teamActivityFilter: { [key: string]: any };
  editFilters: any;
  isDeactivateMemberModalVisible: boolean;
  deactivateMemberModalData?: { [key: string]: any };
  isEditMemberAccessRoleModalVisible: boolean;
  editMemberAccessRoleData?: { [key: string]: any };
  isRevokeMemberModalVisible: boolean;
  revokeMemberModalData?: { [key: string]: any };
}

// Define the initial state using that type
const initialState: TeamStateType = {
  filters: {},
  teamProjectFilters: {},
  teamActivityFilter: {},
  editFilters: {},
  isDeactivateMemberModalVisible: false,
  isEditMemberAccessRoleModalVisible: false,
  isRevokeMemberModalVisible: false,
};

export const teamSlice = createSlice({
  name: 'team',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    updateFilters: (state, action: PayloadAction<any>) => {
      state.filters = { ...action.payload };
    },
    updateSelectedMember: (state, action: PayloadAction<any>) => {
      state.selectedMember = action.payload;
    },
    updateTeamProjectFilters: (state, action: PayloadAction<any>) => {
      state.teamProjectFilters = { ...action.payload };
    },
    updateTeamActivityFilters: (state, action: PayloadAction<any>) => {
      state.teamActivityFilter = { ...action.payload };
    },
    updateEditTeamFilter: (state, action: PayloadAction<any>) => {
      state.editFilters = action.payload;
    },
    updateDeactivateMemberModalVisibility: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isDeactivateMemberModalVisible = action.payload;
    },
    updateDeactivateMemberModalData: (state, action: PayloadAction<any>) => {
      state.deactivateMemberModalData = action.payload;
    },

    updateEditMemberAccessRoleModalVisibility: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isEditMemberAccessRoleModalVisible = action.payload;
    },
    upadateEditMemberAccessRoleData: (state, action: PayloadAction<any>) => {
      state.editMemberAccessRoleData = action.payload;
    },
    updateSelectedContactData: (state, action: PayloadAction<any>) => {
      state.selectedContactData = action.payload;
    },
    upadateOnRevokeMemberClick: (state, action: PayloadAction<any>) => {
      state.isRevokeMemberModalVisible = true;
      state.revokeMemberModalData = action.payload;
    },
    upadateOnRevokeMemberClose: (state) => {
      state.isRevokeMemberModalVisible = false;
      state.revokeMemberModalData = undefined;
    },
  },
});

export const {
  updateFilters,
  updateSelectedMember,
  updateTeamProjectFilters,
  updateTeamActivityFilters,
  updateEditTeamFilter,
  updateDeactivateMemberModalVisibility,
  updateDeactivateMemberModalData,
  updateEditMemberAccessRoleModalVisibility,
  upadateEditMemberAccessRoleData,
  updateSelectedContactData,
  upadateOnRevokeMemberClick,
  upadateOnRevokeMemberClose,
} = teamSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value;

export default teamSlice.reducer;
