import Icon, { IconProps } from '@chakra-ui/icon';
import { OmitCommonProps, As } from '@chakra-ui/react';
import React from 'react';

const _ProjectMotionDesignIcon = (
  props: JSX.IntrinsicAttributes &
    OmitCommonProps<React.SVGProps<SVGSVGElement>, keyof IconProps> &
    IconProps &
    OmitCommonProps<any, keyof IconProps> & { as?: As<any> | undefined }
) => (
  <Icon width={96} height={96} viewBox="0 0 96 96" focusable="false" {...props}>
    <defs>
      <path id="fd" d="M0 0h96v96H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <use fill="#FF9A00" xlinkHref="#fd" />
      <circle
        cx={25}
        cy={25}
        r={25}
        transform="translate(23 23)"
        fill="#FFF2DE"
      />
      <circle fill="#FFC977" cx={48} cy={48} r={20} />
      <circle fill="#C08C3D" cx={48} cy={48} r={15} />
    </g>
  </Icon>
);

export const ProjectMotionDesignIcon = React.memo(_ProjectMotionDesignIcon);
