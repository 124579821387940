import Icon, { IconProps } from '@chakra-ui/icon';
import { OmitCommonProps, As } from '@chakra-ui/react';
import React from 'react';

const _ProjectProductIcon = (
  props: JSX.IntrinsicAttributes &
    OmitCommonProps<React.SVGProps<SVGSVGElement>, keyof IconProps> &
    IconProps &
    OmitCommonProps<any, keyof IconProps> & { as?: As<any> | undefined }
) => (
  <Icon width={96} height={96} viewBox="0 0 96 96" focusable="false" {...props}>
    <defs>
      <path id="bhbhh" d="M0 0h96v96H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="boiu" fill="#fff">
        <use xlinkHref="#bhbhh" />
      </mask>
      <use fill="#9E0042" xlinkHref="#bhbhh" />
      <g mask="url(#boiu)">
        <g transform="translate(22 27)">
          <path fill="#FB75AD" d="M0 0h31v42H0z" />
          <circle fill="#DE025E" cx={21} cy={21} r={17} />
          <path fill="#740232" d="m31 0 21 42H10z" />
        </g>
      </g>
    </g>
  </Icon>
);

export const ProjectProductIcon = React.memo(_ProjectProductIcon);
