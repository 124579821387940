import {
  AbilityBuilder,
  AbilityClass,
  FieldMatcher,
  MatchConditions,
  PureAbility,
  Subject,
} from '@casl/ability';
import { ClientRoleEnum, UserState } from '../../type/types';

type Actions = 'manage' | 'create' | 'read' | 'update' | 'delete' | 'navAccess';
export type Subjects =
  | 'WorkspaceConsole'
  | 'Favourites'
  | 'Profile'
  | 'Connects'
  | 'NewConnects'
  | 'NewGroupConnects'
  | 'BusinessProfile'
  | 'InviteMemberConnect'
  | 'ConnectDetail'
  | 'ConnectDetailItemPreview'
  | 'WorkspaceDetail'
  | 'RequestConnect'
  | 'VideoCall'
  | 'InitVideoCall'
  | 'ConnectOrder'
  | 'Discover'
  | 'all'
  | Subject;

export type AppAbilityType = PureAbility<[Actions, Subjects], MatchConditions>;
export const AppAbility = PureAbility as AbilityClass<AppAbilityType>;
const lambdaMatcher = (matchConditions: MatchConditions) => matchConditions;
export const fieldMatcher: FieldMatcher = (fields) => (field) =>
  fields.includes(field);

export const defineRulesFor = (role: ClientRoleEnum, user: any) => {
  const { can, cannot, rules } = new AbilityBuilder(AppAbility);

  if (user?.state === UserState.activated) {
    switch (role) {
      case ClientRoleEnum.admin: {
        can('navAccess', 'all');
        cannot('navAccess', 'ConnectOrder');
        can('manage', 'all');
        cannot('manage', 'ConnectOrder');
        break;
      }

      case ClientRoleEnum.editor: {
        cannot('navAccess', [
          'WorkspaceConsole',
          'InviteMemberConnect',
          'WorkspaceDetail',
          'RequestConnect',
          'ConnectOrder',
        ]);

        can('navAccess', 'BusinessProfile');
        can('navAccess', 'Profile');
        can('navAccess', 'Discover');

        can('manage', 'all');
        cannot('manage', 'WorkspaceConsole');
        cannot('manage', 'InviteMemberConnect');
        cannot('manage', 'WorkspaceDetail');
        cannot('manage', 'RequestConnect');
        break;
      }

      case ClientRoleEnum.guest: {
        can('navAccess', 'BusinessProfile');
        can('navAccess', 'Profile');
        can('navAccess', 'Favourites');
        can('navAccess', 'VideoCall');
        can('navAccess', 'ConnectOrder');
        can('navAccess', 'Discover');

        cannot('navAccess', [
          'Team',
          'EditTeam',
          'WorkspaceConsole',
          'WorkspaceDetail',
        ]);

        can('manage', 'Connects');
        can('manage', 'ConnectDetail');
        can('manage', 'ConnectDetailItemPreview');
        can('manage', 'ConnectOrder');
        can('manage', 'Discover');

        cannot('manage', 'NewConnects');
        cannot('manage', 'WorkspaceConsole');
        cannot('manage', 'InviteMemberConnect');
        cannot('manage', 'WorkspaceDetail');
        cannot('manage', 'RequestConnect');

        break;
      }
    }
  } else {
    can('navAccess', 'BusinessProfile');
    can('navAccess', 'Profile');
    can('navAccess', 'Favourites');
    can('navAccess', 'Discover');

    cannot('navAccess', [
      'WorkspaceConsole',
      'WorkspaceDetail',
      'ConnectOrder',
    ]);

    can('read', 'Connects');
    can('read', 'ConnectDetail');
    can('read', 'ConnectDetailItemPreview');
    can('manage', 'Discover');

    cannot('manage', 'NewConnects');
    cannot('manage', 'WorkspaceConsole');
    cannot('manage', 'InviteMemberConnect');
    cannot('manage', 'WorkspaceDetail');
    cannot('manage', 'RequestConnect');
    cannot('manage', 'ConnectOrder');
  }

  return rules;
};

export const globalAbility = new AppAbility();
// defineRulesFor(ClientRoleEnum.editor, {}),
// {
//   conditionsMatcher: lambdaMatcher,
//   fieldMatcher: fieldMatcher,
// }
//   {
//     // https://casl.js.org/v5/en/guide/subject-type-detection
//     detectSubjectType: (object) => object!.type,
//   }

export const updateAbility = (
  ability: AppAbilityType,
  role: ClientRoleEnum,
  user: any
): void => {
  const rules = defineRulesFor(role, user);
  ability.update(rules);
};
