import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { queryClient } from '..';
import { CustomRQResponseType } from '../type/types';

// Define a type for the slice state

interface ProjectDirectMessageStateType {
  selectedReceiverId?: string;
  dmChatData?: { [key: string]: any[] };
  dmRecentReceiverId: { [key: string]: any };
  lastReadChat: { [key: string]: string };
  lastThreadReadChat: { [key: string]: any };
  selectedDMThreadNotificationMessageIds?: string[];
}

// Define the initial state using that type
const initialState: ProjectDirectMessageStateType = {
  dmChatData: {},
  lastReadChat: {},
  lastThreadReadChat: {},
  dmRecentReceiverId: {},
};

export const projectDirectMessageSlice = createSlice({
  name: 'projectDirectMessage',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    updateProjectDMReceiverID: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.selectedReceiverId = action.payload;
    },

    // --DM Chat
    updateDMChatHistoryData: (
      state,
      action: PayloadAction<{
        data: any[];
        projectReceiverDMID: string;
      }>
    ) => {
      const initData = { ...state.dmChatData } as { [key: string]: any[] };
      let projectChatData = initData[action.payload.projectReceiverDMID];
      if (projectChatData) {
        projectChatData = action.payload.data.concat(projectChatData);
      } else {
        projectChatData = [...action.payload.data];
      }
      initData[action.payload.projectReceiverDMID] = projectChatData;
      state.dmChatData = initData;
    },
    initializeDMChatData: (
      state,
      action: PayloadAction<{
        data: any[];
        projectReceiverDMID: string;
      }>
    ) => {
      const initData = { ...state.dmChatData } as { [key: string]: any[] };
      initData[action.payload.projectReceiverDMID] = [...action.payload.data];
      state.dmChatData = initData;
    },
    updateDMChatData: (
      state,
      action: PayloadAction<{
        data: any;
        projectReceiverDMID: string;
      }>
    ) => {
      const initData = { ...state.dmChatData };
      let projectChatData = initData[action.payload.projectReceiverDMID];
      if (projectChatData) {
        projectChatData = [action.payload.data, ...projectChatData];
      } else {
        projectChatData = [action.payload.data];
      }
      initData[action.payload.projectReceiverDMID] = projectChatData;
      state.dmChatData = initData;
    },
    resetDMChatDataForSingleProject: (state, action: PayloadAction<string>) => {
      const initData = { ...state.dmChatData } as { [key: string]: any[] };
      let projectChatData = initData[action.payload];
      if (projectChatData) {
        projectChatData = [];
        delete initData[action.payload];
      }
      state.dmChatData = initData;
    },

    updateThreadDMChatDataByMessageId: (
      state,
      action: PayloadAction<{
        data: any;
        workspaceId: string;
        projectReceiverDMID: string;
      }>
    ) => {
      const initData = { ...state.dmChatData } as { [key: string]: any[] };
      let projectChatData = initData[action.payload.projectReceiverDMID];

      if (projectChatData) {
        const message = projectChatData.filter(
          (el: any) => el.id === action.payload.data.message_id.id
        )[0];
        const mesgThreadInitData = message.message_thread
          ? message.message_thread
          : [];

        const index = projectChatData.findIndex((object) => {
          return object.id === action.payload.data.message_id.id;
        });

        message.message_thread = [action.payload.data, ...mesgThreadInitData];

        projectChatData.splice(index, 1, message);

        state.dmChatData = initData;

        // update the open thread notification reply messages

        const selectedProjectThreads =
          state.selectedDMThreadNotificationMessageIds;

        let ids = selectedProjectThreads ? [...selectedProjectThreads] : [];

        const initialThreadNotificationMessageData:
          | CustomRQResponseType
          | undefined = queryClient.getQueryData([
          'dm_project_thread_messages',
          action.payload.workspaceId,
          ids?.join(),
        ]);

        if (
          initialThreadNotificationMessageData &&
          initialThreadNotificationMessageData.data &&
          selectedProjectThreads &&
          Object.keys(selectedProjectThreads).length > 0
        ) {
          ids &&
            ids.length > 0 &&
            queryClient.setQueryData(
              [
                'dm_project_thread_messages',
                action.payload.workspaceId,
                ids?.join(),
              ],
              (oldData: any) => {
                const newData = { ...oldData };
                const { data: messageData } = newData;

                const filteredData = messageData.filter(
                  (el: any) => el.id === message.id
                );

                const index = messageData.findIndex((object: any) => {
                  return object.id === message.id;
                });

                if (filteredData && filteredData.length > 0) {
                  const mesgThreadInitData = filteredData[0].message_thread
                    ? filteredData[0].message_thread
                    : [];

                  const updatedData = {
                    ...filteredData[0],
                    message_thread: [
                      ...mesgThreadInitData,
                      action.payload.data,
                    ],
                  };
                  messageData.splice(index, 1, updatedData);
                }
                return { ...newData, data: messageData };
              }
            );
        }

        // update the open reply thread message
        const initialSelectedMessageData: CustomRQResponseType | undefined =
          queryClient.getQueryData([
            'selected_direct_message_detail',
            action.payload.workspaceId,
            action.payload.data.message_id.id,
          ]);

        if (initialSelectedMessageData && initialSelectedMessageData.data) {
          queryClient.setQueryData(
            [
              'selected_direct_message_detail',
              action.payload.workspaceId,
              action.payload.data.message_id.id,
            ],
            (oldData: any) => {
              const newData = { ...oldData };
              const { data: messageData } = newData;

              const mesgThreadInitData = messageData.message_thread
                ? messageData.message_thread
                : [];
              const updatedData = {
                ...messageData,
                message_thread: [...mesgThreadInitData, action.payload.data],
              };

              return { ...newData, data: updatedData };
            }
          );
        }
      }
    },

    updateAddToProjectChatDataByMessageId: (
      state,
      action: PayloadAction<{
        data: any;
        projectReceiverDMID: string;
        messageId: string;
      }>
    ) => {
      const initData = { ...state.dmChatData } as { [key: string]: any[] };
      const id = action.payload.projectReceiverDMID;
      let projectChatData = initData[id];

      if (projectChatData) {
        const message = {
          ...projectChatData.filter((el: any) => {
            return el.id === action.payload.messageId;
          })[0],
        };

        const index = projectChatData.findIndex((object) => {
          return object.id === action.payload.messageId;
        });

        const initialDMData =
          message.message_items.item && message.message_items.item.project_dm
            ? message.message_items.item.project_dm
            : [];

        const initialDMProjectData =
          message.message_items.item && message.message_items.item.project
            ? message.message_items.item.project
            : [];

        message.message_items.item['project_dm'] = [...initialDMData];
        message.message_items.item['project'] = [
          ...initialDMProjectData,
          action.payload.data,
        ];

        projectChatData.splice(index, 1, message);

        state.dmChatData = initData;
      }
    },

    updateDirectMessageDelete: (
      state,
      action: PayloadAction<{
        projectReceiverDMID: string;
        messageId: string;
      }>
    ) => {
      const initData = { ...state.dmChatData } as { [key: string]: any[] };
      let projectChatData = initData[action.payload.projectReceiverDMID];
      if (projectChatData) {
        const remainingMessages = projectChatData.filter(
          (el: any) => el.id !== action.payload.messageId
        );

        state.dmChatData = {
          ...initData,
          [action.payload.projectReceiverDMID]: remainingMessages,
        };
      }
    },

    // --DM Chat notification

    // Read Chat actions
    initializeAllReadDMChatOfProject: (
      state,
      action: PayloadAction<{
        projectDMChat: { [key: string]: string };
        projectDMThread: { [key: string]: string };
      }>
    ) => {
      state.lastReadChat = action.payload.projectDMChat;

      const threadData = action.payload.projectDMThread;
      threadData &&
        Object.keys(threadData).forEach((el: string) => {
          const initVal = threadData[el];
          if (initVal) {
            threadData[el] = JSON.parse(initVal);
          }
        });
      state.lastThreadReadChat = threadData ? threadData : {};
    },
    updateReadDMChatOfProject: (
      state,
      action: PayloadAction<{
        projectId: string;
        receiverId: string;
        messageId: string;
      }>
    ) => {
      if (state.lastReadChat) {
        state.lastReadChat[
          `project:${action.payload.projectId}:receiver:${action.payload.receiverId}`
        ] = `${action.payload.messageId}::0`;
      }
    },
    resetThreadDMReadChatOfProject: (
      state,
      action: PayloadAction<{ projectId: string }>
    ) => {
      const initialData = { ...state.lastThreadReadChat };
      const filteredIds = [...Object.keys(initialData)].filter(
        (el: string) => !el.includes(action.payload.projectId)
      );

      const newMap = {} as any;

      filteredIds.map((el: string) => {
        newMap[el] = initialData[el];
      });

      state.lastThreadReadChat = { ...newMap };
    },

    updateThreadDMReadChatOfProject: (
      state,
      action: PayloadAction<{ projectId: string }>
    ) => {
      const initialData = { ...state.lastThreadReadChat };
      const filteredIds = [...Object.keys(initialData)].filter((el: string) =>
        el.includes(action.payload.projectId)
      );

      const newMap = {} as any;

      filteredIds.map((el: string) => {
        const newMsgObj = {} as any;
        Object.keys(initialData[el]).forEach((el: string) => {
          newMsgObj[el] = 0;
        });
        newMap[el] = newMsgObj;
      });

      state.lastThreadReadChat = { ...initialData, ...newMap };
    },
    updateReadDMChatDataOnNewProjectIncomingMessage: (
      state,
      action: PayloadAction<{
        projectId: string;
        receiverId: string;
        messageCount: number;
        messageId: string;
      }>
    ) => {
      const initialData =
        state.lastReadChat[
          `project:${action.payload.projectId}:receiver:${action.payload.receiverId}`
        ];

      if (!initialData) {
        state.lastReadChat[
          `project:${action.payload.projectId}:receiver:${action.payload.receiverId}`
        ] = `${action.payload.messageId}::${action.payload.messageCount}`;
      } else {
        const [lastMessageId, count] = initialData.split('::');
        if (lastMessageId !== action.payload.messageId) {
          const newValue = `${action.payload.messageId}::${
            Number(count) + action.payload.messageCount
          }`;

          state.lastReadChat[
            `project:${action.payload.projectId}:receiver:${action.payload.receiverId}`
          ] = newValue;
        }
      }
    },

    resetThreadReadChatDataOnNewIncomingMessage: (
      state,
      action: PayloadAction<{
        projectId: string;
        messageId: string;
        receiverId: string;
      }>
    ) => {
      const key = `project:${action.payload.projectId}:receiver:${action.payload.receiverId}`;
      state.lastThreadReadChat[key] = {
        [action.payload.messageId]: 0,
      };
    },

    updateThreadReadChatDataOnNewIncomingMessage: (
      state,
      action: PayloadAction<{
        projectId: string;
        messageId: string;
        receiverId: string;
      }>
    ) => {
      const key = `project:${action.payload.projectId}:receiver:${action.payload.receiverId}`;
      const initialData = state.lastThreadReadChat[key];

      if (!initialData) {
        state.lastThreadReadChat[key] = {
          [action.payload.messageId]: 1,
        };
      } else {
        if (
          initialData &&
          initialData.hasOwnProperty(action.payload.messageId)
        ) {
          const intCount = initialData[action.payload.messageId];
          initialData[action.payload.messageId] = intCount + 1;
        } else {
          initialData[action.payload.messageId] = 1;
        }
        state.lastThreadReadChat[key] = initialData;
      }
    },

    //   dmRecentReceiverId actions
    updateDMRecentReceiverIdOfProject: (
      state,
      action: PayloadAction<{
        projectId: string;
        receiverIds: string[];
      }>
    ) => {
      state.dmRecentReceiverId[action.payload.projectId] = [
        ...action.payload.receiverIds,
      ];
    },

    updateSelectedDMThreadNotificationMessageIds: (
      state,
      action: PayloadAction<string[] | undefined>
    ) => {
      state.selectedDMThreadNotificationMessageIds = action.payload;
    },

    resetBlinkDMNotificationMessage: (
      state,
      action: PayloadAction<{
        projectId: string;
        messageId: string;
        projectReceiverDMID: string;
      }>
    ) => {
      const initData = { ...state.dmChatData } as { [key: string]: any[] };
      let projectChatData = initData[action.payload.projectReceiverDMID];

      if (projectChatData) {
        const message = projectChatData.filter(
          (el: any) => el.id === action.payload.messageId
        )[0];

        const index = projectChatData.findIndex((object) => {
          return object.id === action.payload.messageId;
        });
        message['isNew'] = false;
        projectChatData.splice(index, 1, message);
        state.dmChatData = initData;
      }
    },
    resetBlinkDMThreadNotificationMessage: (
      state,
      action: PayloadAction<{
        workspaceId: string;
        projectId: string;
        messageId: string;
        threadMessageId: string;
        projectReceiverDMID: string;
      }>
    ) => {
      const initData = { ...state.dmChatData } as { [key: string]: any[] };
      let projectChatData = initData[action.payload.projectReceiverDMID];

      if (projectChatData) {
        const message = projectChatData.filter(
          (el: any) => el.id === action.payload.messageId
        )[0];

        const msgThreadInitData = message.message_thread
          ? [...message.message_thread]
          : [];

        const index = projectChatData.findIndex((object) => {
          return object.id === action.payload.messageId;
        });

        const selectedThreadMessage = {
          ...msgThreadInitData.filter(
            (el: any) => el.id === action.payload.threadMessageId
          )[0],
        };

        console.log(selectedThreadMessage, 'projectChatData');

        const threadIndex = msgThreadInitData.findIndex((object: any) => {
          return object.id === action.payload.threadMessageId;
        });

        selectedThreadMessage['isNew'] = false;
        msgThreadInitData.splice(threadIndex, 1, selectedThreadMessage);

        message.message_thread = [...msgThreadInitData];
        projectChatData.splice(index, 1, message);
        state.dmChatData = initData;

        // update the open thread notification reply messages

        const selectedProjectThreads =
          state.selectedDMThreadNotificationMessageIds;

        let ids = selectedProjectThreads ? [...selectedProjectThreads] : [];

        const initialThreadNotificationMessageData:
          | CustomRQResponseType
          | undefined = queryClient.getQueryData([
          'dm_project_thread_messages',
          action.payload.workspaceId,
          ids?.join(),
        ]);

        if (
          initialThreadNotificationMessageData &&
          initialThreadNotificationMessageData.data &&
          selectedProjectThreads &&
          Object.keys(selectedProjectThreads).length > 0
        ) {
          ids &&
            ids.length > 0 &&
            queryClient.setQueryData(
              [
                'dm_project_thread_messages',
                action.payload.workspaceId,
                ids?.join(),
              ],
              (oldData: any) => {
                const newData = { ...oldData };
                const { data: messageData } = newData;

                const filteredData = [...messageData].filter(
                  (el: any) => el.id === message.id
                );

                const index = messageData.findIndex((object: any) => {
                  return object.id === message.id;
                });

                if (filteredData && filteredData.length > 0) {
                  const msgThreadInitData = filteredData[0].message_thread
                    ? filteredData[0].message_thread
                    : [];

                  const selectedThreadMessage = {
                    ...msgThreadInitData.filter(
                      (el: any) => el.id === action.payload.threadMessageId
                    )[0],
                  };

                  const threadIndex = msgThreadInitData.findIndex(
                    (object: any) => {
                      return object.id === action.payload.threadMessageId;
                    }
                  );

                  selectedThreadMessage['isNew'] = false;
                  msgThreadInitData.splice(
                    threadIndex,
                    1,
                    selectedThreadMessage
                  );

                  const updatedData = {
                    ...filteredData[0],
                    message_thread: [...msgThreadInitData],
                  };
                  messageData.splice(index, 1, updatedData);
                }
                return { ...newData, data: messageData };
              }
            );
        }

        // update the open reply thread message
        const initialSelectedMessageData: CustomRQResponseType | undefined =
          queryClient.getQueryData([
            'selected_direct_message_detail',
            action.payload.workspaceId,
            action.payload.messageId,
          ]);

        if (initialSelectedMessageData && initialSelectedMessageData.data) {
          queryClient.setQueryData(
            [
              'selected_direct_message_detail',
              action.payload.workspaceId,
              action.payload.messageId,
            ],
            (oldData: any) => {
              const newData = { ...oldData };
              const { data: messageData } = newData;

              const msgThreadInitData = messageData.message_thread
                ? messageData.message_thread
                : [];

              const selectedThreadMessage = {
                ...msgThreadInitData.filter(
                  (el: any) => el.id === action.payload.threadMessageId
                )[0],
              };

              const threadIndex = msgThreadInitData.findIndex((object: any) => {
                return object.id === action.payload.threadMessageId;
              });

              selectedThreadMessage['isNew'] = false;
              msgThreadInitData.splice(threadIndex, 1, selectedThreadMessage);

              const updatedData = {
                ...messageData,
                message_thread: [...msgThreadInitData],
              };

              return { ...newData, data: updatedData };
            }
          );
        }
      }
    },

    addBlinkToDMNotificationMessage: (
      state,
      action: PayloadAction<{
        projectId: string;
        messageId: string;
        count: number;
        projectReceiverDMID: string;
      }>
    ) => {
      const initData = { ...state.dmChatData } as { [key: string]: any[] };

      const projectChatData = initData[action.payload.projectReceiverDMID];

      if (projectChatData && projectChatData.length > 0) {
        const message = {
          ...projectChatData.filter(
            (el: any) => el?.id === action.payload.messageId
          )[0],
        };
        const index = projectChatData.findIndex((object) => {
          return object.id === action.payload.messageId;
        });

        message['isNew'] = true;
        projectChatData.splice(index, 1, message);

        console.log(projectChatData, index, 'FINE TUNE ');

        let i = index;
        while (i >= 0 && i <= index) {
          const newMessage = projectChatData[index];
          if (newMessage) {
            newMessage['isNew'] = true;
            projectChatData.splice(i, 1, message);
          }
          i--;
        }
        state.dmChatData = initData;
      }
    },
  },
});

export const {
  updateProjectDMReceiverID,
  updateDMChatHistoryData,
  initializeDMChatData,
  updateDMChatData,
  resetDMChatDataForSingleProject,
  updateThreadDMChatDataByMessageId,
  updateAddToProjectChatDataByMessageId,
  updateDirectMessageDelete,
  initializeAllReadDMChatOfProject,
  updateReadDMChatOfProject,
  resetThreadDMReadChatOfProject,
  updateThreadDMReadChatOfProject,
  updateReadDMChatDataOnNewProjectIncomingMessage,
  updateDMRecentReceiverIdOfProject,
  resetThreadReadChatDataOnNewIncomingMessage,
  updateThreadReadChatDataOnNewIncomingMessage,
  updateSelectedDMThreadNotificationMessageIds,
  resetBlinkDMNotificationMessage,
  resetBlinkDMThreadNotificationMessage,
  addBlinkToDMNotificationMessage,
} = projectDirectMessageSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value;

export default projectDirectMessageSlice.reducer;
