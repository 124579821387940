import {
  chakra,
  VStack,
  Box,
  HStack,
  Text,
  Button,
  Center,
  Tooltip,
} from '@chakra-ui/react';
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { PresentableAvatar } from '../Avatar';
import parser from 'html-react-parser';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { batch } from 'react-redux';
import { getItemsTypeOptionsData } from '../../utils/items';
import { AppDetailContext } from '../../Context/AppDetailContext';
import { ItemsTypeEnum } from '../Library/helper';
import { RootState } from '../../store';
import {
  ClientRoleEnum,
  ConnectStateTypeEnum,
  UserToConnectStateTypeEnum,
} from '../../type/types';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';
import { Document, Page, pdfjs } from 'react-pdf';
import { VideoPreviewComponent } from '../Library/LeftSideComponent/ItemCard/VideoPreviewComponent';
import { ImageBlurLoader } from '../Library/LeftSideComponent/ItemCard/ImageBlurLoader';
import { ConnectDetailDataContext } from '../../pages/ConnectDetail/connectData.context';
import {
  updateOnAddReactionClick,
  updateOnAddTagClick,
  updateOnViewConnectParticipantDetailClick,
} from '../../store/connectDetail';
import { AppSocketContext } from '../../Context/AppSocketContext';
import { AddReactionIcon } from '../../svg/Chat/addReaction.icon';
import { useCreateConnectPurchaseOrder } from '../../apiHooks/Orders/create.hook';
import { DoneTickChatIcon } from '../../svg/Chat/chatDoneTick.icon';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface Props {
  data: { [key: string]: any };
  isOnline: boolean;
  disableHoverEffect?: boolean;
}

const _ChatThreadComponent = (props: Props) => {
  const { isOnline, data, disableHoverEffect = false } = props;
  const [isPDFLoaded, updateIsPDFLoaded] = useState<boolean>(false);
  const [mouseState, updateMouseState] = useState<'LEAVE' | 'ENTER'>('LEAVE');
  const [isMoreActionVisible, updateMoreActionVisible] = useState<boolean>(
    false
  );

  const ref = useRef<HTMLDivElement>(null);
  const itemRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { appDetail } = useContext(AppDetailContext);
  const { connectData, userConnectStateMap } = useContext(
    ConnectDetailDataContext
  );
  const { socket } = useContext(AppSocketContext);

  const params = useParams<{
    connect_id: string;
    workspace_id: string;
  }>();

  const selectedWorkspaceRole = useAppSelector(
    (state: RootState) => state.user.selectedWorkspaceRole
  );

  const selectedWorkspaceId = useAppSelector(
    (state: RootState) => state.user.selectedWorkspaceId
  );

  const { mutate, isLoading } = useCreateConnectPurchaseOrder(
    selectedWorkspaceId,
    params.connect_id as string
  );

  const senderData = useMemo(() => {
    return data && data.sender_id
      ? userConnectStateMap[data?.sender_id]?.user_to_workspace?.user
      : {};
  }, [data, userConnectStateMap]);

  const iconData = useMemo(() => {
    if (data.message_items && data.message_items.type !== 'SELLABLE_PRODUCT') {
      const size =
        data.message_items?.sub_type === ItemsTypeEnum.videoFile
          ? '44px'
          : '20px';
      return getItemsTypeOptionsData(data.message_items?.sub_type, size, size);
    } else if (
      data.message_items &&
      data.message_items.type === 'SELLABLE_PRODUCT'
    ) {
      return { icon: undefined, bg: undefined, label: 'Product' };
    } else {
      return undefined;
    }
  }, [data.message_items]);

  const itemName = useMemo(() => {
    if (data.message_items?.type === 'SELLABLE_PRODUCT') {
      return data.message_items?.content;
    } else if (data.message_items?.type === 'FILE') {
      return data.message_items?.attachment.originalname;
    } else if (data.message_items?.type === 'LINK') {
      const hasProtocol = data.message_items?.content.includes('http');
      const { hostname } = new URL(
        hasProtocol
          ? data.message_items?.content
          : `https://${data.message_items?.content}`
      );
      return hostname;
    } else if (data.message_items?.type === 'VIDEO_CALL') {
      return data.message_items?.video_call_item_metadata.room_name;
    } else if (
      data.message_items?.type === 'PAYMENT_LEDGER' &&
      data.message_items?.sub_type === ItemsTypeEnum.joinedPaidConnect
    ) {
      return 'Paid Connect Payment';
    } else if (
      data.message_items?.type === 'PAYMENT_LEDGER' &&
      data.message_items?.sub_type === ItemsTypeEnum.guestRequestConnect
    ) {
      return 'Request Connect Payment';
    } else {
      return 'Item';
    }
  }, [data]);

  const showAddTagOption = useMemo(() => {
    if (selectedWorkspaceRole === ClientRoleEnum.guest) {
      return false;
    } else if (connectData?.state === ConnectStateTypeEnum.closed) {
      return false;
    } else if (connectData?.state === ConnectStateTypeEnum.draft) {
      return false;
    } else if (
      connectData?.state === ConnectStateTypeEnum.active &&
      (userConnectStateMap[appDetail?.id]?.state ===
        UserToConnectStateTypeEnum.joined ||
        userConnectStateMap[appDetail?.id]?.state ===
          UserToConnectStateTypeEnum.creator)
    ) {
      return true;
    } else {
      return false;
    }
  }, [
    appDetail?.id,
    connectData?.state,
    selectedWorkspaceRole,
    userConnectStateMap,
  ]);

  const handleProfileClick = () => {
    batch(() => {
      dispatch(updateOnViewConnectParticipantDetailClick(senderData?.id));
    });
  };

  const handleAddWorkflow = useCallback(() => {
    batch(() => {
      dispatch(updateOnAddTagClick(data));
    });
  }, [data, dispatch]);

  const handleRemoveReaction = useCallback(
    (reactionId: string) => {
      socket?.emit('connect:message:reaction:remove', {
        message_id: data?.id,
        connect_id: connectData?.id,
        reaction_id: reactionId,
        sender_id: appDetail.id,
        workspace_id: selectedWorkspaceId,
      });
    },
    [appDetail.id, connectData?.id, data?.id, selectedWorkspaceId, socket]
  );

  const onPurchaseInit = useCallback(() => {
    mutate(data?.message_items?.id, {
      onSuccess: (data) => {
        if (data.success) {
          navigate(
            `/workspace/${selectedWorkspaceId}/connect/${params?.connect_id}/order/${data.data.id}`
          );
        }
      },
    });
  }, [
    data?.message_items?.id,
    mutate,
    navigate,
    params?.connect_id,
    selectedWorkspaceId,
  ]);

  return (
    <Box
      py="4"
      px="3"
      _hover={{
        bg: disableHoverEffect ? undefined : '#fdf9f5',
      }}
      ref={ref}
      display="block"
      w="100%"
      pos="relative"
      onMouseEnter={() => {
        updateMouseState('ENTER');
        updateMoreActionVisible(true);
      }}
      onMouseLeave={(e: any) => {
        updateMouseState('LEAVE');
        updateMoreActionVisible(false);
      }}
    >
      {mouseState === 'ENTER' && isMoreActionVisible && (
        <Box
          pos={'absolute'}
          right="2"
          top="-2"
          borderRadius={6}
          bg="white"
          px="10px"
          py="6px"
          border="1px solid #ececef"
          onClick={(e) => {
            e.stopPropagation();
            dispatch(updateOnAddReactionClick(data));
          }}
          color="#5d636a"
          _hover={{
            bg: 'primary.soft_peach',
            borderColor: 'primary.presentable',
            color: 'primary.presentable',
          }}
          cursor="pointer"
          w="fit-content"
          zIndex={10}
        >
          <AddReactionIcon fill="#5d636a" height="4" width="4" />
        </Box>
      )}
      <HStack alignItems="flex-start" w="100%">
        <PresentableAvatar
          size="8"
          borderRadius="4"
          name={senderData?.name}
          profileImg={senderData?.profile_img}
          profileColor={senderData?.profile_color}
          isOnline={isOnline}
        />
        <VStack
          w="calc(100% - 40px)"
          alignItems={'flex-start'}
          onClick={(e) => {
            e.stopPropagation();
            if (
              (data?.message_items?.type === 'FILE' ||
                data?.message_items?.type === 'SELLABLE_PRODUCT') &&
              !data?.message_items?.message_item_transaction_metadata &&
              (data?.message_items?.sub_type === ItemsTypeEnum.imageFile ||
                data?.message_items?.sub_type === ItemsTypeEnum.pdfFile ||
                data?.message_items?.sub_type === ItemsTypeEnum.videoFile ||
                data?.message_items?.sub_type === ItemsTypeEnum.audioFile)
            ) {
              navigate(
                `/workspace/${params.workspace_id}/connect/${params.connect_id}/message-item/${data.message_items?.id}`
              );
            }
          }}
        >
          {data && data.message && data.message.length > 0 && (
            <Box
              bg={'#f0f4f7'}
              fontSize="15"
              py="3"
              px="4"
              borderRightRadius="20"
              borderBottomLeftRadius="20"
              maxW="100%"
              className="p-chat-message"
            >
              {parser(data.message)}
            </Box>
          )}
          {data &&
            data.message_items &&
            Object.keys(data.message_items).length > 0 && (
              <VStack
                borderRightRadius="20"
                borderBottomLeftRadius="20"
                justifyContent="space-between"
                border="1px solid"
                borderColor={'#e7e9ea'}
                alignItems="flex-start"
                overflow={'hidden'}
                mt="0px !important"
                bg="white"
                width={'100%'}
                cursor="pointer"
              >
                {data.message_items?.sub_type === ItemsTypeEnum.videoFile &&
                  (!data.message_items?.video_item_metadata ||
                    (data.message_items?.video_item_metadata &&
                      !data.message_items?.video_item_metadata?.sprite)) && (
                    <Center
                      height={'200px'}
                      overflow={'hidden'}
                      w="100%"
                      bg={iconData?.bg}
                      alignItems={'center'}
                      flexDir="column"
                      justifyContent={'center'}
                      borderTopRadius={8}
                      pt="6"
                    >
                      {iconData?.icon}
                      <Text mt="2" fontSize={'12'}>
                        Generating preview ...
                      </Text>
                    </Center>
                  )}
                {data.message_items?.sub_type === ItemsTypeEnum.videoFile &&
                  data.message_items?.video_item_metadata &&
                  data.message_items?.video_item_metadata?.sprite && (
                    <Box height={'200px'} width="100%" bg="white" ref={itemRef}>
                      <VideoPreviewComponent
                        url={data.message_items?.video_item_metadata?.sprite}
                        containerWidth={326}
                        yPosition={-66}
                        ref={itemRef}
                      />
                    </Box>
                  )}
                {data.message_items?.sub_type === ItemsTypeEnum.pdfFile && (
                  <Center
                    height={'200px'}
                    overflow={'hidden'}
                    w="100%"
                    bg="#f0f4f7"
                    onClick={() => {
                      if (
                        data?.message_items?.type === 'FILE' ||
                        (data?.message_items?.type === 'SELLABLE_PRODUCT' &&
                          !data?.message_items
                            ?.message_item_transaction_metadata)
                      ) {
                        navigate(
                          `/workspace/${params.workspace_id}/connect/${params.connect_id}/message-item/${data.message_items?.id}`
                        );
                      }
                    }}
                  >
                    <Document
                      file={data.message_items?.attachment.location}
                      onLoadSuccess={(val: any) => {
                        updateIsPDFLoaded(true);
                      }}
                      loading=""
                    >
                      <Page height={200} pageNumber={1} />
                    </Document>
                  </Center>
                )}
                {data.message_items?.sub_type === ItemsTypeEnum.imageFile && (
                  <Box
                    height={'200px'}
                    width="100%"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (
                        data?.message_items?.type === 'FILE' ||
                        (data?.message_items?.type === 'SELLABLE_PRODUCT' &&
                          !data?.message_items
                            ?.message_item_transaction_metadata)
                      ) {
                        navigate(
                          `/workspace/${params.workspace_id}/connect/${params.connect_id}/message-item/${data.message_items?.id}`
                        );
                      }
                    }}
                    bg="white"
                  >
                    <ImageBlurLoader
                      sourceUrl={data.message_items?.attachment?.location}
                      borderRadius="0"
                    />
                  </Box>
                )}
                {(data.message_items?.sub_type ===
                  ItemsTypeEnum.joinedPaidConnect ||
                  data.message_items?.sub_type ===
                    ItemsTypeEnum.guestRequestConnect) && (
                  <Center height={'200px'} width="100%" bg="#0d5231">
                    <ImageBlurLoader
                      sourceUrl={
                        'https://presentable.blob.core.windows.net/website/web/money-3.jpg'
                      }
                      borderRadius="0"
                    />
                  </Center>
                )}
                {data.message_items?.type === 'LINK' &&
                  data.message_items.meta_image && (
                    <Box
                      height={'200px'}
                      width="100%"
                      onClick={(e) => {
                        e.stopPropagation();
                        window.open(data.message_items?.content, '_blank');
                      }}
                      bg="white"
                    >
                      <ImageBlurLoader
                        sourceUrl={data.message_items?.meta_image}
                        borderRadius="0"
                      />
                    </Box>
                  )}
                {!(
                  data.message_items?.sub_type ===
                    ItemsTypeEnum.joinedPaidConnect ||
                  data.message_items?.sub_type ===
                    ItemsTypeEnum.guestRequestConnect
                ) &&
                  data.message_items?.sub_type !== ItemsTypeEnum.imageFile &&
                  !(
                    data.message_items?.type === 'LINK' &&
                    data.message_items.meta_image
                  ) &&
                  (!isPDFLoaded ||
                    data.message_items?.sub_type !== ItemsTypeEnum.pdfFile) &&
                  data.message_items?.sub_type !== ItemsTypeEnum.videoFile && (
                    <HStack
                      p="3"
                      pb="3"
                      w="100%"
                      alignItems={'flex-start'}
                      mt="0 !important"
                      justifyContent={'space-between'}
                    >
                      <HStack w="50%">
                        <Center
                          borderRadius={6}
                          bg={iconData?.bg}
                          h="14"
                          w="14"
                        >
                          {iconData?.icon}
                        </Center>
                      </HStack>
                      <HStack w="50%" justifyContent={'flex-end'}>
                        {showAddTagOption &&
                          (!data.message_items.workflow ||
                            (data.message_items.workflow &&
                              data.message_items.workflow.length === 0)) && (
                            <Button
                              variant={'p_secondary'}
                              size={'sm'}
                              fontSize={13}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleAddWorkflow();
                              }}
                              color="#5d636a"
                              borderColor={'#ececef'}
                              fontWeight="normal"
                              _focusWithin={{
                                boxShadow: 'none',
                              }}
                            >
                              Add tag
                            </Button>
                          )}
                        {data.message_items.workflow &&
                          data.message_items.workflow.length > 0 && (
                            <Text
                              w="fit-content"
                              fontSize={13}
                              py="2"
                              px="3"
                              borderRadius={6}
                              fontWeight="500"
                              bg="#f0f4f7"
                              color="#5d636a"
                              maxW="100%%"
                              whiteSpace="nowrap"
                              overflow="hidden"
                              textOverflow="ellipsis"
                              border="1px solid"
                              borderColor={'#ececef'}
                            >
                              {data.message_items.workflow[0].name}
                            </Text>
                          )}
                      </HStack>
                    </HStack>
                  )}
                {data.message_items?.message_item_transaction_metadata && (
                  <VStack px="3" py="2" w="100%" bg="#addfad" m="0 !important">
                    <HStack
                      w="100%"
                      m="0 !important"
                      justifyContent={'space-between'}
                    >
                      <Text
                        fontSize={14}
                        fontWeight={'500'}
                        color={'support.success'}
                      >
                        Amount :{' '}
                        <chakra.span fontWeight={'500'}>
                          ₹
                          {
                            data?.message_items?.message_item_price_metadata
                              ?.amount
                          }
                        </chakra.span>
                      </Text>
                      <HStack>
                        <DoneTickChatIcon
                          height="4"
                          width="4"
                          fill="support.success"
                        />
                        <Text
                          fontSize={14}
                          fontWeight={'500'}
                          color={'support.success'}
                          ml="2px !important"
                        >
                          Paid
                        </Text>
                      </HStack>
                    </HStack>
                    <HStack w="100%" bg="#addfad" m="0 !important">
                      <Text fontSize={12} color={'support.success'}>
                        Transaction Id :{' '}
                        {
                          data?.message_items?.message_item_transaction_metadata
                            ?.razorpay_payment_id
                        }
                      </Text>
                    </HStack>
                  </VStack>
                )}
                {data?.message_items?.message_item_price_metadata &&
                  !data.message_items?.message_item_transaction_metadata && (
                    <HStack
                      w="100%"
                      m="0 !important"
                      px="3"
                      py="2"
                      justifyContent={'space-between'}
                      alignItems="center"
                      bg="secondary.peebble"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Text fontSize={14}>
                        Price :{' '}
                        <chakra.span fontWeight={500}>
                          ₹
                          {
                            data?.message_items?.message_item_price_metadata
                              ?.amount
                          }
                        </chakra.span>
                      </Text>
                      {selectedWorkspaceRole === ClientRoleEnum.guest && (
                        <Button
                          isLoading={isLoading}
                          size="sm"
                          onClick={onPurchaseInit}
                          fontSize="12"
                          lineHeight={'normal'}
                          height="28px"
                        >
                          Buy
                        </Button>
                      )}
                    </HStack>
                  )}
                <HStack
                  p="3"
                  pb="5"
                  w="100%"
                  alignItems={'flex-start'}
                  mt="0 !important"
                  justifyContent={'space-between'}
                >
                  <HStack w="50%">
                    <VStack w={'100%'}>
                      <HStack w="100%">
                        <Text
                          w={'100%'}
                          fontSize="15"
                          lineHeight={'20px'}
                          color="secondary.black"
                          fontWeight="500"
                          textAlign="left"
                          whiteSpace="nowrap"
                          overflow="hidden"
                          textOverflow="ellipsis"
                          onClick={(e) => {
                            e.stopPropagation();
                            if (data?.message_items?.type === 'LINK') {
                              window.open(
                                data?.message_items?.content,
                                '_blank'
                              );
                            }
                          }}
                        >
                          {itemName}
                        </Text>
                      </HStack>
                      <Text
                        w="100%"
                        fontSize="14"
                        color="#5d636a"
                        textAlign="left"
                        lineHeight={'normal'}
                        letterSpacing={'normal'}
                        mt="0 !important"
                      >
                        {iconData?.label}
                      </Text>
                    </VStack>
                  </HStack>

                  {data.message_items?.type === 'VIDEO_CALL' && (
                    <HStack w="48%" justifyContent={'flex-end'}>
                      <Button
                        variant={
                          data?.message_items?.video_call_item_metadata
                            ?.has_ended
                            ? 'p_secondary'
                            : 'p_approve'
                        }
                        bg={
                          data?.message_items?.video_call_item_metadata
                            ?.has_ended
                            ? 'red !important'
                            : undefined
                        }
                        color={
                          data?.message_items?.video_call_item_metadata
                            ?.has_ended
                            ? 'white !important'
                            : undefined
                        }
                        borderColor={
                          data?.message_items?.video_call_item_metadata
                            ?.has_ended
                            ? 'red !important'
                            : undefined
                        }
                        size={'sm'}
                        fontSize={13}
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(
                            `/workspace/${params.workspace_id}/connect/${params.connect_id}/call/${data.message_items?.video_call_item_metadata?.id}`
                          );
                        }}
                        fontWeight="normal"
                        _focusWithin={{
                          boxShadow: 'none',
                        }}
                        isDisabled={
                          data?.message_items?.video_call_item_metadata
                            ?.has_ended
                            ? true
                            : false
                        }
                      >
                        {data?.message_items?.video_call_item_metadata
                          ?.has_ended
                          ? 'Live Connect Ended'
                          : 'Join Live Connect'}
                      </Button>
                    </HStack>
                  )}

                  {!(
                    data.message_items?.sub_type !==
                      ItemsTypeEnum.joinedPaidConnect &&
                    data.message_items?.sub_type !==
                      ItemsTypeEnum.guestRequestConnect &&
                    data.message_items?.sub_type !== ItemsTypeEnum.imageFile &&
                    !(
                      data.message_items?.type === 'LINK' &&
                      data.message_items.meta_image
                    ) &&
                    (!isPDFLoaded ||
                      data.message_items?.sub_type !== ItemsTypeEnum.pdfFile) &&
                    data.message_items?.sub_type !== ItemsTypeEnum.videoFile
                  ) && (
                    <HStack w="50%" justifyContent={'flex-end'}>
                      {showAddTagOption &&
                        (!data.message_items.workflow ||
                          (data.message_items.workflow &&
                            data.message_items.workflow.length === 0)) && (
                          <Button
                            variant={'p_secondary'}
                            size={'sm'}
                            fontSize={13}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleAddWorkflow();
                            }}
                            color="#5d636a"
                            borderColor={'#ececef'}
                            fontWeight="normal"
                            _focusWithin={{
                              boxShadow: 'none',
                            }}
                          >
                            Add tag
                          </Button>
                        )}
                      {data.message_items.workflow &&
                        data.message_items.workflow.length > 0 && (
                          <Text
                            w="fit-content"
                            fontSize={13}
                            py="2"
                            px="2"
                            borderRadius={6}
                            fontWeight="500"
                            lineHeight={'normal'}
                            bg="#f0f4f7"
                            color="#5d636a"
                            maxW="100%%"
                            whiteSpace="nowrap"
                            overflow="hidden"
                            textOverflow="ellipsis"
                            border="1px solid"
                            borderColor={'#ececef'}
                          >
                            {data.message_items.workflow[0].name}
                          </Text>
                        )}
                    </HStack>
                  )}
                </HStack>
              </VStack>
            )}
        </VStack>
      </HStack>
      <Text
        w="100%"
        pl="10"
        fontSize="13"
        lineHeight={'18px'}
        color="black"
        fontWeight="500"
        pt="2"
      >
        <chakra.span
          _hover={{
            textDecoration: 'underline',
            color: 'primary.presentable',
          }}
          onClick={handleProfileClick}
          cursor="pointer"
        >
          {senderData?.name}
        </chakra.span>
        <chakra.span fontWeight="normal" color="#73737d">
          ∙{dayjs(data?.created_at).format('hh:mm a')}
        </chakra.span>
      </Text>
      <HStack w="fit-content" mt="3">
        {data &&
          data.message_reactions &&
          data.message_reactions.length > 0 &&
          data.message_reactions?.map((el: any, key: number) => {
            let labelText = `You reacted with ${el.reaction}`;
            const allUsersId = el.users?.map((el: any) => el.id);
            const hasMyReaction = allUsersId.includes(appDetail?.id);

            const reactedUsersOtherThanMe = el.users?.filter(
              (el: any) => el.id !== appDetail?.id
            );
            if (reactedUsersOtherThanMe.length > 1) {
              labelText = `You & +${reactedUsersOtherThanMe.length} reacted with ${el.reaction}`;
            }
            if (reactedUsersOtherThanMe.length > 0) {
              labelText = `You & ${reactedUsersOtherThanMe[0].name} reacted with ${el.reaction}`;
            }
            return (
              <Tooltip
                hasArrow
                fontSize="12"
                bg="secondary.black"
                px="3"
                py="1"
                label={labelText}
                placement="top"
              >
                <Box
                  borderRadius={12}
                  bg={'#f0f4f7'}
                  px="2"
                  py="1"
                  ml={key !== 0 ? '4px !important' : '10'}
                  border={hasMyReaction ? '1.5px solid' : '1px solid'}
                  borderColor={hasMyReaction ? '#aeb5bb' : '#ececef'}
                  onClick={(e) => {
                    e.stopPropagation();
                    hasMyReaction && handleRemoveReaction(el.id);
                  }}
                  color="#5d636a"
                  _hover={{
                    bg: '#e5e9eb',
                  }}
                  w="fit-content"
                  cursor="pointer"
                >
                  <Text fontSize={'15'}>
                    {el.reaction}{' '}
                    <chakra.span fontSize={'13'} ml="4px !important">
                      {el.users?.length}
                    </chakra.span>
                  </Text>
                </Box>
              </Tooltip>
            );
          })}
      </HStack>
    </Box>
  );
};

export const ChatThreadComponent = React.memo(_ChatThreadComponent);
