import Icon, { IconProps } from '@chakra-ui/icon';
import { OmitCommonProps, As } from '@chakra-ui/react';
import React from 'react';

const _AfterEffectFormatIcon = (
  props: JSX.IntrinsicAttributes &
    OmitCommonProps<React.SVGProps<SVGSVGElement>, keyof IconProps> &
    IconProps &
    OmitCommonProps<any, keyof IconProps> & { as?: As<any> | undefined }
) => (
  <Icon viewBox="0 0 36 35" width={36} height={35} focusable="false" {...props}>
    <g fillRule="nonzero" fill="none">
      <path
        d="M6.357 0H29.54a6.352 6.352 0 0 1 6.356 6.357v22.286A6.352 6.352 0 0 1 29.541 35H6.357A6.352 6.352 0 0 1 0 28.643V6.357A6.352 6.352 0 0 1 6.357 0Z"
        fill="#00005B"
      />
      <path
        d="M14.419 20.94H8.855l-1.137 3.53a.262.262 0 0 1-.284.21H4.622c-.165 0-.21-.09-.165-.27l4.817-13.805c.044-.15.09-.284.15-.464.059-.314.089-.643.089-.972-.015-.075.045-.15.12-.165h3.874c.104 0 .179.045.194.12l5.46 15.256c.044.165 0 .24-.15.24h-3.126c-.105.015-.21-.06-.24-.165l-1.226-3.59v.075Zm-4.697-2.976h3.8c-.09-.314-.21-.688-.345-1.077-.134-.404-.269-.838-.403-1.287-.15-.463-.285-.912-.434-1.376-.15-.463-.284-.897-.404-1.33-.12-.42-.224-.809-.33-1.168h-.03c-.134.644-.298 1.287-.508 1.93-.224.718-.448 1.466-.688 2.214a23.284 23.284 0 0 1-.658 2.094ZM27.97 19.594H23.23c.06.464.209.927.463 1.331.27.404.643.718 1.092.898a4.652 4.652 0 0 0 1.915.374 8.998 8.998 0 0 0 1.555-.165c.464-.06.913-.18 1.331-.344.075-.06.12-.03.12.12v2.288c0 .06-.015.12-.03.18a.35.35 0 0 1-.104.104c-.48.21-.973.36-1.496.45-.703.134-1.406.194-2.124.179-1.137 0-2.094-.18-2.872-.524a5.036 5.036 0 0 1-1.885-1.42 5.489 5.489 0 0 1-1.032-1.96 7.634 7.634 0 0 1-.314-2.184c0-.808.12-1.6.374-2.378a6.104 6.104 0 0 1 1.122-2.05 5.42 5.42 0 0 1 1.81-1.42c.703-.344 1.54-.464 2.498-.464a5.403 5.403 0 0 1 2.318.464 4.12 4.12 0 0 1 1.57 1.196c.39.509.704 1.077.898 1.705.194.599.284 1.212.284 1.825 0 .36-.015.673-.03.957-.03.285-.045.494-.06.629-.014.104-.104.194-.209.194-.09 0-.254.015-.494.03a7.08 7.08 0 0 1-.867.045c-.344 0-.703-.06-1.092-.06ZM23.23 17.41h3.156c.389 0 .673 0 .852-.015a.731.731 0 0 0 .344-.12v-.149c0-.194-.03-.374-.09-.553a1.98 1.98 0 0 0-1.944-1.376 2.106 2.106 0 0 0-1.99 1.136 3.1 3.1 0 0 0-.328 1.077Z"
        fill="#99F"
      />
    </g>
  </Icon>
);

export const AfterEffectFormatIcon = React.memo(_AfterEffectFormatIcon);
