import React, { useState } from "react";
import "quill/dist/quill.snow.css";
import "../../style/p-quill.scss";
import MagicUrl from "quill-magic-url";
import "quill-mention";
import "quill-mention/dist/quill.mention.min.css";
import { useCallback } from "react";
import ReactQuill from "react-quill";

ReactQuill.Quill.register("modules/magicUrl", MagicUrl);

interface Props {
  onFocus?: () => void;
  onBlur?: () => void;
  mentionsData: { id: string; value: string }[];
  placeholder?: string;
  handleEnter: () => void;
  customToolBoxId?: string;
}

const _PresentableRichTextEditor = (
  props: Props,
  ref: React.Ref<ReactQuill> | undefined
) => {
  const [value, setValue] = useState("");
  const {
    onFocus,
    onBlur,
    mentionsData,
    placeholder,
    handleEnter,
    customToolBoxId = "#p-quill-custom-toolbar",
  } = props;

  return (
    <React.Fragment>
      <ReactQuill
        className="p-quill-container"
        theme="snow"
        onFocus={onFocus}
        onBlur={onBlur}
        value={value}
        ref={ref}
        onChange={setValue}
        placeholder={placeholder}
        formats={[
          "bold",
          "italic",
          "link",
          "strike",
          "script",
          "underline",
          "blockquote",
          "header",
          "indent",
          "list",
          "code-block",
          "mention",
        ]}
        modules={{
          toolbar: {
            container: customToolBoxId,
          },
          mention: {
            positioningStrategy: "fixed",
            defaultMenuOrientation: "top",
            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            mentionDenotationChars: ["@", "#"],
            source: useCallback(
              (searchTerm: any, renderList: any, mentionChar: any) => {
                if (searchTerm.length === 0) {
                  renderList(mentionsData, searchTerm);
                } else {
                  const matches = [];
                  for (let i = 0; i < mentionsData.length; i++)
                    if (
                      ~mentionsData[i].value
                        .toLowerCase()
                        .indexOf(searchTerm.toLowerCase())
                    )
                      matches.push(mentionsData[i]);
                  renderList(matches, searchTerm);
                }
              },
              [mentionsData]
            ),
          },
          magicUrl: true,
          keyboard: {
            bindings: {
              enter: {
                key: "enter",
                handler: useCallback((range: any, context: any) => {
                  handleEnter();
                  setValue("");
                  return false;
                }, []),
              },
            },
          },
        }}
      />
    </React.Fragment>
  );
};

const areEqual = (prevMovie: any, nextMovie: any) => {
  return true;
};

export const PresentableRichTextEditor = React.memo(
  React.forwardRef(_PresentableRichTextEditor),
  areEqual
);
