import React from 'react';
import { Center, Box } from '@chakra-ui/react';
import { ProfileIcon } from '../../svg/Icons/profile.icons';
import { ImageBlurLoader } from '../Library/LeftSideComponent/ItemCard/ImageBlurLoader';

interface Props {
  marginRight?: string | number;
  marginLeft?: string | number;
  size: string | number;
  borderRadius: string | number;
  name?: string | null;
  profileImg?: { location: string };
  profileColor: string;
  isOnline?: boolean;
  onLineStatusStyle?: React.CSSProperties | undefined;
}

export const ColorHexReg = /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i;

const _PresentableAvatar = (props: Props) => {
  const {
    marginRight,
    marginLeft,
    size,
    name,
    profileImg,
    profileColor,
    borderRadius,
    isOnline = false,
    onLineStatusStyle,
  } = props;

  const isUnassigned = !name;

  return (
    <React.Fragment>
      {!isUnassigned && !profileImg && (
        <Center
          bg={'primary.soft_peach'}
          mr={marginRight}
          ml={marginLeft}
          h={size}
          w={size}
          borderRadius={borderRadius}
          mt="0 !important"
          pos="relative"
        >
          <ProfileIcon height={size} borderRadius={borderRadius} width={size} />
          {isOnline && (
            <Box
              pos="absolute"
              bottom={'-1px'}
              right={-Number(size) / 6}
              borderRadius={'50%'}
              h={Number(size) / 3}
              w={Number(size) / 3}
              bg="#008b48"
              border={'2px solid white'}
              style={{ ...onLineStatusStyle }}
            />
          )}
        </Center>
      )}
      {!isUnassigned && profileImg && (
        <Box pos={'relative'} borderRadius={borderRadius}>
          {/* <Image
            boxSize={size}
            objectFit="cover"
            src={`${imageCDNBaseUrl}/?url=${profileImg?.location}&h=${
              Number(size) * 4
            }&w=${Number(size) * 4}&dpr=3&output=webp`}
            alt={name}
            borderRadius={borderRadius}
            mr={marginRight}
            ml={marginLeft}
            bg="border.gamma"
          /> */}

          <Box
            h={size}
            w={size}
            borderRadius={borderRadius}
            overflow={'hidden'}
            mr={marginRight}
            ml={marginLeft}
            bg={'primary.soft_peach'}
            >
            <ImageBlurLoader sourceUrl={profileImg?.location} />
          </Box>

          {isOnline && (
            <Box
              pos="absolute"
              bottom={'-1px'}
              right={-Number(size) / 6}
              borderRadius={'50%'}
              h={Number(size) / 3}
              w={Number(size) / 3}
              bg="#008b48"
              border={'2px solid white'}
              style={{ ...onLineStatusStyle }}
            />
          )}
        </Box>
      )}
      {isUnassigned && (
        <Center
          bg={'primary.soft_peach'}
          mr={marginRight}
          ml={marginLeft}
          h={size}
          w={size}
          borderRadius={borderRadius}
          mt="0 !important"
          pos="relative"
        >
          <ProfileIcon height={size} width={size} borderRadius={borderRadius} />
          {isOnline && (
            <Box
              pos="absolute"
              bottom={'-1px'}
              right={-Number(size) / 6}
              borderRadius={'50%'}
              h={Number(size) / 3}
              w={Number(size) / 3}
              bg="#008b48"
              border={'2px solid white'}
              style={{ ...onLineStatusStyle }}
            />
          )}
        </Center>
      )}
    </React.Fragment>
  );
};

export const PresentableAvatar = React.memo(_PresentableAvatar);
