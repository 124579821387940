export const baseUrl =
  process.env.NODE_ENV === 'production'
    ? 'https://api.makepresentable.com/api'
    : 'http://localhost:9000/api';

export const socketBaseUrl =
  process.env.NODE_ENV === 'production'
    ? 'https://api.makepresentable.com'
    : 'http://localhost:9000';

export const mixpanelBaseUrl =
  process.env.NODE_ENV === 'production'
    ? 'https://api.makepresentable.com/mp'
    : `http://localhost:3002/mp`;

export const baseRedirectUrl =
  process.env.NODE_ENV === 'production'
    ? 'https://makepresentable.com'
    : 'http://localhost:3003';

export const baseAppUrl =
  process.env.NODE_ENV === 'production'
    ? 'https://app.makepresentable.com'
    : 'http://localhost:9001';

export const imageCDNBaseUrl =
  process.env.NODE_ENV === 'production'
    ? 'https://images.weserv.nl'
    : 'http://localhost:5050';

export const canvasBlobBaseUrl =
  process.env.NODE_ENV === 'production'
    ? 'https://presentable.blob.core.windows.net/attachments'
    : 'https://presentable.blob.core.windows.net/attachments-testing';

export const videoMetaDataBaseUrl =
  process.env.NODE_ENV === 'production'
    ? 'https://presentable.blob.core.windows.net/test-ms'
    : 'https://presentable.blob.core.windows.net/test-ms';

export const storageContainerName =
  process.env.NODE_ENV === 'production' ? 'attachments' : 'attachments-testing';

export const livekitEndpoint =
  process.env.NODE_ENV === 'production'
    ? 'https://live.makepresentable.com'
    : 'http://localhost:7880';
