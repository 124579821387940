import { Box, CloseButton } from "@chakra-ui/react";
import {
  ChakraStylesConfig,
  Select,
  SelectComponentsConfig,
  SelectInstance,
} from "chakra-react-select";
import { isArray } from "lodash";
import React, { forwardRef, useCallback, useMemo } from "react";
import { ArrowDownIcon } from "../../svg/Icons/arrowDown.icons";

interface Props {
  width: number | string;
  options: any[];
  onChange: (a: any) => void;
  value: any;
  placeholder?: string;
  isMulti?: boolean;
  customChakraStyles?: ChakraStylesConfig<any, boolean, any> | undefined;
  isClearable?: boolean;
  isDisabled?: boolean;
  customComponents?: SelectComponentsConfig<any, boolean, any> | undefined;
  customFormattedOptionLabel?:
    | ((data: any, formatOptionLabelMeta: any) => React.ReactNode)
    | undefined;
  size?: "sm" | "lg" | "md" | undefined;
  menuIsOpen?: boolean | undefined;
  borderWidth?: string | number;
  clearIconSize?: "sm" | "lg" | "md" | undefined;
  isFilterType?: boolean;
  mr?: string;
  isSearchable?: boolean;
}

const _PresentableSelect = (
  props: Props,
  ref: React.Ref<SelectInstance<any, boolean, any>> | undefined
) => {
  const {
    width,
    options = [],
    onChange,
    value,
    placeholder,
    isMulti = false,
    customChakraStyles = {},
    isDisabled = false,
    isClearable = true,
    customComponents = {},
    customFormattedOptionLabel = undefined,
    menuIsOpen = undefined,
    size = "sm",
    clearIconSize = "md",
    borderWidth = 1,
    isFilterType = false,
    mr = "8px !important",
    isSearchable = true,
  } = props;

  const formattedOptions = useMemo(() => {
    let formattedVal = [] as any[];
    if (options && isArray(options)) {
      options?.forEach((el) => {
        if (el.options) {
          const otherOptions = el.options;
          delete el.op;
          formattedVal = [...formattedVal, ...otherOptions];
        }
        formattedVal = [...formattedVal, { ...el }];
      });
    }

    return formattedVal;
  }, [options]);

  const getValue = useCallback(() => {
    if (value && isArray(options)) {
      return isMulti
        ? value
        : formattedOptions.filter((el) => el.value === value)[0];
    } else {
      return null;
    }
  }, [formattedOptions, isMulti, options, value]);

  const ClearIndicator = ({ innerRef, innerProps }: any) => {
    return (
      <CloseButton
        size={clearIconSize}
        ref={innerRef}
        {...innerProps}
        mr="1"
        color={"secondary.smoke"}
        _hover={{
          path: {
            fill: "black",
          },
          bg: "rgba(0, 0, 0, 0.06)",
        }}
      />
    );
  };

  const DropdownIndicator = ({ innerRef, innerProps, hasValue }: any) => {
    return !hasValue ? (
      <ArrowDownIcon
        fill="secondary.smoke"
        ref={innerRef}
        {...innerProps}
        mr="1"
      />
    ) : null;
  };

  return (
    <Box
      w={width}
      cursor={"pointer"}
      borderRadius="6"
      ml="0 !important"
      mr={mr}
    >
      <Select
        ref={ref}
        chakraStyles={{
          control: (provided, state) => ({
            ...provided,
            borderColor: state.hasValue
              ? isFilterType
                ? "black!important"
                : "inherit"
              : "secondary.peebble",
            borderWidth: borderWidth,
            // bg:
            //   state.hasValue && isFilterType ? 'primary.soft_peach' : undefined,
            fontWeight: state.hasValue && isFilterType ? 600 : "normal",
            minH: isFilterType ? undefined : size ? undefined : "44px",
            _focus: {
              borderColor:
                isFilterType && !state.hasValue && !state.menuIsOpen
                  ? "inherit"
                  : "black",
            },
            color: state.hasValue && isFilterType ? "black" : "inherit",
          }),
          option: (provided, state) => {
            return {
              ...provided,
              backgroundColor: state.isSelected
                ? "primary.presentable"
                : "transparent",
              color: state.isSelected ? "white" : "black",
              _hover: {
                backgroundColor: state.isSelected
                  ? "primary.presentable"
                  : "primary.soft_peach",
                color: state.isSelected
                  ? "white !important"
                  : "black !important",
              },
              _active: {
                color: "white !important",
                bg: "primary.presentable",
              },
              fontSize: "13px",
            };
          },
          dropdownIndicator: (provided, state) => ({
            ...provided,
            display: state.hasValue ? "none" : "flex",
            padding: "0px !important",
          }),
          indicatorSeparator: (provided, state) => ({
            ...provided,
            display: "none",
          }),
          menuList: (provided, state) => ({
            ...provided,
            minW: "initial",
            borderRadius: "6",
            boxShadow: "2px 2px 8px 0 rgba(0, 0, 0, 0.2)",
            zIndex: 3,
            py: 0,
          }),
          menu: (provided, state) => ({
            ...provided,
            overflow: "none",
            zIndex: 3,
            border: "none",
          }),
          inputContainer: (provided, state) => ({
            ...provided,
            paddingLeft: 0,
          }),
          groupHeading: (provided) => ({
            ...provided,
            pl: "0.6rem !important",
            bg: "secondary.pearl",
            fontSize: "14",
            mt: "0 !important",
          }),
          placeholder: (provided) => ({
            ...provided,
            color: "secondary.black",
            opacity: 0.5,
          }),
          noOptionsMessage: (provided) => ({
            ...provided,
            fontSize: 14,
          }),
          ...customChakraStyles,
        }}
        name="colors"
        options={options}
        placeholder={placeholder}
        closeMenuOnSelect={true}
        size={size}
        isClearable={isClearable}
        isMulti={isMulti}
        isSearchable={isSearchable}
        onChange={onChange}
        components={{
          DropdownIndicator,
          ClearIndicator,
          ...customComponents,
        }}
        value={getValue()}
        selectedOptionColor="primary.presentable"
        hasStickyGroupHeaders
        tabIndex={2}
        isDisabled={isDisabled}
        formatOptionLabel={customFormattedOptionLabel}
        menuIsOpen={menuIsOpen}
      />
    </Box>
  );
};

export const PresentableSelect = React.memo(forwardRef(_PresentableSelect));
