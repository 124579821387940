import Icon, { IconProps } from '@chakra-ui/icon';
import { OmitCommonProps, As } from '@chakra-ui/react';
import React from 'react';

const _PPTFormatIcon = (
  props: JSX.IntrinsicAttributes &
    OmitCommonProps<React.SVGProps<SVGSVGElement>, keyof IconProps> &
    IconProps &
    OmitCommonProps<any, keyof IconProps> & { as?: As<any> | undefined }
) => (
  <Icon viewBox="0 0 38 35" width={38} height={35} focusable="false" {...props}>
    <defs>
      <linearGradient
        x1="17.372%"
        y1="-6.51%"
        x2="82.628%"
        y2="106.51%"
        id="qwa"
      >
        <stop stopColor="#CA4C28" offset="0%" />
        <stop stopColor="#C5401E" offset="50%" />
        <stop stopColor="#B62F14" offset="100%" />
      </linearGradient>
    </defs>
    <g fillRule="nonzero" fill="none">
      <path
        d="M22.75 19.25 20.125 0h-.196C10.384.028 2.653 7.759 2.625 17.304v.196l20.125 1.75Z"
        fill="#ED6C47"
      />
      <path
        d="M20.321 0h-.196v17.5l8.75 3.5 8.75-3.5v-.196C37.597 7.759 29.866.028 20.321 0Z"
        fill="#FF8F6B"
      />
      <path
        d="M37.625 17.5v.192c-.027 9.548-7.76 17.28-17.308 17.308h-.384c-9.548-.027-17.28-7.76-17.308-17.308V17.5h35Z"
        fill="#D35230"
      />
      <path
        d="M21 8.601v18.673a1.61 1.61 0 0 1-1.601 1.601H6.755c-.245-.28-.481-.578-.7-.875a17.162 17.162 0 0 1-3.43-10.308v-.385a17.11 17.11 0 0 1 2.817-9.432c.193-.298.394-.595.613-.875h13.344c.881.007 1.594.72 1.601 1.601Z"
        fill="#000"
        opacity={0.1}
      />
      <path
        d="M20.125 9.476V28.15a1.61 1.61 0 0 1-1.601 1.601H7.55a14.966 14.966 0 0 1-.796-.875c-.245-.28-.481-.578-.7-.875a17.163 17.163 0 0 1-3.43-10.308v-.384a17.11 17.11 0 0 1 2.817-9.433h13.082c.881.007 1.594.72 1.601 1.601Z"
        fill="#000"
        opacity={0.2}
      />
      <path
        d="M20.125 9.476V26.4A1.614 1.614 0 0 1 18.524 28H6.055a17.162 17.162 0 0 1-3.43-10.307v-.385a17.11 17.11 0 0 1 2.817-9.433h13.082c.881.007 1.594.72 1.601 1.601Z"
        fill="#000"
        opacity={0.2}
      />
      <path
        d="M19.25 9.476V26.4A1.614 1.614 0 0 1 17.649 28H6.055a17.162 17.162 0 0 1-3.43-10.307v-.385a17.11 17.11 0 0 1 2.817-9.433H17.65c.881.007 1.594.72 1.601 1.601Z"
        fill="#000"
        opacity={0.2}
      />
      <path
        d="M1.604 7.875h16.042c.886 0 1.604.718 1.604 1.604V25.52c0 .886-.718 1.604-1.604 1.604H1.604A1.604 1.604 0 0 1 0 25.521V9.48c0-.886.718-1.604 1.604-1.604Z"
        fill="url(#a)"
      />
      <path
        d="M9.8 12.153a4.41 4.41 0 0 1 2.92.856 3.062 3.062 0 0 1 1.018 2.479 3.45 3.45 0 0 1-.502 1.852 3.35 3.35 0 0 1-1.424 1.258 4.834 4.834 0 0 1-2.14.45h-2.03v3.771H5.564V12.153H9.8Zm-2.16 5.266H9.43a2.322 2.322 0 0 0 1.581-.472 1.75 1.75 0 0 0 .535-1.38c0-1.175-.683-1.763-2.05-1.763H7.642v3.615Z"
        fill="#FFF"
      />
    </g>
  </Icon>
);

export const PPTFormatIcon = React.memo(_PPTFormatIcon);
