import { chakra, IconButton, useToast } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { batch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '../../../store';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  addChatAttachmentData,
  initiateChatAttachmentData,
  updateChatAttachmentDataProgress,
  updateChatAtttachmentFileDataSuccess,
} from '../../../store/project';
import { AttachmentChatIcon } from '../../../svg/Chat/attachment.icon';
import { UploadFileRelationEnum } from '../../../type/types';
import { imageFormatType } from '../../../utils/upload.utils';
import { uploadMultipleFile } from '../../../utils/uploadMultiple.utils';
import { toastConfig } from '../../Alert/CustumToastComponent';

interface Props {
  isFocussed: boolean;
}

const _ChatAddAttachmentButton = (props: Props) => {
  const { isFocussed } = props;
  const dispatch = useAppDispatch();
  const toast = useToast();
  const params = useParams<{
    workspace_id: string;
    project_id: string;
  }>();

  const chatAttachmentUploadData = useAppSelector(
    (state: RootState) => state.project.chatAttachmentUploadData
  );

  const fileUploadUpdateHandler = useCallback(
    (progress: number, fileIds: number[]) => {
      dispatch(
        updateChatAttachmentDataProgress({ progress: progress, ids: fileIds })
      );
    },
    [dispatch]
  );

  const fileUploadSuccessHandler = useCallback(
    (data: any, fileIds: number[]) => {
      batch(() => {
        dispatch(
          updateChatAtttachmentFileDataSuccess({
            data: data.data,
            ids: fileIds,
          })
        );
      });
      toast(toastConfig('Files uploaded sucessfully'));
    },
    [dispatch, toast]
  );

  const uploadFiles = useCallback(
    (files: any, fileIds: number[]) => {
      uploadMultipleFile(
        {
          workspace_id: params.workspace_id as string,
          project_id: params.project_id as string,
          payload: files,
          file_relation_type: UploadFileRelationEnum.chat,
        },
        (progress) => fileUploadUpdateHandler(progress, fileIds),
        (data) => fileUploadSuccessHandler(data, fileIds)
      );
    },
    [
      fileUploadSuccessHandler,
      fileUploadUpdateHandler,
      params.workspace_id,
      params.project_id,
    ]
  );

  const readFileAsync = useCallback((file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (files) => {
      let fileIds = [] as number[];
      const fileData = files.map(async (el) => {
        /* Check the file format and file size :
            Only image file of size < 5MB will be converted to data/base64.
            For the rest, we will show the icon
          */
        const isReadableFile =
          imageFormatType.includes(el.type) &&
          el.type !== 'image/svg+xml' &&
          el.size < 1024 * 1024 * 5;

        const contentBuffer = isReadableFile
          ? await readFileAsync(el)
          : undefined;
        const id = Math.random();
        fileIds.push(id);

        return {
          ...el,
          originalname: el.name,
          mimetype: el.type,
          size: el.size,
          progress: 0,
          type: 'RAW',
          imgUrl: contentBuffer,
          id: id,
        };
      });

      const results = await Promise.all(fileData);

      if (chatAttachmentUploadData && chatAttachmentUploadData.length) {
        batch(() => {
          dispatch(addChatAttachmentData(results));
        });
      } else {
        batch(() => {
          dispatch(initiateChatAttachmentData(results));
        });
      }

      await uploadFiles(files, fileIds);
    },
  });

  return (
    <React.Fragment>
      <IconButton
        variant="p_secondary"
        className="p-chat-footer-control"
        icon={<AttachmentChatIcon fill="secondary.lead" height="4" width="4" />}
        h="1.75rem"
        aria-label="Attachment"
        size="sm"
        border={'none'}
        ml="0 !important"
        {...getRootProps()}
        bg={isFocussed ? '#f0f4f7' : 'secondary.pearl'}
        _hover={{
          bg: undefined,
        }}
        _focusWithin={{
          boxShadow: 'none',
        }}
      />
      <chakra.input {...getInputProps()} />
    </React.Fragment>
  );
};

export const ChatAddAttachmentButton = React.memo(_ChatAddAttachmentButton);
