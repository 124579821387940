import Icon, { IconProps } from '@chakra-ui/icon';
import { OmitCommonProps, As } from '@chakra-ui/react';
import React from 'react';

const _ProjectOtherIcon = (
  props: JSX.IntrinsicAttributes &
    OmitCommonProps<React.SVGProps<SVGSVGElement>, keyof IconProps> &
    IconProps &
    OmitCommonProps<any, keyof IconProps> & { as?: As<any> | undefined }
) => (
  <Icon width={96} height={96} viewBox="0 0 96 96" focusable="false" {...props}>
    <defs>
      <path id="cv" d="M0 0h96v96H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <use fill="#FB75AD" xlinkHref="#cv" />
      <path
        fill="#FFDFED"
        d="M10 66h76v15H10zM10 41h76v15H10zM10 16h76v15H10z"
      />
      <circle fill="#DE025E" cx={48} cy={48} r={25} />
    </g>
  </Icon>
);

export const ProjectOtherIcon = React.memo(_ProjectOtherIcon);
