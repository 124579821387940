import Icon, { IconProps } from '@chakra-ui/icon';
import { OmitCommonProps, As } from '@chakra-ui/react';
import React from 'react';

const _ProjectCreativeIcon = (
  props: JSX.IntrinsicAttributes &
    OmitCommonProps<React.SVGProps<SVGSVGElement>, keyof IconProps> &
    IconProps &
    OmitCommonProps<any, keyof IconProps> & { as?: As<any> | undefined }
) => (
  <Icon width={96} height={96} viewBox="0 0 96 96" focusable="false" {...props}>
    <defs>
      <path id="wq" d="M0 0h96v96H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <use fill="#8A5D19" xlinkHref="#wq" />
      <path fill="#C08C3D" d="M24 27h48v42H24z" />
      <path fill="#FF9A00" d="m48 31 17 34H31z" />
    </g>
  </Icon>
);

export const ProjectCreativeIcon = React.memo(_ProjectCreativeIcon);
