import Icon, { IconProps } from '@chakra-ui/icon';
import { OmitCommonProps, As } from '@chakra-ui/react';
import React from 'react';

const _ProjectPrintIcon = (
  props: JSX.IntrinsicAttributes &
    OmitCommonProps<React.SVGProps<SVGSVGElement>, keyof IconProps> &
    IconProps &
    OmitCommonProps<any, keyof IconProps> & { as?: As<any> | undefined }
) => (
  <Icon width={96} height={96} viewBox="0 0 96 96" focusable="false" {...props}>
    <defs>
      <path id="dfqqqf" d="M0 0h96v96H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <use fill="#0BAE57" xlinkHref="#dfqqqf" />
      <path fill="#4DEE96" d="M57 27h19v42H57zM20 27h19v42H20z" />
      <g fill="#4DEE96">
        <path d="M57 27h19v42H57zM20 27h19v42H20z" />
      </g>
      <circle fill="#185C37" cx={48} cy={48} r={15} />
    </g>
  </Icon>
);

export const ProjectPrintIcon = React.memo(_ProjectPrintIcon);
