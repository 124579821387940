import Icon, { IconProps } from '@chakra-ui/icon';
import { OmitCommonProps, As } from '@chakra-ui/react';
import React from 'react';

const _SketchFormatIcon = (
  props: JSX.IntrinsicAttributes &
    OmitCommonProps<React.SVGProps<SVGSVGElement>, keyof IconProps> &
    IconProps &
    OmitCommonProps<any, keyof IconProps> & { as?: As<any> | undefined }
) => (
  <Icon viewBox="0 0 31 35" width={31} height={35} focusable="false" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h32.813v35H0V0Z" />
      <path
        d="M3.232 35C1.45 35 0 33.347 0 31.315V3.688C0 1.653 1.45 0 3.232 0h16.752c.786 0 1.546.325 2.136.918l7.548 7.598c.696.7 1.094 1.707 1.094 2.764v20.035c0 2.032-1.45 3.685-3.232 3.685H3.232Z"
        fill="#FF9F21"
      />
      <path
        d="M8.744 11.598 6.657 14.91a1.414 1.414 0 0 0 .042 1.572l7.323 10.328a1.414 1.414 0 0 0 2.307 0l7.323-10.328a1.414 1.414 0 0 0 .043-1.572l-2.088-3.312c-.259-.411-.71-.66-1.196-.66H9.941c-.486 0-.938.249-1.197.66Z"
        fill="#FFF"
      />
    </g>
  </Icon>
);

export const SketchFormatIcon = React.memo(_SketchFormatIcon);
