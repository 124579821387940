import { VStack, Center, Text, HStack } from '@chakra-ui/react';
import { useContext, useMemo, useRef, useState } from 'react';
import { AppDetailContext } from '../../Context/AppDetailContext';
import { getItemsTypeOptionsData } from '../../utils/items';
import { getProjectTypeOptionsData } from '../../utils/project';
import { ItemsTypeEnum } from '../Library/helper';
import { Document, Page, pdfjs } from 'react-pdf';
import { ImageBlurLoader } from '../Library/LeftSideComponent/ItemCard/ImageBlurLoader';
import { VideoPreviewComponent } from '../Library/LeftSideComponent/ItemCard/VideoPreviewComponent';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface Props {
  data: { [key: string]: any };
  isSelected: boolean;
  handleCardSelect: () => void;
  ml?: string;
  projectData: { [key: string]: any };
  projectCount: number;
}

export const AddItemsToFolderCard = (props: Props) => {
  const [isPDFLoaded, updateIsPDFLoaded] = useState<boolean>(false);
  const { appDetail } = useContext(AppDetailContext);
  const {
    ml,
    data,
    isSelected = false,
    handleCardSelect,
    projectData,
    projectCount,
  } = props;

  const itemRef = useRef<HTMLDivElement>(null);

  const iconData = useMemo(() => {
    return getItemsTypeOptionsData(data?.sub_type, '36px', '36px');
  }, [data]);

  const itemName = useMemo(() => {
    if (data?.name) {
      return data.name;
    } else if (data?.type === 'FILE') {
      return data.attachment.originalname;
    } else if (data?.type === 'LINK') {
      const hasProtocol = data?.content.includes('http');
      const { hostname } = new URL(
        hasProtocol ? data?.content : `https://${data?.content}`
      );
      return hostname;
    } else {
      return 'Item';
    }
  }, [data]);

  const showImage = useMemo(() => {
    if (
      data &&
      data.type === 'FILE' &&
      data?.sub_type === ItemsTypeEnum.imageFile
    ) {
      return true;
    } else if (
      data &&
      data.type === 'LINK' &&
      data?.meta_image &&
      data?.meta_image.length > 0
    ) {
      return true;
    }

    return false;
  }, [data]);

  const isSharingEnabled = useMemo(() => {
    if (appDetail?.id === data.created_by) {
      return true;
    } else if (
      appDetail?.id !== data.created_by &&
      data.access &&
      data.access === 'EVERYONE'
    ) {
      return true;
    } else {
      return false;
    }
  }, [appDetail?.id, data.access, data.created_by]);

  return (
    <VStack
      width={'200px'}
      ml={ml}
      mb="32px !important"
      borderRadius="8"
      cursor={'pointer'}
      className="p-recent-session-card"
      onClick={() => {
        isSharingEnabled && handleCardSelect();
      }}
      opacity={isSharingEnabled ? 1 : 0.6}
      boxShadow="0px 0px 3px 0 rgba(0, 0, 0, 0.05)"
      ref={itemRef}
    >
      <Center
        borderTopRadius="6"
        w="100%"
        h="176px"
        bg={iconData?.bg}
        pos="relative"
      >
        {data?.sub_type === ItemsTypeEnum.videoFile &&
          data.videoItemMetadata &&
          data.videoItemMetadata?.sprite && (
            <VideoPreviewComponent
              url={data.videoItemMetadata?.sprite}
              containerWidth={200}
              yPosition={-9}
              ref={itemRef}
            />
          )}
        {data.sub_type === ItemsTypeEnum.pdfFile && (
          <Center
            w="100%"
            display={isPDFLoaded ? 'flex' : 'none'}
            height={'100%'}
            overflow={'hidden'}
          >
            <Document
              file={data.attachment.location}
              onLoadSuccess={(val: any) => {
                updateIsPDFLoaded(true);
              }}
              loading=""
            >
              <Page height={176} width={196} pageNumber={1} />
            </Document>
          </Center>
        )}
        {showImage && (
          <ImageBlurLoader
            sourceUrl={
              data && data.type === 'LINK'
                ? data.meta_image
                : data.attachment.location
            }
          />
        )}

        {!showImage &&
          data?.sub_type !== ItemsTypeEnum.videoFile &&
          (data?.sub_type !== ItemsTypeEnum.pdfFile || !isPDFLoaded) && (
            <Center w="100%" height={'100%'}>
              {iconData?.icon}
            </Center>
          )}

        {!showImage &&
          data?.sub_type === ItemsTypeEnum.videoFile &&
          (!data.videoItemMetadata ||
            (data.videoItemMetadata && !data.videoItemMetadata?.sprite)) && (
            <VStack>
              <Center
                w="100%"
                height={'100%'}
                alignItems={'center'}
                flexDir="column"
                justifyContent={'center'}
                borderTopRadius={8}
                pt="6"
              >
                {iconData?.icon}
              </Center>
              <Text mt="2" fontSize={'12'}>
                Generating preview ...
              </Text>
            </VStack>
          )}

        {projectData && Object.keys(projectData).length > 0 && (
          <HStack
            w="160px"
            borderRadius={6}
            bottom={3}
            pos="absolute"
            bg="black"
            p="2"
          >
            <Center
              bg="#62009e"
              h="14px"
              w="14px"
              borderRadius={2}
              overflow="hidden"
            >
              {getProjectTypeOptionsData(
                projectData?.icon_number ? projectData?.icon_number : '0',
                '14px',
                '14px'
              )}
            </Center>
            <Text
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              w="100px"
              fontSize="13px"
              color={'white'}
            >
              {projectData?.name}
            </Text>
            {projectCount > 1 && (
              <Center
                fontSize={10}
                fontWeight="600"
                w="5"
                bg="white"
                color={'black'}
                borderRadius="10px"
                h="5"
                // onClick={(e) => {
                //   e.stopPropagation();
                //   dispatch(updateItemCardClick(data?.id));
                // }}
              >
                +{projectCount}
              </Center>
            )}
          </HStack>
        )}
      </Center>
      <VStack
        mt="0 !important"
        w="100%"
        px="10px"
        pt="12px"
        pb="12px"
        alignItems={'flex-start'}
        borderBottomRadius="6px"
        bg={isSelected ? '#e4d4c7' : 'primary.soft_peach'}
      >
        <Text
          fontSize="14px"
          color={'black'}
          fontWeight="600"
          whiteSpace="nowrap"
          overflow="hidden"
          w="100%"
          textOverflow="ellipsis"
        >
          {itemName}
        </Text>
        <Text fontSize="14px" mt="0 !important" color={'#5d636a'}>
          {iconData?.label}
        </Text>
      </VStack>
    </VStack>
  );
};
