import Icon, { IconProps } from '@chakra-ui/icon';
import { OmitCommonProps, As } from '@chakra-ui/react';
import React from 'react';

const _TeamsMeetingIcon = (
  props: JSX.IntrinsicAttributes &
    OmitCommonProps<React.SVGProps<SVGSVGElement>, keyof IconProps> &
    IconProps &
    OmitCommonProps<any, keyof IconProps> & { as?: As<any> | undefined }
) => (
  <Icon width={27} height={26} viewBox="0 0 27 26" focusable="false" {...props}>
    <g fillRule="nonzero" fill="none">
      <path
        d="M22.253 9.927c.215.216.47.387.75.506a2.37 2.37 0 0 0 1.83 0 2.37 2.37 0 0 0 1.255-1.255 2.37 2.37 0 0 0 0-1.83 2.37 2.37 0 0 0-1.255-1.256 2.37 2.37 0 0 0-1.83 0 2.37 2.37 0 0 0-1.256 1.255 2.37 2.37 0 0 0 0 1.83c.12.28.292.535.506.75ZM21.564 12.111v7.09h1.181c.407-.003.81-.04 1.21-.116.4-.07.786-.205 1.144-.4.338-.182.63-.44.854-.752.235-.346.353-.758.336-1.176v-4.646h-4.725ZM17.735 9.14c.314.001.625-.062.914-.185A2.369 2.369 0 0 0 19.904 7.7a2.365 2.365 0 0 0-.002-1.831 2.37 2.37 0 0 0-1.255-1.255c-.29-.124-.6-.187-.915-.185a2.256 2.256 0 0 0-.909.185c-.214.091-.281.21-.576.354V8.6c.296.145.362.263.576.355.288.125.599.186.912.184ZM16.247 21.075c.296.064.402.114.612.142.23.033.461.05.693.051.358-.002.715-.04 1.065-.115a3.147 3.147 0 0 0 1.01-.394 2.32 2.32 0 0 0 .753-.747 2.15 2.15 0 0 0 .297-1.175v-8.203h-4.43v10.44ZM0 23.336l15.36 2.658V0L0 2.659z"
        fill="#5558AF"
      />
      <path
        fill="#FFF"
        d="m11.225 7.976-7.09.431v1.635l2.66-.107v7.582l1.771.103V9.866l2.659-.118z"
      />
    </g>
  </Icon>
);

export const TeamsMeetingIcon = React.memo(_TeamsMeetingIcon);
