import { useInfiniteQuery, UseQueryOptions, useQuery } from 'react-query';
import axios from 'axios';
import { CustomRQResponseType, QuerykeyType } from '../../type/types';
import { baseUrl } from '../../utils/config';

const getAllTeamData = async (
  workspace_id: string,
  { pageParam = 1, queryKey }: QuerykeyType,
  params: any
) => {
  const updatedParams = { ...params };

  const { data } = await axios.get<any, CustomRQResponseType>(
    `${baseUrl}/workspace/${workspace_id}/team/pg-team?per_page=50&page=${pageParam}`,
    {
      headers: {
        Authorization: localStorage.getItem('p-auth') || 'TOKEN',
      },
      params: updatedParams,
    }
  );
  return data;
};

export const usePaginatedTeamData = <T extends any = CustomRQResponseType>(
  workspace_id: string,
  params?: any,
  options?: UseQueryOptions<T, unknown, CustomRQResponseType>
) => {
  return useInfiniteQuery(
    ['teams-pg', workspace_id, params],
    (query) => getAllTeamData(workspace_id, query, params),
    {
      retry: 1,
      getNextPageParam: (page) => {
        return page.data.current_page === page.data.last_page ||
          page.data.last_page === 0
          ? undefined
          : page.data.current_page + 1;
      },
    }
  );
};

const getTeamMemberProjectData = async (
  { pageParam = 1, queryKey }: QuerykeyType,
  member_id: string | undefined,
  workspace_id: string,
  params: any
) => {
  const { data } = await axios.get<any, CustomRQResponseType>(
    `${baseUrl}/workspace/${workspace_id}/project/member/${member_id}?per_page=50&page=${pageParam}`,
    {
      headers: {
        Authorization: localStorage.getItem('p-auth') || 'TOKEN',
      },
      params,
    }
  );
  return data;
};

export const useTeamMemberProjectData = <T extends any = CustomRQResponseType>(
  workspace_id: string,
  member_id: string | undefined,
  params?: any,
  options?: UseQueryOptions<T, unknown, CustomRQResponseType>
) => {
  return useInfiniteQuery<any>(
    ['team_memeber_project', member_id, params],
    (query) => getTeamMemberProjectData(query, member_id, workspace_id, params),
    {
      retry: 1,
      getNextPageParam: (page) => {
        return page.data.current_page === page.data.last_page ||
          page.data.last_page === 0
          ? undefined
          : page.data.current_page + 1;
      },
      enabled: !!member_id,
    }
  );
};

const getAllAccountTeamMemberDataNonPaginated = async (
  workspace_id: string
) => {
  const { data } = await axios.get<any, CustomRQResponseType>(
    `${baseUrl}/workspace/${workspace_id}/team`,
    {
      headers: {
        Authorization: localStorage.getItem('p-auth') || 'TOKEN',
      },
    }
  );
  return data;
};

export const useAllAccountTeamMemberDataNonPaginated = <
  T extends any = CustomRQResponseType
>(
  workspace_id: string,
  options?: UseQueryOptions<T, unknown, CustomRQResponseType>
) => {
  return useQuery<any>(
    ['all_team_members', workspace_id],
    () => getAllAccountTeamMemberDataNonPaginated(workspace_id),
    {
      retry: 1,
      ...options,
    }
  );
};
