import React, { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { useUserData, useWorkspaceUserData } from '../hooks/userData.hook';
import { setUserAuthToken } from '../store/user';
import { RootState } from '../store';
import { useAllUnseenConnectCount } from '../apiHooks/ConnectNotification/read.hook';
import { initializeUnseenConnectsData } from '../store/notification';
import { ConnectsTabsTypeEnum } from '../store/connect';

interface Props {
  children: React.ReactNode;
}

export const AppDetailContext = React.createContext<{
  appDetail: { [key: string]: any };
  isLoadingAppDetail: boolean;
  selectedWorkspaceData: { [key: string]: any };
}>({
  appDetail: {},
  isLoadingAppDetail: true,
  selectedWorkspaceData: {},
});

export const AppDetailContextComponent = (props: Props) => {
  const { children } = props;
  const dispatch = useAppDispatch();
  const selectedWorkspaceId = useAppSelector(
    (state: RootState) => state.user.selectedWorkspaceId
  );

  const selectedConnectTab = useAppSelector(
    (state: RootState) => state.connects.selectedConnectTab
  );

  const activeFilter = useAppSelector(
    (state: RootState) => state.connects.activeFilter
  );

  const closedFilter = useAppSelector(
    (state: RootState) => state.connects.closedFilter
  );

  const requestFilter = useAppSelector(
    (state: RootState) => state.connects.requestFilter
  );

  const unseenParams = useMemo(() => {
    if (selectedConnectTab === ConnectsTabsTypeEnum.active) {
      return activeFilter;
    } else if (selectedConnectTab === ConnectsTabsTypeEnum.closed) {
      return closedFilter;
    } else if (selectedConnectTab === ConnectsTabsTypeEnum.request) {
      return requestFilter;
    } else {
      return {};
    }
  }, [activeFilter, closedFilter, requestFilter, selectedConnectTab]);

  const { data: unseenConnectData } = useAllUnseenConnectCount(
    selectedWorkspaceId,
    { ...unseenParams },
    {
      enabled: !!(selectedWorkspaceId && selectedWorkspaceId.length > 0),
      onSuccess: (data) => {
        if (data.success) {
          dispatch(
            initializeUnseenConnectsData({
              data: data.data.overall,
              dataByConnectState: data.data.overall_by_connect_state,
            })
          );
        }
      },
    }
  );

  useEffect(() => {
    const token = localStorage.getItem('p-auth');
    if (token && token !== null && token.length > 0) {
      dispatch(setUserAuthToken(localStorage.getItem('p-auth')));
    }
  }, [dispatch]);

  const { data, status } = useUserData();

  const { data: workspaceData } = useWorkspaceUserData(selectedWorkspaceId, {
    enabled: !!(
      status === 'success' &&
      selectedWorkspaceId &&
      selectedWorkspaceId.length > 0
    ),
  });

  return (
    <AppDetailContext.Provider
      value={{
        appDetail: data?.data ? data.data : {},
        isLoadingAppDetail: status !== 'success',
        selectedWorkspaceData: workspaceData?.data ? workspaceData.data : {},
      }}
    >
      {children}
    </AppDetailContext.Provider>
  );
};
