import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define a type for the slice state

interface connectDetailItemsType {
  itemFilters: { [key: string]: any };
}

// Define the initial state using that type
const initialState: connectDetailItemsType = {
  itemFilters: {},
};

export const connectDetailItemsSlice = createSlice({
  name: 'connectDetailItems',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    updateConnectDetailItemsFilter: (
      state,
      action: PayloadAction<{ [key: string]: any[] }>
    ) => {
      state.itemFilters = action.payload;
    },
  },
});

export const { updateConnectDetailItemsFilter } =
  connectDetailItemsSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value;

export default connectDetailItemsSlice.reducer;
