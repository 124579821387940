import React from 'react';

interface Props {
  lowQualitySrc: string;
  highQualitySrc: string;
  enabled: boolean;
}

const useProgressiveImg = (props: Props) => {
  const { lowQualitySrc, highQualitySrc } = props;
  const [src, setSrc] = React.useState(lowQualitySrc);

  React.useEffect(() => {
    setSrc(lowQualitySrc);
    const img = new Image();
    img.src = highQualitySrc;

    img.onprogress = (e) => {
      console.log(e, 'onprogress');
    };

    img.onload = () => {
      setSrc(highQualitySrc);
    };
  }, [lowQualitySrc, highQualitySrc]);
  return [src, !!(src === lowQualitySrc)];
};
export default useProgressiveImg;
