import { DraggableStateSnapshot, DropAnimation } from 'react-beautiful-dnd';

export const mapValueWithLabel = (
  list: {
    value: string | number;
    label: string;
  }[],
  value: string
) => {
  let result = value;
  list.forEach((el) => {
    if (el.value === value) {
      result = el.label;
    }
  });
  return result;
};

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const detectURLs = (rawHTML: string) => {
  var doc = document.createElement('html');
  doc.innerHTML = rawHTML;
  var links = doc.getElementsByTagName('a');
  var urls = [];

  for (var i = 0; i < links.length; i++) {
    links[i].getAttribute('href') &&
      urls.push(links[i].getAttribute('href') as string);
  }

  const val: string[] = urls.filter(
    (el) =>
      el !== null &&
      !el.includes('mailto:') &&
      !el.includes('tel:') &&
      !el.includes('zoom.us/u')
  );

  return val;
};

export const replaceURLs = (message: string) => {
  if (!message) return;

  var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
  return message.replace(urlRegex, function(url) {
    var hyperlink = url;
    if (!hyperlink.match('^https?://')) {
      hyperlink = 'http://' + hyperlink;
    }
    return (
      '<a class="p-chat-link" href="' +
      hyperlink +
      '" target="_blank" rel="noopener noreferrer">' +
      url +
      '</a>'
    );
  });
};

export const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const numberInRange = (x: number, min: number, max: number) => {
  return (x - min) * (x - max) <= 0;
};

export const isStringNumber = (val: string) => {
  const isnum = /^\d+$/.test(val);

  return isnum;
};

export const orderLinkedListArray = (
  array: { [key: string]: any }[],
  head: string
) => {
  let finalArray = [] as { [key: string]: any }[];
  const map = {} as { [key: string]: any };
  array.forEach((el) => {
    map[el.id] = el;
  });

  let nextId = head;
  let count = 0;
  while (count < array.length) {
    const element = map[nextId];
    finalArray.push(element);
    nextId = element.next_tag_id;
    count++;
  }

  return finalArray;
};

// a little function to help us with reordering the result
export const reorderDNDData = (
  list: any,
  startIndex: number,
  endIndex: number
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const validURL = (str: string) => {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(str);
};

export const getSize = (text: string) => {
  let size = 0;

  // create the temporary Element
  const ruler = document.createElement('p');
  ruler.style.width = 'auto';
  ruler.style.position = 'absolute';
  ruler.style.whiteSpace = 'nowrap';
  ruler.innerText = text;

  // Adding the element as a child to myElement.
  // it will be added to the DOM
  document.body.appendChild(ruler);

  /**
   * Do the calculations you need to do
   */

  size = ruler.clientWidth;

  // clean up after yourself
  document.body.removeChild(ruler);

  return size;
};

export const getStyle = (style: any, snapshot: DraggableStateSnapshot) => {
  if (!snapshot.isDropAnimating) {
    return style;
  }
  const { moveTo, curve, duration } = snapshot.dropAnimation as DropAnimation;

  // move to the right spot
  const translate = `translate(${moveTo.x < 200 ? moveTo.x : 900}px, ${
    moveTo.y
  }px)`;
  // add a bit of turn for fun
  // const rotate = 'rotate(0.5turn)';

  // patching the existing style
  return {
    ...style,
    transform: `${translate}`,
    // slowing down the drop because we can
    transition: `all ${curve} ${duration + 0.5}s`,
  };
};

export const loadScript = (src: string) => {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};
