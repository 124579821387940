import Icon, { IconProps } from '@chakra-ui/icon';
import { OmitCommonProps, As } from '@chakra-ui/react';
import React from 'react';

const _ProjectIllustrationIcon = (
  props: JSX.IntrinsicAttributes &
    OmitCommonProps<React.SVGProps<SVGSVGElement>, keyof IconProps> &
    IconProps &
    OmitCommonProps<any, keyof IconProps> & { as?: As<any> | undefined }
) => (
  <Icon width={96} height={96} viewBox="0 0 96 96" focusable="false" {...props}>
    <defs>
      <path id="ew" d="M0 0h96v96H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="bas" fill="#fff">
        <use xlinkHref="#ew" />
      </mask>
      <use fill="#DE025E" xlinkHref="#ew" />
      <circle fill="#FB75AD" mask="url(#bas)" cx={48} cy={48} r={25} />
      <circle fill="#740232" mask="url(#bas)" cx={48} cy={48} r={16} />
    </g>
  </Icon>
);

export const ProjectIllustrationIcon = React.memo(_ProjectIllustrationIcon);
