import React, { useContext, useEffect, useRef, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import { RootState } from '../store';
import { updateChatScrollToBottom } from '../store/chat';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { updateProjectOnlineUserData } from '../store/socket';
import { socketBaseUrl } from '../utils/config';
import { AppDetailContext } from './AppDetailContext';
import {
  refreshConnectDetail,
  refreshConnectUnseenDetail,
} from '../store/notification';
import { useWindowFocus } from '../hooks/useWindowFocus';
import { batch } from 'react-redux';
import {
  updateConnectConversationThreadChatDataByMessageIdOnSocketNewMsgReceive,
  updateConnectWorkflowChatDataByMessageItemId,
  updateConversationDataOnSocketDeleteMsgReactionUpdate,
  updateConversationDataOnSocketNewMsgReactionUpdate,
  updateConversationDataOnSocketNewMsgReceive,
  updateConversationDataOnVideoCallEnded,
} from '../store/connectDetailConversation';
import { refreshConnectUnseenNotificationDetail } from '../store/connect';
import {
  updateConnectThreadOnReactionAddOrModify,
  updateConnectThreadOnSocketNewMsgReceive,
  updateConnectThreadOnWorkflowAddOrModify,
  updateConnectUnseenThreadNotification,
} from '../store/connectDetail';

export enum SocketConnectionStates {
  reconnected = 'RECONNECTED', // Fired upon a successful reconnection.
  reconnecting = 'RECONNECTING', // Fired upon an attempt to reconnect.
  reconnectionError = 'RECONNECTION_ERROR', // Fired upon a reconnection attempt error.
  reconnectFail = 'RECONNECT_FAIL', // Fired when couldn't reconnect
  connected = 'CONNECTED',
  disconnected = 'DISCONNECTED',
}

interface SocketContext {
  socket: Socket | undefined;
  socketState: SocketConnectionStates;
  isFocused: boolean;
}
interface Props {
  children: React.ReactNode;
}

export const AppSocketContext = React.createContext<SocketContext>({
  isFocused: true,
} as SocketContext);

const _AppSocketContextComponent = (props: Props) => {
  const { children } = props;
  const [connected, setConnected] = useState<boolean>(false);
  const [socketState, updateSocketState] = useState<SocketConnectionStates>(
    SocketConnectionStates.disconnected
  );

  const isFocused = useWindowFocus();

  // if (isFocused) {
  //   process.env.NODE_ENV === 'development' &&
  // }

  const { appDetail } = useContext(AppDetailContext);
  const dispatch = useAppDispatch();
  const path = window.location.pathname;
  const userToken = useAppSelector((state: RootState) => state.user.userToken);
  const socketRef = useRef<Socket>();
  const socketWorkspaces = useAppSelector(
    (state: RootState) => state.socket.workspaces
  );

  const socketProjects = useAppSelector(
    (state: RootState) => state.socket.projects
  );

  const selectedWorkspaceId = useAppSelector(
    (state: RootState) => state.user.selectedWorkspaceId
  );

  useEffect(() => {
    if (
      !connected &&
      userToken &&
      socketRef.current &&
      socketRef.current?.disconnected &&
      !path.includes('/login')
    ) {
      socketRef.current.connect();
    }
  }, [connected, path, userToken]);

  useEffect(() => {
    // connect to the socket io only when the user is authenticated and logged in
    if (
      !connected &&
      userToken &&
      userToken.length > 0 &&
      appDetail?.id &&
      appDetail.id.length > 0 &&
      !socketRef.current &&
      !path.includes('/login')
    ) {
      const socket: Socket = io(socketBaseUrl, {
        extraHeaders: {
          Authorization: userToken,
        },
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionAttempts: 5,
        forceNew: false,
        // transports: ['websocket'],
      });

      // setting the socket ref
      socketRef.current = socket;

      // updating the connecte state

      //Joining the rooms
      //Fired upon connection to the Namespace (including a successful reconnection).

      socket.on('connect', () => {
        setConnected(true);
        updateSocketState(SocketConnectionStates.connected);
        if (socketWorkspaces && socketWorkspaces.length) {
          socket.emit('join:all:workspace', socketWorkspaces);
        }
        if (socketProjects && socketProjects.length) {
          socket.emit('join:all:connect', socketProjects);
        }
        if (appDetail?.id) {
          socket.emit('join:self:room', appDetail.id);
        }
      });

      socket.on(
        'received:connect:message',
        (value: { data: { [key: string]: any }[]; connect_id: string }) => {
          value.data.forEach((el) => {
            batch(() => {
              dispatch(
                updateConversationDataOnSocketNewMsgReceive({
                  data: {
                    ...el,
                    isNew: el.sender_id === appDetail.id ? false : true,
                  },
                  connectId: el.connect_id,
                })
              );
              dispatch(updateChatScrollToBottom(true));
            });
          });

          // const orderedList = value.data.sort(
          //   (a, b) =>
          //     dayjs(b.created_at).valueOf() - dayjs(a.created_at).valueOf()
          // );
          // batch(() => {
          //   dispatch(updateChatScrollToBottom(true));
          //   dispatch(
          //     updateReadChatDataOnNewProjectIncomingMessage({
          //       projectId: value.connect_id,
          //       messageCount: value.data.length,
          //       messageId: orderedList[0]?.id,
          //     })
          //   );
          // });
        }
      );

      socket.on(
        'received:connect:message:reaction',
        (value: {
          data: { [key: string]: any };
          connect_id: string;
          message_id: string;
          workspace_id: string;
        }) => {
          dispatch(
            updateConversationDataOnSocketNewMsgReactionUpdate({
              data: value.data,
              connectId: value.connect_id,
              messageId: value.message_id,
            })
          );
          dispatch(
            updateConnectThreadOnReactionAddOrModify({
              workspaceId: value.workspace_id,
              connectId: value.connect_id,
              messageId: value.message_id,
            })
          );
        }
      );

      socket.on(
        'received:connect:message:reaction:remove',
        (value: {
          reaction_id: string;
          connect_id: string;
          message_id: string;
          sender_id: string;
          workspace_id: string;
        }) => {
          dispatch(
            updateConversationDataOnSocketDeleteMsgReactionUpdate({
              reactionId: value.reaction_id,
              connectId: value.connect_id,
              messageId: value.message_id,
              senderId: value.sender_id,
            })
          );
          dispatch(
            updateConnectThreadOnReactionAddOrModify({
              workspaceId: value.workspace_id,
              connectId: value.connect_id,
              messageId: value.message_id,
            })
          );
        }
      );

      socket.on(
        'received:connect:thread-message:reaction',
        (value: {
          reaction_id: string;
          connect_id: string;
          message_id: string;
          sender_id: string;
          workspace_id: string;
        }) => {
          dispatch(
            updateConnectThreadOnReactionAddOrModify({
              workspaceId: value.workspace_id,
              connectId: value.connect_id,
              messageId: value.message_id,
            })
          );
        }
      );

      socket.on(
        'received:connect:thread-message:reaction:remove',
        (value: {
          reaction_id: string;
          connect_id: string;
          message_id: string;
          sender_id: string;
          workspace_id: string;
        }) => {
          dispatch(
            updateConnectThreadOnReactionAddOrModify({
              workspaceId: value.workspace_id,
              connectId: value.connect_id,
              messageId: value.message_id,
            })
          );
        }
      );

      socket.on(
        'received:connect:thread-message',
        (value: { [key: string]: any }) => {
          batch(() => {
            dispatch(
              updateConnectConversationThreadChatDataByMessageIdOnSocketNewMsgReceive(
                {
                  data: {
                    ...value,
                    isNew: value.sender_id === appDetail.id ? false : true,
                  },
                  connectId: value.connect_id,
                  workspaceId: value.workspace_id,
                }
              )
            );

            dispatch(
              updateConnectThreadOnSocketNewMsgReceive({
                data: value,
                connectId: value.connect_id,
                workspaceId: value.workspace_id,
              })
            );

            // if (value.sender_id !== appDetail.id) {
            //   dispatch(
            //     updateThreadReadChatDataOnNewProjectIncomingMessage({
            //       projectId: value.project_id,
            //       messageId: value.message_id.id,
            //     })
            //   );
            // }
          });
        }
      );

      // Get the online users of a project
      socket.on(
        'workspace:online:users',
        (data: { projectId: string; users: string[] }) => {
          dispatch(updateProjectOnlineUserData(data));
        }
      );

      // Update the project detail
      socket.on(
        'received:connect:workflow:update',
        (data: {
          connect_id: string;
          message_item: { [key: string]: any };
          workspace_id: string;
          sender_id: string;
        }) => {
          if (data.sender_id !== appDetail?.id) {
            dispatch(
              updateConnectWorkflowChatDataByMessageItemId({
                connectId: data.connect_id,
                messageItemData: data.message_item,
                workspaceId: data.workspace_id,
              })
            );
          }

          dispatch(
            updateConnectThreadOnWorkflowAddOrModify({
              connectId: data.connect_id,
              messageItemData: data.message_item,
              workspaceId: data.workspace_id,
            })
          );
        }
      );

      socket.on(
        'received:connect:update',
        (data: { connect_id: string; workspace_id: string }) => {
          batch(() => {
            dispatch(
              refreshConnectDetail({
                connectId: data.connect_id,
                workspaceId: data.workspace_id,
              })
            );
          });
        }
      );

      socket.on(
        'received:connect-unseen-notification:update',
        (data: { workspace_id: string; connect_id: string }) => {
          batch(() => {
            dispatch(
              refreshConnectUnseenDetail({
                workspaceId: data.workspace_id,
                connectId: data.connect_id,
              })
            );

            dispatch(
              refreshConnectUnseenNotificationDetail({
                connectId: data.connect_id,
                workspaceId: data.workspace_id,
              })
            );
          });
        }
      );

      socket.on(
        'received:connect:thread-notification',
        (data: { connect_id: string; data: { [key: string]: string } }) => {
          console.log('received:connect:thread-notification', data);
          dispatch(
            updateConnectUnseenThreadNotification({
              connectId: data.connect_id,
              data: data.data,
            })
          );
        }
      );

      socket.on(
        'received:connect-video-call-end:notification',
        (data: {
          workspace_id: string;
          connect_id: string;
          video_call_item_metadata: { [key: string]: string };
        }) => {
          console.log('received:connect-video-call-end:notification', data);
          dispatch(
            updateConversationDataOnVideoCallEnded({
              connectId: data.connect_id,
              videoCallItemMetadata: data.video_call_item_metadata,
              workspaceId: data.workspace_id,
            })
          );
        }
      );

      socket.on('disconnect', () => {
        socket.removeAllListeners();
        updateSocketState(SocketConnectionStates.disconnected);
        socketRef.current = undefined;
        setConnected(false);
        userToken && userToken.length && socket.connect();
      });

      socket.on('reconnect', (attempt) => {
        updateSocketState(SocketConnectionStates.reconnected);
      });

      socket.on('reconnect_attempt', (attempt) => {
        updateSocketState(SocketConnectionStates.reconnecting);
      });

      socket.on('reconnect_error', (attempt) => {
        socket.removeAllListeners();
        setConnected(false);
        updateSocketState(SocketConnectionStates.reconnectionError);
      });

      socket.on('reconnect_failed', (attempt) => {
        socket.removeAllListeners();
        setConnected(false);

        updateSocketState(SocketConnectionStates.reconnectFail);
      });
    }
  }, [
    connected,
    dispatch,
    socketWorkspaces,
    socketProjects,
    userToken,
    path,
    appDetail.id,
  ]);

  useEffect(() => {
    if (connected && socketRef.current) {
      if (path.includes('/login')) {
        socketRef.current.removeAllListeners();
        socketRef.current.disconnect();
        socketRef.current = undefined;
        setConnected(false);
        updateSocketState(SocketConnectionStates.disconnected);
      }
    }
  }, [connected, path]);

  useEffect(() => {
    if (connected && socketRef.current) {
      if (socketWorkspaces && socketWorkspaces.length) {
        // alert(`JOined all rooms ${JSON.stringify(socketRooms)}`);
        socketRef.current.emit('join:all:workspace', socketWorkspaces);
      }
      if (socketProjects && socketProjects.length) {
        socketRef.current.emit('join:all:connect', socketProjects);
      }
      if (appDetail?.id) {
        socketRef.current.emit('join:self:room', appDetail.id);
      }
      if (
        appDetail?.id &&
        selectedWorkspaceId &&
        selectedWorkspaceId.length > 0
      ) {
        socketRef.current.emit('all-project:read:message-count', {
          workspaceId: selectedWorkspaceId,
          userId: appDetail.id,
        });

        socketRef.current.emit('all-project:read:dm-message-count', {
          workspaceId: selectedWorkspaceId,
          userId: appDetail.id,
        });
      }
    }
  }, [
    appDetail?.id,
    connected,
    socketWorkspaces,
    socketProjects,
    selectedWorkspaceId,
  ]);

  return (
    <AppSocketContext.Provider
      value={{
        socket: socketRef.current,
        socketState: socketState,
        isFocused: isFocused,
      }}
    >
      {children}
    </AppSocketContext.Provider>
  );
};

export const AppSocketContextComponent = React.memo(_AppSocketContextComponent);
