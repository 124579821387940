import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { queryClient } from '..';
import { ConnectMessageTypeEnum } from '../type/types';
import { groupChatMessage } from '../utils/chat';

// Define a type for the slice state

interface connectDetailConversationType {
  currentPath?: {
    path: string;
    params: { [key: string]: any };
  };
  conversationData: { [key: string]: any[] };
}

// Define the initial state using that type
const initialState: connectDetailConversationType = {
  conversationData: {},
};

export const connectDetailConversationSlice = createSlice({
  name: 'connectDetailConversation',

  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    updateConnectConversationDetailCurrentPath: (
      state,
      action: PayloadAction<{ path: string; params: { [key: string]: any } }>
    ) => {
      state.currentPath = {
        path: action.payload.path,
        params: action.payload.params,
      };
    },
    initializeConversationData: (
      state,
      action: PayloadAction<{
        data: any[];
        connectId: string;
      }>
    ) => {
      const initData = { ...state.conversationData } as {
        [key: string]: any[];
      };

      const finatChatata = [...action.payload.data];

      const map = {} as any;

      finatChatata.forEach((el: any) => {
        if (!map.hasOwnProperty(el.id)) {
          map[el.id] = el;
        }
      });
      const groupedChat = groupChatMessage(Object.values(map));
      initData[action.payload.connectId] = groupedChat;
      state.conversationData = initData;
    },
    updateConversationDataOnSocketNewMsgReceive: (
      state,
      action: PayloadAction<{
        data: any;
        connectId: string;
      }>
    ) => {
      const initData = { ...state.conversationData } as {
        [key: string]: any[];
      };
      let connectChatData = initData[action.payload.connectId];
      if (connectChatData) {
        connectChatData = [{ ...action.payload.data }, ...connectChatData];
      } else {
        connectChatData = [{ ...action.payload.data }];
      }
      initData[action.payload.connectId] = connectChatData;
      state.conversationData = initData;
    },
    updateConnectConversationThreadChatDataByMessageIdOnSocketNewMsgReceive: (
      state,
      action: PayloadAction<{
        data: any;
        connectId: string;
        workspaceId: string;
      }>
    ) => {
      const initData = { ...state.conversationData } as {
        [key: string]: any[];
      };
      let connectConversationData = initData[action.payload.connectId];
      if (connectConversationData) {
        const message = connectConversationData.filter(
          (el: any) => el.id === action.payload.data.message_id.id
        )[0];

        if (message) {
          const mesgThreadInitData = message.message_thread
            ? message.message_thread
            : [];

          const index = connectConversationData.findIndex((object) => {
            return object.id === action.payload.data.message_id.id;
          });

          message.message_thread = [action.payload.data, ...mesgThreadInitData];

          connectConversationData.splice(index, 1, message);
          state.conversationData = initData;

          // update the open thread notification reply messages

          // const selectedProjectThreads =
          //   state.selectedThreadNotificationMessageIds;

          // let ids = selectedProjectThreads ? [...selectedProjectThreads] : [];

          // const initialThreadNotificationMessageData:
          //   | CustomRQResponseType
          //   | undefined = queryClient.getQueryData([
          //   'project_thread_messages',
          //   action.payload.workspaceId,
          //   ids?.join(),
          // ]);

          // if (
          //   initialThreadNotificationMessageData &&
          //   initialThreadNotificationMessageData.data &&
          //   selectedProjectThreads &&
          //   Object.keys(selectedProjectThreads).length > 0
          // ) {
          //   ids &&
          //     ids.length > 0 &&
          //     queryClient.setQueryData(
          //       [
          //         'project_thread_messages',
          //         action.payload.workspaceId,
          //         ids?.join(),
          //       ],
          //       (oldData: any) => {
          //         const newData = { ...oldData };
          //         const { data: messageData } = newData;

          //         const filteredData = messageData.filter(
          //           (el: any) => el.id === message.id
          //         );

          //         const index = messageData.findIndex((object: any) => {
          //           return object.id === message.id;
          //         });

          //         if (filteredData && filteredData.length > 0) {
          //           const mesgThreadInitData = filteredData[0].message_thread
          //             ? filteredData[0].message_thread
          //             : [];

          //           const updatedData = {
          //             ...filteredData[0],
          //             message_thread: [
          //               ...mesgThreadInitData,
          //               action.payload.data,
          //             ],
          //           };
          //           messageData.splice(index, 1, updatedData);
          //         }
          //         return { ...newData, data: messageData };
          //       }
          //     );
          // }
        }
      }
    },
    updateConnectWorkflowChatDataByMessageItemId: (
      state,
      action: PayloadAction<{
        connectId: string;
        messageItemData: { [key: string]: any };
        workspaceId: string;
      }>
    ) => {
      const initData = { ...state.conversationData } as {
        [key: string]: any[];
      };
      let connectChatData = initData[action.payload.connectId];
      if (connectChatData && connectChatData.length > 0) {
        const message = connectChatData.filter(
          (el: any) =>
            !!(
              el &&
              el?.message_items &&
              el?.message_items?.id === action.payload.messageItemData?.id
            )
        )[0];
        const index = connectChatData.findIndex((object) => {
          return object.id === message.id;
        });
        message.message_items = action.payload.messageItemData;
        connectChatData.splice(index, 1, message);

        queryClient.invalidateQueries([
          'selected_message',
          action.payload.workspaceId,
          message.id,
        ]);

        state.conversationData = initData;
      }
    },
    updateConnectMessageAddWorkflowDataByMessageId: (
      state,
      action: PayloadAction<{
        data: any;
        connectId: string;
        messageId: string;
      }>
    ) => {
      const initData = { ...state.conversationData } as {
        [key: string]: any[];
      };
      let connectChatData = initData[action.payload.connectId];
      if (connectChatData) {
        const message = connectChatData.filter(
          (el: any) => el.id === action.payload.messageId
        )[0];

        const index = connectChatData.findIndex((object) => {
          return object.id === action.payload.messageId;
        });

        message.message_items['workflow'] = [action.payload.data];

        connectChatData.splice(index, 1, message);

        state.conversationData = initData;
      }
    },
    updateConversationDataOnSocketNewMsgReactionUpdate: (
      state,
      action: PayloadAction<{
        data: any;
        connectId: string;
        messageId: string;
      }>
    ) => {
      const initData = { ...state.conversationData } as {
        [key: string]: any[];
      };
      let connectChatData = initData[action.payload.connectId];
      if (connectChatData) {
        const message = connectChatData.filter(
          (el: any) => el.id === action.payload.messageId
        )[0];

        const index = connectChatData.findIndex((object) => {
          return object.id === action.payload.messageId;
        });

        message['message_reactions'] = action.payload.data;
        connectChatData.splice(index, 1, message);
        state.conversationData = initData;
      }
    },
    updateConversationDataOnSocketDeleteMsgReactionUpdate: (
      state,
      action: PayloadAction<{
        reactionId: any;
        connectId: string;
        messageId: string;
        senderId: string;
      }>
    ) => {
      const initData = { ...state.conversationData } as {
        [key: string]: any[];
      };
      let connectChatData = initData[action.payload.connectId];
      if (connectChatData) {
        const message = connectChatData.filter(
          (el: any) => el.id === action.payload.messageId
        )[0];

        const index = connectChatData.findIndex((object) => {
          return object.id === action.payload.messageId;
        });

        const initMessageReplies = message.message_reactions;
        const filteredReviews = initMessageReplies.filter(
          (el: any) => el.id !== action.payload.reactionId
        );
        const updatedReviewData = initMessageReplies.filter(
          (el: any) => el.id === action.payload.reactionId
        )[0];
        const allUpdatedData = [...filteredReviews];
        if (updatedReviewData) {
          const filteredUsers = updatedReviewData.users.filter(
            (el: any) => el.id !== action.payload.senderId
          );
          updatedReviewData.users = filteredUsers;
          if (filteredUsers && filteredUsers.length > 0) {
            allUpdatedData.push(updatedReviewData);
          }
        }
        message['message_reactions'] = [...allUpdatedData];
        connectChatData.splice(index, 1, message);
        state.conversationData = initData;
      }
    },

    updateConversationDataOnVideoCallEnded: (
      state,
      action: PayloadAction<{
        connectId: string;
        videoCallItemMetadata: { [key: string]: string };
        workspaceId: string;
      }>
    ) => {
      const initData = { ...state.conversationData } as {
        [key: string]: any[];
      };
      let connectChatData = initData[action.payload.connectId];
      if (connectChatData) {
        const message = connectChatData.filter(
          (el: any) =>
            el.type === ConnectMessageTypeEnum.messageWithVideoCallItem &&
            el?.message_items?.video_call_item_metadata?.id ===
              action.payload.videoCallItemMetadata.id
        )[0];

        const index = connectChatData.findIndex((object) => {
          return object.id === message.id;
        });

        const initmessageItem = message.message_items;
        initmessageItem['video_call_item_metadata'] =
          action.payload.videoCallItemMetadata;

        message.message_items = initmessageItem;
        connectChatData.splice(index, 1, message);
        state.conversationData = initData;
      }
    },
  },
});

export const {
  updateConnectConversationDetailCurrentPath,
  initializeConversationData,
  updateConversationDataOnSocketNewMsgReceive,
  updateConnectConversationThreadChatDataByMessageIdOnSocketNewMsgReceive,
  updateConnectWorkflowChatDataByMessageItemId,
  updateConnectMessageAddWorkflowDataByMessageId,
  updateConversationDataOnSocketNewMsgReactionUpdate,
  updateConversationDataOnSocketDeleteMsgReactionUpdate,
  updateConversationDataOnVideoCallEnded,
} = connectDetailConversationSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value;

export default connectDetailConversationSlice.reducer;
