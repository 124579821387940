import { useAbility } from '@casl/react';
import { Center, Text } from '@chakra-ui/react';
import React, { useContext, useEffect } from 'react';
import { batch } from 'react-redux';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import { Subjects } from '../authorization/Policies/AuthorizationPolicyManager';
import { AbilityContext } from '../Context/AppAbilityContext';
import { AppDetailContext } from '../Context/AppDetailContext';
import { updateConnectCurrentPath } from '../store/connect';
import { updateConnectDetailCurrentPath } from '../store/connectDetail';
import { updateConnectConversationDetailCurrentPath } from '../store/connectDetailConversation';
import { useAppDispatch } from '../store/hooks';
import { updateNavigationCurrentPath } from '../store/notification';
import { Mixpanel } from './mixpanel/handler';

export const ProtectedRoute = ({
  subject,
  children,
  path,
}: {
  subject: Subjects;
  children: React.ReactElement;
  path: string;
}) => {
  const auth = localStorage.getItem('p-auth');
  const ability = useAbility(AbilityContext);
  const dispatch = useAppDispatch();
  const params = useParams<{ [key: string]: string }>();
  const { isLoadingAppDetail } = useContext(AppDetailContext);

  useEffect(() => {
    process.env.NODE_ENV === 'production' &&
      Mixpanel.track(`PW : ${subject?.toString().toUpperCase()}`);

    const payload = {
      path: path,
      params: params,
    };

    batch(() => {
      dispatch(updateNavigationCurrentPath(payload));
      dispatch(updateConnectCurrentPath(payload));
      dispatch(updateConnectDetailCurrentPath(payload));
      dispatch(updateConnectConversationDetailCurrentPath(payload));

      dispatch(updateConnectCurrentPath(payload));
    });
  }, [dispatch, params, path, subject]);

  /*
    Steps 
    - Check if token is present or not 
    - If present, then redirect it to the requested page 
    - If not present, then check of the user app detail is loaded 
        - If user appdetail is not loaded then show the loading page 
        - If user app detail is loaded, then check for page access and show the pages accodingly
  */

  if (!auth) {
    return (
      <Navigate
        to={{
          pathname: '/login',
        }}
        // state={{ referrer: props.location }}
      />
    );
  } else {
    if (isLoadingAppDetail) {
      return <Outlet />;
    } else {
      if (subject && ability.can('navAccess', subject)) {
        return children ? children : <Outlet />;
      } else {
        return (
          <Center>
            <Text>Forbidden Resource</Text>
          </Center>
        );
      }
    }
  }
};
