import Icon, { IconProps } from '@chakra-ui/icon';
import { OmitCommonProps, As } from '@chakra-ui/react';
import React from 'react';

const _PdfFormatIcon = (
  props: JSX.IntrinsicAttributes &
    OmitCommonProps<React.SVGProps<SVGSVGElement>, keyof IconProps> &
    IconProps &
    OmitCommonProps<any, keyof IconProps> & { as?: As<any> | undefined }
) => (
  <Icon viewBox="0 0 31 35" width={31} height={35} focusable="false" {...props}>
    <g fillRule="nonzero" fill="none">
      <path
        d="M2.22 0h18.542l9.828 9.79v22.945a2.182 2.182 0 0 1-2.183 2.182H2.22a2.182 2.182 0 0 1-2.182-2.182V2.182C.037.977 1.015 0 2.22 0Z"
        fill="#E2574C"
      />
      <path
        d="M22.404 16.545c.365 0 .544-.318.544-.627 0-.32-.186-.629-.544-.629h-2.082c-.407 0-.634.337-.634.71v5.116c0 .456.26.71.611.71.35 0 .61-.254.61-.71v-1.404h1.26c.39 0 .585-.32.585-.637 0-.311-.195-.62-.586-.62H20.91v-1.909h1.495Zm-7.037-1.256h-1.523c-.414 0-.707.284-.707.705v5.125c0 .523.422.687.724.687h1.599c1.892 0 3.141-1.245 3.141-3.167-.001-2.031-1.177-3.35-3.234-3.35Zm.073 5.253h-.929v-3.989h.837c1.267 0 1.818.85 1.818 2.023 0 1.098-.541 1.966-1.726 1.966ZM9.86 15.29H8.35c-.426 0-.664.282-.664.71v5.116c0 .456.273.71.64.71.366 0 .639-.254.639-.71v-1.494h.946c1.168 0 2.131-.827 2.131-2.157 0-1.302-.93-2.175-2.182-2.175Zm-.025 3.132h-.87v-1.93h.87c.537 0 .878.419.878.965-.001.546-.341.965-.878.965Z"
        fill="#FFF"
      />
    </g>
  </Icon>
);

export const PdfFormatIcon = React.memo(_PdfFormatIcon);
